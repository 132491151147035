import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";

import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { countries } from "../../../utils/ListCountries";

const ModalEditBooker= ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
}) => {
  const formikRef = useRef();
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );

  const title = t("commons.edit") + " " + t("field.booker");
  const initial = {
    order_id: data?.id,
    bookers_title: data?.bookers_title,
    bookers_name: data?.bookers_name,
    bookers_nationality: data?.bookers_nationality,
    bookers_email: data?.bookers_email,
    bookers_phone: data?.bookers_country_code ? `${data?.bookers_country_code.replace("+", "")}${data?.bookers_phone}` : data?.bookers_phone,
    bookers_country_code: data?.bookers_country_code?.includes("+") ? data?.bookers_country_code.replace("+", "") : data?.bookers_country_code,
    bookers_country_id: data?.bookers_country_id
  }

  const validation = Yup.object().shape({
    bookers_name: Yup.string().required(`${t("field.name")} ${t("commons.required")}`).nullable(),
    bookers_nationality: Yup.string().required(`${t("field.nationality")} ${t("commons.required")}`).nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            if (values.title === "") {
              toastError(`${t("commons.empty_data_message")} ${t("field.title")}!`);
              return;
            }
            if (data?.bookers_title !== "Child") {
              if (values.email === "") {
                toastError(`${t("commons.empty_data_message")} Email!`);
                return;
              } else if (values.bookers_phone === "") {
                toastError(
                  `${t("commons.empty_data_message")} ${t("field.phone")}!`
                );
                return;
              }
            }
            setStatus();
            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Select2
                    name="bookers_title"
                    type="text"
                    errors={props.errors}
                    touched={props.touched}
                    options={[
                      { label: t("commons.mr"), value: "Mr." },
                      { label: t("commons.mrs"), value: "Mrs." },
                      { label: t("commons.ms"), value: "Ms." },
                      { label: t("commons.child"), value: "Child" },
                    ]}
                    value={props?.values?.bookers_title ?? ""}
                    onChange={(name, value) => {
                      props?.setFieldValue("bookers_title", value);
                    }}
                    onBlur={props?.setFieldTouched}
                    title={t("field.title")}
                  />
                  <Input
                    name="bookers_name"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.bookers_name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("bookers_name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                  <Select2
                    clearable={!!props.values?.bookers_nationality}
                    name="bookers_nationality"
                    errors={props.errors}
                    touched={props.touched}
                    title={t("field.nationality")}
                    options={dataCountries}
                    value={props.values?.bookers_nationality}
                    onChange={(name, value) => {
                      const index = dataCountries
                        ?.map(function (e) {
                          return e.value;
                        })
                        .indexOf(value);
                      props?.setFieldValue("bookers_nationality", value);
                      // props?.setFieldValue("countryCode", dataCountries[index]?.countryCode);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <Input
                    name="bookers_email"
                    type="email"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.bookers_email ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("bookers_email", value);
                    }}
                    title={t("field.email")}
                  />
                  <div className="form-group">
                    <div className="form-label">{t("field.phone")}</div>
                    <div className="input-wrapper w-100">
                      <PhoneInput
                        countryCodeEditable={false}
                        placeholder={t("field.placeHolderPhoneNumber")}
                        country={props?.values?.bookers_country_id ?? "id"}
                        inputStyle={{ width: '100%' }}
                        enableSearch={true}
                        value={props?.values?.bookers_phone}
                        onChange={(phone, country) => {
                          props?.setFieldValue("bookers_country_code", country?.dialCode);
                          props?.setFieldValue("bookers_phone", phone);
                          props?.setFieldValue("bookers_country_id", country?.countryCode);
                          // props?.setFieldValue("passenger_nationality", country?.name);
                        }}
                      />
                    </div>
                  </div>
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(ModalEditBooker);
