import { useEffect, useState } from "react";
import TooltipTaxBooking from "../tooltipTaxBooking";
import { withTrans } from "../../i18n/withTrans";
import { numberWithCommas } from "../../utils/Helper";

const SummaryCheckoutPrice = ({ t, category, keyTooltip, titlePrice, passengers, price, dataCheckout, isLounge  }) => {
  const [infoTax, setInfoTax] = useState({});

  useEffect(() => {
    if (dataCheckout) {
      setInfoTax({
        ...dataCheckout,
        isIncludePpn: isLounge
          ? dataCheckout.isIncludePpnLounge
          : dataCheckout.isIncludePpn,
        isIncludeConcession: isLounge
          ? dataCheckout.isIncludeConcessionLounge
          : dataCheckout.isIncludeConcession,
        ppnPercentage: isLounge
          ? dataCheckout.ppnPercentageLounge
          : dataCheckout.ppnPercentage,
        concessionPercentage: isLounge
          ? dataCheckout.concessionPercentageLounge
          : dataCheckout.concessionPercentage,
      })
    }
  }, []);

  return (
    <>
      {category && (
        <div className="subtitle-text text-medium text-primary mt-2 mb-1">
          {category}
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex align-items-center normal-text">
            {titlePrice ?? t("field.price")}
            {(infoTax?.isIncludePpn || infoTax?.isIncludeConcession) && (
              <TooltipTaxBooking
                id={keyTooltip}
                include_ppn={infoTax?.isIncludePpn}
                include_concession={infoTax?.isIncludeConcession}
                ppn_percentage={infoTax?.ppnPercentage}
                concession_percentage={infoTax?.concessionPercentage}
                ppn_price={infoTax?.ppnPrice}
                concession_price={infoTax?.concessionPrice}
                dataOtherDPP={infoTax?.dpp}
              />
            )}
          </div>
          {!infoTax?.isIncludePpn && (
            <div className="normal-text mt-1">
              {keyTooltip === "taxOthers" && infoTax?.dpp
                ? `PPN ${infoTax?.ppnPercentage}% x DPP Rp ${numberWithCommas(infoTax?.dpp)}`
                : `PPN ${infoTax?.ppnPercentage}%`}
            </div>
          )}
          {!infoTax?.isIncludeConcession && (
            <div className="normal-text mt-1">
              {keyTooltip === 'taxOthers' && infoTax.dpp
                ? `Concession ${infoTax?.concessionPercentage}% x DPP Rp ${numberWithCommas(infoTax?.dpp)}`
                : `Concession ${infoTax?.concessionPercentage}%`}
            </div>
          )}
        </div>
        <div className="text-right">
          <div className="normal-text">
            {passengers ? `${passengers} x Rp ${numberWithCommas(price)}` : `Rp ${numberWithCommas(price)}`}
          </div>
          {!infoTax?.isIncludePpn && (
            <div className="normal-text mt-1">
              {"Rp " + numberWithCommas(infoTax?.ppnPrice)}
            </div>
          )}
          {!infoTax?.isIncludeConcession && (
            <div className="normal-text mt-1">
              {"Rp " + numberWithCommas(infoTax?.concessionPrice)}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withTrans(SummaryCheckoutPrice);