import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import Table from "../../../components/commons/table";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import DetailButton from "../../../components/commons/table/detailButton";
import Loader from "../../../components/commons/Loader";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import MasterDataModal from "./MasterDataModal";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

// ACTION REDUX
import AirlinesService from "../../../store/actions/master_data/airline";
import { RoleGroups } from "../../../utils/Constants";

function Index({ t, pending, pending_post, pending_put }) {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataAirlines, setDataAirlines] = useState([]);
  const [dataAirlinesRow, setDataAirlinesRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const showEditModal = (data) => {
    setEditModal(!editModal);
    setDataAirlinesRow(data ? data : []);
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataAirlinesRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };

    if (param) {
      dispatch(AirlinesService.put(param, callback));
    }
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };

    dispatch(AirlinesService.post(param, callback));
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };

    if (dataAirlinesRow) {
      const id = dataAirlinesRow?.id;
      dispatch(AirlinesService.deleted(id, callback, dataAirlinesRow?.name));
    }
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("field.code").toUpperCase(),
      accessor: "code",
    },
    {
      Header: t("field.airline").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let name = original.name;
        name = name.length > 40 ? name.substring(0, 40) + "..." : name;
        return <>{name}</>;
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
            </MenuItem>
            <MenuItem onClick={() => showDeleteModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show: !RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code)
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
      };
      dispatch(AirlinesService.get(param, resolve));
    }).then((res) => {
      setDataAirlines(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText]);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("masterData.airlines")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
          style={{ marginRight: 20 }}
        />
        {!RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code) && (
          <>
            <div className="nav-separator mr-3" />
            <Button
              title={t("commons.add") + " " + t("masterData.airlines")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={showAddModal}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter(item => item.show !== false)}
            data={dataAirlines ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <MasterDataModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
        pending={pending_post}
      />

      <MasterDataModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={dataAirlinesRow}
        pending={pending_put}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        data={dataAirlinesRow}
        icon="info"
        param={
          dataAirlinesRow?.name?.length > 40
            ? dataAirlinesRow?.name?.substring(0, 40) + "..."
            : dataAirlinesRow?.name
        }
        isDelete
      />
    </>
  );
}

const mapStateToProps = ({ airline: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
