import React, { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans"
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Card from "../../components/commons/cards/card";
import CardFlush from "../../components/commons/cards/cardFlush";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import { toRupiah } from "../../utils/Helper";

const ModalDetail = ({ t, show, toggle, action, data, pending }) => {
    const handleEdit = () => {
        toggle();
        action();
    }

    return (
        <Modal
            isOpen={show}
            modalTransition={{ timeout: 700 }}
            backdropTransition={{ timeout: 1300 }}
        >
            <ModalHeader className="text-extra-bold pt-5">
                {" "}
                Detail{" "}
                <span className="close clickable" onClick={() => toggle()}>
                    &times;
                </span>
            </ModalHeader>
            <ModalBody>
                <div style={{ padding: "0px 15px 20px 10px" }}>
                    <DynamicButton
                        titleLeft={
                            data?.type === "Group" 
                                ? t("masterData.groupInfo") 
                            : data?.type === "Other" 
                                ? t("masterData.otherInfo") 
                            : t("masterData.individualInfo")} 
                            iconLeft="info" 
                    />
                    <Card className="rounded">
                        <CardFlush
                            list
                            label={t("field.year")}
                            desc={data?.year}
                        />
                        <CardFlush
                            list
                            label={t("commons.type")}
                            desc={
                                data?.type === 'Individual' 
                                    ? 'Individual' 
                                    : data?.type === 'Group' 
                                    ? 'Group (Charter, Hajj, Umrah)'
                                    : `Group ${t('masterData.others')}`
                            }
                        />
                        <CardFlush
                            list
                            label={t("field.price")}
                            desc={toRupiah(data?.price ?? 0)}
                            last
                        />
                    </Card>
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonModal
                    toggle={toggle}
                    confirm={() => handleEdit()}
                    confirmTitle={t("commons.edit")}
                    disabledConfirm={pending}
                />
            </ModalFooter>
        </Modal>
    )

}

export default withTrans(ModalDetail)