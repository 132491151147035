import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import moment from "moment";
import Select from "react-select";

import SearchButton from "../../../components/commons/buttons/SearchButton";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DatePicker from "../../../components/forms/DatePicker";
import TabelPareto from "./TabelPareto";
import StatistikPareto from "./StatistikPareto";
import { reactSelectCustomStyles } from "../../../utils/Constants";
import { withTrans } from "../../../i18n/withTrans";

const Index = ({ t, airport_list }) => {
  const [activeTab, setActiveTab] = useState("statistik");
  const [searchText, setSearchText] = useState("");
  const [airportId, setAirportId] = useState("");
  const [filterYear, setFilterYear] = useState(moment().format("yyyy"));
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth() + 1);
  const [monthYearPicker, setMonthYearPicker] = useState(new Date());

  return (
    <>
      <HeaderMenu title="Pareto">
        <div className="row mr-3">
          <div className="tab-menu overflow">
            <div style={{ width: "fit-content" }}>
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                value={activeTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  setActiveTab(newValue);
                }}
              >
                <Tab
                  label={t("dataAnalytics.statistics")}
                  value="statistik"
                />
                <Tab
                  label={t("field.table")}
                  value="tabel"
                />
              </Tabs>
            </div>
          </div>
        </div>
      </HeaderMenu>
      <div style={{
          maxWidth: '63vw',
          padding: '0 40px',
        }}
      >
        <div className="overflow">
          <div className="d-flex justify-content-end">
            {activeTab === 'tabel' && (
              <SearchButton
                placeholder={t("commons.enterKeyword")}
                toggle={(value) => setSearchText(value.length > 0 ? value : "")}
              />
            )}
            <Select
              classNamePrefix="select"
              className="basic-single mx-3"
              isSearchable
              name="airport_id"
              styles={reactSelectCustomStyles}
              options={[
                {
                  value: "",
                  label: `${t("commons.all")} ${t("field.airport")}`,
                },
                ...airport_list,
              ]}
              value={
                airport_list?.filter((obj) => {
                  return obj?.value === airportId;
                })[0] ?? {
                  label: `${t("commons.all")} ${t("field.airport")}`,
                  value: "",
                }
              }
              onChange={(item) => {
                setAirportId(item.value);
              }}
            />
            <DatePicker
              isLocale
              useCustomInput
              showMonthYearPicker
              maxDate={new Date()}
              value={monthYearPicker}
              onChange={(date) => {
                const value = date ?? new Date()
                setMonthYearPicker(value);
                setFilterMonth(new Date(value).getMonth() + 1);
                setFilterYear(moment(value).format("yyyy"));
              }}
            />
          </div>
          <br />
          {activeTab === 'tabel' && (
            <TabelPareto searchText={searchText} airport_id={airportId} month={filterMonth} year={filterYear} />
          )}
          {activeTab === 'statistik' && (
            <StatistikPareto airport_id={airportId} month={filterMonth} year={filterYear} />
          )}
        </div>
      </div>
    </>
  );
};

export default withTrans(Index);
