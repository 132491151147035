
const MenuItem = ({ title, child, icon, style, ...otherProps }) => {
  return (
    <div className="sidebar-container">
      <div className="nav-item m-0 sidebar-item" {...otherProps}>
        <i className="material-icons-round sidebar-icon">{icon}</i>
        <span className={`title text-medium hover-show`}>{title}</span>
      </div>
    </div>
  );
};

export default MenuItem;
