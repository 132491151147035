import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, Container } from "reactstrap";
import { Divider } from "@mui/material";
import moment from "moment";

import { withTrans } from "../../../../i18n/withTrans";
import { numberWithCommas } from "../../../../utils/Helper";
import Button from "../../../../components/commons/buttons/Button";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import SearchButton from "../../../../components/commons/buttons/SearchButton";
import Promo from "../../../../store/actions/promo";

const VoucherModal = ({ formik, show, toggle, cancel, t }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  const [voucher, setVoucher] = useState([]);
  const [voucherCrossSelling, setVoucherCrossSelling] = useState([]);
  const [appliedVoucher, setAppliedVoucher] = useState(false);
  const [appliedCrossSelling, setAppliedCrossSelling] = useState(false);
  function differents(item) {
    var start_date = moment()?.format("YYYY-MM-DD");
    var end_date = moment(item?.period_end);
    let period = moment.duration(end_date.diff(start_date)).asDays();
    return period;
  }
  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        length: 99999,
        search_text: searchText,
        airport_id:
          formik?.values?.flight_type === 0
            ? formik?.values?.airport_id_to
            : formik.values?.flight_type === 2
            ? formik.values?.airport_id_transfer
            : formik?.values?.airport_id_from,
        valid: true,
        service_date: formik?.values?.date,
        product_id: formik?.values?.product_id,
        is_cross_selling: formik?.values?.is_cross_selling == 1 ? true : false,
      };
      if (!formik?.values?.product_id) return;
      dispatch(Promo.get(param, resolve));
    }).then((res) => {
      setVoucher(
        res?.data?.filter((item) => item?.type !== 'cross-selling')
      );
      setVoucherCrossSelling(
        res?.data?.filter((item) => item?.type === 'cross-selling')
      );
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [searchText]);

  useEffect(() => {
    setAppliedCrossSelling(formik?.values?.applied_cross_selling);
  }, [formik?.values?.applied_cross_selling]);

  useEffect(() => {
    setAppliedVoucher(formik?.values?.applied_promo);
  }, [formik?.values?.applied_promo]);

  const handleAddVoucher = (item) => {
    if (item !== null && item !== undefined){
      formik.setFieldValue("promo_id", item?.id);
      formik.setFieldValue("promo_name", item?.name);
      formik.setFieldValue("promo_type", item?.type);
      formik.setFieldValue("promo_percent", item?.discount_percent);
      formik.setFieldValue("promo_price", item?.discount_price);
      if (item?.type === 'bundling') formik.setFieldValue("bundling_name", item?.bundling_name);
      if (formik?.values?.is_cross_selling != 1) toggle();
    } else {
      formik.setFieldValue("promo_id", null);
      formik.setFieldValue("promo_name", null);
      formik.setFieldValue("promo_type", null);
      formik.setFieldValue("promo_percent", null);
      formik.setFieldValue("promo_price", null);
      formik.setFieldValue("discount", null);
    }
  };

  const handleAddCrossSelling = (item) => {
    if (item !== null){
      formik.setFieldValue("promo_id_cross_selling", item?.id);
      formik.setFieldValue("promo_name_cross_selling", item?.name);
      formik.setFieldValue("promo_percent_cross_selling", item?.discount_percent);
    } else {
      formik.setFieldValue("promo_id_cross_selling", null);
      formik.setFieldValue("promo_name_cross_selling", null);
      formik.setFieldValue("promo_percent_cross_selling", null);
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {"Voucher"}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container className="pb-30">
            <SearchButton
              placeholder={t("commons.enterPromoName")}
              toggle={(value) => handleSearch(value)}
              showStyle={true}
              width={{ width: "400px" }}
            />
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "scroll",
                overflowX: "clip",
              }}
              className="mt-4"
            >
              {voucherCrossSelling?.length > 0 && (
                <div className="mb-4">
                  <h5 className="title text-primary">
                    {t("field.cross_selling")}
                  </h5>
                  {voucherCrossSelling?.map((item, index) => (
                    <div className="my-3">
                      <div className="d-flex justify-content-between">
                        <div className="title-voucher">
                          <div className="text-extra-bold pb-1">{item.name}</div>
                          <div className="normal-title">
                            <span>
                              {t("promo.airportCrossSelling")}:
                            </span>
                            <span className="text-primary">
                              {" "}{item?.discount_percent}%
                            </span>
                            <span
                              style={{
                                borderRight: "1px solid #A9A9A9",
                                height: 20,
                                marginLeft: 8,
                                marginRight: 8,
                              }}
                            ></span>
                            <span>
                              {t("promo.airportService")}:
                            </span>
                            <span className="text-primary">
                              {" "}{parseInt(100 - item?.discount_percent)}%
                            </span>
                          </div>
                        </div>
                        <div className="button-voucher">
                          <Button
                            title={
                              appliedCrossSelling === item
                                ? t("field.applied") 
                                : t("field.apply")
                            }
                            rounded={true}
                            variant={
                              appliedCrossSelling === item
                                ? "secondary" 
                                : "primary"
                            }
                            confirmTitle={t("commons.save")}
                            onClick={() => {
                              setAppliedCrossSelling(appliedCrossSelling !== item ? item : null);
                              formik?.setFieldValue(
                                "applied_cross_selling",
                                appliedCrossSelling !== item ? item : null
                              );
                            }}
                            icon
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Divider orientation="horizontal" />
                </div>
              )}
              {voucher?.length > 0 && (
                <>
                  <h5 className="title text-primary">
                    Promo
                  </h5>
                  {voucher?.map((item, index) => (
                    <div className="my-3">
                      <div className="d-flex justify-content-between">
                        <div className="title-voucher">
                          <div className="text-extra-bold pb-1">
                            {item?.type !== 'bundling'
                                ? `${t("commons.discount")} ${item?.discount_price !== 0 
                                    ? 'Rp' + numberWithCommas(`${item?.discount_price}`) 
                                    : item.discount_percent + '%'} ${item.name}`
                                : item.name
                            }
                          </div>
                          <div className="normal-title">
                            <span className="text-primary">
                              {item?.type === "blast" 
                                ? item?.type_label 
                                : item?.type === 'bundling'
                                ? 'Bundling'
                                : t("promo.special")
                              }
                            </span>
                            <span
                              style={{
                                borderRight: "1px solid #A9A9A9",
                                height: 20,
                                marginLeft: 8,
                                marginRight: 8,
                              }}
                            ></span>
                            <span>
                              {moment(item?.period_end).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")
                                ? `${t("field.expired")} ${differents(item)} ${t("field.day")}`
                                : `${t("field.expired")} ${t("field.today")}`}
                            </span>
                          </div>
                        </div>
                        <div className="button-voucher">
                          {formik?.values?.is_cross_selling ? (
                            <Button
                              title={appliedVoucher === item ? t("field.applied") : t("field.apply")}
                              rounded={true}
                              variant={appliedVoucher === item ? "secondary" : "primary"}
                              confirmTitle={t("commons.save")}
                              onClick={() => {
                                setAppliedVoucher(appliedVoucher !== item ? item : null);
                                formik?.setFieldValue(
                                  "applied_promo",
                                  appliedVoucher !== item ? item : null
                                );
                              }}
                              icon
                            />
                          ): (
                            <Button
                              title={t("field.apply")}
                              rounded={true}
                              variant="primary"
                              confirmTitle={t("commons.save")}
                              onClick={() => handleAddVoucher(item)}
                              icon
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <Divider orientation="horizontal" />
                </>
              )}
              {!voucherCrossSelling?.length && !voucher?.length && (
                <div className="text-center">{t("commons.dataNotFound")}</div>
              )}
              {formik?.values?.is_cross_selling && (
                <div style={{ marginTop: 50 }}>
                  <ButtonModal
                    confirmTitle={t("commons.save")}
                    confirm={() => {
                      handleAddCrossSelling(appliedCrossSelling);
                      handleAddVoucher(appliedVoucher);
                      toggle();
                    }}
                    toggle={() => {
                      setAppliedVoucher(null);
                      setAppliedCrossSelling(null);
                      cancel(formik);
                      toggle();
                    }}
                  />
                </div>
              )}
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(VoucherModal);
