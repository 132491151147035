import { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import { withTrans } from "../../../i18n/withTrans";
import { Role, RoleGroups } from "../../../utils/Constants";
import { getLengthPassAdult, getLengthPassChild, getLengthPassInfant, moreThanInfantAge } from "../../../utils/Helper";
import { toastError } from "../../../components/commons/toast";
import Table from "../../../components/commons/table";
import ModalEditPassenger from "./modal/ModalEditPassenger";
import BookingService from "../../../store/actions/booking";
import moment from "moment";

const DetailPassenger = ({ t, match, currentUser, dataDetail, handleRefresh }) => {
  const dispatch = useDispatch()
  const { role_code, airport_id } =  currentUser.user;
  const isCanEdit =
    airport_id !== dataDetail?.airport_id_cross_selling && RoleGroups.subAdminRoles.includes(role_code);

  const [editPassengerModal, setEditPassengerModal] = useState(false);
  const [dataPassengerRow, setDataPassengerRow] = useState(null);

  const showEditPassengerModal = (data) => {
    setEditPassengerModal(!editPassengerModal);
    if (data !== null) {
      setDataPassengerRow(data);
    } else {
      setDataPassengerRow(null);
    }
  };

  const handleEditPassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (param.title === "Infant") {
      if(
        param.passenger_date_of_birth === "" ||
        param.passenger_date_of_birth === undefined
      ) {
        toastError(`${t("commons.empty_data_message")} ${t("field.birth_date")}!`);
        return;
      } else {
        if(moreThanInfantAge(param.passenger_date_of_birth)) {
          toastError(`${t("commons.birth_date_less_than_message")}!`);
          return;
        }
      }
    }
    if(param.passenger_nationality === "") {
      toastError(`${t("commons.empty_data_message")} ${t("field.nationality")}!`);
      return;
    }
    if (param.name === "") {
      toastError(
        `${t("commons.empty_data_message")} ${t("field.name")}!`
      );
      return;
    }
    if (
      param?.email &&
      !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        param?.email
      ))
    ) {
      toastError(`${t("commons.wrong_email_format")}!`);
      return;
    }
    if (
      param?.title !== "Infant" &&
      param?.title !== "Child"
    ) {
      if (param.email === "") {
        toastError(`${t("commons.empty_data_message")} Email!`);
        return;
      } else if (
        param.phoneNumber === "" ||
        param.phoneNumber.includes("undefined") ||
        param.phoneNumber === param.countryCode
      ) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.phone")}!`
        );
        return;
      }
    }
    
    if (
      param.phoneNumber !== "" &&
      param.phoneNumber !== undefined &&
      param.phoneNumber !== null &&
      !param.phoneNumber.includes("undefined") &&
      param.phoneNumber !== param.countryCode
    ) {
      const phone = param.phoneNumber
        .replace("+", "")
        .replace(param?.countryCode, "");

      param.phoneNumber = 
        phone.charAt(0) == 0
          ? phone?.substring(1)
          : phone;
      param.countryCode = `+${param.countryCode}`;
    } else {
      param.phoneNumber = ""
      param.countryCode = ""
    }

    if (param?.id) {
      dispatch(BookingService.editActualPax(param, callback));
    } else {
      dispatch(BookingService.addActualPax({order_id : match?.params?.id, ...param}, callback));
    }
  };

  const handleDeletePassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (param) {
      dispatch(BookingService.delActualPax({ id: param?.id }, callback));
    }
  };

  const columnPassengers = [
    {
      Header: "No",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
      width: 10,
    },
    {
      Header: t("field.passenger"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div
            className="d-flex justify-content-end"
            onClick={() => showEditPassengerModal(original)}
          >
            <span className="material-icons-round right-icon">
              navigate_next
            </span>
          </div>
        );
      },
      show: isCanEdit,
    },
  ];

  return (
    <>
    <div className="wrapper-detail-card">
      <div
        className="text-extra-bold"
        style={{ padding: 15, opacity: 0.5 }}
      >
        <Row>
          <Col md={3}>{t("field.passenger")}</Col>
          <Col md={9}>
            <div className="text-medium d-flex justify-content-end">
              <div className="text-medium">
                {dataDetail?.list_order_passengers?.length
                  ? dataDetail?.list_order_passengers?.find((item) => item?.email)?.email
                  : dataDetail?.customer_email}
              </div>
              <div className="vertical-divider" />
              <div className="text-medium">
                {dataDetail?.list_order_passengers?.length
                  ? `${
                    dataDetail?.list_order_passengers?.find((item) => item?.phone_number)?.phone_number &&
                    dataDetail?.list_order_passengers?.find((item) => item?.country_code)?.country_code
                      ? `${dataDetail?.list_order_passengers?.find((item) => item?.country_code)?.country_code}${
                        dataDetail?.list_order_passengers?.find((item) => item?.phone_number)?.phone_number}`
                      : dataDetail?.list_order_passengers?.find((item) => item?.phone_number)?.phone_number
                    }`
                  : dataDetail?.customer_handphone}
              </div>
              <div
                className="ml-3 p-1 border rounded material-icons"
                style={{ marginTop: -5 }}
              >
                call
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Table
        columns={columnPassengers.filter((item) => item.show !== false)}
        data={dataDetail?.list_order_passengers ?? []}
        minHeight="100%"
      />
      <Divider orientation="horizontal" />
      <div
        className="d-flex align-items-center mt-4"
        style={{ paddingLeft: 15, paddingRight: 15 }}>
        <p
          className="normal-title text-extra-bold"
          style={{ opacity: 0.5 }}
        >
          Total PAX
        </p>
        <div className="ml-auto">
          <p
            className="normal-title text-bold"
          >
            {`${getLengthPassAdult(dataDetail?.list_order_passengers)} ${t("commons.adult")}, ${
              getLengthPassChild(dataDetail?.list_order_passengers)} ${t("commons.child")}, ${
              getLengthPassInfant(dataDetail?.list_order_passengers)} ${t("commons.infantLabel")}`}
          </p>
        </div>
      </div>
      <Divider orientation="horizontal" />
      {isCanEdit && (
          <div
            className="d-flex justify-content-start p-3"
            onClick={() => showEditPassengerModal(null)}
          >
            <div className="left-icon float-left rounded-fill" style={{ padding: "5px 8px" }}>
              <span
                className="material-icons clickable"
                style={{ fontSize: 25, color: "#63ae5c" }}
              >
                person
              </span>
            </div>
            <div
              className="clickable normal-title text-extra-bold pt-3 pl-3"
              style={{ color: "#63ae5c" }}
            >
              {t("field.addPassenger")}
            </div>
          </div>
        )}
    </div>
    <div className="wrapper-detail-card">
      <div
        className="ml-3 text-extra-bold"
        style={{ padding: 15, opacity: 0.5 }}
      >
        <Row>
          <Col md={3}>{t("field.booker")}</Col>
        </Row>
      </div>
      <Divider orientation="horizontal" />
      <Row className="py-3"
        style={{ fontSize: 14, padding: "15px 25px" }}>
        <Col md={3} className="text-extra-bold"
          style={{ color: "#000" }}>
            {t("field.booker")}
        </Col>
        <Col md={3} className="text-extra-bold"
          style={{ color: "#000" }}>
            {t("field.nationality")}
        </Col>
        <Col md={3} className="text-extra-bold"
          style={{ color: "#000" }}>
            {t("field.email")}
        </Col>
        <Col md={3} className="text-extra-bold"
          style={{ color: "#000" }}>
            {t("field.phone")}
        </Col>
      </Row>
      <Divider orientation="horizontal" />
      <Row className="py-3"
        style={{ fontSize: 14, padding: "15px 25px" }}>
        <Col md={3}>
          {dataDetail?.bookers_name ?? ""}
        </Col>
        <Col md={3}>
          {dataDetail?.bookers_nationality ?? ""}
        </Col>
        <Col md={3}>
          {dataDetail?.bookers_email ?? ""}
        </Col>
        <Col md={3}>
          {dataDetail?.bookers_phone && dataDetail?.bookers_country_code
            ? `${dataDetail?.bookers_country_code}${dataDetail?.bookers_phone}` 
            : dataDetail?.bookers_phone && !dataDetail?.bookers_country_code
            ? dataDetail?.bookers_phone
            : ""}
        </Col>
      </Row>
    </div>

    <ModalEditPassenger
      reset={handleDeletePassenger}
      show={editPassengerModal}
      toggle={() => showEditPassengerModal(null)}
      action={handleEditPassenger}
      data={dataPassengerRow}
      listPassengersLength={dataDetail?.list_order_passengers?.length}
    />
    </>
  )
}

export default withTrans(DetailPassenger)