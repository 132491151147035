import dashboards from "../../../views/dashboard/Dashboard";
import {
  GET_DASHBOARD_PENDING,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_STATUS_PENDING,
  GET_DASHBOARD_STATUS_SUCCESS,
  GET_DASHBOARD_STATUS_ERROR,
  GET_DASHBOARD_CHART_PENDING,
  GET_DASHBOARD_CHART_SUCCESS,
  GET_DASHBOARD_CHART_ERROR,
  GET_DASHBOARD_TABLE_PENDING,
  GET_DASHBOARD_TABLE_SUCCESS,
  GET_DASHBOARD_TABLE_ERROR,
  POST_DASHBOARD_PENDING,
  POST_DASHBOARD_SUCCESS,
  POST_DASHBOARD_ERROR,
  PUT_DASHBOARD_PENDING,
  PUT_DASHBOARD_SUCCESS,
  PUT_DASHBOARD_ERROR,
  DELETE_DASHBOARD_PENDING,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_ERROR,
} from "../../actions/dashboard";

const initialState = {
  pending: false,
  pending_chart: false,
  error: null,
  data_list: null,
  data_chart: null,
  pending_post: false,
  data_post: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_DASHBOARD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DASHBOARD_STATUS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_DASHBOARD_STATUS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_DASHBOARD_CHART_PENDING:
      return {
        ...state,
        pending_chart: true,
      };
    case GET_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        pending_chart: false,
        data_chart: action.data,
      };
    case GET_DASHBOARD_CHART_ERROR:
      return {
        ...state,
        pending_chart: false,
        error: action.error,
      };
    case GET_DASHBOARD_TABLE_PENDING:
      return {
        ...state,
        pending_chart: true,
      };
    case GET_DASHBOARD_TABLE_SUCCESS:
      return {
        ...state,
        pending_chart: false,
        data_chart: action.data,
      };
    case GET_DASHBOARD_TABLE_ERROR:
      return {
        ...state,
        pending_chart: false,
        error: action.error,
      };
    case POST_DASHBOARD_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_DASHBOARD_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case PUT_DASHBOARD_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_DASHBOARD_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DELETE_DASHBOARD_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_DASHBOARD_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default dashboard;
