import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import CardFlush from "../../components/commons/cards/cardFlush";
import SideMenu from "../../components/commons/modal/SideModal";
import ModalImage from "./ModalImage";
// import ModalUsage from "./ModalUsage";
import { withTrans } from "../../i18n/withTrans";

//Service
import RewardService from "../../store/actions/reward";

const DetailModal = ({
  t,
  show,
  toggle,
  id,
  detailPromo,
}) => {
  const dispatch = useDispatch();
  const arrayAirport = [];
  
  const [data, setData] = useState(null);
  const [airportList, setAirportList] = useState([]);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalUsage, setShowModalUsage] = useState(false);

  const handleModalImage = () => {
    setShowModalImage(true);
  };

  const handleModalUsage = () => {
    setShowModalUsage(true);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        id: id
      };
      dispatch(RewardService.get(param, resolve));
    }).then((res) => {
      setData(res?.data[0]);
      setAirportList(
        res?.data[0]?.list_airports_detail?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.name}`,
          name: `${airport?.name}`,
        }))
      );
    });
  }, [])

  return (
    <>
      <Modal isOpen={show}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {`Detail`}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Container className="px-3">
          <ModalBody className="px-4">
            <p className="h5 text-extra-bold">{data?.name}</p>
            <div className="wrapper-detail-card">
              {
                (detailPromo = [
                  {
                    label: t("promo.startDate"),
                    desc: moment(data?.period_start).format("DD/MM/YYYY"),
                  },
                  {
                    label: t("promo.endDate"),
                    desc: moment(data?.period_end).format("DD/MM/YYYY"),
                  },
                  {
                    label: t("promo.promoCode"),
                    desc: data?.code,
                  },
                  {
                    label: t("promo.promoValue"),
                    desc: data?.discount_price > 0
                      ? "Rp " + numberWithCommas(data?.discount_price)
                      : data?.discount_percent + "%",
                  },
                  {
                    label: t("promo.minimumPoint"),
                    desc: data?.point,
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    list
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                    last={index === 6}
                  />
                )))
              }
            </div>
            <div className="wrapper-detail-card">
              {data?.list_airports_detail
                ? data?.list_airports_detail?.map((item, index) => {
                  const idx = airportList
                    ?.map(function (e) {
                      return e.value;
                    })
                    .indexOf(item?.id);
                  if (idx > -1) {
                    arrayAirport.push(airportList[idx]);
                  }
                })
                : ""}
              {
                (detailPromo = [
                  {
                    label: t("field.airport"),
                    desc: `${arrayAirport.label}` ?? " ",
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    multipleList={arrayAirport}
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                  />
                )))
              }
            </div>
            <div className="wrapper-detail-card">
              {
                (detailPromo = [
                  {
                    label: t("promo.description"),
                    desc: data?.description,
                  },
                ].map((detailPromo, index) => (
                  <CardFlush
                    descList
                    key={index}
                    label={detailPromo.label}
                    desc={detailPromo.desc}
                  />
                )))
              }
            </div>
            <SideMenu
              title={t("promo.showBanner")}
              icon="image"
              toggle={() => handleModalImage()}
            />
            {/* <SideMenu
              title={t("promo.showUsage")}
              icon="local_offer"
              toggle={() => handleModalUsage()}
            /> */}
          </ModalBody>
        </Container>
      </Modal>
      <ModalImage
        show={showModalImage}
        toggle={() => setShowModalImage(false)}
        image={
          data?.banner !== null
            ? process.env.REACT_APP_API_URL + data?.banner?.substr(1, data?.banner?.length)
            : null
        }
      />
      {/* <ModalUsage
        show={showModalUsage}
        toggle={() => setShowModalUsage(false)}
        data={data?.list_orders}
        title={t("promo.usage")}
      /> */}
    </>
  );
};

export default withTrans(DetailModal);
