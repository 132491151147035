import { useState, useEffect } from "react";
import { Container, Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ReactTooltip from "react-tooltip";
import { Row, } from "reactstrap";
import { withTrans } from "../../../../i18n/withTrans";
import { flightTypeList, flightTypeTransferList } from "../../../../utils/Constants";
import HeaderMenu from "../../../../components/commons/menu/HeaderMenu";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import Card from "../../../../components/commons/cards/card";
import CardHeader from "../../../../components/commons/cards/cardHeader";
import CardBody from "../../../../components/commons/cards/cardBody";
import Input from "../../../../components/forms/Input";
import InputPrepend from "../../../../components/forms/InputPrepend";
import Select2Multi from "../../../../components/forms/Select2Multi";
import Checkbox from "../../../../components/forms/Checkbox";
import GroupPriceModal from "./GroupPriceModal";

const DetailPriceRegular = ({ formik, toggle, index, indexCancel, t }) => {
  const [activeTab, setActiveTab] = useState("0");
  const [indexGroupPrice, setIndexGroupPrice] = useState();
  const [modalGroupPrice, setModalGroupPrice] = useState(false);

  let airportsTip = formik?.values?.airports
    ? [...formik?.values?.airports]
    : [];

  if (airportsTip?.length > 1) airportsTip?.shift();

  const ShowTab = (active, props) => {
    let name, value, checked, nameChecked, labelChecked;

    if (active === "0") {
      name = `period_list[${index}].meet_and_greet_dom`;
      value = props?.values?.period_list[index]?.meet_and_greet_dom;
      nameChecked = `period_list[${index}].is_checked_meet_dom`;
      checked = props?.values?.period_list[index]?.is_checked_meet_dom;
      labelChecked = `${t("product.activate_meet")}`;
    } else if (active === "1") {
      name = `period_list[${index}].meet_and_greet_inter`;
      value = props?.values?.period_list[index]?.meet_and_greet_inter;
      nameChecked = `period_list[${index}].is_checked_meet_inter`;
      checked = props?.values?.period_list[index]?.is_checked_meet_inter;
      labelChecked = `${t("product.activate_meet_inter")}`;
    } else if (active === "2") {
      name = `period_list[${index}].fast_track`;
      value = props?.values?.period_list[index]?.fast_track;
      nameChecked = `period_list[${index}].is_checked_fast`;
      checked = props?.values?.period_list[index]?.is_checked_fast;
      labelChecked = `${t("product.activate_fast")}`;
    } else if (active === "3") {
      name = `period_list[${index}].transfer`;
      value = props?.values?.period_list[index]?.transfer;
      nameChecked = `period_list[${index}].is_checked_transfer`;
      checked = props?.values?.period_list[index]?.is_checked_transfer;
      labelChecked = `${t("product.activate_transfer")}`;
    }

    const handleAddGroupPrice = (index) => {
      if (index !== null && index !== undefined) {
        setIndexGroupPrice(index);
      } else {
        if (value?.group_prices === undefined) {
          value.group_prices = [];
        }
        let group_prices = value?.group_prices;
        props?.setFieldValue(`${name}.group_prices`, [
          ...group_prices,
          {
            min_pax: "",
            max_pax: "",
            goshow_adult_price: "",
            goshow_child_price: "",
            goshow_infant_price: "",
            reg_adult_price: "",
            reg_child_price: "",
            reg_infant_price: ""
          },
        ]);
        setIndexGroupPrice(value?.group_prices?.length);
      }
      setModalGroupPrice(true);
    }

    return (
      <>
        <div className="checkbox-wrapper">
          <Checkbox
            style={{ marginTop: "30px" }}
            name={nameChecked}
            checked={checked}
            onChange={props?.handleChange}
            value={1}
            label={labelChecked}
            disabled
          />
        </div>
        {checked == 1 && (
          <>
            <Select2Multi
              title={t("commons.activities")}
              name={`${name}.flight_types`}
              options={
                active === "3" ? flightTypeTransferList : flightTypeList(t)
              }
              className="mb-4"
              disabled
              errors={props?.errors}
              touched={props?.touched}
              value={value?.flight_types}
              onChange={(x, value) => {
                let data = value;
                if (!data.length > 0) {
                  data = "";
                }
                props?.setFieldValue(
                  `${name}.flight_types`,
                  data
                );
              }}
              onBlur={props?.setFieldTouched}
            />
            <Card className="rounded">
              <CardHeader title={t("field.price")} />
              <CardBody style={{ paddingTop: 0 }}>
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.adult")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.child")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-group" style={{ marginBottom: 0 }}>
                      <label className="form-label mb-1">
                        {t("commons.infantLabel")}
                      </label>
                    </div>
                  </div>
                </div>
                <InputPrepend
                  mb="0"
                  errors={props?.errors}
                  touched={props?.touched}
                  title={t("field.regular")}
                  multiple={[
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_adult_price`,
                      value: value?.prices?.reg_adult_price,
                      placeholder: `${t("commons.adult")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let regular_adult = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_adult_price`,
                          regular_adult
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_child_price`,
                      value: value?.prices?.reg_child_price,
                      placeholder: `${t("commons.child")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let regular_child = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_child_price`,
                          regular_child
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.reg_infant_price`,
                      value: value?.prices?.reg_infant_price,
                      placeholder: `${t("commons.infantLabel")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let regular_infant = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.reg_infant_price`,
                          regular_infant
                        );
                      },
                    },
                  ]}
                />
                <InputPrepend
                  mb="0"
                  errors={props?.errors}
                  touched={props?.touched}
                  title="Go Show"
                  multiple={[
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_adult_price`,
                      value: value?.prices?.goshow_adult_price,
                      placeholder: `${t("commons.adult")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let goshow_adult = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_adult_price`,
                          goshow_adult
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_child_price`,
                      value: value?.prices?.goshow_child_price,
                      placeholder: `${t("commons.child")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let goshow_child = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_child_price`,
                          goshow_child
                        );
                      },
                    },
                    {
                      prefix: "Rp ",
                      isCurrency: true,
                      thousandSeparator: true,
                      name: `${name}.prices.goshow_infant_price`,
                      value: value?.prices?.goshow_infant_price,
                      placeholder: `${t("commons.infantLabel")}`,
                      disabled: true,
                      onChange: (data) => {
                        const val = data.target.value;
                        const raw = Number(val.replace(/[^0-9\.-]+/g, ""));
                        let goshow_infant = raw > 0 ? raw : "";
                        props?.setFieldValue(
                          `${name}.prices.goshow_infant_price`,
                          goshow_infant
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
            {value?.group_prices?.length ? (
            <Card className="rounded">
              <CardHeader title={t("field.groupPrice")} />
              <CardBody style={{ paddingTop: 0 }}>
                {value?.group_prices?.length > 0 && (
                  <>
                    <Row
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        color: "rgba(36, 39, 38, 0.3)",
                        paddingBottom: 3,
                      }}
                    >
                      <Col md={2}>
                        <div className="text-center">
                          Min Pax
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="text-center">
                          Max Pax
                        </div>
                      </Col>
                      <Col md={8}>
                        <div></div>
                      </Col>
                    </Row>
                    {value?.group_prices?.map((item, index) => {
                      return (
                        <>
                          <Row
                            style={{
                              paddingTop: 8,
                              paddingBottom: 8,
                            }}
                          >
                            <Col md={2}>
                              <div className="text-center">
                                {item?.min_pax ?? item?.max_pax}
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="text-center">
                                {item?.max_pax ?? item?.min_pax}
                              </div>
                            </Col>
                            <Col md={8}>
                              <div
                                className="d-flex justify-content-end"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleAddGroupPrice(index)}
                              >
                                <span className="material-icons-round right-icon">
                                  navigate_next
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ marginTop: '0.3rem', marginBottom: '0.3rem' }} />
                        </>
                      )
                    })}
                  </>
                )}
              </CardBody>
            </Card>
            ) : <></>}
          </>
        )}
        <GroupPriceModal
          show={modalGroupPrice}
          formik={props}
          index={indexGroupPrice}
          value={value}
          name={name}
          toggle={() => setModalGroupPrice(false)}
        />
      </>
    )
  };

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => toggle()}
              >
                arrow_back
              </span>
            </div>
            Detail {t("field.price")}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.priceName")}
                desc={formik?.values?.name}
                leftIcon="assignment_ind"
              />
              <CardFlush
                sideMenu
                title={t("field.airport")}
                leftIcon="flight"
                desc={
                  <div className="row">
                    <div className="col-8">
                      {formik?.values?.airports
                        ? formik?.values?.airports[0]
                        : ""}
                    </div>
                    {formik?.values?.airports?.length > 1 && (
                      <div className="col-4">
                        <p
                          className="button-tooltip"
                          data-tip
                          data-for="airportsTip"
                        >
                          {`+${formik?.values?.airports?.length - 1}`}
                        </p>
                        <ReactTooltip
                          id="airportsTip"
                          place="bottom"
                          effect="solid"
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {airportsTip.join(",\n")}
                          </p>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                }
              />
            </Card>
          </div>
        </div>
        <div className="side-menu-detail">
          <Container>
            <HeaderMenu
              title={t("commons.details")}
            />
            <Card className="rounded mx-3">
              <CardHeader title={t("product.period")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.startDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`period_list[${index}].start_date`}
                      type="date"
                      disabled
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.period_list[index]?.start_date ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik?.setFieldValue(
                          `period_list[${index}].start_date`,
                          value
                        );
                      }}
                    />
                  </Col>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("promo.endDate")}</div>
                  <Col md={8}>
                    <Input
                      name={`period_list[${index}].end_date`}
                      type="date"
                      disabled
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.period_list[index]?.end_date ?? ""}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik?.setFieldValue(
                          `period_list[${index}].end_date`,
                          value
                        );
                      }}
                    />
                  </Col>
                </div>
              </CardBody>
            </Card>
            <Card className="rounded mx-3">
              <CardHeader title={t("field.service")} />
              <CardBody>
                <div
                  className="content-container-side"
                  style={{ padding: "0" }}
                >
                  <div className="tab-menu overflow">
                    <div style={{ width: "fit-content" }}>
                      <Tabs
                        scrollButtons="auto"
                        variant="scrollable"
                        value={activeTab}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                          setActiveTab(newValue);
                        }}
                      >
                        <Tab
                          label="Meet and Greet Domestic"
                          value="0"
                        />
                        <Tab
                          label="Meet and Greet International"
                          value="1"
                        />
                        <Tab label="Fast Track" value="2" />
                        <Tab label="Transfer" value="3" />
                      </Tabs>
                    </div>
                  </div>
                  <div>{ShowTab(activeTab, formik)}</div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default withTrans(DetailPriceRegular);
