import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { SidebarItem } from "./SidebarItems";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import MenuItem from "../../commons/sidebar/MenuItem";
import Profile from "./Profiles";
import './sidebar.scss'

const Index = ({ visible, close, t }) => {
  const currentUser = getUser()

  return (
    <div className={visible ? "sidebar sidebar-mini" : "sidebar sidebar-full"}>
      <div className={visible ? "backdrop back-show" : "backdrop"} onClick={close} />
      <div className="sidebar-title">
        <label style={{ fontSize: 20 }}>
          <Profile />
        </label>
      </div>
      <div className="horizontal-line" />
      <div className="sidebar-item-wrapper">
        {
          SidebarItem.map((item) => {
            return (
              <React.Fragment>
                {item.roles?.indexOf(currentUser?.user?.role_code) !== -1 && (

                  <NavLink to={item.url}>
                    <MenuItem
                      title={t(`${item.title}`)}
                      icon={item.icon}
                    />
                  </NavLink>

                )}
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  );
};

export default withRouter(withTrans(Index));
