import "./card.scss";

const cardHeader = ({ title, style, isDashboard = false, position = "left" }) => {
  return (
    <>
      {isDashboard && (
        <>
          <div className={`card-header-dashboard ${position}`} style={style}>{title}</div>
        </>
      )}
      {!isDashboard && (
        <>
          <div className={`card-header rounded ${position}`} style={style}>{title}</div>
        </>
      )}
    </>
  );
};

export default cardHeader;
