import React from "react";
import { Formik, Form } from "formik";
import { withTrans } from "../../i18n/withTrans";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import DatePicker from "../../components/forms/DateRangePicker";
import {getUser} from "../../utils/User";
import { Role } from "../../utils/Constants";

function ModalFilter({
  t,
  show,
  filter,
  toggle,
  toggleFilter,
  isDelete,
  onApplyFilter,
  dataAirport
}) {

  const currentUser = getUser();
  const isSubAdmin = currentUser?.user.role_code === Role.Sub_admin;

  const handleReset = (formik) => {
    formik.setFieldValue("status", "");
    formik.setFieldValue("start_date", "");
    formik.setFieldValue("end_date", "");
    formik.setFieldValue("role", "");
    formik.setFieldValue(
      "airport_id", 
      isSubAdmin
        ? currentUser?.user.airport_id 
        : ""
    );
    toggleFilter({ 
      status: "", 
      start_date: "", 
      end_date: "", 
      role: "", 
      airport_id: isSubAdmin 
        ? currentUser?.user.airport_id 
        : ""
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              status: filter?.status ?? "",
              role: filter?.role ?? "",
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              airport_id: filter?.airport_id ?? "",
            }}
            onSubmit={(values) => {
              onApplyFilter({
                start_date: values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date: values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                status: values.status,
                role: values.role,
                airport_id: isSubAdmin 
                  ? currentUser?.user.airport_id
                  : values?.airport_id,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("field.date")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {currentUser?.user.role_code !== Role.Superadmin && (
                  <Select2
                    title="Role"
                    name="role"
                    options={
                      [
                        {
                          value: "",
                          label: `${t("commons.all")} Role`,
                        },
                        {
                          value: "admin_pusat",
                          label: "Admin Pusat",
                        },
                        {
                          value: "admin_bandara",
                          label: "Admin Bandara",
                        },
                        {
                          value: "corporate",
                          label: "Agent",
                        },
                        {
                          value: "assistant",
                          label: "Assistant",
                        },
                        {
                          value: "contact_center",
                          label: "Contact Center",
                        },
                        {
                          value: "customer",
                          label: "Customer",
                        },
                        {
                          value: "finance",
                          label: "Finance",
                        },
                        {
                          label: "Finance Bandara",
                          value: "finance_bandara",
                        },
                        {
                          value: "lead_contact_center",
                          label: "Lead Contact Center",
                        },
                        {
                          value: "sales",
                          label: "Sales",
                        },
                        {
                          label: "Sales Bandara",
                          value: "sales_bandara",
                        },
                        {
                          label: "Supervisor",
                          value: "supervisor",
                        },
                      ]
                    }
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.role}
                    onChange={(name, value) => {
                      props.setFieldValue("role", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                {currentUser?.user.role_code === Role.Central_admin && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...dataAirport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title="Status"
                  name="status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: true,
                      label: t("status.success"),
                    },
                    {
                      value: false,
                      label: t("status.failed"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.status}
                  onChange={(name, value) => {
                    props.setFieldValue("status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = ({  }) => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalFilter)
);
