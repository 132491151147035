import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import id from "date-fns/locale/id";

const Index = ({
  isLocale,
  useCustomInput,
  showIcon,
  showMonthYearPicker,
  customDateFormat,
  maxDate,
  value,
  onChange
}) => {

  const CustomDatePickerInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <div>
        <div className="input-wrapper" style={{ width: "100%" }}>
          <span className="material-icons-round left-icon-input">
            insert_invitation
          </span>
          <input
            value={value}
            className="form-control form-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
          ></input>
        </div>
      </div>
    </>
  ));

  return (
    <DatePicker
      showIcon={showIcon ?? true}
      locale={isLocale ? id : false}
      customInput={useCustomInput ? <CustomDatePickerInput /> : false}
      selected={value}
      onChange={onChange}
      showMonthYearPicker={showMonthYearPicker ??  false}
      maxDate={maxDate}
      dateFormat={customDateFormat ?? "MMMM yyyy"}
    />
  );
};

export default Index;