import React from "react";
import { string } from "prop-types";
import "./status.scss";

const bgColor = {
  primary: "#3BC862",
  primaryLabel: "#fff",
  warning: "#EDAA21",
  warningLabel: "#fff",
  danger: "#D10D0D",
  dangerLabel: "#fff",
  secondary: "#B8B8D6",
  secondaryLabel: "#fff",
  info: "#66a7e3",
  infoLabel: "#fff",
};

function TableStatusComponent(props) {
  const { status, style, color, toggle, leftIcon, rightIcon } = props;
  return (
    <div
      style={{
        backgroundColor: bgColor[color],
        color: bgColor[color + "Label"],
        ...style,
      }}
      className="status"
      onClick={toggle}
    >
      {leftIcon && (
        <span className="material-icons-round left-icon">{leftIcon}</span>
      )}

      <span>{status}</span>

      {rightIcon && (
        <span className="material-icons-round right-icon">{rightIcon}</span>
      )}
    </div>
  );
}

TableStatusComponent.propTypes = {
  name: string,
};

export default TableStatusComponent;
