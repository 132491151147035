import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import Table from "../../../components/commons/table";
import TableStatus from "../../../components/commons/table/status";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";

const ReportAssistant = ({ t }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
      };
      dispatch(ReportService.getAssistant(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter, searchText]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  const handleDownloadReport = () => {
    new Promise((resolve) => {
      setLoadingDownload(true);
      dispatch(ReportService.downloadAssistant(paramFilter, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };
  
  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: t("field.assistant").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let name = original.assistant_name ?? "-";
        return name;
      },
    },
    {
      Header: t("report.serviceTime").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {original?.date && original?.time 
              ? `${moment(original?.date).format("DD/MM/YYYY")} ${original?.time}`
              : "-"}
          </>
        );
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: t("field.region").toUpperCase().toUpperCase(),
      accessor: "region",
    },
    {
      Header: "ID BOOKING",
      accessor: "booking_id",
    },
    {
      Header: t("field.start").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let service_start = "";
        if (original?.service_start_time) {
          service_start = moment(original?.service_start_time).format(
            "DD/MM/YYYY, HH:mm:ss"
          );
        } else {
          service_start = "-";
        }
        return service_start;
      },
    },
    {
      Header: t("field.finish").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let service_end = "";
        if (original?.service_end_time) {
          service_end = moment(original?.service_end_time).format(
            "DD/MM/YYYY, HH:mm:ss"
          );
        } else {
          service_end = "-";
        }
        return service_end;
      },
    },
    {
      Header: t("field.product").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];

        return original?.product_name ?? "";
      },
    },
    {
      Header: t("field.bookingType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let booking_type = "";
        if (original?.booking_type) {
          booking_type =
            original?.booking_type === 1
              ? t("field.arrival")
              : original?.booking_type === 2
              ? t("field.departure")
              : "Transfer";
        }
        return booking_type;
      },
    },
    {
      Header: t("report.cooperation").toUpperCase(),
      accessor: "corporate_group_type",
    },
    {
      Header: "TOTAL PAX",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const total_passenger = original.total_pax === null
          ? 0
          : original.total_pax;
        return <>{total_passenger}</>;
      },
    },
    {
      Header: "ACTUAL PAX",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const actual_pax = original.actual_pax === null
          ? 0
          : original.actual_pax;
        return <>{actual_pax}</>;
      },
    },
    {
      Header: t("field.passenger").toUpperCase(),
      accessor: "passenger_list",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.passenger_list ?? "-";
      },
    },
    {
      Header: "REMARK",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.remarks_evidence ?? "-";
      },
    },
    {
      Header: "RATE",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.rating ?? "-";
      },
    },
    {
      Header: `${t("field.payment").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        let { is_paid } = original;
        return (
          <TableStatus
            status={
              is_paid === false
                ? t("status.unpaid")
                : original?.corporate_group_type === "Regular"
                  ? t("status.paid")
                  : t("status.invoicePayment")
            }
            color={
              is_paid === false
                ? "danger"
                : "primary"
            }
          />
        );
      },
      minWidth: 110,
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <SearchButton
          style={{ marginTop: 15 }}
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <div style={{ width: 300, marginLeft: 15, marginTop: 15 }}>
          <DatePicker
            name="start_date"
            handlechange={(startDate, endDate) => {
              setParamFilter({
                start_date: startDate === "" || startDate === undefined
                  ? "" 
                  : moment(startDate).format("YYYY-MM-DD"),
                end_date: endDate === "" || endDate === undefined
                  ? "" 
                  : moment(endDate).format("YYYY-MM-DD"),
              });
            }}
            containerPosition="auto"
            notReadOnly
            format="DD MMMM YYYY"
            startDate={paramFilter?.start_date}
            endDate={paramFilter?.end_date}
          />
        </div>
        <div className="nav-separator mr-3 ml-3" />
        <Button
          title={(
            <>
              {loadingDownload && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={!loadingDownload ? "download" : null}
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleDownloadReport}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
    </>
  )
}

export default withTrans(ReportAssistant)