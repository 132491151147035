import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Divider } from "@mui/material";

import CardFlush from "../../../components/commons/cards/cardFlush";
import { withTrans } from "../../../i18n/withTrans"
import { numberWithCommas } from "../../../utils/Helper"
import SummaryCheckoutPrice from "../../../components/summaryCheckoutPrice";

const DetailPrice = ({
  t,
  dataDetail,
  dataOther,
  passengerAdultLength,
  passengerChildLength,
  passengerInfantLength,
  dataLounge,
  dataTransports,
}) => {
  const [showPriceService, setShowPriceService] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  const renderPassengerPrice = () => {
    const includePPN = dataDetail?.is_include_ppn;
    const includeConcession = dataDetail?.is_include_concession;
    const priceType =
      dataDetail?.corporate_group_type === "MOB" &&
        (dataDetail?.under_quota_adult !== 0 ||
          dataDetail?.under_quota_child !== 0 ||
          dataDetail?.under_quota_infant !== 0)
        ? 'MOBUnderQuota'
        : dataDetail?.corporate_group_type

    return priceType === 'Package'
      ? <SummaryCheckoutPrice
          keyTooltip={`taxPricePackage`}
          price={dataDetail?.price_service}
          dataCheckout={{
            isIncludePpn: dataDetail?.is_include_ppn,
            isIncludeConcession: dataDetail?.is_include_concession,
            ppnPercentage: dataDetail?.ppn_percentage,
            concessionPercentage: dataDetail?.concession_percentage,
            ppnPrice: dataDetail?.ppn_tax_price,
            concessionPrice: dataDetail?.concession_tax_price,
          }}
        />
      : ["adult", "child", "infant"].map((type) => {
          let number;
          let priceOriginal = dataDetail[`${type}_price_original`];
          let pricePPN = dataDetail[`ppn_${type}_price`];
          let priceConcession = dataDetail[`concession_${type}_price`];

          if (priceType === 'MOBUnderQuota') {
            number = dataDetail[`under_quota_${type}`];
            priceOriginal = dataDetail[`${type}_price_original_mob_under_quota`];
          } else if (priceType === 'MOB') {
            number = dataDetail[`plus_passenger_${type}`];
          } else {
            number = type === 'adult' 
              ? passengerAdultLength 
              : type === 'child' 
                ? passengerChildLength 
                : passengerInfantLength;
          }
          
          return (
            number !== "" && number !== 0 && (
              <SummaryCheckoutPrice
                category={t(`commons.${type}`)}
                keyTooltip={`taxPrice${type.charAt(0).toUpperCase() + type.slice(1)}`}
                passengers={number}
                price={priceOriginal}
                dataCheckout={{
                  isIncludePpn: includePPN,
                  isIncludeConcession: includeConcession,
                  ppnPercentage: dataDetail?.ppn_percentage,
                  concessionPercentage: dataDetail?.concession_percentage,
                  ppnPrice: pricePPN,
                  concessionPrice: priceConcession,
                }}
              />
            )
          );
        })
  }

  const renderDetailLounge = () => {
    return ["adult", "child", "infant"].map((type) => {
      const number = dataLounge[0][`number_lounge_${type}`];
      const priceOriginal = dataLounge[0][`${type}_price_original`];
      const pricePPN = dataLounge[0][`ppn_${type}_lounge`];
      const priceConcession = dataLounge[0][`concession_${type}_lounge`];

      return (
        number !== "" && number !== 0 && (
          <SummaryCheckoutPrice
            category={t(`commons.${type}`)}
            keyTooltip={`taxLounge${type.charAt(0).toUpperCase() + type.slice(1)}`}
            passengers={number}
            price={priceOriginal}
            dataCheckout={{
              isIncludePpn: dataLounge[0]?.is_include_ppn,
              isIncludeConcession: dataLounge[0]?.is_include_concession,
              ppnPercentage: dataLounge[0]?.ppn_percentage,
              concessionPercentage: dataLounge[0]?.concession_percentage,
              ppnPrice: pricePPN,
              concessionPrice: priceConcession,
            }}
          />
        )
      );
    })
  }

  return (
    <div className="wrapper-detail-card">
      <div className="text-extra-bold container-card" style={{ opacity: 0.5 }}>
        {t("field.detailPrice")}
      </div>
      <Divider orientation="horizontal" />
      <CardFlush
        list
        label={t("field.grandTotal")}
        desc={
          dataDetail?.total_price
            ? `Rp ${numberWithCommas(dataDetail.total_price)}`
            : "Rp 0"
        }
        size={{ label: 6, desc: 6 }}
        descBold
        descEnd
        last
      />
      <div className="card-body border-top py-3 container-card">
        <div className="d-flex justify-content-between">
          {dataDetail?.corporate_group_type === "MOB" &&
          dataDetail?.under_quota_adult === 0 &&
          dataDetail?.under_quota_child === 0 &&
          dataDetail?.under_quota_infant === 0 &&
          dataDetail?.plus_passenger_adult === 0 &&
          dataDetail?.plus_passenger_child === 0 &&
          dataDetail?.plus_passenger_infant === 0 ? (
            <>
              <div className="d-flex align-items-center normal-title text-extra-bold">
                {t("field.MOBPrice")}
                <span
                  className="material-icons"
                  style={{ fontSize: 18 }}
                  data-tip
                  data-for="includeMOBQuota"
                >
                  info_outlined
                </span>
                <ReactTooltip id="includeMOBQuota" place="top" effect="solid">
                  <p style={{ whiteSpace: "pre-line" }}>
                    {t("booking.priceMOBQuota")}
                  </p>
                </ReactTooltip>
              </div>
              <div className="normal-title" style={{ cursor: "pointer" }}>
                Rp 0
              </div>
            </>
          ) : (
            <>
              <div className="normal-title text-extra-bold">
                {dataDetail?.corporate_group_type === "Group"
                  ? t("field.groupPrice")
                  : dataDetail?.corporate_group_type === "Package"
                  ? t("field.packagePrice")
                  : dataDetail?.corporate_group_type === "FIT"
                  ? t("field.FITPrice")
                  : dataDetail?.corporate_group_type === "MOB" &&
                    (dataDetail?.under_quota_adult !== 0 ||
                      dataDetail?.under_quota_child !== 0 ||
                      dataDetail?.under_quota_infant !== 0)
                  ? `MOB ${t("field.priceUnderQuota")}`
                  : dataDetail?.corporate_group_type === "MOB" &&
                    (dataDetail?.plus_passenger_adult !== 0 ||
                      dataDetail?.plus_passenger_child !== 0 ||
                      dataDetail?.plus_passenger_infant !== 0)
                  ? t("field.individualPrice")
                  : t("field.price")}{" "}
                {dataDetail?.product_name_eng}
              </div>
              {dataDetail?.promo_detail?.type === "bundling" ? (
                <div className="normal-title text-extra-bold mr-2">
                  {`${
                    dataDetail?.list_order_passengers?.length
                  } x Rp ${numberWithCommas(
                    dataDetail?.bundling_promo_main_price +
                      dataDetail?.bundling_promo_product_price
                  )}`}
                </div>
              ) : (
                <div
                  onClick={() => setShowPriceService(!showPriceService)}
                  className="d-flex align-items-center"
                  style={{ marginRight: "-30px", cursor: "pointer" }}
                >
                  <span className="normal-title text-extra-bold mr-2">
                    Rp {numberWithCommas(dataDetail?.price_service)}
                  </span>
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {showPriceService && (
          renderPassengerPrice()
        )}
      </div>
      <div className="card-body border-top py-3 container-card">
        <div className="d-flex justify-content-between">
          <div className="normal-title text-extra-bold">
            {t("field.lounge")}
          </div>
          {dataLounge?.length ? (
            <div
              onClick={() => setShowLounge(!showLounge)}
              className="d-flex align-items-center"
              style={{ marginRight: "-30px", cursor: "pointer" }}
            >
              <span className="normal-title text-extra-bold mr-2">
                Rp {numberWithCommas(dataLounge[0]?.total_price)}
              </span>
              <span className="material-icons-round left-icon">
                expand_more
              </span>
            </div>
          ) : (
            <div
              className="normal-title text-extra-bold"
              style={{ cursor: "pointer" }}
            >
              Rp 0
            </div>
          )}
        </div>
        {showLounge && dataLounge?.length > 0 && (
          renderDetailLounge()
        )}
      </div>
      {dataDetail?.passenger_type_label_eng === "Corporate" ? (
        <div className="card-body border-top py-3 container-card">
          <div className="d-flex justify-content-between">
            <div className="normal-title text-extra-bold">
              {t("field.transportation")}
            </div>
            {dataTransports?.length ? (
              <div
                onClick={() => setShowTransport(!showTransport)}
                className="d-flex align-items-center"
                style={{ marginRight: "-30px", cursor: "pointer" }}
              >
                <span className="normal-title text-extra-bold mr-2">
                  Rp {numberWithCommas(dataTransports[0]?.total_price)}
                </span>
                <span className="material-icons-round left-icon">
                  expand_more
                </span>
              </div>
            ) : (
              <div
                className="normal-title text-extra-bold"
                style={{ cursor: "pointer" }}
              >
                Rp 0
              </div>
            )}
          </div>
          {showTransport && (
            <SummaryCheckoutPrice
              keyTooltip="taxTransports"
              passengers={`${dataTransports[0]?.total_unit} unit`}
              price={dataTransports[0]?.price_original}
              dataCheckout={{
                isIncludePpn: dataTransports[0]?.is_include_ppn,
                isIncludeConcession: dataTransports[0]?.is_include_concession,
                ppnPercentage: dataTransports[0]?.ppn_percentage,
                concessionPercentage: dataTransports[0]?.concession_percentage,
                ppnPrice: dataTransports[0]?.ppn,
                concessionPrice: dataTransports[0]?.concession,
              }}
            />
          )}
        </div>
      ) : dataTransports?.length ? (
        <div className="card-body d-flex justify-content-between border-top py-3 container-card">
          <div className="d-flex align-items-center normal-title text-extra-bold">
            {t("field.transportation")}
            {dataTransports[0]?.total_unit > 0 &&
              dataTransports[0]?.is_include_ppn && (
                <>
                  <span
                    className="material-icons"
                    style={{ fontSize: 18 }}
                    data-tip
                    data-for="taxTransports"
                  >
                    info_outlined
                  </span>
                  <ReactTooltip id="taxTransports" place="top" effect="solid">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {`${t("product.includes_ppn")} ${
                        dataTransports[0]?.ppn_percentage
                      }%, Rp ${numberWithCommas(dataTransports[0]?.ppn)}`}
                    </p>
                  </ReactTooltip>
                </>
              )}
          </div>
          <div className="normal-title ">
            {dataTransports[0]?.total_unit +
              " unit  x Rp" +
              numberWithCommas(dataTransports[0]?.price_original)}
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {dataOther?.length ? (
        <div className="card-body border-top py-3 container-card">
          <div className="d-flex justify-content-between" >
            <div className="normal-title text-extra-bold">
              {t("masterData.others")}
            </div>
            <div
              onClick={() => setShowOthers(!showOthers)}
              className="d-flex align-items-center"
              style={{ marginRight: "-30px", cursor: "pointer" }}
            >
              <span className="normal-title text-extra-bold mr-2">
                Rp{" "}
                {numberWithCommas(
                  dataDetail?.promo_detail?.type === "bundling"
                    ? dataOther[0]?.total_price -
                        dataDetail?.list_order_passengers?.length *
                          dataDetail?.bundling_promo_product_price
                    : dataOther[0]?.total_price
                )}
              </span>
              <span className="material-icons-round left-icon">
                expand_more
              </span>
            </div>
          </div>
          {showOthers && (
            <SummaryCheckoutPrice
              keyTooltip="taxOthers"
              price={dataOther[0]?.price_original}
              dataCheckout={{
                isIncludePpn: dataOther[0]?.is_include_ppn,
                isIncludeConcession: dataOther[0]?.is_include_concession,
                ppnPercentage: dataOther[0]?.ppn_percentage,
                concessionPercentage: dataOther[0]?.concession_percentage,
                ppnPrice: dataOther[0]?.ppn,
                concessionPrice: dataOther[0]?.concession,
                dpp: dataOther[0]?.dpp,
              }}
            />
          )}
        </div>
      ) : (
        <div></div>
      )}
      {dataDetail?.promo_id && (
        <div className="card-body border-top py-3 container-card">
          <div className="normal-title text-extra-bold mb-1">
            {dataDetail?.promo_detail?.type === "bundling"
              ? "Promo Bundling"
              : "Promo"}
          </div>
          <div className="d-flex justify-content-between">
            <div className="normal-text mt-1">
              {dataDetail?.promo_detail?.name}
              <span
                style={{
                  borderRight: "1px solid #A9A9A9",
                  height: 20,
                  marginLeft: 8,
                  marginRight: 8,
                }}
              ></span>
              <span className="text-primary">
                {dataDetail?.promo_detail?.type === "blast"
                  ? dataDetail?.promo_detail?.type_label
                  : dataDetail?.promo_detail?.type === "specific"
                  ? t("promo.special")
                  : dataDetail?.promo_detail?.type === "bundling"
                  ? dataDetail?.promo_detail?.bundling_name
                  : ""}
              </span>
            </div>
            {dataDetail?.promo_detail?.type !== "bundling" && (
              <div className="text-right">
                <div className="normal-text text-danger mb-1">
                  {dataDetail?.price_cut
                    ? `Rp ${numberWithCommas(dataDetail.price_cut)}`
                    : "Rp 0"}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {dataDetail?.promo_id_cross_selling && (
        <>
          <div className="card-body border-top py-3 container-card">
            <div className="normal-title text-extra-bold mb-2">
              {t("field.cross_selling")}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <div className="normal-text">
                {t("promo.airportCrossSelling")}
                <span className="text-primary">
                  {dataDetail?.promo_detail_cross_selling ? (
                    <>
                      <span
                        style={{
                          borderRight: "1px solid #A9A9A9",
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      ></span>
                      <span>
                        {`${dataDetail?.promo_detail_cross_selling?.discount_percent}%`}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="normal-text text-primary">
                Rp {numberWithCommas(dataDetail?.price_cut_cross_selling ?? 0)}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <div className="normal-text">
                {t("promo.airportService")}
                <span className="text-primary">
                  {dataDetail?.promo_detail_cross_selling ? (
                    <>
                      <span
                        style={{
                          borderRight: "1px solid #A9A9A9",
                          height: 20,
                          marginLeft: 8,
                          marginRight: 8,
                        }}
                      ></span>
                      <span>
                        {`${parseInt(
                          100 -
                            dataDetail?.promo_detail_cross_selling
                              ?.discount_percent
                        )}%`}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="normal-text text-primary">
                Rp{" "}
                {numberWithCommas(dataDetail?.total_price_cross_selling ?? 0)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withTrans(DetailPrice)