import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Divider } from "@mui/material";
import { withTrans } from "../../../../i18n/withTrans";
import UserAssistant from "../../../../store/actions/schedule/operational";
import moment from "moment";

const AssistantHistory = ({ t, show, toggle, action, data, id }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [assistant, setAssistant] = useState([]);
  const [page, setPage] = useState(1);

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        length: 9999,
        search_text: searchText,
        order_id: id,
      };
      dispatch(UserAssistant.getHistory(param, resolve));
    }).then((res) => {
      setAssistant(res?.data);
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [data]);

  const initial = data
    ? {
      assistants: data,
    }
    : {
      name: "",
      code: "",
    };

  const validation = Yup.object().shape({
    name: Yup.string().required(t("commons.required")).nullable(),
    code: Yup.string().required(t("commons.required")).nullable(),
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("schedule.replacement_history")}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
            setStatus();

            action(values);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <div
                    style={{
                      maxHeight: "400px",
                      overflowY: "scroll",
                      overflowX: "clip",
                    }}
                    className="mt-4"
                  >
                    {assistant?.length > 0 ? (
                      assistant?.map((item, index) => {
                        let name = item.list_assistants ? item.list_assistants?.map(item => item?.assistant_name).join(", ") : item.assistant_name === null ? "-" : item.assistant_name;
                        return (
                          <React.Fragment key={index + item?.id}>
                            <div className="d-flex justify-content-between my-3">
                              <div className="name-assistant">
                                <div style={{ paddingRight: 8 }}>
                                  <strong>{name}</strong>
                                </div>
                              </div>
                              <div className="assign normal-title">
                                <span>
                                  {t("field.assignedAt")}{" "}
                                  {moment(item.updated_at).format(
                                    "DD/MM/YYYY, HH:mm"
                                  )}
                                </span>
                              </div>
                            </div>
                            <Divider orientation="horizontal" />
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <div className="text-center">
                        {t("commons.dataNotFound")}
                      </div>
                    )}
                  </div>
                </Container>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(AssistantHistory);
