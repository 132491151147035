import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import Table from '../../../components/commons/table';
import { withTrans } from "../../../i18n/withTrans";
import { numberWithCommas } from '../../../utils/Helper';
// Service
import ReportService from "../../../store/actions/report/";

const Index = ({ t, filterYear, filterAirport, isMonthLessThanCurrent }) => {
  const dispatch = useDispatch();
  let lang = localStorage.getItem('joumpa_language');
  const [dataReport, setDataReport] = useState([]);
  const [dataTotalRKAP, setDataTotalRKAP] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        year: filterYear,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        airport_id: filterAirport,
      };
      dispatch(ReportService.getRKAPIncome(param, resolve));
    }).then((res) => {
      setDataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const handleRefreshTotalRKAP = () => {
    setDataTotalRKAP(null);
    new Promise((resolve) => {
      const param = {
        year: filterYear,
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        airport_id: filterAirport,
      };
      dispatch(ReportService.getTotalIncomeRKAP(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataTotalRKAP(res?.data);
      }
    });
  };

  useEffect(() => {
    handleRefresh();
    handleRefreshTotalRKAP();
  }, [page, filterAirport, filterYear]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [filterAirport, filterYear]);

  const columnsSales = [
    {
      Header: "NO",
      accessor: "no",
      colSpanFooter: 2,
      Footer: () => {
        return (
          <div style={{ fontWeight: 800, minWidth: 150 }}>
            <p>
              {lang === "id" ? "Total Keseluruhan" : "Total"}
            </p>
            <p>
              {t("report.avgOverall")}
            </p>
          </div>
        )
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
      hideColFooter: true,
    },
    {
      Header: t("month.jan").toUpperCase(),
      textAlign: isMonthLessThanCurrent(0) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_1",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_1)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_1 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_1)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_1 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_1)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_1",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_1)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_1 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_1)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_1 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_1)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(0),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.feb").toUpperCase(),
      textAlign: isMonthLessThanCurrent(1) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_2",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_2)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_2 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_2)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_2 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_2)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_2",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_2)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_2 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_2)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_2 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_2)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(1),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.mar").toUpperCase(),
      textAlign: isMonthLessThanCurrent(2) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_3",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_3)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_3 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_3)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_3 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_3)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_3",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_3)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_3 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_3)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_3 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_3)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(2),
          minWidth: 180,
        }
      ]
    },
    {
      Header: "APRIL",
      textAlign: isMonthLessThanCurrent(3) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_4",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_4)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_4 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_4)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_4 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_4)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_4",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_4)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_4 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_4)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_4 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_4)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(3),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.may").toUpperCase(),
      textAlign: isMonthLessThanCurrent(4) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_5",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_5)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_5 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_5)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_5 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_5)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_5",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_5)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_5 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_5)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_5 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_5)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(4),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.jun").toUpperCase(),
      textAlign: isMonthLessThanCurrent(5) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_6",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_6)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_6 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_6)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_6 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_6)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_6",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_6)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_6 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_6)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_6 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_6)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(5),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.jul").toUpperCase(),
      textAlign: isMonthLessThanCurrent(6) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_7",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_7)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_7 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_7)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_7 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_7)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_7",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_7)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_7 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_7)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_7 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_7)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(6),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.aug").toUpperCase(),
      textAlign: isMonthLessThanCurrent(7) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_8",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_8)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_8 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_8)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_8 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_8)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_8",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_8)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_8 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_8)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_8 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_8)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(7),
          minWidth: 180,
        }
      ]
    },
    {
      Header: "SEPTEMBER",
      textAlign: isMonthLessThanCurrent(8) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_9",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_9)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_9 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_9)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_9 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_9)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_9",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_9)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_9 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_9)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_9 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_9)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(8),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.oct").toUpperCase(),
      textAlign: isMonthLessThanCurrent(9) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_10",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_10)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_10 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_10)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_10 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_10)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_10",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_10)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_10 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_10)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_10 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_10)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(9),
          minWidth: 180,
        }
      ]
    },
    {
      Header: "NOVEMBER",
      textAlign: isMonthLessThanCurrent(10) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_11",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_11)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_11 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_11)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_11 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_11)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_11",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_11)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_11 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_11)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_11 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_11)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(10),
          minWidth: 180,
        }
      ]
    },
    {
      Header: t("month.dec").toUpperCase(),
      textAlign: isMonthLessThanCurrent(11) ? 'center' : 'left',
      columns: [
        {
          Header: "RKAP",
          accessor: "income_12",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_12)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncome_12 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncome_12)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncome_12 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncome_12)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          minWidth: 180,
        },
        {
          Header: t("report.realization").toUpperCase(),
          accessor: "income_real_12",
          Cell: ({ row }) => {
            const original = row ? row?.original : [];
            return <>{"Rp " + numberWithCommas(original?.income_real_12)}</>;
          },
          Footer: () => {
            return (
              <div style={{ fontWeight: 800 }}>
                <p>
                  {dataTotalRKAP?.totalIncomeReal_12 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.totalIncomeReal_12)}` 
                    : '-'}
                </p>
                <p>
                  {dataTotalRKAP?.averageIncomeReal_12 
                    ? `Rp ${numberWithCommas(dataTotalRKAP.averageIncomeReal_12)}` 
                    : '-'}
                </p>
              </div>
            );
          },
          show: isMonthLessThanCurrent(11),
          minWidth: 180,
        }
      ]
    },
  ];

  return (
    <Table
      footer
      customFooterGroup
      columns={columnsSales.map((item) => ({
        ...item,
        columns: item?.columns?.filter((col) => col.show !== false)
      }))}
      data={dataReport ?? []}
      pagination={{ page, totalData, maxPage, toggle: setPage }}
    />
  )
}

export default withTrans(Index)