import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import * as Yup from "yup";

import TemplateData from "../../assets/files/Format Upload RKAP.xlsx";
import Select2 from "../../components/forms/Select2";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import DynamicButton from "../../components/forms/DynamicButton";
import { withTrans } from "../../i18n/withTrans";
import { toastError } from "../../components/commons/toast";

//Service
import ReportService from "../../store/actions/report/";

const UploadModal = ({ t, show, toggle, data_post, error }) => {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fileProp, setFileProp] = useState({
    title: "Upload File RKAP",
    subtitle: "Format .xls or .xlsx (Max. 2MB)",
    message_error: false,
  });

  let yearOptions = [];
  for (let i = new Date().getFullYear(); i >= 2021; i--) {
    yearOptions = [...yearOptions, { label: i, value: i }];
  }

  const initial = {
    year: "",
    file: null,
  };

  const validation = Yup.object().shape({
    year:
      Yup.string()
        .required(`${t("field.year")} ${t("commons.required")}`)
        .nullable(),
  });

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp({
        title: "Upload File RKAP",
        subtitle: "Format .xls or .xlsx (Max. 2MB)",
        message_error: false,
      });
    }
  }, [data_post]);

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = TemplateData;
    link.setAttribute("download", `File Template RKAP.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    if (Number(selectorFiles.files[0].size) < 2097152) {
      formik.setFieldValue("file", selectorFiles.files[0]);
      setFileProp({
        title: selectorFiles.files[0].name,
        subtitle:
          `Size: ${(Number(selectorFiles.files[0].size) * 0.001).toFixed(2)} KB`,
        message_error: false,
      });
    } else {
      toastError("Maksimum file size 2 MB!");
    }
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}Upload RKAP{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          initialValues={initial}
          validationSchema={validation}
          onSubmit={ async (values, { setStatus }) => {
            setStatus();
            if (!(values?.file)) {
              setLoadingSubmit(false);
              setFileProp({
                title: "Upload File RKAP",
                subtitle: "Format .xls or .xlsx (Max. 2MB)",
                message_error: `File RKAP ${t("commons.required")}`,
              });
              return;
            }

            let formData = new FormData();
            formData.append("file", values?.file);
            formData.append("year", values?.year);

            const callback = () => {
              toggle();
              setFileProp({
                title: "Upload File RKAP",
                subtitle: "Format .xls or .xlsx (Max. 2MB)",
                message_error: false,
              });
            };
            setLoadingSubmit(true);
            dispatch(ReportService.importRKAP(formData, callback));
          }}
        >
          {(props) => (
            <Form>
              <Container>
                <ModalBody>
                  <div className="px-2">
                    <Select2
                      title={t("field.year")}
                      name="year"
                      options={yearOptions}
                      errors={props?.errors}
                      touched={props?.touched}
                      value={props?.values?.year}
                      onChange={(name, value) => {
                        props?.setFieldValue("year", value);
                      }}
                      onBlur={props?.setFieldTouched}
                    />
                    <div style={{
                      borderRadius: '6px',
                      border: '1px solid #63AE5C',
                      background: '#ECF5EB',
                      padding: "21px 25px",
                    }}>
                      <p className="normal-title text-primary">
                        {t("field.downloadRKAPInfo")}
                        <span
                          className="text-bold"
                          onClick={handleDownloadTemplate}
                        >
                          <u>{` ${t("field.here")}`}</u>
                        </span>
                      </p>
                    </div>
                  </div>
                  <DynamicButton
                    titleLeft={fileProp?.title}
                    subtitleLeft={fileProp?.subtitle}
                    iconLeft="description"
                    toggle={openFileDialog}
                    error={fileProp?.message_error}
                  />
                  <input
                    type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleChange(e.target, props)}
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                </ModalBody>
              </Container>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={loadingSubmit}
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ report: { pending, data_post, error } }) => {
  return { pending, data_post, error };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(UploadModal));
