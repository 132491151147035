import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { withTrans } from "../../../../i18n/withTrans";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import InputPrepend from "../../../../components/forms/InputPrepend";

const GroupPriceModal = ({ formik, index, value, name, show, toggle, t }) => {

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("field.groupPrice")}{" "}
          <span
            className="close clickable"
            onClick={() => toggle()}
          >
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label mb-0">
                    Min Pax
                  </label>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label mb-0">
                    Max Pax
                  </label>
                </div>
              </Col>
            </Row>
            <InputPrepend
              mt="0"
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  disabled: true,
                  name: `${name}.group_prices[${index}].min_pax`,
                  value: value?.group_prices === undefined
                    ? "" 
                    : value?.group_prices[index]?.min_pax,
                  type: "number",
                  placeholder: "Min Pax",
                },
                {
                  disabled: true,
                  name: `${name}.group_prices[${index}].max_pax`,
                  value: value?.group_prices === undefined
                    ? "" 
                    : value?.group_prices[index]?.max_pax,
                  type: "number",
                  placeholder: "Max Pax",
                },
              ]}
            />
            <div className="form-group mb-2 mt-4">
              <label className="form-label mb-0">
                {t("field.regularPrice")}
              </label>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.adult")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.child")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.infantLabel")}
                  </label>
                </div>
              </div>
            </div>
            <InputPrepend
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_adult_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_adult_price,
                  placeholder: t("commons.adult"),
                },
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_child_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_child_price,
                  placeholder: t("commons.child"),
                },
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_infant_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_infant_price,
                  placeholder: t("commons.infantLabel"),
                },
              ]}
            />
            <div className="form-group mb-2 mt-4">
              <label className="form-label mb-0">
                {t("field.goshowPrice")}
              </label>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.adult")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.child")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.infantLabel")}
                  </label>
                </div>
              </div>
            </div>
            <InputPrepend
              mb="5"
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_adult_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_adult_price,
                  placeholder: t("commons.adult"),
                },
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_child_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_child_price,
                  placeholder: t("commons.child"),
                },
                {
                  disabled: true,
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_infant_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_infant_price,
                  placeholder: t("commons.infantLabel"),
                },
              ]}
            />
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(GroupPriceModal);
