const cardSelectionProduct = ({
  selectedType,
  productType,
  title,
  desc,
  notAvailable
}) => {
  return  (
    <div>
      {notAvailable ? (
        <>
        <span className="card-header-inactive">
          <span
            style={{
              borderRadius: "50%",
              backgroundColor: "#FFFF",
              borderColor: "#A5A5C0",
              marginRight: "25px",
              height: "100%",
              color: "#A5A5C0",
            }}
            className="material-icons-round"
          >
            info
          </span>
          {desc}
        </span>
        </>
      ) : (
        <>
        <span
          className={
            selectedType === productType
              ? "card-header"
              : "card-header-inactive"
          }
        >
          <span
            style={{
              borderRadius: "50%",
              backgroundColor: "#63AE5C",
              borderColor: "#63AE5C",
              marginRight: "25px",
              height: "100%",
              color: "#FFFF",
            }}
            className="material-icons-round"
          >
            {selectedType === productType
              ? "done"
              : "info"}
          </span>
          <div>
            {title}
            <div
              style={{
                fontSize: 12,
                display: "block",
                lineHeight: "1rem",
                paddingTop: 10,
              }}
            >
              {desc}
            </div>
          </div>
        </span>
        </>
      )}
    </div>
  )
}

export default cardSelectionProduct