import { withTrans } from "../../../i18n/withTrans";
import { numberWithCommas } from "../../../utils/Helper";

const DropdownLounge = ({ t, lounge, loungePrice }) => {
  return (
    <>
      <div className="normal-title">
        {`${lounge.lounge_name ?? lounge.name} (${
          lounge?.route_type?.charAt(0).toUpperCase() + lounge?.route_type?.slice(1)
        })`}
      </div>
      <div className="d-flex justify-content-between">
        <div className="col-md-4">
          <div className="d-flex flex-wrap justify-content-between normal-title">
            {t("commons.adult")}
            <div className="text-primary">
              {loungePrice
                ? lounge?.adult_price == null
                  ? " Rp 0"
                  : " Rp " + numberWithCommas(lounge.adult_price)
                : lounge?.price_adult == null
                ? " Rp 0"
                : " Rp " + numberWithCommas(lounge.price_adult)}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-wrap justify-content-between normal-title">
            {t("commons.child")}
            <div className="text-primary">
              {loungePrice
                ? lounge?.child_price == null
                  ? " Rp 0"
                  : " Rp " + numberWithCommas(lounge.child_price)
                : lounge?.price_child == null
                ? " Rp 0"
                : " Rp " + numberWithCommas(lounge.price_child)}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-wrap justify-content-between normal-title">
            {t("commons.infantLabel")}
            <div className="text-primary">
              {loungePrice
                ? lounge?.infant_price == null
                  ? " Rp 0"
                  : " Rp " + numberWithCommas(lounge.infant_price)
                : lounge?.price_infant == null
                ? " Rp 0"
                : " Rp " + numberWithCommas(lounge.price_infant)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTrans(DropdownLounge)