import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../../utils/User";
import Layout from "../layout";

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = getUser();
      if (!currentUser) {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        );
      }
      
      if (roles && roles.indexOf(currentUser.user.role_code) === -1) {
        return <Redirect to={{ pathname: "/access-denied" }} />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default PrivateRoute;
