import {
  GET_COMMISSION_PENDING,
  GET_COMMISSION_SUCCESS,
  GET_COMMISSION_ERROR,
  POST_COMMISSION_PENDING,
  POST_COMMISSION_SUCCESS,
  POST_COMMISSION_ERROR,
  PUT_COMMISSION_PENDING,
  PUT_COMMISSION_SUCCESS,
  PUT_COMMISSION_ERROR,
  DELETE_COMMISSION_PENDING,
  DELETE_COMMISSION_SUCCESS,
  DELETE_COMMISSION_ERROR,
} from "../../../actions/master_data/commission";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  data_post: null,
  data_put: false,
  pending: false,
  data_delete: null,
};

const commission = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMISSION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_COMMISSION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_COMMISSION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_COMMISSION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_COMMISSION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_post: action.data,
      };
    case POST_COMMISSION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case PUT_COMMISSION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case PUT_COMMISSION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_put: action.data,
      };
    case PUT_COMMISSION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case DELETE_COMMISSION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DELETE_COMMISSION_SUCCESS:
      return {
        ...state,
        pending: false,
        data_delete: action.data,
      };
    case DELETE_COMMISSION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default commission;
