import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import SideMenu from "../../components/commons/menu/SideMenu";

import Operational from "./operational";
import Shift from "./shift_asst";

// Service
import AirportService from "../../store/actions/master_data/airport";

const Index = ({ t, location }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(location?.state?.tab ?? "shift_assistant");
  const [dataAirport, setDataAirport] = useState([]);

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  };

  useEffect(() => {
    handleAirport();
  }, []);

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            {t("field.schedule")}
          </div>
          <SideMenu
            title={t("field.shiftAssistant")}
            subtitle={t("schedule.list_shift_assistant")}
            toggle={() => setShow("shift_assistant")}
            active={show === "shift_assistant"}
          />
          <SideMenu
            title={t("field.operational")}
            subtitle={t("schedule.list_operational")}
            toggle={() => setShow("operational")}
            active={show === "operational"}
          />
        </div>
        {show === "shift_assistant" && (
          <>
            <Shift dataAirport={dataAirport} />
          </>
        )}
        {show === "operational" && (
          <>
            <Operational />
          </>
        )}
      </div>
    </div>
  );
};

export default withTrans(Index);
