import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import { withTrans } from "../../../../i18n/withTrans";
import { Divider } from "@mui/material";
import { toastError } from "../../../../components/commons/toast";
import Button from "../../../../components/commons/buttons/Button";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import SearchButton from "../../../../components/commons/buttons/SearchButton";
import moment from "moment";
import UserAssistant from "../../../../store/actions/master_data/assistant";
import ConfirmationModal from "../../../../components/commons/modal/ConfirmModal";

const AssistantModal = ({ formik, show, toggle, t, action }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [assistant, setAssistant] = useState([]);
  const handleRefresh = () => {
    let airport_id =
      formik?.values?.flight_type === 0
        ? formik?.values?.airport_id_to
        : formik.values?.flight_type === 2
        ? formik.values?.airport_id_transfer
        : formik?.values?.airport_id_from;
    const dateMoment = moment(
      formik?.values?.flight_type === 2
        ? formik?.values?.date_transfer_to
        : formik?.values?.date
    );
    const getDate = moment().format("YYYY-MM-DD");
    const dow = dateMoment.day() === 0 ? 7 : dateMoment.day();
    new Promise((resolve) => {
      let param = {
        length: 9999,
        search_text: searchText,
        airport_id: airport_id,
        date: formik?.values?.date, 
        time: formik?.values?.time,
      };
      dispatch(UserAssistant.getAssignAssistant(param, resolve));
    }).then((res) => {
      if (res) {
        setAssistant(res?.data);
      }
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [
    searchText, 
    formik?.values?.airport_id_to,
    formik.values?.airport_id_transfer,
    formik?.values?.airport_id_from,
    formik?.values?.date_transfer_to,
    formik?.values?.date
  ]);

  const showConfirmation = (item) => {
    setConfirmModal(!confirmModal);
    setSelectedAssistant(item)
  };

  const handleAddAssistances = (id, name, gender, formik) => {
    let datas = formik?.values?.assistant_list ?? [];
    let i = datas ? datas.findIndex(x => id === x.id) : -1;
    if (i > -1) {
      datas.splice(i, 1);
    } else {
      datas = [...datas, {id: id, name: name, gender: gender}];
    }
    datas = datas;
    formik?.setFieldValue("assistant_list", datas);
  }

  const handleAssistanceIds = () => {
    let datas = selectedAssistant?.values?.assistant_list ?? [];
    let val = {};
    let assistants = [];
    datas.map((item, index) => {
      let objAssistant = { 
        id: item?.id,
        assistant_id: item?.id, 
        assistant_name: item?.name || item?.assistant_name, 
        assistant_gender: item?.gender || item?.assistant_gender,
        is_main: false 
      };
      if (index === 0) {
        objAssistant.is_main = true;
      }
      assistants = [...assistants, objAssistant]
    })
    val.assistant_list = assistants;
    if (assistants.length > 0) {
      if (formik?.values?.passenger_list?.length === 1 && assistants.length > 1) {
        toastError(t('booking.asstCantMoreThanOne'))
      } else {
        action(val);
        toggle();
        setConfirmModal(false);
      }
    } else {
      toastError(t('commons.empty_data_message'))
    }
  }

  const initial = 
  {
    assistant_list: formik?.values?.assistant_list ?? []
  };

  const ButtonShow = ({ id, name, gender, props }) => {
    let values = props?.values?.assistant_list ?? [];
    let isSelected = values && values?.length > 0 ? values.findIndex(x => id === x.id) > -1 : false;
    let title = isSelected ? t('status.assigned') :  t('commons.assign');
    let buttonType = isSelected ? "secondary" : "primary";
    return (
      <div>
        <Button
          title={title}
          type={'button'}
          rounded={true}
          variant={buttonType}
          confirmTitle={t("commons.save")}
          onClick={() => handleAddAssistances(id, name, gender, props)}
          icon
        />
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("booking.availableAssistant")}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container className="pb-40">
            <SearchButton
              placeholder={t("commons.enterKeyword")}
              toggle={(value) => handleSearch(value)}
              showStyle={true}
              width={{ width: "400px" }}
            />
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                overflowX: "clip",
              }}
              className="mt-4"
            >
              <Formik
                enableReinitialize
                initialValues={initial}
              >
                {(props) => (
                  <Form>
                    {assistant?.map((item, index) => {
                      return (
                        <React.Fragment key={`${item.id}`}>
                          <div className="my-3 d-flex justify-content-between">
                            <div className="desc">
                              <div className="text-extra-bold">{item?.name}</div>
                              <div className="normal-title">
                                {item.gender === 1
                                  ? t("commons.men")
                                  : t("commons.woman")}
                              </div>
                            </div>
                            <div className="trigger">
                              <ButtonShow
                                id={item?.id}
                                name={item?.name}
                                gender={item.gender === 1 ? t("commons.men") : t("commons.woman")}
                                props={props}
                              />
                            </div>
                          </div>
                          <Divider orientation="horizontal" />
                        </React.Fragment>
                      )
                    }
                    )}
                    {assistant?.length < 1 && (
                      <div className="text-center">
                        {t("commons.dataNotFound")}
                      </div>
                    )}
                    <div style={{ marginTop: 50 }}>
                      <ButtonModal
                        toggle={toggle}
                        confirmTitle={t("commons.save")}
                        cancelTitle={t("commons.cancel")}
                        confirm={() => showConfirmation(props)}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={handleAssistanceIds}
        icon="info"
        message={t("commons.saveConfirm")}
        isDelete
      />
    </>
  );
};

export default withTrans(AssistantModal);
