import React from "react";
import Button from "../buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

function ButtonModal({
  t,
  deleted,
  toggle,
  confirm,
  confirmTitle,
  cancelTitle,
  cancelIcon,
  icon,
  noIcon,
  disabledConfirm,
  reset,
  hideConfirm,
  type,
  typeConfirm,
  resetTitle,
}) {
  return (
    <div style={{ margin: "20px 0px" }}>
      <div
        style={{
          lineHeight: "30px",
          display: "flex",
          justifyContent: reset ? "space-between" : "flex-end",
          alignItems: "center",
        }}
      >
        {reset && (
          <div>
            <p
              className="text-bold"
              style={{ cursor: "pointer", margin: 0 }}
              onClick={reset}
            >
              {resetTitle ? resetTitle : "Reset"}
            </p>
          </div>
        )}
        <div>
          <Button
            variant="button secondary"
            rounded
            title={cancelTitle ?? t("commons.cancel")}
            onClick={toggle}
            leftIcon={!noIcon ? (icon ? "close" : cancelIcon) : false}
            type={type ?? "Button"}
          />
          {!hideConfirm && (
            <Button
              variant={"button " + (deleted ? "danger" : "primary")}
              style={{ color: "#fff" }}
              rounded
              title={confirmTitle ?? t("commons.yes")}
              onClick={confirm}
              leftIcon={!noIcon ? (icon ? "done" : null) : null}
              type={deleted ? "Button" : typeConfirm ?? "Submit"}
              disabled={disabledConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withTrans(ButtonModal);
