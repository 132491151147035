import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../../utils/User";

import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/commons/table/detailButton";
import Loader from "../../../components/commons/Loader";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import Table from "../../../components/commons/table";
import LabelPills from "../../../components/commons/table/labelPills";
import TableStatus from "../../../components/commons/table/status";
import ShiftAsstModal from "./ShiftAsstModal";
import ModalFilter from "./modalFilter";
import Button from "../../../components/commons/buttons/Button";
import { Role, RoleGroups } from "../../../utils/Constants";

// Service
import ShiftAsstService from "../../../store/actions/schedule/shift_asst";

const Shift_Asst = ({ t, pending, dataAirport }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [dataAssistant, setDataAssistant] = useState([]);
  const [dataAsstRow, setDataAsstRow] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    airport_id: "",
    status_assistant: "",
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const showEditModal = (data) => {
    setModalEdit(!modalEdit);
    setDataAsstRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setModalEdit(!modalEdit);
    };
    dispatch(ShiftAsstService.put(param, callback));
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      airport_id: param?.airport_id,
      status_assistant: param?.status_assistant,
    })
    setShowModalFilter(false);
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const columns = [
    {
      Header: "#ID",
      accessor: "assistant_id",
    },
    {
      Header: t("field.assistant").toUpperCase(),
      accessor: "name",
    },
    {
      Header: t("field.airport").toUpperCase(),
      accessor: "airport",
    },
    {
      Header: "SHIFT",
      accessor: "shift",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let shift = [];
        if (original?.list_shifts.length > 0) {
          original?.list_shifts.map((item, index) => {
            if (item.day_of_week == activeTab) {
              //shift.push(item.shift);
              shift = item.shifts;
            }
          });
        }
        return (
          <div className="row">
            {shift?.length > 0 ? (
              shift?.map((item) => {
                return item?.id === null ? (
                  <LabelPills data={t("field.noScheduleYet")} />
                ) : (
                  <LabelPills
                    data={`${item?.start_time} - ${item?.end_time}`}
                  />
                );
              })
            ) : (
              <LabelPills data={t("field.noScheduleYet")} />
            )}
          </div>
        );
      },
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { status_assistant } = original;
        return (
          <TableStatus
            status={
              status_assistant === "Active"
                ? t("promo.active")
                : t("promo.nonactive")
            }
            color={
              status_assistant === "Active"
                ? "primary"
                : "danger"
            }
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditModal(original)}>
              <span style={{ fontSize: 14 }}>{`Edit ${t(
                "field.shiftAssistant"
              )}`}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show: !RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code)
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        airport_id: paramFilter?.airport_id ? paramFilter?.airport_id : '',
        status_assistant: paramFilter?.status_assistant ? paramFilter?.status_assistant : '',
        search_text: searchText,
        // day_of_week: activeTab ?? null,
      };
      dispatch(ShiftAsstService.get(param, resolve));
    }).then((res) => {
      setDataAssistant(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter, activeTab]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.shiftAssistant")}>
        <div className="tab-menu overflow">
          <div style={{ maxWidth: "680px" }}>
            <Tabs
              value={activeTab}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => {
                setActiveTab(newValue);
              }}
            >
              <Tab label={t("day.mon")} value="1" />
              <Tab label={t("day.tue")} value="2" />
              <Tab label={t("day.wed")} value="3" />
              <Tab label={t("day.thu")} value="4" />
              <Tab label={t("day.fri")} value="5" />
              <Tab label={t("day.sat")} value="6" />
              <Tab label={t("day.sun")} value="7" />
            </Tabs>
          </div>
        </div>
      </HeaderMenu>
      <div className="content-container-side">
        <hr />
        <div className="d-flex mb-4 justify-content-end">
          <SearchButton
            placeholder={t("commons.enterKeyword")}
            toggle={(value) => handleSearch(value)}
            style={{ marginRight: 20 }}
          />
          <Button
            title="Filter"
            size="sm"
            rounded={true}
            variant="button-light btn-lg ml-3"
            onClick={handleFilterModal}
          />
        </div>
        <div className="overflow">
          <Table
            columns={columns.filter(item => item.show !== false)}
            data={dataAssistant ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <ShiftAsstModal
        day={activeTab}
        show={modalEdit}
        toggle={showEditModal}
        data={dataAsstRow}
        action={handleEdit}
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        dataAirport={dataAirport}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status_assistant: props.status_assistant,
            airport_id: props.airport_id,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ shift_asst: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Shift_Asst)
);
