import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import moment from "moment";

import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { Role, RoleAdminBooking, statusBooking, isNotTodayAndTomorrow, corporateGroupType, RoleGroups } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";

import Button from "../../../components/commons/buttons/Button";
import Loader from "../../../components/commons/Loader";
import Card from "../../../components/commons/cards/card";
import CardFlush from "../../../components/commons/cards/cardFlush";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import IconButton from "../../../components/commons/buttons/IconButton";
import PagingLogoModal from "../../../components/commons/modal";
import EditPagingModal from "../modal/ModalEditPaging";
import AssignAssistantModal from "../modal/AssignAssistantModal";
import EvidenceAssistantModal from "../modal/ShowEvidenceModal";

import BookingService from "../../../store/actions/booking";
import CorpPriceService from "../../../store/actions/corp_reservation";
import AirportService from "../../../store/actions/master_data/airport";
import DetailPrice from "./DetailPrice";
import DetailPassenger from "./DetailPassenger";
import DetailPayment from "./DetailPayment";
import { getContactInfoBooking, getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../../utils/Helper";

const DetailBooking = ({ data, error, t, pending, match }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const currentUrl = match?.url?.split("/")?.[1];

  const [airportCrossSelling, setAirportCrossSelling] = useState();
  const [dataDetail, setDataDetail] = useState("");
  const [dataLounge, setDataLounge] = useState([]);
  const [dataTransports, setDataTransports] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [evidenceAssistant, setEvidenceAssistant] = useState(false);
  const [passengerAdultLength, setPassengerAdultLength] = useState(0);
  const [passengerChildLength, setPassengerChildLength] = useState(0);
  const [passengerInfantLength, setPassengerInfantLength] = useState(0);
  const [showPagingLogo, setShowPagingLogo] = useState(false);
  const [showEditPaging, setShowEditPaging] = useState(false);
  const [adminBooking] = useState(
    RoleAdminBooking?.includes(currentUser?.user.role_code)
      ? true
      : false
  );

  const handleGetDetailAirportCrossSelling = (idAirport) => {
    new Promise((resolve) => {
      let param = {
        id: idAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setAirportCrossSelling(
          res?.data?.map((airport) => `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform
          }`)[0]
        );
      }
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(BookingService.get(param, resolve));
    }).then((res) => {
      setDataDetail(res?.data[0]);
      setPassengerAdultLength(getLengthPassAdult(res?.data[0]?.list_order_passengers));
      setPassengerChildLength(getLengthPassChild(res?.data[0]?.list_order_passengers));
      setPassengerInfantLength(getLengthPassInfant(res?.data[0]?.list_order_passengers));
      setDataLounge(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Lounge"
        )
      );
      setDataTransports(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Transport"
        )
      );
      setDataOther(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Other"
        )
      ); 
      if(res?.data[0]?.is_cross_selling && res?.data[0]?.airport_id_cross_selling) {
        handleGetDetailAirportCrossSelling(res?.data[0]?.airport_id_cross_selling)
      }
    });
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const showAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const showEvidenceAssistant = () => {
    setEvidenceAssistant(!evidenceAssistant);
  };

  const handleChangeAssistant = (param) => {
    const callback = () => {
      setAssignModal(!assignModal);
      handleRefresh();
    };
    if (param) {
      dispatch(CorpPriceService.change_assistant(param, callback));
    }
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() => history.push(`/${currentUrl}`)}
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.booking")}`}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.product")}
                desc={
                  <>
                    <div>{dataDetail?.product_name_eng}</div>
                    {dataDetail?.status_go_show !== null && (
                      <div className="normal-text text-primary mt-2">
                        {dataDetail?.status_go_show === 1
                          ? "Go Show"
                          : "Go Show On The Spot"}
                      </div>
                    )}
                  </>
                }
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.bookingId")}
                desc={dataDetail?.unique_transaction_id}
                leftIcon="confirmation_number"
              />
              {dataDetail?.booking_no_from_partner && (
                <CardFlush
                  sideMenu
                  title={`${t("field.bookingId")} ${t("field.partner")}`}
                  desc={dataDetail?.booking_no_from_partner}
                  leftIcon="confirmation_number"
                />
              )}
              <CardFlush
                sideMenu
                title="SDF"
                desc={dataDetail?.sdf ? dataDetail?.sdf : "-"}
                leftIcon="book_online"
              />
              <CardFlush
                sideMenu
                title={t("field.flightType")}
                desc={
                  localStorage.getItem("joumpa_language") === "en" ||
                  localStorage.getItem("joumpa_language") === "en-US"
                    ? dataDetail?.type_label_eng
                    : dataDetail?.type_label
                }
                leftIcon="flight"
              />
              {dataDetail?.status !== 0 && (
                <CardFlush
                  sideMenu
                  title={t("field.assistant")}
                  desc={
                    dataDetail?.list_assistants
                      ? dataDetail?.list_assistants
                          ?.map((item) => {
                            return item?.is_main
                              ? `${item?.assistant_name} (main)`
                              : item?.assistant_name;
                          })
                          .join(", ")
                      : "-"
                  }
                  leftIcon="assignment_ind"
                  last={dataDetail?.order_from !== "web-public" ? false : true}
                />
              )}
              {dataDetail?.order_from !== "web-public" && (
                <CardFlush
                  sideMenu
                  title={t("commons.addedBy")}
                  desc={dataDetail?.adder_name ? dataDetail?.adder_name : "-"}
                  leftIcon="group_add"
                  last
                />
              )}
            </Card>
            <Card className="rounded">
              <CardFlush
                sideMenu
                last
                title={"Status"}
                desc={
                  statusBooking(t)?.find(
                    (status) => status.enum === dataDetail?.status
                  )?.label
                }
                leftIcon={
                  dataDetail?.status ===
                  statusBooking(t)?.find(
                    (status) => status.label === t("status.done")
                  )?.enum
                    ? "done"
                    : "info"
                }
                iconColor={
                  statusBooking(t)?.find(
                    (status) => status.enum === dataDetail?.status
                  )?.color
                }
              />
            </Card>
            {currentUser.user.airport_id !==
              dataDetail?.airport_id_cross_selling &&
              RoleGroups.subAdminRoles.includes(currentUser?.user?.role_code) &&
              dataDetail?.status === 0 && (
                <Button
                  title={`${t("booking.assistantAssign")}`}
                  rounded={true}
                  style={{ width: "100%" }}
                  variant="primary"
                  disabled={isNotTodayAndTomorrow(dataDetail?.date)}
                  onClick={showAssignModal}
                />
              )}
          </div>
        </div>
        <div>
          <HeaderMenu title={t("commons.details")} />
          <div style={{ padding: "0 40px" }}>
            <div>
              <Stepper
                Linear
                activeStep={-1}
                alternativeLabel
                style={{
                  width:
                    dataDetail?.list_order_milestones?.length > 7
                      ? 150 * dataDetail?.list_order_milestones?.length
                      : "100%",
                }}
              >
                {dataDetail?.list_order_milestones?.map((label, index) => (
                  <Step key={label.index} completed={label.status}>
                    <StepButton key={label.index} color="inherit">
                      {localStorage.getItem("joumpa_language") === "en" ||
                      localStorage.getItem("joumpa_language") === "en-US"
                        ? label.name_eng
                        : label.name}
                      <br />{" "}
                      {label.status === 1 && label.updated_at !== null
                        ? moment(label?.updated_at).format("HH:mm")
                        : ""}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="wrapper-detail-card">
              {
                (data = [
                  {
                    label: t("field.customer"),
                    desc:
                      dataDetail?.customer_name ??
                      (dataDetail?.list_order_passengers?.length &&
                        dataDetail?.list_order_passengers[0]?.name),
                  },
                  {
                    label: t("field.contact"),
                    desc: getContactInfoBooking(dataDetail),
                  },
                  {
                    label: t("field.corporate"),
                    desc: dataDetail?.corporate_name,
                  },
                  {
                    label: t("field.cooperationType"),
                    desc: corporateGroupType(
                      t,
                      dataDetail?.corporate_group_type_name
                    )[dataDetail?.corporate_group_type]?.label,
                  },
                  {
                    label: t("field.bookingTime"),
                    desc: `${moment(dataDetail?.booking_date).format(
                      "DD/MM/YYYY"
                    )}, ${moment(dataDetail?.booking_date).format("HH:mm")}`,
                  },
                ].map((item, i) => {
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                }))
              }
              <CardFlush
                list
                label={t("field.airline")}
                desc={`${dataDetail?.airplane_name} / ${dataDetail?.airplane_code}${dataDetail?.airplane_number}`}
                size={{ label: 3, desc: 8 }}
              />
              {dataDetail?.is_cross_selling && (
                <CardFlush
                  list
                  label={t("field.cross_selling")}
                  desc={airportCrossSelling}
                  size={{ label: 3, desc: 8 }}
                />
              )}
              {dataDetail?.type_label === "Transfer" ? (
                (data = [
                  {
                    label: t("field.origin"),
                    desc: `${dataDetail?.airport_code_from} - ${
                      dataDetail?.airport_city_from
                    } - ${dataDetail?.airport_name_from}  ${
                      dataDetail?.airport_country_from !== "Indonesia" &&
                      dataDetail?.airport_country_from !== undefined
                        ? "-" + dataDetail?.airport_country_from
                        : ""
                    }`,
                  },
                  {
                    label: `${t("field.transfer")}`,
                    desc: `${dataDetail?.airport_code_transfer} - ${
                      dataDetail?.airport_city_transfer
                    } - ${dataDetail?.airport_name_transfer} ${
                      dataDetail?.airport_country_transfer !== "Indonesia" &&
                      dataDetail?.airport_country_transfer !== undefined
                        ? "-" + dataDetail?.airport_country_transfer
                        : ""
                    }`,
                  },
                  {
                    label: t("field.destination"),
                    desc: `${dataDetail?.airport_code_to} - ${
                      dataDetail?.airport_city_to
                    } - ${dataDetail?.airport_name_to} ${
                      dataDetail?.airport_country_to !== "Indonesia" &&
                      dataDetail?.airport_country_to !== undefined
                        ? "-" + dataDetail?.airport_country_to
                        : ""
                    }`,
                  },
                  {
                    label: t("field.arrivalTime"),
                    desc:
                      moment(dataDetail?.date).format("DD/MM/YYYY") +
                      " " +
                      dataDetail?.time,
                  },
                  {
                    label: t("field.departureTime"),
                    desc: `${moment(dataDetail?.date_transfer_to).format(
                      "DD/MM/YYYY"
                    )}, ${dataDetail?.time_transfer_to}`,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                )))
              ) : (
                <>
                  <CardFlush
                    list
                    label={t("field.origin")}
                    desc={`${dataDetail?.airport_code_from} - ${
                      dataDetail?.airport_city_from
                    } - ${dataDetail?.airport_name_from}  ${
                      dataDetail?.airport_country_from !== "Indonesia" &&
                      dataDetail?.airport_country_from !== undefined
                        ? "-" + dataDetail?.airport_country_from
                        : ""
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={t("field.destination")}
                    desc={`${dataDetail?.airport_code_to} - ${
                      dataDetail?.airport_city_to
                    } - ${dataDetail?.airport_name_to} ${
                      dataDetail?.airport_country_to !== "Indonesia" &&
                      dataDetail?.airport_country_to !== undefined
                        ? "-" + dataDetail?.airport_country_to
                        : ""
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={
                      dataDetail?.type_label === t("field.departure")
                        ? t("field.departureTime")
                        : t("field.arrivalTime")
                    }
                    desc={`${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${
                      dataDetail?.time
                    }`}
                    size={{ label: 3, desc: 8 }}
                  />
                </>
              )}
              <CardFlush
                list
                label={t("field.note")}
                desc={dataDetail?.passenger_notes ?? "-"}
                size={{ label: 3, desc: 9 }}
                last
              />
            </div>
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card" style={{ opacity: 0.5 }}>
                {t("field.additionalService")}
              </div>
              <Divider orientation="horizontal" />
              {dataLounge?.length || dataTransports?.length || dataOther?.length ? (
                <CardFlush
                  addtService
                  isLounge={dataLounge?.length > 0}
                  isTransport={dataTransports?.length > 0}
                  isOther={dataOther?.length > 0}
                  nameLounge={
                    dataLounge?.length ? dataLounge[0]?.name : t("commons.none")
                  }
                  nameTransport={
                    dataTransports?.length
                      ? dataTransports[0]?.region !== null
                        ? dataTransports[0]?.region +
                          " - " +
                          dataTransports[0]?.name +
                          " ( " +
                          (dataTransports[0]?.total_unit
                            ? dataTransports[0]?.total_unit
                            : "1") +
                          " unit)"
                        : dataTransports[0]?.name +
                          " ( " +
                          (dataTransports[0]?.total_unit
                            ? dataTransports[0]?.total_unit
                            : "1") +
                          " unit)"
                      : t("commons.none")
                  }
                  descOther={
                    dataOther?.length ? dataOther[0]?.name : t("commons.none")
                  }
                />
              ) : (
                <CardFlush
                  addtService
                  isLounge={false}
                  isTransport={false}
                  isOther={false}
                  nameLounge={t("commons.none")}
                  nameTransport={t("commons.none")}
                  descOther={t("commons.none")}
                />
              )}
            </div>
            <DetailPassenger
              dataDetail={dataDetail}
              currentUser={currentUser}
              handleRefresh={handleRefresh}
            />
            <DetailPrice
              dataDetail={dataDetail}
              dataOther={dataOther}
              passengerAdultLength={passengerAdultLength}
              passengerChildLength={passengerChildLength}
              passengerInfantLength={passengerInfantLength}
              dataLounge={dataLounge}
              dataTransports={dataTransports}
            />
            <div className="wrapper-detail-card">
              <div className="text-extra-bold container-card">
                <Row>
                  <Col md={7}>
                    <span style={{ opacity: 0.5 }}>
                      {t("field.pagingInfo")}
                    </span>
                    <span class="ml-2 normal-text text-medium">
                      {dataDetail?.show_joumpa_logo
                        ? `(${t("field.includeLogoJoumpa")})`
                        : `(Not ${t("field.includeLogoJoumpa")})`}
                    </span>
                  </Col>
                  <Col md={5}>
                    <div className="d-flex justify-content-end">
                      <a
                        className="link text-bold clickable"
                        style={{ fontSize: 14 }}
                        onClick={
                          dataDetail?.paging_logo !== null
                            ? () => setShowPagingLogo(!showPagingLogo)
                            : null
                        }
                      >
                        {dataDetail?.paging_logo !== null
                          ? t("field.pagingImage")
                          : ""}
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider orientation="horizontal" />
              <CardFlush
                list
                label={t("field.pagingName")}
                desc={dataDetail?.paging_name ? dataDetail?.paging_name : "-"}
                size={{ label: 4, desc: 8 }}
                descEnd
                last
              />
              {dataDetail?.passenger_type_label_eng === "Corporate" &&
                adminBooking &&
                currentUser.user.airport_id !==
                  dataDetail?.airport_id_cross_selling && (
                  <>
                    <Divider orientation="horizontal" />
                    <IconButton
                      onClick={() => setShowEditPaging(!showEditPaging)}
                      icon="edit"
                      title={`Edit ${t("field.pagingInfo")}`}
                    />
                  </>
                )}
            </div>
            {dataDetail?.passenger_type_label_eng !== "Corporate" && (
              <DetailPayment
                currentUser={currentUser}
                dataDetail={dataDetail}
                handleRefresh={handleRefresh}
                match={match}
              />
            )}
            {dataDetail?.status === 5 && dataDetail?.evidences?.length ? (
              <div className="wrapper-detail-card">
                <div className="text-extra-bold container-card">
                  <Row>
                    <Col md={6} style={{ opacity: 0.5 }}>
                      {t("booking.evidenceAssistant")}
                    </Col>
                    <Col md={6}>
                      <div className="d-flex justify-content-end">
                        <div
                          className="text-primary text-bold clickable"
                          style={{ fontSize: 14 }}
                          onClick={showEvidenceAssistant}
                        >
                          {t("booking.openEvidenceAssistant")}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <EditPagingModal
        show={showEditPaging}
        toggle={() => setShowEditPaging(!showEditPaging)}
        callback={() => {
          setShowEditPaging(!showEditPaging);
          handleRefresh();
        }}
        data={dataDetail}
      />

      <AssignAssistantModal
        data={dataDetail}
        show={assignModal}
        toggle={showAssignModal}
        action={handleChangeAssistant}
        id={match?.params?.id}
      />

      <EvidenceAssistantModal
        show={evidenceAssistant}
        title={t("booking.evidenceAssistant")}
        toggle={() => setEvidenceAssistant(!evidenceAssistant)}
        images={dataDetail?.evidences}
      />

      <PagingLogoModal
        show={showPagingLogo}
        title={t("field.pagingImage")}
        toggle={() => setShowPagingLogo(!showPagingLogo)}
        content={
          <div>
            {process.env.REACT_APP_API_URL +
            dataDetail?.paging_logo?.substr(
              1,
              dataDetail?.paging_logo?.length
            ) ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  dataDetail?.paging_logo?.substr(
                    1,
                    dataDetail?.paging_logo?.length
                  )
                }
                alt={t("field.pagingImage")}
                className="img img-fluid"
                width="100%"
                style={{ objectFit: "cover", width: "100%" }}
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = ({ booking: { error } }) => {
  return { error };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailBooking)
);
