import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import { getUser } from "../../../utils/User";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/commons/table/detailButton";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../../components/commons/Loader";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import Table from "../../../components/commons/table";
import Select from "react-select";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import UserAssistantModal from "./UserAssistantModal";

// Service
import UserAssistant from "../../../store/actions/master_data/assistant";
import { RoleGroups } from "../../../utils/Constants";

const Assistant = ({ t, pending, dataAirport }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const isCenterSPV = RoleGroups.centerSPVRoles.includes(currentUser?.user?.role_code);
  const isSubAdmin = RoleGroups.subAdminRoles.includes(currentUser?.user?.role_code);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataAssistant, setDataAssistant] = useState([]);
  const [dataAssistantRow, setDataAssistantRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [airport, setAirport] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };
  const handleFilterAirport = (value) => {
    if (value) {
      setAirport(value?.value);
    }
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };

    dispatch(UserAssistant.post(param, callback));
  };

  const showEditModal = (data) => {
    setEditModal(!editModal);
    setDataAssistantRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };

    dispatch(UserAssistant.put(param, callback));
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataAssistantRow(data ? data : []);
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (dataAssistantRow) {
      const id = dataAssistantRow?.id;
      const name = dataAssistantRow?.name;
      dispatch(UserAssistant.deleted(id, callback, name));
    }
  };

  const columns = [
    {
      Header: "#ID",
      accessor: "assistant_id",
    },
    {
      Header: t("field.name").toUpperCase(),
      accessor: "name",
    },
    {
      Header: t("field.phone").toUpperCase(),
      accessor: "handphone",
      Cell: ({ row }) => {
        const { original } = row;
        return original?.handphone && original?.country_code 
          ? `${original?.country_code}${original?.handphone}` 
          : original?.handphone && !original?.country_code 
          ? original?.handphone
          : "-";
      },
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const { original } = row;
        let city, code, name;
        if (original?.airport_data !== null) {
          city = original?.airport_data?.city;
          code = original?.airport_data?.code;
          name = original?.airport_data?.name;
        }
        return original?.airport_data !== null ? `${code}-${city}-${name}` : "-";
      },
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
            </MenuItem>
            <MenuItem onClick={() => showDeleteModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show: !isCenterSPV
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        airport_id: airport ? airport : `all`,
        search_text: searchText,
      };
      dispatch(UserAssistant.get(param, resolve));
    }).then((res) => {
      setDataAssistant(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, airport]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, airport]);

  const customSelect = {
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      textAlign: "left",
      fontWeight: "700",
      background: "#ffff",
      borderRadius: "32px",
      padding: "1px 1px 1px 10px",
      height: "48px",
      minWidth: "200px",
      border: "2px solid #E5E5F0",
      borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      marginRight: "7px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    option: (options, state) => ({
      ...options,
      cursor: "pointer",
      textAlign: "left",
      paddingLeft: "15px",
    }),
  };

  const airport_list = dataAirport.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`,
  }));

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.assistant")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        {isSubAdmin ? (
          <Select
            className="ml-3"
            isSearchable={false}
            placeholder={`${currentUser?.user?.airport_code}  -  ${currentUser?.user?.airport_city}`}
            styles={customSelect}
            isDisabled
          />
        ) : (
          <Select
            className="ml-3"
            placeholder={t("field.airport")}
            isSearchable={true}
            options={[
              {
                value: "all",
                label: `${t("commons.all")} ${t("field.airport")}`,
              },
              ...airport_list,
            ]}
            styles={customSelect}
            onChange={(value) => handleFilterAirport(value)}
          />
        )}
        <div className="nav-separator mr-3 ml-3" />
        {!isCenterSPV && (
          <Button
            title={t("commons.add") + " " + t("field.user")}
            leftIcon="add"
            rounded={true}
            variant="primary"
            onClick={showAddModal}
          />
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns.filter(item => item.show !== false)}
            data={dataAssistant ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <UserAssistantModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
        dataAirport={airport_list}
      />

      <UserAssistantModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={dataAssistantRow}
        dataAirport={airport_list}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        param={`` + dataAssistantRow?.name}
        icon="info"
        isDelete
      />
    </>
  );
};

const mapStateToProps = ({ assistant: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Assistant)
);
