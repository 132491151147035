import {
  GET_REPORT_PENDING,
  GET_REPORT_SUCCESS,
  GET_REPORT_ERROR,
  GET_RKAP_TOTAL_PENDING,
  GET_RKAP_TOTAL_SUCCESS,
  GET_RKAP_TOTAL_ERROR,
  POST_REPORT_PENDING,
  POST_REPORT_SUCCESS,
  POST_REPORT_ERROR,
  PUT_REPORT_PENDING,
  PUT_REPORT_SUCCESS,
  PUT_REPORT_ERROR,
  DELETE_REPORT_PENDING,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
} from "../../actions/report";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_add: false,
  data_add: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case GET_RKAP_TOTAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_RKAP_TOTAL_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_RKAP_TOTAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_REPORT_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_REPORT_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_REPORT_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case PUT_REPORT_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_REPORT_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_REPORT_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DELETE_REPORT_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_REPORT_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default report;
