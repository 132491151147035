import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";

import BarChart from "../../../components/commons/charts/bar";
import PieChart from "../../../components/commons/charts/pie";
import Card from "../../../components/commons/cards/card";
import CardBody from "../../../components/commons/cards/cardBody";
import Loader from "../../../components/commons/Loader";
import { withTrans } from "../../../i18n/withTrans";

// Service
import DataAnalyticsService from "../../../store/actions/data_analytics";

const StatistikPareto = ({ t, pendingBarChart, pendingPieChart, airport_id, month, year, }) => {
  const dispatch = useDispatch();
  const [dataChart, setDataChart] = useState([]);
  const [dataLabel, setDataLabel] = useState([]);
  const [dataCumulative, setDataCumulative] = useState([]);
  const [dataPieChart, setDataPieChart] = useState([]);

  const handleChart = () => {
    new Promise((resolve) => {
      let param = {
        airport_id: airport_id ?? "",
        month: month,
        year: year,
      };
      dispatch(DataAnalyticsService.getPieChartPareto(param, resolve));
    }).then((res) => {
      if (res?.data?.corporate_percentage !== null && res?.data?.regular_percentage !== null) {
        setDataPieChart([{
          data: [res?.data?.corporate_percentage, res?.data?.regular_percentage],
          backgroundColor: [
            "#3BBC7D",
            "#FF416C",
          ],
        }])
      } else {
        setDataPieChart([]);
      }
    });
  };

  const handleGrafikPareto = () => {
    new Promise((resolve) => {
      let param = {
        airport_id: airport_id ?? "",
        month: month,
        year: year,
        length: 999,
        page: 1,
      };
      dispatch(DataAnalyticsService.getGrafikPareto(param, resolve));
    }).then((res) => {
      let arrayTotal = [];
      let arrayAgent = [];
      let arrayCumulative = [];
      res.data.map((item, index) => {
        arrayAgent.push(item.name);
        arrayTotal.push(parseInt(item.grand_total));
        arrayCumulative.push(item.cumulative_percentage);
      });

      setDataChart(arrayTotal);
      setDataLabel(arrayAgent);
      setDataCumulative(arrayCumulative);
    });
  };

  useEffect(() => {
    handleGrafikPareto();
    handleChart();
  }, [airport_id, month, year]);

  return (
    <>
      <Card rounded className="col-md">
        <div className="p-2">
          <p className="text-bold">
            {t("dataAnalytics.titlePareto")}
          </p>
        </div>
        <Divider orientation="horizontal" />
        <CardBody>
          {pendingPieChart ? (
            <Loader cardContent loading={pendingPieChart} />
          ) : !pendingPieChart && dataPieChart?.length ? (
            <PieChart
              labels={["Business to Business (B to B)", "Business to Customer (B to C)"]}
              datasets={dataPieChart}
              pending_chart={pendingPieChart}
            />
          ) : (
            <div className="text-center">
              {t("commons.dataNotFound")}
            </div>
          )}
        </CardBody>
      </Card>
      <Card rounded className="col-md">
        <div className="p-2">
          <p className="text-bold">
            {t("dataAnalytics.titleParetoMarket")}
          </p>
        </div>
        <Divider orientation="horizontal" />
        <div className="my-3 py-2">
          {pendingBarChart ? (
            <Loader cardContent loading={pendingBarChart} />
          ) : !pendingBarChart && dataChart?.length ? (
            <BarChart
              labels={dataLabel}
              datasets={[
                {
                  label: t("field.sales"),
                  data: dataChart,
                  fill: true,
                  borderColor: "#04AA59",
                  borderRadius: 5,
                  barPercentage: 0.5,
                  yAxisID: 'y',
                  order: 1
                },
                {
                  label: `%${t("dataAnalytics.cumulative")}`,
                  isPercentage: true,
                  data: dataCumulative,
                  fill: false,
                  borderColor: "#EDAA21",
                  type: 'line',
                  yAxisID: 'y1',
                  order: 0
                },
              ]}
              data_length={dataChart?.length}
              height={400}
              stepSize={1}
              yTickDisplay
            />
          ) : (
            <div className="text-center">
              {t("commons.dataNotFound")}
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = ({ data_analytics: { pending: pendingPieChart }, data_pareto: { pending: pendingBarChart } }) => {
  return { pendingPieChart, pendingBarChart };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(StatistikPareto));
