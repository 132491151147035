import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import "../../cards/card.scss";

const LabelPlus = ({ data, idRow }) => {
  let labelArray = [];
  return (
    <>
      {data?.map((item, index) => {
        if (data?.length > 2 && index > 1 && index < data?.length - 1) {
          labelArray.push(
            <div key={index}>
              <div className="my-1">
                <span className="text-center mb-2">{item?.name}</span>
              </div>
              <Divider
                style={{ background: "white" }}
                orientation="horizontal"
              />
            </div>
          );
        } else if (data?.length > 2 && index > 1 && index === data?.length - 1) {
          labelArray.push(
            <div className="my-1" key={index}>
              <span className="text-center mb-2">{item?.name}</span>
            </div>
          );
        }
      })}
      {data?.map((item, index) =>
        index > 1 ? (
          <>
            <ReactTooltip
              place="bottom"
              type="dark"
              effect="float"
              id={`tooltip-${idRow}`}
            >
              {labelArray}
            </ReactTooltip>
          </>
        ) : (
          <label className="rounded-label mr-2 p-1 px-3">{item?.name}</label>
        )
      )}
      {data?.length > 2 ? (
        <div className="text-right">
          <a
            className="green-label text-extra-bold"
            data-tip="React-tooltip"
            data-for={`tooltip-${idRow}`}
          >
            + {data?.length - 2}
          </a>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default LabelPlus;
