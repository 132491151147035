import Chart from "chart.js/auto";
import React, { useState, useRef, useEffect } from "react";
import _uniqueId from "lodash/uniqueId";
import Loader from "../Loader";

let chart;

const LineChart = ({
  height = 250,
  labels = ["Jan", "Feb", "March"],
  options = {
    yTickDisplay: true,
  },
  stepSize = 2,
  datasets = [
    {
      label: "Sales",
      data: [86, 67, 91],
      fill: false,
    },
  ],
  pending_chart
}) => {
  const chartRef = useRef(null);
  const [id] = useState(_uniqueId("linechart-"))
  useEffect(() => {
    buildChart();
  });

  const buildChart = () => {
    if (typeof chart !== "undefined") chart.destroy();
    const myChartRef = chartRef.current.getContext("2d");

    datasets.forEach((elem) => {
      if (elem.fill) {
        let bgStartColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, ".6)")
            : "rgba(0, 166, 207, .6)";
        let bgStopColor =
          "borderColor" in elem
            ? elem.borderColor.replace(/[\d\.]+\)$/g, "0)")
            : "rgba(0, 166, 207, 0)";

        let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height);
        bgGradient.addColorStop(0, bgStartColor);
        bgGradient.addColorStop(1, bgStopColor);

        elem.backgroundColor = bgGradient;
      }
    });

    chart = new Chart(myChartRef, {
      type: "line",
      data: {
        //Bring in data
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                let label2 = context?.dataset?.label2 || "";

                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  if (context.dataset.nonParsed) {
                    label += context.parsed.y
                  } else {
                    label += new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    }).format(context.parsed.y);
                  }
                }

                if (label2) {
                  label2 += ": ";
                }
                if (context.raw.x !== null && context.raw.x !== undefined) {
                  label2 += context.raw.x;
                }
                return [label, label2];
              },
            },
          },
        },
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 4,
          },
          point: {
            borderWidth: 3.5,
            radius: 3,
          },
        },
        legend: {
          position: "bottom",
          align: "start",
        },
        scales: {
          y: {
            // ticks: {
            //   display:
            //     "yTickDisplay" in options ? options.yTickDisplay : true,
            //   stepSize: stepSize,
            // },
            gridLines: {
              display:
                "yGridDisplay" in options ? options.yGridDisplay : true,
              // drawOnChartArea: "xdrawOnChartArea" in options ? options.xdrawOnChartArea : true,
            },
          },
          x: {
            ticks: {
              display:
                "xTickDisplay" in options ? options.xTickDisplay : true,
              stepSize: stepSize,
            },
            gridLines: {
              display:
                "xGridDisplay" in options ? options.xGridDisplay : true,
              // drawOnChartArea: "ydrawOnChartArea" in options ? options.ydrawOnChartArea : true,
            },
          },
        },
      },
    });
  };

  return (
    <>
      <div style={{ height: height + "px", position: "relative" }}>
        {pending_chart && <Loader loading={pending_chart} />}
        <canvas id={id} ref={chartRef} />
      </div>
    </>
  );
};

export default LineChart;
