import React from "react";
import { Row, Col } from "reactstrap";

import Input from "../../../../components/forms/Input";
import Select2 from "../../../../components/forms/Select2";
import DynamicForm from "../../../../components/forms/DynamicForm";
import { withTrans } from "../../../../i18n/withTrans";
import { getUser } from "../../../../utils/User";
import { Role } from "../../../../utils/Constants";

const DetailLounge = ({ t, formik, idx }) => {
  const currentUser = getUser();
  const isCentralAdmin = currentUser?.user.role_code === Role.Central_admin;
  const index = idx;

  return (
    <>
      <Input
        name={`lounges[${index}].name`}
        errors={formik.errors}
        touched={formik.touched}
        {...formik.getFieldProps(`lounges[${index}].name`)}
        title={t("field.lounge")}
        type="text"
        disabled={!isCentralAdmin}
      />
      <Select2
        title={t('field.routeType')}
        name={`lounges[${index}].route_type`}
        options={[
          { value: "international", label: t("commons.international") },
          { value: "domestic", label: t("commons.domestic") }
        ]}
        errors={formik?.errors}
        touched={formik?.touched}
        value={formik?.values?.lounges[index].route_type}
        onChange={(name, value) => {
            formik?.setFieldValue(`lounges[${index}].route_type`, value);
        }}
        onBlur={formik?.setFieldTouched}
        disabled={!isCentralAdmin}
      />
      <Row style={{ marginTop: 20 }}>
        <Col md={4} style={{ paddingRight: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("commons.adult")}</label>
          </div>
        </Col>
        <Col md={4} style={{ paddingLeft: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("commons.child")}</label>
          </div>
        </Col>
        <Col md={4} style={{ paddingLeft: "0px" }}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <label className="form-label">{t("commons.infantLabel")}</label>
          </div>
        </Col>
      </Row>
      <DynamicForm
        input={`lounges[${index}].price_adult`}
        nameCenter={`lounges[${index}].price_child`}
        name={`lounges[${index}].price_infant`}
        placeholderLeft={t("commons.adult")}
        placeholderCenter={t("commons.child")}
        placeholder={t("commons.infantLabel")}
        typeLeft="number"
        type="number"
        typeCenter="number"
        currencyLeft
        currencyCenter
        currency
        formik={formik}
        size={{ title: 4, center: 4, right: 4, }}
        disabledLeft={!isCentralAdmin}
        disabledCenter={!isCentralAdmin}
        disabledRight={!isCentralAdmin}
      />
    </>
  );
};

export default withTrans(DetailLounge);
