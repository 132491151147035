import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";

import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import Select2 from "../../../components/forms/Select2";
import Input from "../../../components/forms/Input";
import DynamicForm from "../../../components/forms/DynamicForm";
import InputPrepend from "../../../components/forms/InputPrepend";
import Checkbox from "../../../components/forms/Checkbox";
import TooltipTaxAdtService from "../../../components/tooltipTaxAdtService";
import { withTrans } from "../../../i18n/withTrans";
import { getRouteTypeByFlightType, numberWithCommas } from "../../../utils/Helper";

import AirportService from "../../../store/actions/master_data/airport";
import DropdownLounge from "../../../components/commons/dropdowns/DropdownLounge";

const AdtServiceReservation = ({ formik, button, t }) => {
  const dispatch = useDispatch();
  const [dataLounge, setDataLounge] = useState([]);
  const [showTransports, setShowTransports] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [adtServiceIncludeConcession, setAdtServiceIncludeConcession] = useState(false);
  const [adtServiceIncludePPN, setAdtServiceIncludePPN] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const toggleTransportation = () => {
    setShowTransports((showTransports) => !showTransports);
  }

  const toggleLounge = () => {
    setShowLounge((showLounge) => !showLounge);
  }

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        id:
          formik?.values?.flight_type === 0
            ? formik?.values?.airport_id_to
            : formik?.values?.flight_type === 2
            ? formik?.values?.airport_id_transfer
            : formik?.values?.airport_id_from,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      const lounges = !formik?.values?.loungePrice ? res?.data[0]?.lounges : formik?.values?.loungePrice;

      const routeType = getRouteTypeByFlightType(
        formik?.values?.flight_type,
        formik?.values?.airport_uniform_from,
        formik?.values?.airport_uniform_to
      );
  
      setDataLounge(lounges?.filter((item) => item?.route_type === routeType));
    });
  }, []);

  const lounge_list = dataLounge.map((item) => ({
    label: (<DropdownLounge lounge={item} loungePrice={formik?.values?.loungePrice} />),
    value: item.id,
    name: `${item?.name} (${item?.route_type})`,
    adult_price: formik?.values?.loungePrice
      ? item.adult_price
      : item.price_adult,
    child_price: formik?.values?.loungePrice
      ? item.child_price
      : item.price_child,
    infant_price: formik?.values?.loungePrice
      ? item.infant_price
      : item.price_infant,
  }));

  useEffect(() => {
    if (!formik?.values?.lounge) return;

    const index = lounge_list?.map((e) => e.value).indexOf(formik?.values?.lounge);

    if (index > -1) {
      formik.setFieldValue(
        "total_lounge_adult",
        formik?.values?.lounge_adult_number * lounge_list[index]?.adult_price
      );
      formik.setFieldValue(
        "total_lounge_child",
        formik?.values?.lounge_child_number * lounge_list[index]?.child_price
      );
      formik.setFieldValue(
        "total_lounge_infant",
        formik?.values?.lounge_infant_number * lounge_list[index]?.infant_price
      );
      formik.setFieldValue(
        "total_lounges",
        formik?.values?.total_lounge_adult +
          formik?.values?.total_lounge_child +
          formik?.values?.total_lounge_infant
      );
    }
  }, [
    formik?.values?.lounge_adult_number,
    formik?.values?.lounge_child_number,
    formik?.values?.lounge_infant_number,
    formik?.values?.total_lounge_adult,
    formik?.values?.total_lounge_child,
    formik?.values?.total_lounge_infant,
  ]);
  
  const dataCars = formik?.values?.productTransports?.length
    ? formik?.values?.productTransports.map((item) => ({
        label: (
          <>
            <div className="d-flex justify-content-between">
              <div className="normal-title">{item?.car_name}</div>
              <div className="normal-title text-extra-bold">
                {`${item?.capacity} ${t("field.person")}`}
              </div>
              <div className="normal-title text-extra-bold">
                {item?.price == null
                  ? "Rp 0"
                  : "Rp " + numberWithCommas(item?.price)}
              </div>
            </div>
          </>
        ),
        value: item?.id,
        name: item?.car_name,
        price: item?.price,
      }))
    : [];

  const total_tax = (price) => {
    if ((formik?.values?.additional_service_is_include_concession == 1) 
      && (formik?.values?.additional_service_is_include_ppn == 1)) {
      return 0;
    }
    if (
      (!(formik?.values?.additional_service_is_include_concession == 1) 
        && (formik?.values?.additional_service_is_include_ppn == 1)) ||
      ((formik?.values?.additional_service_is_include_ppn == 1) 
        && !(formik?.values?.additional_service_is_include_concession == 1))
    ) {
      const total = (price * formik?.values?.additional_service_concession_percentage) / 100;
      return total;
    }
    if (
      (!(formik?.values?.additional_service_is_include_ppn == 1) 
        && (formik?.values?.additional_service_is_include_concession == 1)) ||
      ((formik?.values?.additional_service_is_include_concession == 1) 
        && !(formik?.values?.additional_service_is_include_ppn == 1))
    ) {
      const total = (price * formik?.values?.additional_service_ppn_percentage) / 100;
      return total;
    }
    if (!(formik?.values?.additional_service_is_include_concession == 1) 
      && !(formik?.values?.additional_service_is_include_ppn == 1)) {
      let concession_percentage = formik?.values?.additional_service_concession_percentage ?? 0;
      let ppn_percentage = formik?.values?.additional_service_ppn_percentage ?? 0;
      const total =
        (price * concession_percentage) / 100 +
        ((price + price * (concession_percentage / 100)) *
          ppn_percentage) /
          100;
      return total;
    }
  };

  useEffect(() => {
    if (
      formik?.values?.additional_service_dpp !== null && 
      formik?.values?.additional_service_dpp !== 0
    ) {
      formik?.setFieldValue(
        "additional_service_total_price",
        formik?.values?.additional_service_price + total_tax(formik?.values?.additional_service_dpp)
      );
    } else {
      formik?.setFieldValue(
        "additional_service_total_price",
        formik?.values?.additional_service_price + total_tax(formik?.values?.additional_service_price)
      );
    }
  }, [
    adtServiceIncludeConcession,
    adtServiceIncludePPN,
    formik?.values?.additional_service_concession_percentage,
    formik?.values?.additional_service_ppn_percentage,
  ]);

  return (
    <Row>
      <Col md={9}>
        <Card className="rounded">
          <CardHeader title={t("field.additionalService")} />
          <CardBody>
            <div className="wrapper-detail-card">
              <div
                className="d-flex justify-content-between container-card"
                style={{ fontSize: 14 }}
              >
                <div className="text-extra-bold">{t("field.lounge")}</div>
                <div onClick={toggleLounge} style={{ cursor: "pointer" }}>
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showLounge && (
                <>
                  <div className="p-3 pt-0">
                    <Select2
                      clearable={!!formik.values?.lounge}
                      name="lounge"
                      title={t("field.lounge")}
                      errors={formik.errors}
                      options={lounge_list}
                      touched={formik.touched}
                      value={formik.values?.lounge}
                      onChange={(name, value) => {
                        const index = lounge_list
                          ?.map(function (e) {
                            return e.value;
                          })
                          .indexOf(value);
                        if (index > -1) {
                          formik.setFieldValue("lounge_name", lounge_list[index]?.name);
                          formik.setFieldValue("lounge_price_adult", lounge_list[index]?.adult_price);
                          formik.setFieldValue("lounge_price_child", lounge_list[index]?.child_price);
                          formik.setFieldValue("lounge_price_infant", lounge_list[index]?.infant_price);
                        }
                        formik.setFieldValue("lounge", value);
                        if (formik?.values?.loungePrice) {
                          formik.setFieldValue("corporate_lounge_id", value);
                        } else {
                          formik.setFieldValue("lounge_id", value);
                        }
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Row style={{ marginTop: 20 }}>
                      <Col md={4} style={{ paddingRight: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.adult")}
                          </label>
                        </div>
                      </Col>
                      <Col md={4} style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.child")}
                          </label>
                        </div>
                      </Col>
                      <Col md={4} style={{ paddingLeft: "0px" }}>
                        <div className="form-group" style={{ marginBottom: 0 }}>
                          <label className="form-label">
                            {t("commons.infantLabel")}
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <DynamicForm
                      input="lounge_adult_number"
                      nameCenter="lounge_child_number"
                      name="lounge_infant_number"
                      placeholderLeft={t("commons.adult")}
                      placeholderCenter={t("commons.child")}
                      placeholder={t("commons.infantLabel")}
                      typeLeft="number"
                      type="number"
                      typeCenter="number"
                      minLeft="0"
                      minCenter="0"
                      min="0"
                      formik={formik}
                      size={{ title: 4, center: 4, right: 4 }}
                    />
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">Total</div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithCommas(formik?.values?.total_lounges)}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.adult")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithCommas(formik?.values?.total_lounge_adult)}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.child")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithCommas(formik?.values?.total_lounge_child)}
                    </div>
                  </div>
                  <div className="d-flex card-body justify-content-between border-top p-3">
                    <div className="normal-title text-extra-bold">
                      {t("commons.infantLabel")}
                    </div>
                    <div className="normal-title text-extra-bold">
                      Rp {numberWithCommas(formik?.values?.total_lounge_infant)}
                    </div>
                  </div>
                </>
              )}
            </div>
            {dataCars?.length > 0 && (
              <div className="wrapper-detail-card">
                <div
                  className="d-flex justify-content-between container-card"
                  style={{ fontSize: 14 }}
                >
                  <div className="text-extra-bold">
                    {t("field.transportation")}
                  </div>
                  <div
                    onClick={toggleTransportation}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                </div>
                {showTransports && (
                  <div style={{ padding: 15 }}>
                    <Select2
                      clearable={!!formik.values?.car_type}
                      name="car_type"
                      errors={formik.errors}
                      title={t("field.carType")}
                      touched={formik.touched}
                      options={dataCars}
                      value={formik.values?.car_type}
                      onChange={(name, value) => {
                        const index = dataCars
                          ?.map(function (e) {
                            return e.value;
                          })
                          .indexOf(value);
                        if (index > -1) {
                          const car = {
                            name: value ? dataCars[index].name : "",
                            capacity: value ? dataCars[index].capacity : "",
                            price: value ? dataCars[index].price : "",
                            id: value ? dataCars[index].value : "",
                          };
                          formik.setFieldValue("orderExtras.car", car);
                        }
                        formik.setFieldValue("car_type", value);
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                    <Input
                      title={t("field.totalUnit")}
                      name="total_unit"
                      type="number"
                      min={0}
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("total_unit")}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                )}
              </div>

            )}
            
            <div className="wrapper-detail-card">
              <div
                className="d-flex justify-content-between container-card"
                style={{ fontSize: 14 }}
              >
                <div className="text-extra-bold">
                  {t("masterData.others")}
                </div>
                <div
                  onClick={() => setShowOthers(showOthers => !showOthers)}
                  style={{ cursor: "pointer" }}
                >
                  <span className="material-icons-round left-icon">
                    expand_more
                  </span>
                </div>
              </div>
              {showOthers && (
                <div style={{ padding: 15 }}>
                  <Input
                    title={t("field.desc")}
                    name="additional_service_description"
                    type="text"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("additional_service_description")}
                  />
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="additional_service_is_include_concession"
                    checked={formik?.values?.additional_service_is_include_concession}
                    onChange={(e) => {
                      if (formik?.values?.additional_service_is_include_concession) {
                        formik?.setFieldValue("additional_service_is_include_concession", null);
                        setAdtServiceIncludeConcession(false);
                      } else {
                        formik?.setFieldValue("additional_service_is_include_concession", ["1"]);
                        setAdtServiceIncludeConcession(true);
                      }
                    }}
                    value={1}
                    label={t("product.includes_concession")}
                  />
                  <InputPrepend
                    value={formik?.values?.additional_service_concession_percentage}
                    {...formik?.getFieldProps("additional_service_concession_percentage")}
                    name="additional_service_concession_percentage"
                    label="concession_percentage"
                    title={`% ${t("product.concession")}`}
                    inputmode="numeric"
                    type="number"
                    errors={formik?.errors}
                    touched={formik?.touched}
                    placeholder={t("promo.percentage")}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onChange={(data) => {
                      const val = data.target.value;
                      formik?.setFieldValue("additional_service_concession_percentage", val);
                    }}
                  />
                  <Checkbox
                    style={{ marginTop: "30px" }}
                    name="additional_service_is_include_ppn"
                    checked={formik?.values?.additional_service_is_include_ppn}
                    onChange={(e) => {
                      if (formik?.values?.additional_service_is_include_ppn) {
                        formik?.setFieldValue("additional_service_is_include_ppn", null);
                        setAdtServiceIncludePPN(false);
                      } else {
                        formik?.setFieldValue("additional_service_is_include_ppn", ["1"]);
                        setAdtServiceIncludePPN(true);
                      }
                    }}
                    value={1}
                    label={t("product.includes_ppn")}
                  />
                  <InputPrepend
                    value={formik?.values?.additional_service_ppn_percentage}
                    {...formik?.getFieldProps("additional_service_ppn_percentage")}
                    name="additional_service_ppn_percentage"
                    label="ppn_percentage"
                    title="% PPN"
                    inputmode="numeric"
                    type="number"
                    errors={formik?.errors}
                    touched={formik?.touched}
                    placeholder={t("promo.percentage")}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    onChange={(data) => {
                      const val = data.target.value;
                      formik?.setFieldValue("additional_service_ppn_percentage", val);
                    }}
                  />
                  <InputPrepend
                    mb="0"
                    errors={formik?.errors}
                    touched={formik?.touched}
                    title="DPP"
                    multiple={[
                      {
                        prefix: "Rp ",
                        isCurrency: true,
                        thousandSeparator: true,
                        name: "additional_service_dpp",
                        value: formik?.values?.additional_service_dpp,
                        placeholder: "DPP",
                        onChange: (data) => {
                          const val = data.target.value;
                          const dpp = Number(val.replace(/[^0-9\.-]+/g, ""));
                          formik?.setFieldValue(
                            "additional_service_dpp",
                            dpp
                          );
                          if (dpp !== null && dpp !== 0) {
                            formik?.setFieldValue(
                              "additional_service_total_price",
                              formik?.values?.additional_service_price + total_tax(dpp)
                            );
                          } else {
                            formik?.setFieldValue(
                              "additional_service_total_price",
                              formik?.values?.additional_service_price
                                + total_tax(formik?.values?.additional_service_price)
                            );
                          }
                        },
                        onKeyDown: (e) => {
                          exceptThisSymbols.includes(e.key) &&
                            e.preventDefault();
                        },
                      },
                    ]}
                  />
                  <InputPrepend
                    mb="0"
                    errors={formik?.errors}
                    touched={formik?.touched}
                    title={t("field.price")}
                    multiple={[
                      {
                        prefix: "Rp ",
                        isCurrency: true,
                        thousandSeparator: true,
                        name: "additional_service_price",
                        value: formik?.values?.additional_service_price,
                        placeholder: `${t("field.price")}`,
                        onChange: (data) => {
                          const val =
                            data.target.value;
                          const price = Number(
                            val.replace(
                              /[^0-9\.-]+/g,
                              ""
                            )
                          );
                          formik?.setFieldValue(
                            "additional_service_price",
                            price
                          );
                          if (
                            formik?.values?.additional_service_dpp !== null && 
                            formik?.values?.additional_service_dpp !== 0
                          ) {
                            formik?.setFieldValue(
                              "additional_service_total_price",
                              price + total_tax(formik?.values?.additional_service_dpp)
                            );
                          } else {
                            formik?.setFieldValue(
                              "additional_service_total_price",
                              price + total_tax(price)
                            );
                          }
                        },
                        onKeyDown: (e) => {
                          exceptThisSymbols.includes(e.key) && e.preventDefault()
                        },
                      },
                    ]}
                  />
                   <InputPrepend
                    mt="0"
                    errors={formik?.errors}
                    touched={formik?.touched}
                    title={
                      <div className="d-flex align-items-center">
                        {`Total ${t("field.price")}`}
                        {(!formik?.values?.additional_service_is_include_ppn ||
                          !formik?.values?.additional_service_is_include_concession) && (
                            <TooltipTaxAdtService
                              include_ppn={formik?.values?.additional_service_is_include_ppn == 1 ? true : false}
                              include_concession={formik?.values?.additional_service_is_include_concession == 1 ? true : false}
                              ppn_percentage={formik?.values?.additional_service_ppn_percentage}
                              concession_percentage={formik?.values?.additional_service_concession_percentage}
                              price={formik?.values?.additional_service_dpp ?? formik?.values?.additional_service_price}
                            />
                        )}
                      </div>
                    }
                    multiple={[
                      {
                        disabled: true,
                        prefix: "Rp ",
                        isCurrency: true,
                        thousandSeparator: true,
                        name: "additional_service_total_price",
                        value: formik?.values?.additional_service_total_price,
                        placeholder: `Total ${t("field.price")}`,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport }) => {
  return { airport };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(AdtServiceReservation)
);
