import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import AssistantModal from "./modal/AssistantModal";
import Card from "../../../components/commons/cards/card";
import CardBody from "../../../components/commons/cards/cardBody";
import CardFlush from "../../../components/commons/cards/cardFlush";
import CardFlushActivatedIcon from "../../../components/commons/cards/cardFlushActivatedIcon";
import CardHeader from "../../../components/commons/cards/cardHeader";
import SummaryCheckoutPrice from "../../../components/summaryCheckoutPrice";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";
import { getLengthPassAdult, getLengthPassChild, getLengthPassInfant, numberWithCommas } from "../../../utils/Helper";

import CorpReservService from "../../../store/actions/corp_reservation";

const Checkout = ({ button, formik, data, t }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const [groupPrice, setGroupPrice] = useState(0);
  const [transportPrice, setTransportPrice] = useState(0);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [dataCheckout, setDataCheckout] = useState(formik?.values);
  const [passengersAdult, setPassengersAdult] = useState(0);
  const [passengersChild, setPassengersChild] = useState(0);
  const [passengersInfant, setPassengersInfant] = useState(0);
  const [showPriceService, setShowPriceService] = useState(false);
  const [showPriceUnderQuota, setShowPriceUnderQuota] = useState(false);
  const [showLounge, setShowLounge] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [assistantModal, setAssistantModal] = useState(false);

  useEffect(() => {
    getPriceFromApi();
  }, [data]);

  const getPriceFromApi = () => {
    const values = formik?.values;

    new Promise((resolve) => {
      if (values?.passenger_list?.length < 1) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.passenger")}!`
        );
        return;
      }

      let param = {
        additional_service_description: values?.additional_service_description || null,
        additional_service_concession_percentage: 
          parseFloat(values?.additional_service_concession_percentage),
        additional_service_ppn_percentage: 
          parseFloat(values?.additional_service_ppn_percentage),
        additional_service_price: values?.additional_service_price || null,
        additional_service_dpp: values?.additional_service_dpp || null,
        additional_service_is_include_ppn: 
          values?.additional_service_is_include_ppn == 1 ? true : false,
        additional_service_is_include_concession: 
          values?.additional_service_is_include_concession == 1 ? true : false,
        airplane_name: values?.airplane_name,
        airplane_name_transfer_to: values?.airplane_name_transfer_to,
        airplane_number: values?.airplane_number,
        airplane_number_transfer_to: values?.airplane_number_transfer_to,
        airport_id_from: values?.airport_id_from,
        airport_id_to: values?.airport_id_to,
        airport_id_transfer: values?.airport_id_transfer,
        assistant_id: values?.assistant_id,
        bookers_title: values?.bookers_title,
        bookers_name: values?.bookers_name,
        bookers_nationality: values?.bookers_nationality,
        bookers_email: values?.bookers_email,
        bookers_phone: values?.bookers_phone,
        corporate_id: values?.corporate_id,
        corporate_lounge_id: values?.corporate_lounge_id || null,
        corporate_price_id: values?.corporate_price_id,
        corporate_type_group_id: values?.corporate_type_group_id,
        corporate_type_group_property_id: values?.corporate_type_group_property_id,
        corporate_type_id: values?.corporate_type_id,
        customer_id: null,
        date: values?.date,
        date_transfer_to: values?.date_transfer_to,
        lounge_adult_number: values?.lounge_adult_number || 0,
        lounge_child_number: values?.lounge_child_number || 0,
        lounge_infant_number: values?.lounge_infant_number || 0,
        lounge_id: values?.lounge_id || null,
        passenger_list: values?.passenger_list,
        passenger_notes: values?.passenger_notes,
        paging_name: values?.paging_name,
        price_period_id: values?.price_period_id,
        product_code: values?.product_code,
        product_list_id: values?.product_list_id,
        promo_id: values?.promo_id,
        region: values?.region,
        time: values?.time,
        time_transfer_to: values?.time_transfer_to,
        transport_price_id: values?.orderExtras?.car?.id || null,
        transport_number: values?.total_unit,
        type: values?.flight_type + 1,
      };
      dispatch(CorpReservService.calculate_price(param, resolve));
    }).then((res) => {
      setDataCheckout({...formik?.values, ...res?.data});
      setPassengersAdult(getLengthPassAdult(values?.passenger_list));
      setPassengersChild(getLengthPassChild(values?.passenger_list));
      setPassengersInfant(getLengthPassInfant(values?.passenger_list));
      setGrandTotalPrice(res?.data?.total_price);
      setGroupPrice(res?.data?.price_service_original);
      setTransportPrice(res?.data?.transportPrice);
      formik.setFieldValue("total_service_price", res?.data?.price_service);
      formik.setFieldValue("total_lounge_price", res?.data?.totalLoungePrice);
      formik.setFieldValue("total_transport_price", res?.data?.totalTransportPrice);
    });
  };

  const toggleLounge = () => {
    setShowLounge(!showLounge);
  };

  const toggleTransport = () => {
    setShowTransport(!showTransport);
  };

  const togglePriceService = () => {
    setShowPriceService(!showPriceService);
  };

  const handleDeleteAsst = (formik) => {
    formik?.setFieldValue("assistant_list", null);
  };
  
  const handleAddAssistant = (param) => {
    formik?.setFieldValue("assistant_list", param?.assistant_list);
    setDataCheckout({...dataCheckout, assistant_list: param?.assistant_list});
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <Card className="rounded">
            <CardHeader title="Booking Resume" />
            <CardBody>
              <CardFlush
                list
                label={t("field.flightType")}
                desc={formik.values?.flight_type === 0
                  ? t("field.arrival")
                  : formik.values?.flight_type === 1
                  ? t("field.departure")
                  : "Transfer"
                }
                size={{ label: "5", desc: "5" }}
              />
              <CardFlush
                list
                label={
                  formik.values.flight_type === 0 ||
                  formik.values.flight_type === 2
                    ? t("field.arrvAirline") + " / No."
                    : t("field.airline") + " / No."
                }
                desc={`${formik.values?.airplane_name} / ${formik?.values?.airplane_code}${formik.values?.airplane_number}`}
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.deptAirline") + " / No."}
                  desc={`${formik.values?.airplane_name_transfer_to} / ${formik?.values?.airplane_code_transfer}${formik.values?.airplane_number_transfer_to}`}
                  size={{ label: "5", desc: "5" }}
                />
              )}
              <CardFlush
                list
                label={t("field.origin")}
                desc={
                  formik.values?.airport_uniform_from !== "Indonesia"
                    ? `${formik.values?.airport_name_from} - ${formik.values?.airport_uniform_from}`
                    : formik.values?.airport_name_from
                }
                size={{ label: "5", desc: "5" }}
              />
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("Transfer")}
                  desc={
                    formik.values?.airport_uniform_transfer !== "Indonesia"
                      ? `${formik.values?.airport_name_transfer} - ${formik.values?.airport_uniform_transfer}`
                      : formik.values?.airport_name_transfer
                  }
                  size={{ label: "5", desc: "5" }}
                />
              )}
              {
                (data = [
                  {
                    label: t("field.destination"),
                    desc: formik.values?.airport_uniform_to !== "Indonesia"
                      ? `${formik.values?.airport_name_to} - ${formik.values?.airport_uniform_to}`
                      : formik.values?.airport_name_to,
                  },
                  {
                    label:
                      formik.values.flight_type === 0 ||
                      formik.values.flight_type === 2
                        ? t("field.arrivalTime")
                        : t("field.departureTime"),
                    desc: `${moment(formik.values.date).format("DD/MM/YYYY")}, ${formik.values.time}`
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: "5", desc: "5" }}
                  />
                )))
              }
              {formik.values?.flight_type === 2 && (
                <CardFlush
                  list
                  label={t("field.departureTime")}
                  desc={`${moment(formik.values?.date_transfer_to).format("DD/MM/YYYY")}, ${formik.values?.time_transfer_to}`}
                  size={{ label: "5", desc: "5" }}
                />
              )}
            </CardBody>
          </Card>
          <Card className="rounded">
            <CardHeader title={t("field.additionalService")} />
            <CardBody>
              <CardFlushActivatedIcon
                active={dataCheckout.lounge !== "" && (
                  dataCheckout?.lounge_adult_number > 0 ||
                  dataCheckout?.lounge_child_number > 0 ||
                  dataCheckout?.lounge_infant_number > 0
                )}
                icon="chair"
                title="Lounge"
                desc={dataCheckout.lounge !== "" && (
                  dataCheckout?.lounge_adult_number > 0 ||
                  dataCheckout?.lounge_child_number > 0 ||
                  dataCheckout?.lounge_infant_number > 0)
                    ? dataCheckout.lounge_name
                    : t("commons.none")
                }
              />
              <div className="my-3" />
              <CardFlushActivatedIcon
                active={dataCheckout.car_type !== ""}
                icon="local_taxi"
                title={t("field.transportation")}
                desc={dataCheckout?.car_type !== ""
                  ? dataCheckout?.orderExtras?.car?.name +
                    " (" +
                    dataCheckout?.total_unit +
                    ")"
                  : t("commons.none")
                }
              />
              <div className="my-3" />
              <CardFlushActivatedIcon
                active={
                  formik?.values?.additional_service_description !== "" &&
                  formik?.values?.additional_service_concession_percentage !== "" &&
                  formik?.values?.additional_service_ppn_percentage !== "" &&
                  formik?.values?.additional_service_price > 0
                }
                icon="loupe"
                title="Others"
                desc={formik?.values?.additional_service_description !== "" &&
                  formik?.values?.additional_service_concession_percentage !== "" &&
                  formik?.values?.additional_service_ppn_percentage !== "" &&
                  formik?.values?.additional_service_price > 0
                    ? formik?.values?.additional_service_description
                    : t("commons.none")
                }
              />
            </CardBody>
          </Card>
          {role !== "corporate" && (
            <Card className="rounded">
              <CardHeader title={t("booking.assistantAssign")} />
              <CardBody>
                {formik?.values?.assistant_list === null ||
                formik?.values?.assistant_list === undefined ? (
                  <div
                    className={`border px-4 py-3 rounded 
                      ${formik?.values?.date <=
                          moment().add(1, "days").format("YYYY-MM-DD")
                          ? "clickable"
                          : "disable"
                      }`}
                    onClick={() => setAssistantModal(true)}
                  >
                    <div className="d-flex flex-column">
                      <div className="normal-title pb-1 text-extra-bold">
                        {t("field.assistantNotChosen")}
                      </div>
                      <div className="normal-title">
                        {t("field.selectAssistant")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="px-4 py-3 rounded card-activated d-flex justify-content-between clickable"
                  >
                    <div
                      className="d-flex flex-column"
                      onClick={() => setAssistantModal(true)}
                    >
                      <div className="normal-title pb-1 text-extra-bold">
                        {formik?.values?.assistant_list?.map((item) => item?.assistant_name).join(", ")}
                      </div>
                      <div className="normal-title">
                        {`${t("field.main_assistant")} : ${
                          formik?.values?.assistant_list?.find(
                            (item) => item?.is_main === true
                          )?.assistant_name
                        }`}
                      </div>
                    </div>
                    <div
                      className="trigger"
                      onClick={() => handleDeleteAsst(formik)}
                    >
                      <span className="material-icons-outlined">close</span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          <Card className="rounded">
            <CardHeader title={t("field.detailPrice")} />
            <div className="d-flex card-body justify-content-between py-3">
              <div className="normal-title text-extra-bold">
                {t("field.grandTotal")}
              </div>
              <div className="normal-title text-extra-bold">
                Rp {numberWithCommas(grandTotalPrice)}
              </div>
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {dataCheckout?.corporateType === "MOB" && (
                  dataCheckout?.mobAdultUnderQuotaNumber !== 0 ||
                  dataCheckout?.mobChildUnderQuotaNumber !== 0 ||
                  dataCheckout?.mobInfantUnderQuotaNumber !== 0
                ) ? (
                  <>
                  <div className="normal-title text-extra-bold">
                    {`MOB ${t("field.priceUnderQuota")} ${formik?.values?.product_name}`}
                  </div>
                  <div
                    onClick={() => setShowPriceUnderQuota(!showPriceUnderQuota)}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                  </>
                ) : (dataCheckout?.corporateType === "MOB" &&
                  dataCheckout?.mobAdultUnderQuotaNumber === 0 &&
                  dataCheckout?.mobChildUnderQuotaNumber === 0 &&
                  dataCheckout?.mobInfantUnderQuotaNumber === 0 &&
                  dataCheckout?.mobAdultIndividualNumber === 0 &&
                  dataCheckout?.mobChildIndividualNumber === 0 &&
                  dataCheckout?.mobInfantIndividualNumber === 0) ? (
                    <>
                    <div className="d-flex align-items-center normal-title text-extra-bold">
                      {t("field.MOBPrice")} {formik?.values?.product_name}
                      <span
                        className="material-icons"
                        style={{ fontSize: 18 }}
                        data-tip
                        data-for="includeMOBQuota"
                      >
                        info_outlined
                      </span>
                      <ReactTooltip id="includeMOBQuota" place="top" effect="solid">
                        <p style={{ whiteSpace: "pre-line" }}>
                          {t("booking.priceMOBQuota")}
                        </p>
                      </ReactTooltip>
                    </div>
                    <div
                      className="normal-title"
                      style={{ cursor: "pointer" }}
                    >
                      Rp 0
                    </div>
                    </>
                ) : (
                  <>
                  <div className="normal-title text-extra-bold">
                    {dataCheckout?.corporateType === "Group"
                      ? `${t("field.groupPrice")} ${formik?.values?.product_name}`
                      : dataCheckout?.corporateType === "Package"
                      ? `${t("field.packagePrice")} ${formik?.values?.product_name}`
                      : dataCheckout?.corporateType === "FIT"
                      ? `${t("field.FITPrice")} ${formik?.values?.product_name}`
                      : `${t("field.individualPrice")} ${formik?.values?.product_name}`}
                  </div>
                  <div
                    onClick={togglePriceService}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.price_service)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                  </>
                )}
              </div>
              {showPriceUnderQuota && (
                <>
                {dataCheckout?.mobAdultUnderQuotaNumber !== 0 && (
                  <SummaryCheckoutPrice
                    category={t("commons.adult")}
                    keyTooltip="taxIndividualAdult"
                    passengers={dataCheckout?.mobAdultUnderQuotaNumber}
                    price={dataCheckout?.adultPriceOriginalUnderQuota}
                    dataCheckout={{
                      ...dataCheckout,
                      ppnPrice:  dataCheckout?.ppnAdultPrice,
                      concessionPrice: dataCheckout?.concessionAdultPrice,
                    }}
                  />
                )}
                {dataCheckout?.mobChildUnderQuotaNumber !== 0 && (
                  <SummaryCheckoutPrice
                    category={t("commons.child")}
                    keyTooltip="taxIndividualChild"
                    passengers={dataCheckout?.mobChildUnderQuotaNumber}
                    price={dataCheckout?.childPriceOriginalUnderQuota}
                    dataCheckout={{
                      ...dataCheckout,
                      ppnPrice:  dataCheckout?.ppnChildPrice,
                      concessionPrice: dataCheckout?.concessionChildPrice,
                    }}
                  />
                )}
                {dataCheckout?.mobInfantUnderQuotaNumber !== 0 && (
                  <SummaryCheckoutPrice
                    category={t("commons.infantLabel")}
                    keyTooltip="taxIndividualInfant"
                    passengers={dataCheckout?.mobInfantUnderQuotaNumber}
                    price={dataCheckout?.infantPriceOriginalUnderQuota}
                    dataCheckout={{
                      ...dataCheckout,
                      ppnPrice:  dataCheckout?.ppnInfantPrice,
                      concessionPrice: dataCheckout?.concessionInfantPrice,
                    }}
                  />
                )}
                </>
              )}
              {showPriceService && (
                <>
                {dataCheckout?.corporateType === "FIT" || dataCheckout?.corporateType === "Group" ? (
                  <>
                  {passengersAdult !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.adult")}
                      keyTooltip="taxAdult"
                      passengers={passengersAdult}
                      price={dataCheckout.adultPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnAdultPrice,
                        concessionPrice: dataCheckout?.concessionAdultPrice,
                      }}
                    />
                  )}
                  {passengersChild !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.child")}
                      keyTooltip="taxChild"
                      passengers={passengersChild}
                      price={dataCheckout.childPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnChildPrice,
                        concessionPrice: dataCheckout?.concessionChildPrice,
                      }}
                    />
                  )}
                  {passengersInfant !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.infantLabel")}
                      keyTooltip="taxInfant"
                      passengers={passengersInfant}
                      price={dataCheckout.infantPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnInfantPrice,
                        concessionPrice: dataCheckout?.concessionInfantPrice,
                      }}
                    />
                  )}
                  </>
                ) : dataCheckout?.corporateType === "Package" ? (
                  <SummaryCheckoutPrice
                    keyTooltip="taxGroup"
                    price={groupPrice}
                    dataCheckout={{
                      ...dataCheckout,
                      ppnPrice:  dataCheckout?.ppnTaxPrice,
                      concessionPrice: dataCheckout?.concessionTaxPrice,
                    }}
                  />
                ) : dataCheckout?.corporateType === "MOB" &&
                  (dataCheckout?.mobAdultIndividualNumber !== 0 ||
                  dataCheckout?.mobChildIndividualNumber !== 0 ||
                  dataCheckout?.mobInfantIndividualNumber !== 0) ? (
                  <>
                  {dataCheckout?.mobAdultIndividualNumber !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.adult")}
                      keyTooltip="taxIndividualAdult"
                      passengers={dataCheckout?.mobAdultIndividualNumber}
                      price={dataCheckout.adultPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnAdultPrice,
                        concessionPrice: dataCheckout?.concessionAdultPrice,
                      }}
                    />
                  )}
                  {dataCheckout?.mobChildIndividualNumber !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.child")}
                      keyTooltip="taxIndividualChild"
                      passengers={dataCheckout?.mobChildIndividualNumber}
                      price={dataCheckout.childPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnChildPrice,
                        concessionPrice: dataCheckout?.concessionChildPrice,
                      }}
                    />
                  )}
                  {dataCheckout?.mobInfantIndividualNumber !== 0 && (
                    <SummaryCheckoutPrice
                      category={t("commons.infantLabel")}
                      keyTooltip="taxIndividualInfant"
                      passengers={dataCheckout?.mobInfantIndividualNumber}
                      price={dataCheckout.infantPriceOriginal}
                      dataCheckout={{
                        ...dataCheckout,
                        ppnPrice:  dataCheckout?.ppnInfantPrice,
                        concessionPrice: dataCheckout?.concessionInfantPrice,
                      }}
                    />
                  )}
                  </>
                ) : (<></>)} 
                </>
              )}
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.lounge")}
                </div>
                {formik?.values?.lounge ? (
                  <div
                    onClick={toggleLounge}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.totalLoungePrice)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showLounge && (
                <>
                  {formik?.values?.lounge_adult_number !== "" &&
                    formik?.values?.lounge_adult_number !== 0 && (
                      <SummaryCheckoutPrice
                        isLounge
                        category={t("commons.adult")}
                        keyTooltip="taxLoungeAdult"
                        passengers={formik?.values?.lounge_adult_number}
                        price={formik?.values?.lounge_price_adult}
                        dataCheckout={{
                          ...dataCheckout,
                          ppnPrice:  dataCheckout?.ppnAdultLoungePrice,
                          concessionPrice: dataCheckout?.concessionAdultLoungePrice,
                        }}
                      />
                    )}
                  {formik?.values?.lounge_child_number !== "" &&
                    formik?.values?.lounge_child_number !== 0 && (
                      <SummaryCheckoutPrice
                        isLounge
                        category={t("commons.child")}
                        keyTooltip="taxLoungeChild"
                        passengers={formik?.values?.lounge_child_number}
                        price={formik?.values?.lounge_price_child}
                        dataCheckout={{
                          ...dataCheckout,
                          ppnPrice:  dataCheckout?.ppnChildLoungePrice,
                          concessionPrice: dataCheckout?.concessionChildLoungePrice,
                        }}
                      />
                    )}
                  {formik?.values?.lounge_infant_number !== "" &&
                    formik?.values?.lounge_infant_number !== 0 && (
                      <SummaryCheckoutPrice
                        isLounge
                        category={t("commons.infantLabel")}
                        keyTooltip="taxLoungeInfant"
                        passengers={formik?.values?.lounge_infant_number}
                        price={formik?.values?.lounge_price_infant}
                        dataCheckout={{
                          ...dataCheckout,
                          ppnPrice:  dataCheckout?.ppnInfantLoungePrice,
                          concessionPrice: dataCheckout?.concessionInfantLoungePrice,
                        }}
                      />
                    )}
                </>
              )}
            </div>
            <div className="card-body border-top py-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="normal-title text-extra-bold">
                  {t("field.transportation")}
                </div>
                {formik?.values?.car_type ? (
                  <div
                    onClick={toggleTransport}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.totalTransportPrice)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                ) : (
                  <div
                    className="normal-title text-extra-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Rp 0
                  </div>
                )}
              </div>
              {showTransport && (
                <SummaryCheckoutPrice
                  keyTooltip="taxTransport"
                  passengers={`${formik?.values?.total_unit} unit`}
                  price={transportPrice}
                  dataCheckout={{
                    ...dataCheckout,
                    isIncludePpn: dataCheckout?.isIncludePpnTransport,
                    isIncludeConcession: dataCheckout?.isIncludeConcessionTransport,
                    ppnPercentage: dataCheckout?.ppnPercentageTransport,
                    concessionPercentage: dataCheckout?.concessionPercentageTransport,
                    ppnPrice:  dataCheckout?.ppnTransport,
                    concessionPrice: dataCheckout?.concessionTransport,
                  }}
                />
              )}
            </div>
            {formik?.values?.additional_service_price > 0 && (
              <div className="card-body bg-light-secondary border-top py-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="normal-title text-extra-bold">
                    {t('masterData.others')}
                  </div>
                  <div
                    onClick={() => setShowOthers(!showOthers)}
                    className="d-flex align-items-center"
                    style={{ marginRight: "-30px", cursor: "pointer" }}
                  >
                    <span className="normal-title text-extra-bold mr-2">
                      Rp{" "}
                      {numberWithCommas(dataCheckout?.additionalServicePriceTotal)}
                    </span>
                    <span className="material-icons-round left-icon">
                      expand_more
                    </span>
                  </div>
                </div>
                {showOthers && (
                  <SummaryCheckoutPrice
                    keyTooltip="taxOthers"
                    price={formik?.values?.additional_service_price}
                    dataCheckout={{
                      isIncludePpn: formik?.values?.additional_service_is_include_ppn,
                      isIncludeConcession: formik?.values?.additional_service_is_include_concession,
                      ppnPercentage: formik?.values?.additional_service_ppn_percentage,
                      concessionPercentage: formik?.values?.additional_service_concession_percentage,
                      ppnPrice: dataCheckout?.ppnAdditionalService,
                      concessionPrice: dataCheckout?.concessionAdditionalService,
                      dpp: formik?.values?.additional_service_dpp,
                    }}
                  />
                )}
              </div>
            )}
          </Card>
          {button(null, dataCheckout)}
        </Col>
      </Row>
      <AssistantModal
        show={assistantModal}
        formik={formik}
        action={handleAddAssistant}
        toggle={() => setAssistantModal(!assistantModal)}
      />
    </>
  );
};

const mapStateToProps = ({ corporate }) => {
  return { corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
