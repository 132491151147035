import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AUDIT_TRAIL_PENDING = "GET_AUDIT_TRAIL_PENDING";
export const GET_AUDIT_TRAIL_SUCCESS = "GET_AUDIT_TRAIL_SUCCESS";
export const GET_AUDIT_TRAIL_ERROR = "GET_AUDIT_TRAIL_ERROR";

// URL: URL_{URL}
const AUDIT_TRAIL_URL = `v1/audit_trail`;

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_AUDIT_TRAIL_PENDING));
    API.get(AUDIT_TRAIL_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_AUDIT_TRAIL_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_AUDIT_TRAIL_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                let message = err?.response?.data?.message;
                if (message?.includes("java")) {
                    message = "Data tidak ditemukan";
                }
                toastError(message);
            }
            // toastError(err?.response?.data?.message);
        });
};

const audit_trail = {
    get,
};
export default audit_trail;
