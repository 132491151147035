import ReactTooltip from "react-tooltip";
import { numberWithCommas } from "../../utils/Helper";
import { withTrans } from "../../i18n/withTrans";

const TooltipTaxAdtService = ({
  t,
  include_ppn,
  include_concession,
  ppn_percentage,
  concession_percentage,
  price
}) => {
  return (
    <>
      <span
        className="material-icons"
        style={{ fontSize: 18 }}
        data-tip
      >
        info_outlined
      </span>
      <ReactTooltip place="top" effect="solid">
        <p style={{ whiteSpace: "pre-line" }}>
          {!include_ppn && 
          include_concession && 
          ppn_percentage !== null 
          ? (
            <span>
              {`${t("field.price")} plus PPN ${ppn_percentage}% of Rp ${numberWithCommas(price)}`}
            </span>
          ) : include_ppn &&
            !include_concession &&
            concession_percentage !== null ? (
            <span>
              {`${t("field.price")} plus ${t("product.concession")} ${
                concession_percentage
              }% of Rp ${numberWithCommas(price)}`}
            </span>
          ) : !include_ppn &&
            !include_concession &&
            concession_percentage !== null &&
            ppn_percentage !== null ? (
            <span>
              {`${t("field.price")} plus PPN ${
                ppn_percentage
              }% & ${t("product.concession")} ${
                concession_percentage
              }% of Rp ${numberWithCommas(price)}`}
            </span>
          ) : (
            ""
          )}
        </p>
      </ReactTooltip>
    </>
  );
};

export default withTrans(TooltipTaxAdtService);