import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import { withTrans } from "../../i18n/withTrans";

function ModalAdd({ t, show, toggle, image, type }) {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Banner{" "}
          <span className="close clickable" onClick={() => toggle()}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          {image ? (
            <img
              src={image}
              alt="user"
              className="img"
              width="100%"
              style={{ objectFit: "cover", width: "100%" }}
            />
          ) : (
            <p>Dokumen tidak ditemukan</p>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={() => toggle()}
            hideConfirm
            cancelTitle={t("commons.close")}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ fileManagement }) => {
  return { fileManagement };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(ModalAdd)
);
