import React, { useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Container,
  Col,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import moment from "moment";
import * as Yup from "yup";

import { withTrans } from "../../i18n/withTrans";
import { toastError } from "../../components/commons/toast";
import { getUser } from "../../utils/User";
import { productCode, Role, RoleWithAirport } from "../../utils/Constants"
import Select2 from "../../components/forms/Select2";
import Input from "../../components/forms/Input";
import ButtonModal from "../../components/commons/modal/ButtonModal";
import Textarea from "../../components/forms/Textarea";
import Select2Multi from "../../components/forms/Select2Multi";
import DynamicButton from "../../components/forms/DynamicButton";
import ConfirmationModal from "../../components/commons/modal/ConfirmModal";
import PromoService from "../../store/actions/promo"
import FormPromoPriceModal from "./FormPromoPriceModal";

const FormPromoModal = ({
  t,
  show,
  toggle,
  action,
  data,
  dataAirport,
  dataProduct,
  confirm,
  data_post,
  data_put,
  error_message
}) => {
  const formikRef = useRef();
  const fileRef = useRef();
  const currentUser = getUser();
  const isSubAdmin = currentUser?.user?.role_code === Role.Sub_admin;
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [showPage, setShowPage] = useState(1);
  const [allowedTypeFile] = useState(['png', 'jpg', 'jpeg']);
  const [fileProp, setFileProp] = useState({
    title: "Upload Banner",
    subtitle: "Format .JPG or .PNG (Max. 2MB)",
    description: "Dimensions: 312 x 120 px",
    message_error: false,
  });
  const [haveAirport] = useState(
    RoleWithAirport?.includes(currentUser?.user.role_code)
      ? true
      : false
  );
  const [image, setImage] = useState(null);
  const steps = ["Detail Promo", "Promo Value"];

  const dispatch = useDispatch();
  const title = (data ? t("commons.edit") : t("commons.add")) + " " + "Promo";
  const initial = data
    ? {
      id: data?.id,
      name: data?.name,
      code: data?.code,
      type: data?.type,
      is_service_date_period: data?.is_service_date_period,
      period_end: moment(data?.period_end).format("YYYY-MM-DD"),
      period_start: moment(data?.period_start).format("YYYY-MM-DD"),
      quantity: data?.quantity,
      description: data?.description,
      discount_percent: data?.discount_percent,
      discount_price: data?.discount_price,
      promo_type: data?.discount_price === 0 ? 0 : 1,
      banner_file_path: data?.banner,
      airport_ids: data?.list_airports_detail
        ? data?.list_airports_detail?.map((item, index) => item?.id).join().split(',')
        : isSubAdmin
          ? [currentUser?.user?.airport_id]
          : "",
      list_airport_cross_selling: data?.list_airport_cross_selling
        ? data?.list_airport_cross_selling.map((item, index) => item.airport_id).join().split(',')
        : [],
      service_type: data?.type === 'bundling'
        ? data?.list_products[0]
        : data?.list_products
        ? data?.list_products.map((item, index) => item).join().split(',')
        : "",
      main_price: data?.bundling_main_price,
      bundling_name: data?.bundling_name ?? "",
      bundling_product_price: data?.bundling_product_price,
      bundling_promo_type: data?.bundling_discount_price === 0 ? 0 : 1,
      bundling_discount_price: data?.bundling_discount_price || "",
      bundling_discount_percent: data?.bundling_discount_percent || ""
    }
    : {
      airport_ids:
        isSubAdmin
          ? [currentUser?.user?.airport_id]
          : "",
      list_airport_cross_selling : [],
      banner: "",
      code: "",
      name: "",
      description: "",
      discount_price: "",
      discount_percent: "",
      order_number: 0,
      type: "",
      service_type: "",
      quantity: "",
      is_service_date_period: null,
      period_end: "",
      period_start: "",
      promo_type: "",
      bundling_name: "",
      bundling_product_price: "",
      bundling_promo_type: "",
      bundling_discount_price: "",
      bundling_discount_percent: ""
    };

  const handleConfirm = () => {
    const fileInfo  = {
      title: "Upload Banner",
      subtitle: "Format .JPG or .PNG (Max. 2MB)",
      description: "Dimensions: 312 x 120 px",
      message_error: false,
    }
    setFileProp(fileInfo);
    formikRef?.current?.setFieldValue("file_info", fileInfo);
    setShowModalConfirm(false);
    toggle(false);
  };

  const validation = {
    1: Yup.object().shape({
      name: Yup.string().required(`${t("promo.promoName")} ${t("commons.required")}`).nullable(),
      description: Yup.string().required(`${t("promo.description")} ${t("commons.required")}`).nullable(),
      is_service_date_period: Yup.boolean().required(`${t("promo.typePeriodPromo")} ${t("commons.required")}`).nullable(),
      period_end: Yup.string().required(`${t("promo.endDate")} ${t("commons.required")}`).nullable(),
      period_start: Yup.string().required(`${t("promo.startDate")} ${t("commons.required")}`).nullable(),
      type: Yup.string().required(`${t("promo.promoType")} ${t("commons.required")}`).nullable(),
      promo_type: 
        Yup.string().when(['type'], {
          is: (type) => type !== 'bundling',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable(),
        }),
      quantity:
        Yup.string().when('type', {
          is: 'specific',
          then: (d) => d.required(`${t("promo.numberOfPromo")} ${t("commons.required")}`).nullable()
        }),
      code:
        Yup.string().when('type', {
          is: 'blast',
          then: (d) => d.required(`${t("promo.promoCode")} ${t("commons.required")}`).nullable()
        }),
      discount_price:
        Yup.string().when('promo_type', {
          is: '1',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        }),
      discount_percent:
        Yup.string().when('promo_type', {
          is: '0',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        }),
      airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
      list_airport_cross_selling:
        Yup.array().when('type', {
          is: 'cross-selling',
          then: (d) => d.required(`${t("promo.airportCrossSelling")} ${t("commons.required")}`).nullable()
        }),
      service_type: Yup.mixed().required(`${t("field.product")} ${t("commons.required")}`).nullable(),
      bundling_name:
        Yup.string().when('type', {
          is: 'bundling',
          then: (d) => d.required(`${t("promo.bundlingProduct")} ${t("commons.required")}`).nullable()
        }),
    }),
    2: Yup.object().shape({
      promo_type: Yup.string().required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable(),
      discount_price:
        Yup.string().when('promo_type', {
          is: '1',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        }),
      discount_percent:
        Yup.string().when('promo_type', {
          is: '0',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("commons.required")}`).nullable()
        }),
      bundling_product_price:
        Yup.string().required(`${t("promo.priceOf")} ${t("promo.bundlingProduct")} ${t("commons.required")}`).nullable(),
      bundling_promo_type: Yup.string().required(`${t("promo.promoType")} ${t("commons.required")}`).nullable(),
      bundling_discount_price:
        Yup.string().when('bundling_promo_type', {
          is: '1',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("promo.bundlingProduct")} ${t("commons.required")}`).nullable()
        }),
      bundling_discount_percent:
        Yup.string().when('bundling_promo_type', {
          is: '0',
          then: (d) => d.required(`${t("promo.promoValue")} ${t("promo.bundlingProduct")} ${t("commons.required")}`).nullable()
        }),
    }),
  };

  const airport_list = dataAirport?.map((airport) => ({
    value: airport?.id,
    label: `${airport?.code}-${airport?.city}-${airport?.name}`,
  }));

  const product_list = dataProduct?.map((product) => ({
    value: product?.id,
    label: product?.name_eng,
  }));

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp({
        title: "Upload Banner",
        subtitle: "Format .JPG or .PNG (Max. 2MB)",
        description: "Dimensions: 312 x 120 px",
        message_error: false,
      });
      setShowPage(1);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
      setFileProp({
        title: "Upload Banner",
        subtitle: "Format .JPG or .PNG (Max. 2MB)",
        description: "Dimensions: 312 x 120 px",
        message_error: false,
      });
      setShowPage(1);
    }
  }, [data_put]);

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const handleChange = (selectorFiles, formik) => {
    const extFile = selectorFiles?.files[0]?.name.split(".")?.pop();
    if (!allowedTypeFile.includes(extFile)) {
      toastError("File type is not allowed");
      return;
    }

    if (Number(selectorFiles.files[0].size) < 2097152) {
      formik.setFieldValue("banner_file", selectorFiles.files[0]);
      const img = new Image()
      img.src = window.URL.createObjectURL(selectorFiles.files[0])
      img.onload = () => {
        const fileInfo  = {
          title: selectorFiles.files[0].name,
          subtitle:
            `Size: ${(Number(selectorFiles.files[0].size) * 0.001).toFixed(2)} KB`,
          description: `Dimensions: ${img.width} x ${img.height} px`,
          message_error: false,
        }
        setFileProp(fileInfo);
        formik.setFieldValue("file_info", fileInfo);
      }
    } else {
      toastError("Maksimal file yang dapat diimport 2 MB");
    }
  };

  useEffect(() => {
    if (data?.banner) {
      convertImgToBase64URL(
        process.env.REACT_APP_API_URL +
        data?.banner.substr(1, data?.banner?.length),
        function (base64Img) {
          const name = data?.banner.split("/");

          var arr = base64Img.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }

          const file = new File([u8arr], name[name.length - 1], { type: mime });
          setImage(file);
          const img = new Image();
          img.src = window.URL.createObjectURL(file)
          img.onload = () => {
            setFileProp({
              title: file.name,
              subtitle:
                `Size: ${(Number(file.size) * 0.001).toFixed(2)} KB`,
              description: `Dimensions: ${img.width} x ${img.height} px`,
              message_error: false,
            });
          }
        }
      );
    }
  }, [data?.banner]);

  const convertImgToBase64URL = (url, callback) => {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  };

  const handleNextPage = async (formik, page) => {
    if (page === 2) {
      await formik.validateForm(validation[1]);
  
      Object.keys(formik.values).forEach((field) => {
        formik.setFieldTouched(field, true);
      });

      //Check if there are errors from validation yup page 1
      const excludedFieldsBundlingPromo = ['promo_type', 'discount_price', 'discount_percent'];
      const listErrors = Object.keys(formik.errors);
      const pageOneValidationFields = Object.keys(validation[1].fields).filter((field) => !excludedFieldsBundlingPromo.includes(field));
      const isNotValid = listErrors.some((error) => pageOneValidationFields.includes(error))

      if (isNotValid) return;

      new Promise((resolve) => {
        if (!(formik?.values?.banner_file || formik?.values?.banner_file_path)) {
          setFileProp({
            title: "Upload Banner",
            subtitle: "Format .JPG or .PNG (Max. 2MB)",
            description: "Dimensions: 312 x 120 px",
            message_error: `Banner ${t("commons.required")}`,
          });
          return;
        }
  
        const productLabel =
          product_list?.find((item) => item?.value === formik?.values?.service_type)?.label;
        const values = {
          list_airport_id: formik?.values?.airport_ids,
          product_name: productCode[productLabel]?.enum
        };
  
        dispatch(PromoService.checkPrice(values, resolve));
      }).then((res) => {
        if (res?.data) {
          setShowPage(page);
          formik?.setFieldValue("main_price", res?.data);
        }
      });
    } else {
      setShowPage(page);
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation[showPage]}
          enableReinitialize
          onSubmit={(values, { setStatus }) => {
            setStatus();
            if (values.type === 'blast' && !(values?.banner_file || values?.banner_file_path)) {
              setLoadingSubmit(false);
              setFileProp({
                title: "Upload Banner",
                subtitle: "Format .JPG or .PNG (Max. 2MB)",
                description: "Dimensions: 312 x 120 px",
                message_error: `Banner ${t("commons.required")}`,
              });
              return;
            }
            if (data) {
              values.id = data?.id;
            }
            let formData = new FormData();
            if (values.type === "blast") {
              formData.append("code", values.code);
            }
            if (values.id) {
              formData.append("id", values.id);
              if (values.banner_file) {
                formData.append("file", values?.banner_file);
              } else {
                formData.append("file", image);
              }
            } else {
              formData.append("file", values?.banner_file);
            }
            formData.append("description", values.description);
            if (values.promo_type === 1) {
              formData.append("discount_percent", 0);
              if (!Number.isInteger(values.discount_price)) {
                formData.append(
                  "discount_price",
                  Number(values.discount_price.replace(/[^0-9\.-]+/g, ""))
                );
              } else {
                formData.append("discount_price", values.discount_price);
              }
            } else {
              formData.append("discount_price", 0);
              formData.append("discount_percent", values.discount_percent);
            }
            formData.append("list_airports", values.airport_ids);
            if (values.list_airport_cross_selling.length) {
              formData.append("list_airport_cross_selling", values.list_airport_cross_selling);
            }
            if (values?.type === "bundling") {
              formData.append("list_products", [values.service_type]);
              formData.append("bundling_name", values.bundling_name);
              formData.append("bundling_product_price", values.bundling_product_price);
              if (values.bundling_promo_type === 1) {
                formData.append("bundling_discount_percent", 0);
                formData.append("bundling_discount_price", values.bundling_discount_price);
              } else {
                formData.append("bundling_discount_price", 0);
                formData.append("bundling_discount_percent", values.bundling_discount_percent);
              }
            } else {
              formData.append("list_products", values.service_type);
            }
            formData.append("name", values.name);
            formData.append("order_number", 0);
            formData.append("is_service_date_period", values?.is_service_date_period);
            formData.append("period_end", moment(values.period_end).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("period_start", moment(values.period_start).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("quantity", values.quantity ? values.quantity : 0);
            formData.append("type", values.type);
            setLoadingSubmit(true);
            action(formData);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  {props?.values?.type === 'bundling' && (
                    <div className="mb-4">
                      <Stepper nonLinear activeStep={showPage - 1}>
                        {steps?.map((label, index) => (
                          <Step key={label}>
                            <StepButton
                              color="inherit"
                              onClick={() => handleNextPage(props, index + 1)}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                  {showPage === 1 && (
                    <>
                      <Input
                        name="name"
                        errors={props.errors}
                        touched={props.touched}
                        {...props.getFieldProps("name")}
                        title={t("promo.promoName")}
                        type="text"
                      />
                      <Select2
                        title={t("promo.promoType")}
                        name="type"
                        options={
                          [
                            {
                              value: "specific",
                              label: t("promo.special"),
                            },
                            {
                              value: "blast",
                              label: "Blast",
                            },
                            {
                              value: "bundling",
                              label: t("promo.bundlingProduct"),
                            },
                          ].concat(
                            !haveAirport
                              ? {
                                  value: "cross-selling",
                                  label: t("field.cross_selling"),
                                }
                              : []
                          )}
                        type="text"
                        errors={props?.errors}
                        touched={props?.touched}
                        {...props?.getFieldProps("type")}
                        value={props.values.type}
                        onChange={(name, value) => {
                          props?.setFieldValue("type", value);
                        }}
                      />
                      {props?.values?.type === "specific" && (
                        <>
                          {" "}
                          <Input
                            name="quantity"
                            errors={props.errors}
                            touched={props.touched}
                            value={props.values.quantity}
                            {...props.getFieldProps("quantity")}
                            title={t("promo.numberOfPromo")}
                            type="number"
                            style={{ marginTop: "10px" }}
                            placeholder={t("promo.numberOfPromo")}
                          />
                        </>
                      )}
                      {props?.values?.type === "blast" && (
                        <>
                          {" "}
                          <Input
                            name="code"
                            errors={props.errors}
                            touched={props.touched}
                            value={props.values.code}
                            {...props.getFieldProps("code")}
                            title={t("promo.promoCode")}
                            type="text"
                            style={{ marginTop: "10px" }}
                          />
                        </>
                      )}
                      {props?.values?.type !== "bundling" && (
                        <Row>
                          <Col md={12} style={{ paddingRight: "0px" }}>
                            <div className="form-group my-0">
                              <label className="form-label">
                                {
                                  props?.values?.type === "cross-selling" 
                                    ? t("promo.labelPromoValueService") 
                                    : t("promo.promoValue")
                                }
                              </label>
                            </div>
                          </Col>
                          <Col md={4} style={{ paddingRight: "0px" }}>
                            <Select2
                              name="promo_type"
                              options={
                                [
                                  {
                                    value: 0,
                                    label: `${t("promo.percentage")} (%)`,
                                  }
                                ].concat(
                                  props?.values?.type !== "cross-selling" 
                                    ? {
                                        value: 1,
                                        label: `${t("promo.rupiah")} (Rp)`,
                                      }
                                    : [] 
                                )
                              }
                              type="text"
                              errors={props?.errors}
                              touched={props?.touched}
                              {...props?.getFieldProps("promo_type")}
                              onChange={(name, value) => {
                                props?.setFieldValue("promo_type", value);
                              }}
                            />
                          </Col>
                          <Col md={8} style={{ paddingLeft: "0px" }}>
                            {props?.values?.promo_type === 0 ? (
                              <Input
                                name="discount_percent"
                                errors={props.errors}
                                touched={props.touched}
                                type="number"
                                min="0"
                                max="100"
                                placeholder={t("promo.promoValue")}
                                {...props.getFieldProps("discount_percent")}
                              />
                            ) : (
                              <Input
                                currency
                                errors={props.errors}
                                touched={props.touched}
                                thousandSeparator={true}
                                prefix={"Rp "}
                                placeholder={t("promo.promoValue")}
                                name="discount_price"
                                {...props.getFieldProps("discount_price")}
                                onChangeValue={(e) => {
                                  const val = Number(e?.target?.value.replace(/[^0-9\.-]+/g, ""));
                                  props?.setFieldValue("discount_price", val);
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                      <Select2
                        title={t("promo.typePeriodPromo")}
                        name="is_service_date_period"
                        options={
                          [
                            {
                              value: true,
                              label: t("report.serviceDate"),
                            },
                            {
                              value: false,
                              label: t("report.bookingDate"),
                            }
                          ]
                        }
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props.values.is_service_date_period}
                        onChange={(name, value) => {
                          props?.setFieldValue("is_service_date_period", value)
                          
                        }}
                      />
                      <Row style={{ marginTop: "10px" }}>
                        <Col md={6}>
                          <Input
                            title={props?.values?.is_service_date_period
                              ? t("promo.serviceStartDate")
                              : t("promo.bookingStartDate")}
                            name="period_start"
                            type="date"
                            errors={props.errors}
                            touched={props.touched}
                            {...props?.getFieldProps("period_start")}
                            value={props.values?.period_start}
                          />
                        </Col>
                        <Col md={6}>
                          <Input
                            title={props?.values?.is_service_date_period
                              ? t("promo.serviceEndDate")
                              : t("promo.bookingEndDate")}
                            name="period_end"
                            type="date"
                            errors={props.errors}
                            touched={props.touched}
                            {...props?.getFieldProps("period_end")}
                            value={props.values?.period_end}
                          />
                        </Col>
                      </Row>
                      {props?.values?.type === "cross-selling" && (
                        <Select2Multi
                          title={t("promo.airportCrossSelling")}
                          name="list_airport_cross_selling"
                          options={[
                            {
                              label: `${t("commons.all")} ${t("field.airport")}`,
                              value: 'all',
                            },
                            ...airport_list
                          ]}
                          className="mb-2"
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.list_airport_cross_selling}
                          onChange={(name, value) => {
                            let data;
                            if (value.includes("all")) {
                              data = airport_list?.map((item) => {
                                return item?.value
                              });
                            } else {
                              data = value;
                            }
                            props?.setFieldValue("list_airport_cross_selling", data);
                          }}
                          onBlur={props?.setFieldTouched}
                        />
                      )}
                      <Select2Multi
                        title={t("field.airport")}
                        disabled={isSubAdmin}
                        name="airport_ids"
                        options={
                          isSubAdmin
                            ? [{
                                label: `${currentUser?.user?.airport_code} - ${currentUser?.user?.airport_city} - ${currentUser?.user?.airport_name}`,
                                value: currentUser?.user?.airport_id,
                              }]
                            : [{
                                label: `${t("commons.all")} ${t("field.airport")}`,
                                value: 'all',
                              },
                              ...airport_list
                            ]
                        }
                        className="mb-2"
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.airport_ids}
                        onChange={(name, value) => {
                          let data;
                          if (value.includes("all")) {
                            data = airport_list?.map((item) => {
                              return item?.value
                            });
                          } else {
                            data = value;
                          }
                          props?.setFieldValue("airport_ids", data);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                      
                      {props?.values?.type === "bundling" ? (
                        <>
                        <Select2
                          title={t("promo.mainProduct")}
                          name="service_type"
                          options={product_list}
                          className="mb-2"
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.service_type}
                          onChange={(name, value) => {
                            props?.setFieldValue("service_type", value);
                          }}
                          onBlur={props?.setFieldTouched}
                        />
                        <Input
                          errors={props.errors}
                          touched={props.touched}
                          title={t("promo.bundlingProduct")}
                          name="bundling_name"
                          {...props.getFieldProps("bundling_name")}
                        />
                        </>
                      ) : (
                        <Select2Multi
                          title={t("field.product")}
                          name="service_type"
                          options={product_list}
                          className="mb-2"
                          errors={props?.errors}
                          touched={props?.touched}
                          value={props?.values?.service_type}
                          onChange={(name, value) => {
                            props?.setFieldValue("service_type", value);
                          }}
                          onBlur={props?.setFieldTouched}
                        />
                      )}
                      <Textarea
                        title={t("promo.description")}
                        rows="4"
                        name="description"
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.description}
                        {...props?.getFieldProps("description")}
                      />
                      <DynamicButton
                        titleLeft={
                          props?.values?.file_info
                            ? props?.values?.file_info?.title
                            : fileProp?.title
                        }
                        subtitleLeft={
                          props?.values?.file_info
                            ? props?.values?.file_info?.subtitle
                            : fileProp?.subtitle
                        }
                        description={
                          props?.values?.file_info
                            ? props?.values?.file_info?.description
                            : fileProp?.description
                        }
                        iconLeft="insert_drive_file"
                        toggle={openFileDialog}
                        error={
                          props?.values?.file_info
                            ? props?.values?.file_info?.message_error
                            : fileProp?.message_error
                        }
                      />
                      <input
                        type="file"
                        ref={fileRef}
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e.target, props)}
                        accept=".png, .jpeg, .jpg"
                      />
                    </>
                  )}
                  {showPage === 2 && <FormPromoPriceModal formik={props} />}
                </Container>
              </ModalBody>
              <ModalFooter>
                {props?.values?.type === 'bundling' ? (
                  <ButtonModal
                    toggle={() => {
                      if (showPage == 2) {
                        handleNextPage(props, 1)
                      } else {
                        setShowModalConfirm(true);
                        setMessageConfirm("cancel");
                      }
                    }}
                    cancelTitle={showPage == 1 ? t("commons.cancel") : t("commons.back")}
                    confirm={showPage == 1 ? () => handleNextPage(props, 2) : confirm}
                    confirmTitle={showPage == 1 ? t("commons.next") : t("commons.save")}
                    disabledConfirm={loadingSubmit}
                    typeConfirm={showPage == 1 ? "Button" : "Submit"}
                  />
                ) : (
                  <ButtonModal
                    toggle={() => {
                      setShowModalConfirm(true);
                      setMessageConfirm("cancel");
                    }}
                    confirm={confirm}
                    confirmTitle={t("commons.save")}
                    disabledConfirm={loadingSubmit}
                  />
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={showModalConfirm}
        toggle={() => setShowModalConfirm(false)}
        confirm={handleConfirm}
        isDelete="delete"
        cancelTitle={t("commons.no")}
        confirmTitle={t("commons.yes")}
        message={
          data
            ? `${t("confirm.edit")} <b>Promo</b>`
            : `${t("confirm.add")} <b>Promo</b>`
        }
        icon="info"
      />
    </>
  );
};

const mapStateToProps = ({ promo: { pending, data_post, data_put, error_message } }) => {
  return { pending, data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(FormPromoModal));
