import DynamicButton from "../DynamicButton";
import { withTrans } from "../../../i18n/withTrans";

const InputImage = ({
  t,
  title,
  subtitle,
  icon,
  handleOpenFileDialog,
  handleChangeImage,
  fileRef,
  errors,
  selectedImage,
  handleRemoveSelectedImg
}) => {
  return (
    <div>
      <DynamicButton
        titleCenter={title ?? t("booking.paymentProof")}
        subtitleCenter={subtitle ?? t("booking.uploadPaymentProof")}
        iconCenter={icon ?? "add_photo_alternate"}
        toggle={handleOpenFileDialog}
        errors={errors}
      />
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={handleChangeImage}
        accept="image/png, image/jpeg"
      />
      {selectedImage && (
        <div>
          <img
            src={selectedImage}
            style={{
              width: "100%",
              maxHeight: 320,
              objectFit: "cover",
            }}
            alt="Thumb"
          />
          <button
            onClick={handleRemoveSelectedImg}
            style={{
              cursor: "pointer",
              padding: 8,
              background: "#d10d0d",
              color: "white",
              border: "none",
              width: "100%",
            }}
          >
            {t("commons.delete")}
          </button>
        </div>
      )}
    </div>
  )
}

export default withTrans(InputImage);