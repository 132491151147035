import React from "react";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import Input from "../../../../components/forms/Input";
import { withTrans } from "../../../../i18n/withTrans";

const DetailShift = ({ t, formik, idx }) => {
  const index = idx;

  return (
    <div>
      <Input
        name={`shifts[${index}].name`}
        errors={formik.errors}
        touched={formik.touched}
        {...formik.getFieldProps(`shifts[${index}].name`)}
        title="Shift"
        type="text"
      />
      <Row style={{ alignItems: 'center' }}>
        <Col>
          <Input
            title={t("field.start_time")}
            name={`shifts[${index}].start_time`}
            errors={formik?.errors}
            touched={formik?.touched}
            value={formik?.values?.shifts[index].start_time}
            onChange={(e) => {
              let value = e.target.value;
              formik.setFieldValue(`shifts[${index}].start_time`, value);
            }}
            type="time"
          />
        </Col>
        <Col style={{ maxWidth: 20, padding: 0 }}>
          <Divider orientation="horizontal" sx={{ borderBottomWidth: '1.5px', background: '#63AE5C' }} />
        </Col>
        <Col>
          <Input
            title={t("field.end_time")}
            name={`shifts[${index}].end_time`}
            errors={formik?.errors}
            touched={formik?.touched}
            value={formik?.values?.shifts[index].end_time}
            onChange={(e) => {
              let value = e.target.value;
              formik.setFieldValue(`shifts[${index}].end_time`, value);
            }}
            type="time"
          />
        </Col>
      </Row>
    </div>
  );
};

export default withTrans(DetailShift);
