import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Divider } from "@mui/material";
import { withTrans } from "../../i18n/withTrans";
import { calculateDiscount } from "../../utils/Constants";
import Select2 from "../../components/forms/Select2";
import Input from "../../components/forms/Input";

const FormPromoPriceModal = ({ t, formik }) => {
  const promoValue = (isBundling) => {
    const promoType = isBundling 
      ? formik?.values?.bundling_promo_type 
      : formik?.values?.promo_type
    return (
      <Row>
        <Col md={12} style={{ paddingRight: "0px" }}>
          <div className="form-group my-0">
            <label className="form-label">
              {
                formik?.values?.type === "cross-selling" 
                  ? t("promo.labelPromoValueService") 
                  : formik?.values?.type === "bundling" && isBundling
                  ? `${t("promo.promoValue")} ${t("promo.bundlingProduct")}` 
                  : formik?.values?.type === "bundling" && !isBundling
                  ? `${t("promo.promoValue")} ${t("promo.mainProduct")}`
                  : t("promo.promoValue")
              }
            </label>
          </div>
        </Col>
        <Col md={4} style={{ paddingRight: "0px" }}>
          <Select2
            name={isBundling ? "bundling_promo_type" : "promo_type"}
            options={
              [
                {
                  value: 0,
                  label: `${t("promo.percentage")} (%)`,
                }
              ].concat(
                formik?.values?.type !== "cross-selling" 
                  ? {
                      value: 1,
                      label: `${t("promo.rupiah")} (Rp)`,
                    }
                  : [] 
              )
            }
            type="text"
            errors={formik?.errors}
            touched={formik?.touched}
            {...formik?.getFieldProps(isBundling ? "bundling_promo_type" : "promo_type")}
            onChange={(name, value) => {
              formik?.setFieldValue(isBundling ? "bundling_promo_type" : "promo_type", value);
            }}
          />
        </Col>
        <Col md={8} style={{ paddingLeft: "0px" }}>
          {promoType === 0 ? (
            <Input
              name={isBundling ? "bundling_discount_percent" : "discount_percent"}
              errors={formik.errors}
              touched={formik.touched}
              type="number"
              min="0"
              max="100"
              placeholder={t("promo.promoValue")}
              {...formik.getFieldProps(isBundling ? "bundling_discount_percent" : "discount_percent")}
            />
          ) : (
            <Input
              currency
              errors={formik.errors}
              touched={formik.touched}
              thousandSeparator={true}
              prefix={"Rp "}
              placeholder={t("promo.promoValue")}
              name={isBundling ? "bundling_discount_price" : "discount_price"}
              {...formik.getFieldProps(isBundling ? "bundling_discount_price" : "discount_price")}
              onChangeValue={(e) => {
                const val = Number(e?.target?.value.replace(/[^0-9\.-]+/g, ""));
                formik?.setFieldValue(isBundling ? "bundling_discount_price" : "discount_price", val);
              }}
            />
          )}
        </Col>
      </Row>
    )
  }

  useEffect(() => {
    if (formik?.values?.type !== 'bundling') return;

    const values = formik?.values;
    let totalMainPrice = 0;
    let totalBundlingPrice = 0;
    const mainPromoType = values?.promo_type === 0 ? 'percentage' : 'price'
    const bundlingPromoType = values?.bundling_promo_type === 0 ? 'percentage' : 'price'

    if (values?.main_price && (values?.discount_price || values?.discount_percent)) {
      totalMainPrice =
        calculateDiscount(
          mainPromoType,
          values?.discount_percent,
          values?.discount_price,
          values?.main_price
        )
    }

    if (
      values?.bundling_product_price &&
      (values?.bundling_discount_price || values?.bundling_discount_percent)
    ) {
      totalBundlingPrice =
        calculateDiscount(
          bundlingPromoType,
          values?.bundling_discount_percent,
          values?.bundling_discount_price,
          values?.bundling_product_price
        )
    }

    const total = totalMainPrice + totalBundlingPrice
    formik?.setFieldValue("total_price", total)

  }, [
    formik?.values?.main_price,
    formik?.values?.bundling_product_price,
    formik?.values?.promo_type,
    formik?.values?.discount_price,
    formik?.values?.discount_percent,
    formik?.values?.bundling_promo_type,
    formik?.values?.bundling_discount_price,
    formik?.values?.bundling_discount_percent
  ])

  return (
    <>
      <Input
        disabled
        currency
        errors={formik.errors}
        touched={formik.touched}
        thousandSeparator={true}
        prefix={"Rp "}
        title={`${t("promo.priceOf")} ${t("promo.mainProduct")}`}
        placeholder={`${t("promo.priceOf")} ${t("promo.mainProduct")}`}
        name="main_price"
        {...formik.getFieldProps("main_price")}
      />
      {promoValue()}
      <Input
        currency
        errors={formik.errors}
        touched={formik.touched}
        thousandSeparator={true}
        prefix={"Rp "}
        title={`${t("promo.priceOf")} ${t("promo.bundlingProduct")}`}
        placeholder={`${t("promo.priceOf")} ${t("promo.bundlingProduct")}`}
        name="bundling_product_price"
        onChangeValue={(e) => {
          const val = Number(e?.target?.value.replace(/[^0-9\.-]+/g, ""));
          formik?.setFieldValue("bundling_product_price", val);
        }}
        {...formik.getFieldProps("bundling_product_price")}
      />
      {promoValue(true)}
      <Divider orientation="horizontal" className="mt-2 mb-3" />
      <Input
        disabled
        currency
        errors={formik.errors}
        touched={formik.touched}
        thousandSeparator={true}
        prefix={"Rp "}
        title={`Total ${t("field.price")}`}
        placeholder={`Total ${t("field.price")}`}
        name="total_price"
        {...formik.getFieldProps("total_price")}
      />
    </>
  );
};

const mapStateToProps = ({ promo: { pending, data_post, data_put, error_message } }) => {
  return { pending, data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(FormPromoPriceModal));
