import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Tab, Tabs, Divider } from "@mui/material";

import Card from "../../../components/commons/cards/card";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import { withTrans } from "../../../i18n/withTrans";
import { GroupType, RoleGroups } from "../../../utils/Constants";
import { getUser } from "../../../utils/User";

// ACTION REDUX
import CorporateService from "../../../store/actions/master_data/corporate";

const DetailModal = ({ t, show, toggle, data }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(GroupType.Charter);
  const [dataDetail, setDataDetail] = useState([]);
  const title = t("commons.detail");

  const handleRefresh = (id) => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 1,
        id: id,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataDetail(res?.data[0]);
      }
    });
  };

  useEffect(() => {
    if (data?.id) {
      handleRefresh(data?.id);
    }
  }, [data]);

  const styledComponent = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    padding: 15,
    color: "#000",
  };

  const styledComponentBorder = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 14,
    padding: 15,
    color: "#000",
    borderBottom: "1px solid #e5e5f0",
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <div style={{ margin: "0px 20px" }}>
            <p className="text-extra-bold pb-20" style={{ fontSize: 20 }}>
              {dataDetail?.name}
            </p>
            <div>
              <Card className="rounded">
                <div style={styledComponentBorder}>
                  <div className="text-extra-bold">ID</div>
                  <div>{dataDetail?.unique_id}</div>
                </div>
                {dataDetail?.type_list?.map((item, i) => { 
                  return (
                    <>
                      <div style={styledComponent}>
                        <div className="text-extra-bold">
                          {`${t("field.cooperationType")} ${i+1}`}
                        </div>
                        <div>{item?.corporate_type}</div>
                      </div>
                      <div style={{ padding: 15 }}>
                        {item?.corporate_type === "Group" && (
                          <div>
                            <Tabs
                              value={activeTab}
                              textColor="primary"
                              indicatorColor="primary"
                              onChange={(event, newValue) => {
                                setActiveTab(newValue);
                              }}
                            >
                              <Tab
                                label={t("masterData.charter")}
                                value={GroupType.Charter}
                              />
                              <Tab
                                label={t("masterData.umrah")}
                                value={GroupType.Umrah}
                              />
                              <Tab 
                                label={t("masterData.hajj")}
                                value={GroupType.Hajj} 
                              />
                              <Tab
                                label={t("masterData.others")}
                                value={GroupType.Others}
                              />
                            </Tabs>
                            {item?.group_list?.map((el) => {
                              if (el.group_type === activeTab) {
                                return (
                                  <div style={{ marginTop: 15 }}>
                                    {el?.property_list?.map((prop) => { 
                                      return (
                                        <Card className="rounded" style={{ marginBottom: 15 }}>
                                          <div style={styledComponent}>
                                            <div className="text-extra-bold">
                                              {!prop?.min_pax && prop?.max_pax 
                                                ? `< ${prop?.max_pax} pax` 
                                                : prop?.min_pax && !prop?.max_pax 
                                                ? `> ${prop?.min_pax} pax` 
                                                : `${prop?.min_pax} - ${prop?.max_pax} pax`}
                                            </div>
                                          </div>
                                        </Card>
                                      )
                                    })}
                                  </div>
                                );
                              } else {
                                <div>Tidak ada data</div>
                              }
                            })}
                          </div>
                        )}
      
                        {item?.corporate_type === "MOB" && (
                          <Card className="rounded">
                            <div
                              className="d-flex justify-content-between"
                              style={{ padding: 15 }}
                            >
                              <div className="normal-title text-extra-bold">MOB </div>
                              <div>{item?.group_list[0]?.property_list[0]?.quota}</div>
                            </div>
                          </Card>
                        )}

                        {item?.corporate_type === "Package" && (
                          item?.group_list[0]?.property_list?.map((property) => { 
                            return (
                              <Card className="rounded" style={{ marginBottom: 15 }}>
                                <div style={styledComponent}>
                                  <div className="text-extra-bold">
                                    {!property?.min_pax && property?.max_pax 
                                      ? `< ${property?.max_pax} pax` 
                                      : property?.min_pax && !property?.max_pax 
                                      ? `> ${property?.min_pax} pax` 
                                      : `${property?.min_pax} - ${property?.max_pax} pax`}
                                  </div>
                                </div>
                              </Card>
                            )
                          })
                        )}
                      </div>
                      <hr style={{ marginTop: 0 }} />
                    </>
                  )
                })}
                
              </Card>
            </div>
          </div>
        </ModalBody>
        {!RoleGroups.contactCenterRoles.includes(currentUser?.user.role_code) && (
          <ModalFooter>
            <ButtonModal
              toggle={toggle}
              confirmTitle="Edit"
              cancelTitle={t("commons.close")}
              hideConfirm
            />
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default withTrans(DetailModal);
