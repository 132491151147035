import moment from "moment";

export const Role = {
  Superadmin: "superadmin",
  Central_admin: "admin_pusat",
  Contact_center: "contact_center",
  Lead_Contact_center: "lead_contact_center",
  Sub_admin: "admin_bandara",
  Corporate: "corporate",
  Finance: "finance",
  Sales: "sales",
  Finance_bandara: "finance_bandara",
  Sales_bandara: "sales_bandara",
  Supervisor: "supervisor",
  Assistant: "assistant",
  Customer: "customer"
};

export const RoleWithAirport = [
  "admin_bandara",
  "supervisor",
  "finance_bandara",
  "sales_bandara",
  "assistant"
];

export const RoleAdminBooking = [
  "admin_bandara",
  "supervisor",
  "assistant"
];

export const RoleGroups  = {
  salesFinanceRoles: [
    "sales", "finance", "sales_bandara", "finance_bandara"
  ],
  reschedulePermissions: [
    "supervisor", "admin_bandara", "admin_pusat", "lead_contact_center",  "contact_center"
  ],
  sdfExportPermissions: [
    "admin_pusat", "lead_contact_center", "contact_center", "admin_bandara", "supervisor", "finance", "finance_bandara"
  ],
  subAdminRoles: [
    "admin_bandara", "supervisor"
  ],
  assignAssistantPermissions: [
    "corporate", "admin_pusat","contact_center", "lead_contact_center"
  ],
  contactCenterRoles: [
    "lead_contact_center", "contact_center"
  ],
  adminCorpReservation: [
    "lead_contact_center", "contact_center", "corporate", "admin_bandara", "supervisor", "assistant"
  ],
  superuserRoles: [
    "admin_pusat", "contact_center", "lead_contact_center"
  ],
  centerSPVRoles: [
    "contact_center", "lead_contact_center","supervisor"
  ]
};

export const Status = {
  Pending: "Pending",
  Draft: "Draft",
  NotOk: "Not Ok",
  Reviewed: "Reviewed",
  NotApproved: "Not Approved",
  Approved: "Approved",
  Bridging: "Bridging",
  Closed: "Closed",
};

export const RoleName = {
  superadmin: "Superadmin",
  admin_pusat: "Admin Pusat",
  contact_center: "Contact Center",
  lead_contact_center: "Lead Contact Center",
  admin_bandara: "Admin Bandara",
  corporate: "Corporate",
  finance: "Finance",
  sales: "Sales",
  supervisor: "Supervisor",
  assistant: "Assistant",
  finance_bandara: "Finance Bandara",
  sales_bandara: "Sales Bandara",
};

export const CoorperationTypeList = [
  { value: "MOB", label: "Minimum Omset Bruto (MOB)" },
  { value: "Group", label: "Group" },
  { value: "Package", label: "Package" },
  { value: "FIT", label: "FIT" },
];

export const CarList = [
  { value: "Toyota Avanza", label: "Toyota Avanza" },
  { value: "Kijang Innova", label: "Kijang Innova" },
  { value: "Toyota Camry", label: "Toyota Camry" },
  { value: "Toyota Alphard", label: "Toyota Alphard" },
  { value: "Hiace", label: "Hiace" },
  { value: "Bus", label: "Bus" },
];

export const GroupType = {
  Hajj: "Hajj",
  Charter: "Charter",
  Umrah: "Umrah",
  Others: "Other",
};

export const handleGroupType = (value, t) => {
  if (value) {
    switch (value) {
      case GroupType.Charter:
        return t("masterData.charter");
      case GroupType.Hajj:
        return t("masterData.hajj");
      case GroupType.Umrah:
        return t("masterData.umrah");
      case GroupType.Others:
        return t("masterData.others");
      default:
        return null;
    }
  }
};

export const flightTypeList = (t) => [
  {
    label: `${t("field.arrival")}`,
    value: 5,
  },
  {
    label: `${t("field.departure")}`,
    value: 6,
  },
];

export const flightTypeTransferList = [
  {
    label: "International to Domestic",
    value: 2,
  },
  {
    label: "International to International",
    value: 1,
  },
  {
    label: "Domestic to International",
    value: 3,
  },
  {
    label: "Domestic to Domestic",
    value: 4,
  }
];

export const productCode = {
  "Meet and Greet International": {
    enum: 1
  },
  "Meet and Greet Domestic": {
    enum: 2
  },
  "Fast Track": {
    enum: 3
  },
  "Transfer": {
    enum: 4
  },
  "Lounge": {
    enum: 5
  },
  "Transport": {
    enum: 6
  }
}

export const reactSelectCustomStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: "#ddd",
    "&:hover": {
      borderColor: "#ddd",
    },
    cursor: "pointer",
    padding: "5px",
    borderRadius: "6px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#000",
    visibility: "visible",
    cursor: "pointer",
    svg: {
      width: "24px",
      height: "22px",
      "&hover": {
        color: "#0D51FF",
      },
    },
  }),
  option: (options, state) => ({
    ...options,
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "15px",
  }),
};

export const service_list = (t) => [
  {
    value: "",
    label: `${t("commons.all")} ${t("commons.activities")}`,
  },
  {
    value: "International Arrival",
    label: t("field.arrivalInter"),
  },
  {
    value: "Domestic Arrival",
    label: t("field.arrivalDomestic"),
  },
  {
    value: "International Departure",
    label: t("field.departureInter"),
  },
  {
    value: "Domestic Departure",
    label: t("field.departureDomestic"),
  },
  {
    value: "Transfer",
    label: "Transfer",
  },
];

export const payment_list = [
  {
    value: "Cash",
    label: "Cash",
  },
  {
    value: "Doku",
    label: "Doku",
  },
  {
    value: "Midtrans",
    label: "Midtrans",
  },
  {
    value: "Invoice",
    label: "Invoice Agent",
  },
  {
    value: "Transfer",
    label: "Transfer",
  },
];

export const payment_list_regular = [
  {
    value: "cash",
    label: "Cash",
  },
  {
    value: "transfer",
    label: "Transfer",
  },
  {
    value: "deposit",
    label: "Deposit",
  },
];

export const activities = (t) => [
  {
    value: "",
    label: `${t("commons.all")} ${t("commons.activities")}`,
  },
  {
    value: "International Arrival",
    label: t("field.arrivalInter"),
  },
  {
    value: "Domestic Arrival",
    label: t("field.arrivalDomestic"),
  },
  {
    value: "International Departure",
    label: t("field.departureInter"),
  },
  {
    value: "Domestic Departure",
    label: t("field.departureDomestic"),
  },
  {
    value: "Transfer International to International",
    label: "Transfer International to International",
  },
  {
    value: "Transfer International to Domestic",
    label: "Transfer International to Domestic",
  },
  {
    value: "Transfer Domestic to International",
    label: "Transfer Domestic to International",
  },
  {
    value: "Transfer Domestic to Domestic",
    label: "Transfer Domestic to Domestic",
  },
];

export const calculateDiscount = (type, discountPercent, discountPrice, price) => {
  let total = price
  if (type === 'percentage') {
    total -= (discountPercent / 100) * price
  } else {
    total -= discountPrice
  }

  return total
}

export const promoTypeList = (t) => [
  {
    value: "",
    label: `${t("commons.all")} Type`,
  },
  {
    value: "blast",
    label: "Blast",
  },
  {
    value: "bundling",
    label: t("promo.bundlingProduct"),
  },
  {
    value: "cross-selling",
    label: t("field.cross_selling"),
  },
  {
    value: "specific",
    label: t("promo.special"),
  }
]

export const statusBooking = (t) => ([
  {
    enum: -1,
    label: t("status.cancelled"),
    color: "#A5A5C0"
  },
  {
    enum: 0,
    label: t("status.new"),
    color: "#D10D0D"
  },
  {
    enum: 1,
    label: t("status.assigned"),
    color: "#f0ad4e"
  },
  {
    enum: 2,
    label: t("status.onProgress"),
    color: "#f0ad4e"
  },
  {
    enum: 3,
    label: "Fully Booked",
    color: "#f0ad4e"
  },
  {
    enum: 4,
    label: "Need Refund",
    color: "#66a7e3"
  },
  {
    enum: 5,
    label: t("status.done"),
    color: "#5cb85c"
  },
  {
    enum: 6,
    label: t("status.refund"),
    color: "#D10D0D"
  },
  {
    enum: 7,
    label: t("status.reschedule"),
    color: "#D10D0D"
  },
])

export const isNotTodayAndTomorrow = (date) => {
  return (
    date !== moment().format("YYYY-MM-DD") &&
    moment().format("YYYY-MM-DD") !== moment(date).subtract(1, "days").format("YYYY-MM-DD")
  )
}

export const corporateGroupType = (t, GroupName) => ({
  MOB: {
    label: "MOB"
  },
  FIT: {
    label: "FIT"
  },
  Package: {
    label: t("field.package")
  },
  Group: {
    label: `${t("field.group")} ${GroupName ?? ''}`
  }
})

export const product_list = [
  {
    label: "MEET & GREET INTERNASIONAL",
    name: "Meet and Greet International",
    type: 1,
  },
  {
    label: "MEET & GREET DOMESTIC",
    name: "Meet and Greet Domestic",
    type: 2,
  },
  {
    label: "FAST TRACK",
    name: "Fast Track",
    type: 3,
  },
  {
    label: "TRANSFER",
    name: "Transfer",
    type: 4,
  },
]

export const titlePassenger = ["Mr.", "Mrs.", "Ms.", "Infant", "Child"];
