import { Row, Col } from "reactstrap";
import Select2 from "../Select2";
import Input from "../Input";
import { ErrorMessage } from "formik";

const DynamicForm = ({
  pbRow,
  title,
  name,
  nameLeft,
  nameCenter,
  formik,
  option,
  optionLeft,
  toggle,
  key,
  value,
  valueLeft,
  valueCenter,
  label,
  labelLeft,
  labelCenter,
  type,
  typeLeft,
  typeCenter,
  input,
  inputCenter,
  placeholder,
  placeholderLeft,
  placeholderCenter,
  min,
  minLeft,
  minCenter,
  max,
  maxLeft,
  maxCenter,
  size,
  currency,
  currencyCenter,
  currencyLeft,
  handleOptionLeft,
  onChangeLeft,
  onChangeCenter,
  onChangeRight,
  disabledLeft,
  disabledCenter,
  disabledRight
}) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Row className={`pb-${pbRow ? pbRow : '10'}`} key={key}>
        <Col
          md={size?.title ?? 6}
          style={{
            alignSelf: "center",
            paddingRight: input || optionLeft ? "0px" : "",
          }}
        >
          {!input && !optionLeft && (
            <div className="form-group">
              <label className="form-label">{title}</label>
            </div>
          )}

          {input && (
            <Input
              title={title}
              name={input}
              errors={formik?.errors}
              touched={formik?.touched}
              placeholder={placeholderLeft ?? "Tulis data"}
              type={typeLeft ?? "text"}
              min={minLeft}
              max={maxLeft}
              currency={currencyLeft}
              {...formik?.getFieldProps(input)}
              onKeyDown={(e) =>
                typeLeft === "number" && (
                  exceptThisSymbols.includes(e.key) &&
                  e.preventDefault()
                )
              }
              onChangeValue={onChangeLeft}
              rightlabel={labelLeft}
              toggleCurrency={formik}
              disabled={disabledLeft}
            />
          )}

          {optionLeft && (
            <Select2
              isClearable={false}
              name={nameLeft}
              options={optionLeft ?? []}
              errors={formik?.errors}
              touched={formik?.touched}
              value={valueLeft}
              min={minLeft}
              max={maxLeft}
              onChange={(nameLeft, value) => {
                if (handleOptionLeft) {
                  handleOptionLeft(`${nameLeft}`, value);
                } else {
                  formik?.setFieldValue(`${nameLeft}`, value);
                }
              }}
              onBlur={formik?.setFieldTouched}
              disabled={disabledLeft}
            />
          )}
        </Col>
        {nameCenter ? (
          <>
            <Col
              md={size?.center ?? 3}
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              {
                <Input
                  name={nameCenter}
                  errors={formik?.errors}
                  touched={formik?.touched}
                  placeholder={placeholderCenter ?? "Tulis data"}
                  type={typeCenter ?? "text"}
                  min={minCenter}
                  max={maxCenter}
                  onKeyDown={(e) =>
                    typeCenter === "number" && (
                      exceptThisSymbols.includes(e.key) &&
                      e.preventDefault()
                    )
                  }
                  onChangeValue={onChangeCenter}
                  {...formik?.getFieldProps(nameCenter)}
                  rightlabel={labelCenter}
                  currency={currencyCenter}
                  toggleCurrency={formik}
                  disabled={disabledCenter}
                />
              }
            </Col>
            <Col md={size?.right ?? 4} style={{ paddingLeft: "0px" }}>
              {option ? (
                <Select2
                  name={name}
                  options={option ?? []}
                  errors={formik?.errors}
                  touched={formik?.touched}
                  value={value}
                  min={min}
                  max={max}
                  onChange={(name, value) => {
                    formik?.setFieldValue(`${name}`, value);
                  }}
                  onBlur={formik?.setFieldTouched}
                  disabled={disabledRight}
                />
              ) : (
                <Input
                  name={name}
                  errors={formik?.errors}
                  touched={formik?.touched}
                  placeholder={placeholder ?? "Tulis data"}
                  type={type ?? "text"}
                  onKeyDown={(e) =>
                    type === "number" && (
                      exceptThisSymbols.includes(e.key) &&
                      e.preventDefault()
                    )
                  }
                  onChangeValue={onChangeRight}
                  min={min}
                  max={max}
                  {...formik?.getFieldProps(name)}
                  rightlabel={label}
                  currency={currency}
                  toggleCurrency={formik}
                  disabled={disabledRight}
                />
              )}
            </Col>
          </>
        ) : (
          <Col
            md={size?.value ?? 6}
            style={{ paddingLeft: input || optionLeft ? "0px" : "" }}
          >
            <div
              style={{ marginTop: title && input && optionLeft ? 35 : "" }}
            ></div>
            {option ? (
              <Select2
                name={name}
                options={option ?? []}
                errors={formik?.errors}
                touched={formik?.touched}
                value={value}
                min={min}
                max={max}
                onChange={(name, value) => {
                  formik?.setFieldValue(`${name}`, value);
                }}
                onBlur={formik?.setFieldTouched}
                disabled={disabledRight}
              />
            ) : (
              <Input
                name={name}
                errors={formik?.errors}
                touched={formik?.touched}
                placeholder={placeholder ?? "Tulis data"}
                type={type ?? "text"}
                onKeyDown={(e) =>
                  type === "number" && (
                    exceptThisSymbols.includes(e.key) &&
                    e.preventDefault()
                  )
                }
                min={min}
                max={max}
                {...formik?.getFieldProps(name)}
                rightlabel={label}
                currency={currency}
                toggleCurrency={formik}
                disabled={disabledRight}
              />
            )}
          </Col>
        )}
      </Row>
      <div style={{ position: "absolute", right: 15, top: 15 }}>
        <span
          className="material-icons-round"
          style={{
            cursor: toggle ? "pointer" : "default",
            opacity: toggle ? "0.8" : "0.1",
          }}
          onClick={toggle}
        >
          close
        </span>
      </div>
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </div>
  );
};

export default DynamicForm;
