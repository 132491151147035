import { FormikConsumer } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { TagsInput } from "react-tag-input-component";
import "./inputTags.scss";

const InputTags = ({formik, name, placeholder, title, index}) => {
  const [selected, setSelected] = useState(
    formik.values.transports[index].region_list === ""
    || formik.values.transports[index].region_list === null 
    ? [] 
    : formik.values.transports[index].region_list
  );
  useEffect (() => {
    if(selected !== null && selected?.length > 0) {
      formik.setFieldValue(name, selected)
    }
  }, [selected])
  return (
    <div>
      <label className="rti--label">
        {title}
      </label>
      <TagsInput
        value={selected}
        onChange={setSelected}
        name={name}
        index={index}
        placeHolder={placeholder}
      />
    </div>
  );
}

export default InputTags