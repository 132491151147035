import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { withTrans } from "../../../../i18n/withTrans";
import Input from "../../../../components/forms/Input";
import Select2 from "../../../../components/forms/Select2";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import InputPrepend from "../../../../components/forms/InputPrepend";
import { toastError } from "../../../../components/commons/toast";

const CorporateLoungeModal = ({ formik, index, value, name, show, toggle, t, reset, confirm }) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("field.passengerData")}{" "}
          <span
            className="close clickable"
            onClick={() => toggle()}
          >
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Input
              name={`${name}.corporate_lounge_list[${index}].name`}
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps(`${name}.corporate_lounge_list[${index}].name`)}
              title={t("field.lounge")}
              type="text"
            />
            <Select2
              title={t('field.routeType')}
              name={`${name}.corporate_lounge_list[${index}].route_type`}
              options={[
                { value: "international", label: t("commons.international") },
                { value: "domestic", label: t("commons.domestic") }
              ]}
              errors={formik?.errors}
              touched={formik?.touched}
              value={
                value?.corporate_lounge_list === undefined
                  ? ""
                  : value?.corporate_lounge_list[index]?.route_type
              }
              onChange={(nameSelected, valueSelected) => {
                formik?.setFieldValue(`${name}.corporate_lounge_list[${index}].route_type`, valueSelected);
              }}
              onBlur={formik?.setFieldTouched}
            />
            <Row style={{ marginTop: 20 }}>
              <Col md={4} style={{ paddingRight: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label">{t("commons.adult")}</label>
                </div>
              </Col>
              <Col md={4} style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label">{t("commons.child")}</label>
                </div>
              </Col>
              <Col md={4} style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label">{t("commons.infantLabel")}</label>
                </div>
              </Col>
            </Row>
            <InputPrepend
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.corporate_lounge_list[${index}].adult_price`,
                  value: value?.corporate_lounge_list === undefined
                    ? ""
                    : value?.corporate_lounge_list[index]?.adult_price,
                  placeholder: t("commons.adult"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_adult = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.corporate_lounge_list[${index}].adult_price`,
                      price_adult
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.corporate_lounge_list[${index}].child_price`,
                  value: value?.corporate_lounge_list === undefined
                    ? ""
                    : value?.corporate_lounge_list[index]?.child_price,
                  placeholder: t("commons.child"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_child = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.corporate_lounge_list[${index}].child_price`,
                      price_child
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.corporate_lounge_list[${index}].infant_price`,
                  value: value?.corporate_lounge_list === undefined
                    ? ""
                    : value?.corporate_lounge_list[index]?.infant_price,
                  placeholder: t("commons.infantLabel"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_infant = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.corporate_lounge_list[${index}].infant_price`,
                      price_infant
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
              ]}
            />
          </Container>
          <div style={{ margin: "50px 20px 0 20px" }}>
            <ButtonModal
              reset={() => reset()}
              toggle={() => toggle()}
              confirm={() => {
                if (value?.corporate_lounge_list[index]?.name === "") {
                  toastError(`${t("commons.empty_data_message")} ${t("field.name")}!`);
                  return;
                }
                if (value?.corporate_lounge_list[index]?.adult_price === "") {
                  formik?.setFieldValue(`${name}.corporate_lounge_list[${index}].adult_price`, 0);
                }
                if (value?.corporate_lounge_list[index]?.child_price === "") {
                  formik?.setFieldValue(`${name}.corporate_lounge_list[${index}].child_price`, 0);
                }
                if (value?.corporate_lounge_list[index]?.infant_price === "") {
                  formik?.setFieldValue(`${name}.corporate_lounge_list[${index}].infant_price`, 0);
                }
                confirm()
              }}
              resetTitle={t("commons.delete")}
              confirmTitle={t("commons.save")}
              icon
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(CorporateLoungeModal);
