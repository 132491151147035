
import { Row, Col } from "reactstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import DetailButton from "../../../../components/commons/table/detailButton";
import RenewalHistoryModal from "../modal/RenewalHistoryModal";
import Card from "../../../../components/commons/cards/card";
import CardHeader from "../../../../components/commons/cards/cardHeader";
import CardBody from "../../../../components/commons/cards/cardBody";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import LabelPills from "../../../../components/commons/table/labelPills";
import LabelPlus from "../../../../components/commons/table/labelPlus";
import TableStatus from "../../../../components/commons/table/status";
import { withTrans } from "../../../../i18n/withTrans";

import CorporatePrice from "../../../../store/actions/product/corporate_price";

const ShowTabCorporateType = ({ t, active, props, id, idType, setIdType, addPrice }) => {
  const dispatch = useDispatch();
  const dateNow = moment().format("YYYY-MM-DD");
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [renewalHistory, setRenewalHistory] = useState([]);
  let idx, data;

  if (active === "0") {
    idx = props?.values?.type_list
      .map((item) => {
        return item?.corporate_type;
      })
      .indexOf("Group");
    data = props?.values?.type_list[idx]?.period_list;
    setIdType(props?.values?.type_list[idx]?.id);
  } else if (active === "1") {
    idx = props?.values?.type_list
      .map((item) => {
        return item?.corporate_type;
      })
      .indexOf("Package");
    data = props?.values?.type_list[idx]?.period_list;
    setIdType(props?.values?.type_list[idx]?.id);
  } else if (active === "2") {
    idx = props?.values?.type_list
      .map((item) => {
        return item?.corporate_type;
      })
      .indexOf("MOB");
    data = props?.values?.type_list[idx]?.period_list;
    setIdType(props?.values?.type_list[idx]?.id);
  } else if (active === "3") {
    idx = props?.values?.type_list
      .map((item) => {
        return item?.corporate_type;
      })
      .indexOf("FIT");
    data = props?.values?.type_list[idx]?.period_list;
    setIdType(props?.values?.type_list[idx]?.id);
  }

  const getRenewalHistory = (id) => {
    new Promise((resolve) => {
      let param = {
        corporate_price_period_id: id
      };
      dispatch(CorporatePrice.getRenewalHistory(param, resolve));
    }).then((res) => {
      if(res?.data) {
        setRenewalHistory(res?.data);
      }
    });
    setShowHistoryModal(!showHistoryModal)
  }

  const handleDelete = (index) => {
    data.splice(index, 1);
    props?.setFieldValue(`type_list[${idType}].period_list`, data);
  };

  return (
    <div className="py-4" style={{ overflowX: 'auto' }}>
      <Card>
        <CardHeader title={t("field.price")} />
        <CardBody>
          {data !== undefined && (
            <Row
              style={{
                fontWeight: 800,
                fontSize: "14px",
                color: "rgba(36, 39, 38, 0.3)",
                paddingBottom: 3,
              }}
            >
              <Col md={5}>
                <Row>
                  <Col md={2} className="px-0 text-center">
                    {t("commons.number")}
                  </Col>
                  <Col md={5}>
                    {t("field.product")}
                  </Col>
                  <Col md={5}>
                    {t("commons.activities")}
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col md={4}>
                    {t("field.airport")}
                  </Col>
                  <Col md={4}>
                    {t("promo.startDate")}
                  </Col>
                  <Col md={4}>
                    {t("promo.endDate")}
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
                <Row>
                  <Col md={9}>
                    {t("field.expiredStatus")}
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </Col>
            </Row>
          )}
          {data?.length < 1 && (
            <CardFlush
              list
              label={t("commons.no_data")}
              size={{ label: 12 }}
            />
          )}
          {data?.map((item, index) => {
            let airports = [];
            const flights = [];
            const serviceList = [];
            if (item?.is_checked_fast == 1) {
              serviceList.push("Fast Track");
              item?.fast_track?.flight_list?.map((flight) => {
                if (flight === 5) {
                  flights?.push(t("field.arrival"))
                }
                if (flight === 6) {
                  flights?.push(t("field.departure"))
                }
              });
              item?.fast_track?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }
            if (item?.is_checked_meet_dom == 1) {
              serviceList.push("Meet and Greet Domestic");
              item?.meet_and_greet_dom?.flight_list?.map((flight) => {
                if (flight === 5) {
                  flights?.push(t("field.arrival"))
                }
                if (flight === 6) {
                  flights?.push(t("field.departure"))
                }
              });
              item?.meet_and_greet_dom?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }
            if (item?.is_checked_meet_inter == 1) {
              serviceList.push("Meet and Greet International");
              item?.meet_and_greet_inter?.flight_list?.map((flight) => {
                if (flight === 5) {
                  flights?.push(t("field.arrival"))
                }
                if (flight === 6) {
                  flights?.push(t("field.departure"))
                }
              });
              item?.meet_and_greet_inter?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }
            if (item?.is_checked_transfer == 1) {
              serviceList.push("Transfer");
              item?.transfer?.flight_list?.map((flight) => {
                if (flight === 1) {
                  flights?.push("International to International")
                }
                if (flight === 2) {
                  flights?.push(t("International to Domestic"))
                }
                if (flight === 3) {
                  flights?.push(t("Domestic to International"))
                }
                if (flight === 4) {
                  flights?.push(t("Domestic to Domestic"))
                }
              });
              item?.transfer?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }
            if (item?.is_checked_lounge == 1) {
              serviceList.push("Lounge");
              item?.lounge?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }
            if (item?.is_checked_transport == 1) {
              serviceList.push("Transport");
              item?.transport?.airport_code_list?.map((airport) => {
                airports?.push(airport?.name)
              });
            }

            const flightList = [...new Set(flights)];
            airports = [...new Set(airports)];
            let uniqueAirportList = [];
            airports?.map((airport) => {
              uniqueAirportList?.push({
                name: airport
              }) 
            });

            return (
              <Row
                style={{
                  fontSize: "14px",
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
              >
                <Col md={5}>
                  <Row>
                    <Col md={2} className="px-0 text-center">
                      {index + 1}
                    </Col>
                    <Col md={5}>
                      {serviceList?.map((item, i) => (
                        <LabelPills data={item} />
                      ))}
                    </Col>
                    <Col md={5}>
                      {flightList?.length ? flightList?.map((item, i) => (
                        <LabelPills data={item} />
                      )) : "-"}
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <Row>
                    <Col md={4}>
                      <LabelPlus data={uniqueAirportList} idRow={uniqueAirportList?.map((item, i) => i)}/>
                    </Col>
                    <Col md={4}>
                      {moment(item?.renewal_start_date ?? item?.start_date).format("DD/MM/YYYY")}
                    </Col>
                    <Col md={4}>
                      {moment(item?.renewal_end_date ?? item?.end_date).format("DD/MM/YYYY")}
                    </Col>
                  </Row>
                </Col>
                <Col md={2}>
                  <Row>
                    <Col md={9}>
                      <TableStatus
                        status={
                          item?.start_date > dateNow
                            ? t("status.notStarted")
                            : item?.end_date < dateNow
                            ? t("field.expired")
                            : moment(item?.end_date).subtract(1, 'month').format("YYYY-MM-DD") <= dateNow
                            ? t("field.expiredSoon")
                            : t("promo.active")
                        }
                        color={
                          item?.start_date > dateNow
                            ? "info"
                            : item?.end_date < dateNow
                            ? "danger"
                            : moment(item?.end_date).subtract(1, 'month').format("YYYY-MM-DD") <= dateNow
                            ? "warning"
                            : "primary"
                        }
                      />
                    </Col>
                    <Col md={3}>
                      <DetailButton>
                        <MenuItem onClick={() => addPrice(props, index, idx)}>
                          {t("commons.detail")}
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(index)}>
                          {t("commons.delete")}
                        </MenuItem>
                        {id && data?.length && (
                          <MenuItem onClick={() => getRenewalHistory(item?.id)}>
                            {t("product.renewalHistory")}
                          </MenuItem>
                        )}
                      </DetailButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          {data !== undefined && (
            <div
              style={{
                color: "#63ae5c",
                padding: "10px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => addPrice(props, null, idx)}
            >
              <div
                style={{
                  borderRadius: "50%",
                  border: "solid 1px #63ae5c",
                  width: 30,
                  height: 30,
                  paddingTop: 2,
                  marginRight: 20,
                  textAlign: "center",
                }}
              >
                <span className="material-icons-round">add</span>
              </div>
              <p
                className="text-bold"
                style={{
                  fontSize: 14,
                  margin: 0,
                  alignSelf: "center",
                }}
              >
                {t("commons.add") + " " + t("field.price")}
              </p>
            </div>
          )}
        </CardBody>
      </Card>
      
      <RenewalHistoryModal
        show={showHistoryModal}
        toggle={() => setShowHistoryModal(!showHistoryModal)}
        data={renewalHistory}
      />
    </div>
  );
};

export default withTrans(ShowTabCorporateType)