import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as Yup from "yup";
import moment from "moment";

import AddPriceCorporate from "./AddPriceCorporate";
import ShowTabCorporateType from "./ShowTabCorporateType";
import Loader from "../../../../components/commons/Loader";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import ConfirmationModal from "../../../../components/commons/modal/ConfirmModal";
import Card from "../../../../components/commons/cards/card";
import CardHeader from "../../../../components/commons/cards/cardHeader";
import CardBody from "../../../../components/commons/cards/cardBody";
import Select2 from "../../../../components/forms/Select2";
import { toastError } from "../../../../components/commons/toast";
import { history } from "../../../../utils/History";
import { withTrans } from "../../../../i18n/withTrans";

//Service
import MasterCorporate from "../../../../store/actions/master_data/corporate";
import CorporatePrice from "../../../../store/actions/product/corporate_price";
import AirportService from "../../../../store/actions/master_data/airport";

const Index = ({ pending, error, data_post, data_put, t }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [listCorps, setListCorps] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [idType, setIdType] = useState(0);
  const [activeTab, setActiveTab] = useState("0");
  const [indexPrice, setIndexPrice] = useState();
  const [indexCancel, setIndexCancel] = useState(null);
  const [lastPriceValue, setLastPriceValue] = useState();
  const [showPageIndex, setShowPageIndex] = useState(true);
  const [showPagePrice, setShowPagePrice] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push({ pathname: "/product", state: { tab: "corp_price" } });
  };

  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
      setAirportActivitiesList(formikRef?.current?.values);
    }
  }, [error]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_put]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res?.data?.map((airport) => {
          list = [
            ...list,
            {
              ...airport,
              value: airport?.id,
              label: `${airport?.code}-${airport?.city}-${airport?.name}`,
            },
          ];
        });
      }
      setDataAirport(list);
    });
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      if (id) {
        dispatch(MasterCorporate.get(param, resolve));
      } else {
        dispatch(CorporatePrice.getNoPrice(param, resolve));
      }
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map(
          (item) =>
            (list = [
              ...list,
              {
                value: item?.id,
                label: item?.name,
                name: item?.name,
              },
            ])
        );
      }
      setListCorps(list);
    });
  }, []);

  const getDataCorp = (id) => {
    new Promise((resolve) => {
      let param = {
        corporate_id: id,
      };
      dispatch(CorporatePrice.getById(param, resolve));
    }).then((res) => {
      if (res) {
        formikRef?.current?.setFieldValue("type_list", res.data.type_list);
      }
    });
  };

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        corporate_id: id,
      };
      if (id) {
        dispatch(CorporatePrice.getById(param, resolve));
      }
    }).then((res) => {
      if (res) {
        formikRef?.current?.setFieldValue("name", res?.data?.name);
        formikRef?.current?.setFieldValue("responsible_airport", res?.data?.responsible_airport);
        formikRef?.current?.setFieldValue("type_list", res?.data?.type_list);
        res?.data?.type_list?.forEach((item, indexType) => {
          if (item?.period_list?.length) {
            item?.period_list?.forEach((period, index) => {
              if (period?.product_list?.length) {
                period?.product_list?.forEach((product) => {
                  if (product?.tax?.is_include_concession) {
                    product.is_includes_concession = ["1"];
                  }
                  if (product?.tax?.is_include_ppn) {
                    product.is_includes_ppn = ["1"];
                  }

                  product?.group_list?.forEach((group) => {
                    group?.property_list?.forEach((property) => {
                      if (property?.prices !== null) {
                        if (property?.prices?.infant_is_free) {
                          property.prices.infant_is_free = ["1"];
                        } else {
                          property.prices.infant_is_free = null;
                        }
                      }
                    });
                  });

                  if (product?.product_name === 1) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_meet_inter`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_inter`,
                      product
                    );
                  }
                  if (product?.product_name === 2) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_meet_dom`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_dom`,
                      product
                    );
                  }
                  if (product?.product_name === 3) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_fast`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].fast_track`,
                      product
                    );
                  }
                  if (product?.product_name === 4) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_transfer`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transfer`,
                      product
                    );
                  }
                  if (product?.product_name === 5) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_lounge`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].lounge`,
                      product
                    );
                    
                    if (!product?.lounge_list?.length) {
                      formikRef?.current?.setFieldValue(
                        `type_list[${indexType}].period_list[${index}].lounge.lounge_list`, 
                        [{
                          lounge_id: "",
                          adult_price: "",
                          child_price: "",
                          infant_price: "",
                        }]
                      );
                    }
                  }
                  if (product?.product_name === 6) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_transport`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transport`,
                      product
                    );
                  }
                });
              }
            });
          }
        });
        setAirportActivitiesList(res?.data);
      }
    });
  }, []);
  
  const setAirportActivitiesList = (data) => {
    data?.type_list?.forEach((item, indexType) => {
      item?.period_list?.length && item?.period_list?.forEach((period, index) => {
        period?.product_list?.length && period?.product_list?.forEach((product) => {
          const nameProps = product?.product_name === 1
            ? `type_list[${indexType}].period_list[${index}].meet_and_greet_inter`
            : product?.product_name === 2
            ? `type_list[${indexType}].period_list[${index}].meet_and_greet_dom`
            : product?.product_name === 3
            ? `type_list[${indexType}].period_list[${index}].fast_track`
            : product?.product_name === 4
            ? `type_list[${indexType}].period_list[${index}].transfer`
            : product?.product_name === 5
            ? `type_list[${indexType}].period_list[${index}].lounge`
            : `type_list[${indexType}].period_list[${index}].transport`;
          
          formikRef?.current?.setFieldValue(
            `${nameProps}.airport_list`,
            product?.airport_list?.map((x) => x.airport_id)
          );
          formikRef?.current?.setFieldValue(
            `${nameProps}.airport_code_list`,
            product?.airport_list?.map((x) => {
              return {
                id: x?.id,
                name: `${x?.code}`
              }
            })
          );
          formikRef?.current?.setFieldValue(
            `${nameProps}.flight_list`,
            product?.flight_list?.map((x) => x.flight_type)
          );
        });
      });
    });
  };

  const validation = Yup.object().shape({
    name: Yup.string().required(t("commons.required")).nullable(),
    id: Yup.string().required(t("commons.required")).nullable(),
    type_list: Yup.array().required(t("commons.required")).nullable(),
  });

  const initialValues = {
    id: id ?? "",
    name: "",
    responsible_airport: "",
    type_list: [],
  };

  const addPrice = (formik, index, idxType) => {
    if (index !== null && index !== undefined) {
      setIndexPrice(index);
      setIndexCancel(index);
      setLastPriceValue(formik?.values?.type_list[idxType]?.period_list);
    } else {
      setIndexPrice(formik?.values?.type_list[idxType]?.period_list?.length);
      setIndexCancel(null);
    }
    setShowPagePrice(!showPagePrice);
    setShowPageIndex(!showPageIndex);
  };

  const compareFlightTypes = (array1, array2) => {
    //filter array1 that have same flight_type with array2 
    const sameValuesInArray1 = array1.filter((item) =>
      array2.some((entry) => entry.flight_type === item.flight_type));

    //filter array2 that not have same flight_type with array1
    const spliceSameValuesInArray2 = array2.filter((item) =>
      !array1.some((entry) => item.flight_type === entry.flight_type))

    const results = [...sameValuesInArray1, ...spliceSameValuesInArray2];
    return results;
  };

  const compareDataAirports = (array1, array2) => {
    //filter array1 that have same airport_id with array2 
    const sameValuesInArray1 = array1.filter((item) =>
      array2.some((entry) => entry.airport_id === item.airport_id));

    //filter array2 that not have same airport_id with array1
    const spliceSameValuesInArray2 = array2.filter((item) =>
      !array1.some((entry) => item.airport_id === entry.airport_id))

    const results = [...sameValuesInArray1, ...spliceSameValuesInArray2];
    return results;
  };

  return (
    <>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={async (values, { setStatus }) => {
          let isNotValid = false;
          const allPriceEmpty = values?.type_list.every(item => !item?.period_list?.length);
          if (allPriceEmpty) {
            toastError(
              `${t("commons.empty_data_message")} ${t("product.period")}!`
            );
            setLoadingSubmit(false);
            isNotValid = true;
          }

          values?.type_list?.map((item) => {
            item?.period_list?.map((el) => {
              if (el?.activate_renewal == 1) {
                el.start_date = el?.renewal_start_date;
                el.end_date = el?.renewal_end_date;
              }

              let product_list = [];
              if (el?.is_checked_meet_inter == 1) {
                el.meet_and_greet_inter.airport_list =
                  el?.meet_and_greet_inter?.airport_list?.map(
                    (item, index) => ({ airport_id: item })
                  );
                el.meet_and_greet_inter.flight_list =
                  el?.meet_and_greet_inter?.flight_list?.map((item, index) => ({
                    flight_type: item,
                  }));
                el.meet_and_greet_inter["product_name"] = 1;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(1);
                  if (idx > -1) {
                    el.meet_and_greet_inter.airport_list =
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.meet_and_greet_inter.airport_list
                      );
                    el.meet_and_greet_inter.flight_list =
                      compareFlightTypes(
                        el?.product_list[idx]?.flight_list,
                        el.meet_and_greet_inter.flight_list
                      );
                  }
                }

                product_list?.push(el?.meet_and_greet_inter);
              }

              if (el?.is_checked_meet_dom == 1) {
                el.meet_and_greet_dom.airport_list =
                  el?.meet_and_greet_dom?.airport_list?.map((item, index) => ({
                    airport_id: item,
                  }));
                el.meet_and_greet_dom.flight_list =
                  el?.meet_and_greet_dom?.flight_list?.map((item, index) => ({
                    flight_type: item,
                  }));
                el.meet_and_greet_dom["product_name"] = 2;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(2);
                  if (idx > -1) {
                    el.meet_and_greet_dom.airport_list =
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.meet_and_greet_dom.airport_list
                      );
                    el.meet_and_greet_dom.flight_list =
                      compareFlightTypes(
                        el?.product_list[idx]?.flight_list,
                        el.meet_and_greet_dom.flight_list
                      );
                  }
                }

                product_list?.push(el?.meet_and_greet_dom);
              }

              if (el?.is_checked_fast == 1) {
                el.fast_track.airport_list = el?.fast_track?.airport_list?.map(
                  (item, index) => ({ airport_id: item })
                );
                el.fast_track.flight_list = el?.fast_track?.flight_list?.map(
                  (item, index) => ({ flight_type: item })
                );
                el.fast_track["product_name"] = 3;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(3);
                  if (idx > -1) {
                    el.fast_track.airport_list =
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.fast_track.airport_list
                      );
                    el.fast_track.flight_list =
                      compareFlightTypes(
                        el?.product_list[idx]?.flight_list,
                        el.fast_track.flight_list
                      );
                  }
                }

                product_list?.push(el?.fast_track);
              }

              if (el?.is_checked_transfer == 1) {
                el.transfer.airport_list = el?.transfer?.airport_list?.map(
                  (item, index) => ({ airport_id: item })
                );
                el.transfer.flight_list = el?.transfer?.flight_list?.map(
                  (item, index) => ({ flight_type: item })
                );
                el.transfer["product_name"] = 4;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(4);
                  if (idx > -1) {
                    el.transfer.airport_list = 
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.transfer.airport_list
                      );
                    el.transfer.flight_list = 
                      compareFlightTypes(
                        el?.product_list[idx]?.flight_list,
                        el.transfer.flight_list
                      );
                  }
                }

                product_list?.push(el?.transfer);
              }

              if (el?.is_checked_lounge == 1) {
                el.lounge.airport_list = el?.lounge?.airport_list?.map(
                  (item, index) => ({ airport_id: item })
                );
                el.lounge["product_name"] = 5;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(5);
                  if (idx > -1) {
                    el.lounge.airport_list = 
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.lounge.airport_list
                      );
                    if (el?.lounge?.lounge_list?.length) {
                      el.lounge.lounge_list = el?.lounge?.lounge_list?.filter((item) => item?.lounge_id);
                    }
                  }
                }
                product_list?.push(el?.lounge);
              }

              if (el?.is_checked_transport == 1) {
                el.transport.airport_list = el?.transport?.airport_list?.map(
                  (item, index) => ({ airport_id: item })
                );
                el.transport["product_name"] = 6;

                if (el?.product_list?.length) {
                  const idx = el?.product_list
                    .map((product) => product?.product_name)
                    .indexOf(6);
                  if (idx > -1) {
                    el.transport.airport_list = 
                      compareDataAirports(
                        el?.product_list[idx]?.airport_list,
                        el.transport.airport_list
                      );
                  }
                }
                product_list?.push(el?.transport);
              }

              el["product_list"] = product_list;

              el?.product_list?.forEach((product) => {
                if (id) {
                  if (!product?.tax || product?.tax === null) {
                    product.tax = {};
                  }
                  if (product?.is_includes_concession == 1) {
                    product.tax.is_include_concession = true;
                  } else {
                    product.tax.is_include_concession = false;
                  }
                  if (product?.is_includes_ppn == 1) {
                    product.tax.is_include_ppn = true;
                  } else {
                    product.tax.is_include_ppn = false;
                  }
                  product.tax.is_concession_from_airport = true;
                  product.tax.is_ppn_from_airport = true;
                } else {
                  if (product?.is_includes_concession == 1) {
                    product.is_include_concession = true;
                  } else {
                    product.is_include_concession = false;
                  }
                  if (product?.is_includes_ppn == 1) {
                    product.is_include_ppn = true;
                  } else {
                    product.is_include_ppn = false;
                  }
                  product.is_concession_from_airport = true;
                  product.is_ppn_from_airport = true;
                }


                product?.group_list?.forEach((group) => {
                  group?.property_list?.forEach((property) => { 
                    if (property?.prices === null) {
                      property.prices = {};
                    }
                    if (property?.prices?.infant_is_free == 1) {
                      property.prices.infant_is_free = true;
                    } else {
                      property.prices.infant_is_free = false;
                    }
                    if (property?.prices?.price) {
                      property.prices.price = property?.prices?.price;
                    }
                    if (property?.prices?.adult_price) {
                      property.prices.adult_price =
                        property?.prices?.adult_price;
                    }
                    if (property?.prices?.child_price) {
                      property.prices.child_price =
                        property?.prices?.child_price;
                    }
                    if (property?.prices?.infant_price) {
                      property.prices.infant_price =
                        property?.prices?.infant_price;
                    }
                  });
                });
              });
            });
          });

          const callback = () => {
            history.push({ pathname: "/product", state: { tab: "corp_price" } });
          };

          if (!isNotValid) {
            setStatus();
            if (id) {
              setLoadingSubmit(true);
              dispatch(CorporatePrice.put(values, callback));
            } else {
              setLoadingSubmit(true);
              dispatch(CorporatePrice.post(values, callback));
            }
          }
        }}
      >
        {(props) => (
          <Form>
            <>
              {showPagePrice && (
                <AddPriceCorporate
                  formik={props}
                  idTypeList={idType}
                  index={indexPrice}
                  indexCancel={indexCancel}
                  lastPriceValue={lastPriceValue}
                  toggle={addPrice}
                />
              )}
              {showPageIndex && (
                <div className="menu-container">
                  {pending && <Loader loading={pending} />}
                  <div className="col-md-12 px-5">
                    <div className="d-flex align-items-center">
                      <div className="treatment-title menu-title text-extra-bold pl-0">
                        <div className="treatment-title-icon ">
                          {id
                            ? `${t("commons.edit")} ${t("field.price")} ${t(
                                "field.corporation"
                              )}`
                            : `${t("commons.set")} ${t("field.price")} ${t(
                                "field.corporation"
                              )}`}
                        </div>
                      </div>
                    </div>
                    <Card className="rounded">
                      <CardHeader title={t("field.corporateName")} />
                      <CardBody>
                        <div style={{ paddingBottom: '0.3em' }}>
                          <Select2
                            name="id"
                            options={listCorps}
                            type="text"
                            errors={props?.errors}
                            touched={props?.touched}
                            {...props?.getFieldProps("id")}
                            value={props.values.id}
                            onChange={(name, value) => {
                              const idx = listCorps
                                .map((item) => {
                                  return item?.value;
                                })
                                .indexOf(value);
                              getDataCorp(value);
                              props?.setFieldValue("id", value);
                              props?.setFieldValue("name", listCorps[idx].name);
                            }}
                            disabled={id}
                          />
                        </div>
                        <hr />
                        <div>
                          <p style={{
                              color: '#63ae5c',
                              fontWeight: 'bold',
                              paddingTop: '1em',
                              paddingBottom: '0.8em',
                            }}
                          >
                            {t("field.pullBranch")}
                          </p>
                          <Select2
                            clearable={!!props.values?.responsible_airport}
                            name="responsible_airport"
                            options={dataAirport}
                            type="text"
                            errors={props?.errors}
                            touched={props?.touched}
                            value={props.values.responsible_airport}
                            onChange={(name, value) => {
                              const idx = dataAirport
                                .map((item) => {
                                  return item?.value;
                                })
                                .indexOf(value);
                              props?.setFieldValue("responsible_airport", value);
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title={t("field.cooperationType")} />
                      {props?.values?.name && (
                        <CardBody>
                          <div
                            className="content-container-side"
                            style={{ padding: "0" }}
                          >
                            <div className="tab-menu overflow">
                              <div style={{ width: "fit-content" }}>
                                <Tabs
                                  scrollButtons="auto"
                                  variant="scrollable"
                                  value={activeTab}
                                  textColor="primary"
                                  indicatorColor="primary"
                                  onChange={(event, newValue) => {
                                    setActiveTab(newValue);
                                  }}
                                >
                                  {props?.values?.type_list?.map((item) => {
                                    return (
                                      <Tab
                                        label={item?.corporate_type}
                                        value={
                                          item?.corporate_type === "Group"
                                            ? "0"
                                            : item?.corporate_type === "Package"
                                            ? "1"
                                            : item?.corporate_type === "MOB"
                                            ? "2"
                                            : "3"
                                        }
                                      />
                                    );
                                  })}
                                </Tabs>
                              </div>
                            </div>
                            <ShowTabCorporateType
                              id={id}
                              addPrice={addPrice}
                              active={activeTab}
                              props={props}
                              idType={idType}
                              setIdType={(id) => setIdType(id)}
                            />
                          </div>
                        </CardBody>
                      )}
                    </Card>
                    <div style={{ float: "right" }}>
                      <ButtonModal
                        cancelTitle={t("commons.cancel")}
                        confirmTitle={t("commons.save")}
                        toggle={() => showCancelModal()}
                        disabledConfirm={loadingSubmit}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        show={cancelModal}
        toggle={showCancelModal}
        confirm={handleCancel}
        icon="info"
        isDelete
        isCancel={t("commons.back")}
        isConfirm={t("commons.yesCancel")}
      >
        <Col md={8}>
          <div className="normal-title">
            {t("commons.areYouSureCancel")}{" "}
            <strong>{`${t("field.price")} ${t("field.corporation")}`}</strong> ?
          </div>
        </Col>
      </ConfirmationModal>
    </>
  );
};

const mapStateToProps = ({ corp_price: { error } }) => {
  return { error };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
