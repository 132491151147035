import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import DetailButton from "../../components/commons/table/detailButton";
import MenuItem from "@mui/material/MenuItem";
import Loader from "../../components/commons/Loader";
import Button from "../../components/commons/buttons/Button";
import Table from "../../components/commons/table";

import CommissionService from "../../store/actions/master_data/commission";
import ModalFilter from "./ModalFilter";
import ModalData from "./ModalData";
import ModalDetail from "./ModalDetail";

const Commission = ({ t, pending }) => {

    const dispatch = useDispatch();

    const [dataCommission, setDataCommission] = useState([]);
    const [commissionRow, setCommissionRow] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [totalData, setTotalData] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [showModalData, setShowModalData] = useState(false);
    const [filter, setFilter] = useState("");

    const handleFilterModal = () => {
        setShowModalFilter(true);
    };

    const showDataModal = (data) => {
        setShowModalData(!showModalData);
        setCommissionRow(data ?? null);
    };

    const showDetailModal = (data) => {
        setShowModalDetail(!showModalData);
        setCommissionRow(data ?? null);
    };

    const handleRefresh = () => {
        new Promise((resolve) => {
            let param = {
                page: page === 0 ? 1 : page ?? 1,
                length: 10,
                id: null,
                search_text: searchText,
                type: filter?.type,
                year: filter?.year ?? "",
            };
            dispatch(CommissionService.get(param, resolve));
        }).then((res) => {
            setDataCommission(res?.data);
            setTotalData(res?.totalCount);
            setMaxPage(Math.ceil(res?.totalCount / 10));
        });
    };

    const onApplyFilter = (filter) => {
        setFilter(filter);
        setShowModalFilter(false);
    };

    const handleSubmit = (data) => {

        const callback = () => {
            setShowModalData(false);
            handleRefresh();
        };

        if (commissionRow) {
            data.id = commissionRow.id;
            dispatch(CommissionService.put(data, callback));
        } else {
            dispatch(CommissionService.post(data, callback));
        }

    };

    useEffect(() => {
        handleRefresh();
    }, [page, searchText, filter]);

    useEffect(() => {
        setPage((prev) => (prev === null ? null : 1));
    }, [searchText, filter]);

    const columns = [
        {
            Header: "NO",
            accessor: "no",
        },
        {
            Header: t("field.year"),
            accessor: "year",
        },
        {
            Header: `${t("commons.type")} `,
            Cell: ({ row }) => {
                const original = row ? row?.original : [];
                let type = original.type;
                return <>{
                    type === 'Individual' 
                        ? 'Individual' 
                        : type === 'Group' 
                        ? 'Group (Charter, Hajj, Umrah)' 
                        : `Group ${t('masterData.others')}`
                }</>;
            },
        },
        {
            Header: "",
            accessor: "actions",
            Cell: ({ row }) => {
                const original = row ? row?.original : [];
                return (
                    <DetailButton>
                        <MenuItem
                            onClick={() => showDetailModal(original)}
                        >
                            <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
                        </MenuItem>
                    </DetailButton>
                );
            },
        },
    ]

    return (
        <React.Fragment>
            {pending && <Loader fullScreen loading={pending} />}
            <HeaderMenu title={t("field.commission")}>
                <Button
                    title="Filter"
                    size="sm"
                    rounded={true}
                    variant="button-light btn-lg ml-3"
                    onClick={() => handleFilterModal()}
                />
                <div className="nav-separator mr-3 ml-3" />

                <Button
                    title={`${t("commons.add")} ${t("field.commission")}`}
                    leftIcon="add"
                    rounded={true}
                    variant="primary"
                    onClick={() => showDataModal(null)}
                />
            </HeaderMenu>
            <div className="content-container-side">
                <div className="overflow">
                    <Table
                        columns={columns}
                        data={dataCommission ?? []}
                        pagination={{ page, totalData, maxPage, toggle: setPage }}
                        isLoading={pending}
                    />
                </div>
            </div>
            <ModalFilter
                show={showModalFilter}
                toggle={() => setShowModalFilter(false)}
                filter={filter}
                onApplyFilter={(value) => onApplyFilter(value)}
                pending={pending}
            />
            <ModalData
                show={showModalData}
                toggle={() => setShowModalData(false)}
                action={(value) => handleSubmit(value)}
                data={commissionRow}
                title={`${commissionRow ? t('commons.edit') : t('commons.add')} ${t('field.commission')}`}
                pending={pending}
            />
            <ModalDetail
                show={showModalDetail}
                toggle={() => setShowModalDetail(false)}
                action={() => setShowModalData(commissionRow)}
                data={commissionRow}
                pending={pending}
            />
        </React.Fragment>
    )

}

const mapStateToProps = ({ commission: { pending, data } }) => {
    return { pending, data };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Commission));