import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, Divider } from "@mui/material";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicForm from "../../../components/forms/DynamicForm";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import Button from "../../../components/commons/buttons/Button";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { CoorperationTypeList, GroupType } from "../../../utils/Constants";

// ACTION REDUX
import CorporateService from "../../../store/actions/master_data/corporate";

const MasterDataModal = ({ t, show, toggle, action, data, confirm, pending }) => {
  const formikRef = useRef();
  const [activeTab, setActiveTab] = useState("0");
  const [dataDetail, setDataDetail] = useState([]);
  const [listPax, setLisPax] = useState({});
  const [lastMinPax, setLastMinPax] = useState(0);
  const [lastMaxPax, setLastMaxPax] = useState(0);
  const [showCooperation, setShowCooperation] = useState(0);

  const dispatch = useDispatch();
  const title =
    (data ? t("commons.edit") : t("commons.add")) +
    " " +
    t("field.corporation");
  
  const handleRefresh = (id) => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 1,
        id: id,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      if (res?.data) {
        setDataDetail(res?.data[0]);
        if (res?.data[0]?.type_list) {
          let listPax = {};
          res?.data[0]?.type_list?.map((item) => {
            item?.group_list?.map((el) => { 
              if (el.group_type === GroupType.Charter) {
                listPax.charter = el;
              }
              if (el.group_type === GroupType.Hajj) {
                listPax.hajj = el;
              }
              if (el.group_type === GroupType.Umrah) {
                listPax.umrah = el;
              }
              if (el.group_type === GroupType.Others) {
                listPax.others = el;
              }
              if (el.group_type === "MOB") {
                listPax.mob = el;
              }
              if (el.group_type === "Package") {
                listPax.package = el;
              }
            })
          });
          setLisPax(listPax);

          formikRef?.current?.setFieldValue(
            "cooperation", 
            res?.data[0]?.type_list?.map(item => ({
              ...item,
              id: item?.id,
              type: item?.corporate_type,
              max_mob: listPax?.mob?.property_list[0]?.quota ?? "",
              package: listPax?.package?.property_list ?? [{
                min_pax: "",
                max_pax: "",
              }],
              charter: listPax?.charter?.property_list ?? [{
                  min_pax: "",
                  max_pax: "",
              }],
              umrah: listPax?.umrah?.property_list ?? [{
                  min_pax: "",
                  max_pax: "",
              }],
              hajj: listPax?.hajj?.property_list ?? [{
                  min_pax: "",
                  max_pax: "",
              }],
              others: listPax?.others?.property_list ?? [{
                min_pax: "",
                max_pax: "",
              }],
              is_checked_charter: listPax.charter ? ["1"] : null,
              is_checked_hajj: listPax.hajj ? ["1"] : null,
              is_checked_umrah: listPax.umrah ? ["1"] : null,
              is_checked_others: listPax.others ? ["1"] : null,
            }))
          );

        }
      }
    });
  };

  useEffect(() => {
    if (data?.id) {
      handleRefresh(data?.id);
    }
  }, [data]);

  const initial = {
    id: dataDetail?.id ?? null,
    name: dataDetail?.name ?? "",
    unique_id: dataDetail?.unique_id ?? "",
    cooperation: [{
      type: "",
      list_pax: [],
      charter: [{
        min_pax: "",
        max_pax: "",
      }],
      umrah: [{
        min_pax: "",
        max_pax: "",
      }],
      hajj: [{
        min_pax: "",
        max_pax: "",
      }],
      others: [{
        min_pax: "",
        max_pax: "",
      }],
      package: [{
        min_pax: "",
        max_pax: "",
      }],
      is_checked_charter: null,
      is_checked_hajj: null,
      is_checked_umrah: null,
      is_checked_others: null,
      max_mob: "",
    }],
  };

  const validateMinMax = (name) => {
    return Yup.object({
      min_pax: Yup.number()
        .min(1, `minimum ${name} must be greater than or equal to 1`)
        .test({
          name: 'max',
          exclusive: false,
          params: {},
          message: `minimum ${name} must be less than or equal to maximum ${name}`,
          test: function (value) {
            if(this.parent.max_pax)
              return value ? value <= Number(this.parent.max_pax) : ' '
            else 
              return ' '
          }
        })
        // .required(t("commons.required"))
        .nullable(),
      max_pax: Yup.number()
        .test({
          name: 'min',
          exclusive: false,
          params: {},
          message: `maximum ${name} must be greater than or equal to minimum ${name}`,
          test: function (value) {
            return value ? value >= Number(this.parent.min_pax) : ' '
          },
        })
        // .required(t("commons.required"))
        .nullable(),
    })
  }

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("field.corporateName")} ${t("commons.required")}`).nullable(),
    unique_id: Yup.string().required(`ID ${t("commons.required")}`).nullable(),
    cooperation: Yup.array().of(
      Yup.object().shape({ 
        is_checked_charter: Yup.array().notRequired().nullable(),
        is_checked_hajj: Yup.array().notRequired().nullable(),
        is_checked_umrah: Yup.array().notRequired().nullable(),
        is_checked_others: Yup.array().notRequired().nullable(),
        charter: Yup.array().of(
          Yup.object().when(['is_checked_charter'], {
            is: (is_checked_charter) => is_checked_charter !== null,
            then: validateMinMax('charter')
          }).nullable(),
        ),
        hajj: Yup.array().of(
          Yup.object().when(['is_checked_hajj'], {
            is: (is_checked_hajj) => is_checked_hajj !== null,
            then: validateMinMax('hajj')
          }).nullable(),
        ),
        umrah: Yup.array().of(
          Yup.object().when(['is_checked_umrah'], {
            is: (is_checked_umrah) => is_checked_umrah !== null,
            then: validateMinMax('umrah')
          }).nullable(),
        ),
        others: Yup.array().of(
          Yup.object().when(['is_checked_others'], {
            is: (is_checked_others) => is_checked_others !== null,
            then: validateMinMax('')
          }).nullable()
        ),
      })
    ),
  });

  const showTab = (active, props, i) => {
    let name , label , value , group , data, indexCooperation;
    if (active === "0") {
      name = "charter";
      label = t("masterData.charter");
      value = props?.values?.cooperation[i].is_checked_charter;
      data = props?.values?.cooperation[i].charter;
      indexCooperation = i;
    } else if (active === "1") {
      name = "umrah";
      label = t("masterData.umrah");
      value = props?.values?.cooperation[i].is_checked_umrah;
      data = props?.values?.cooperation[i].umrah;
      indexCooperation = i;
    } else if (active === "2") {
      name = "hajj";
      label = t("masterData.hajj");
      value = props?.values?.cooperation[i].is_checked_hajj;
      data = props?.values?.cooperation[i].hajj;
      indexCooperation = i;
    } else if (active === "3") {
      name = "others";
      label = t("masterData.others");
      value = props?.values?.cooperation[i].is_checked_others;
      data = props?.values?.cooperation[i].others;
      indexCooperation = i;
    }

    return (
      <>
        <div className="checkbox-wrapper">
          <Checkbox
            style={{ marginTop: "30px" }}
            name={`cooperation[${indexCooperation}]is_checked_${name}`}
            checked={value ?? []}
            onChange={(event) => {
              let check = event.target.checked
              if (check) {
                props.setFieldValue(`cooperation[${indexCooperation}]is_checked_${name}`, ["1"])
                props.setFieldValue(`cooperation[${indexCooperation}]${name}`, [{
                  min_pax: "",
                  max_pax: "",
                }])
              } else {
                props.setFieldValue(`cooperation[${indexCooperation}]is_checked_${name}`, null)
                props.setFieldValue(`cooperation[${indexCooperation}]${name}`, [{
                  min_pax: "",
                  max_pax: "",
                }])
              }
            }}
            value={1}
            label={label}
          />
        </div>
        {value !== null && value?.includes("1") && (
          <React.Fragment>
            {data?.map((item, i) => { 
              return ( 
                <>
                  <DynamicForm
                    input={`cooperation[${indexCooperation}]${name}[${i}].min_pax`}
                    name={`cooperation[${indexCooperation}]${name}[${i}].max_pax`}
                    placeholderLeft={"Min"}
                    placeholder={"Max"}
                    typeLeft="number"
                    type="number"
                    minLeft={i >= 1 ? data[i-1]?.max_pax + 1 : 1}
                    min={i >= 1 ? data[i-1]?.max_pax + 1 : 1}
                    formik={props}
                    errors={props?.errors}
                    touched={props?.touched}
                    size={{ title: 6, value: 6 }}
                    toggle={
                      data?.length > 1
                        ? () => handleDeleteList(item, i, props, name)
                        : null
                    }
                  />
                </>
              )
            })}
            <div style={{ textAlign: "center" }}>
              <Button
                variant="button secondary"
                rounded
                title={t("commons.add") + " " + t("field.group")}
                onClick={() => handleAddGroup(props, name)}
                type="Button"
                style={{ padding: "8px 20px" }}
              />
            </div>
          </React.Fragment>
        )}
      </>
    );
  };

  const handleDeleteList = (item, index, props, name) => {
    let values = props?.values?.cooperation;

    values.map((x, i) => { 
      if(name === "charter") {
        if (x.charter && item && (index !== undefined || index !== null)) {
          let valuesCharter = x.charter;
          valuesCharter.splice(index, 1);
          props?.setFieldValue(`cooperation[${i}]charter`, valuesCharter);
        }
      }
      if (name === "hajj") {
        if (x.hajj && item && (index !== undefined || index !== null)) {
          let valuesHajj = x.hajj;
          valuesHajj.splice(index, 1);
          props?.setFieldValue(`cooperation[${i}]hajj`, valuesHajj);
        }
      }
      if (name === "umrah") {
        if (x.umrah && item && (index !== undefined || index !== null)) {
          let valuesUmrah = x.umrah;
          valuesUmrah.splice(index, 1);
          props?.setFieldValue(`cooperation[${i}]umrah`, valuesUmrah);
        }
      }
      if (name === "others") {
        if (x.others && item && (index !== undefined || index !== null)) {
          let valuesOthers = x.others;
          valuesOthers.splice(index, 1);
          props?.setFieldValue(`cooperation[${i}]others`, valuesOthers);
        }
      }

    })
  };

  const handleAddGroup = (props, name) => {
    let values = props?.values.cooperation;

    values.map((item, i) => {
      if(name === "charter") {
        item.charter = addFieldChecked(item.charter);
        props?.setFieldValue(`cooperation[${i}]charter`, item.charter);
      }
      if(name === "hajj") {
        item.hajj = addFieldChecked(item.hajj)
        props?.setFieldValue(`cooperation[${i}]hajj`, item.hajj);
      }
      if(name === "umrah") {
        item.umrah = addFieldChecked(item.umrah)
        props?.setFieldValue(`cooperation[${i}]umrah`, item.umrah);
      }
      if(name === "others") {
        item.others = addFieldChecked(item.others)
        props?.setFieldValue(`cooperation[${i}]others`, item.others);
      }
    })
  };

  const addFieldChecked = (values) => { 
    values = [...values, { 
      min_pax: "", 
      max_pax: "" 
    }];
    return values
  }

  const handleAddCooperation = (props) => {
    let values = props?.values.cooperation;
    let last_element = values.length > 0 ? values[values.length - 1] : null;
    if (last_element.type) {
      values = [...values, {
        type: "",
        list_pax: [],
        charter: [],
        umrah: [],
        hajj: [],
        others: [],
        is_checked_charter: null,
        is_checked_hajj: null,
        is_checked_umrah: null,
        is_checked_others: null,
        max_mob: ""
      }];
      props?.setFieldValue(`cooperation`, values);
    } else {
      toastError(t("commons.empty_data_message"));
    }
  };

  const deleteCooperationType = (item, index, props) => {
    let values = props?.values?.cooperation;
    
    if (values && item && (index !== undefined || index !== null)) {
      values.splice(index, 1);
      props?.setFieldValue("cooperation", values);
    }
  };

  const handleAddPaxPackage = (props, indexCooperation) => {
    let packages = props?.values.cooperation[indexCooperation]?.package;
    let last_element = packages.length > 0 ? packages[packages.length - 1] : null;
    if (last_element) {
      if (last_element?.min_pax || last_element?.max_pax) {
        packages = [...packages, { min_pax: "", max_pax: "" }];
        props?.setFieldValue(`cooperation[${indexCooperation}].package`, packages);
      } else {
        toastError(t("commons.empty_data_message"));
      }
    } else {
      packages = [...packages, { min_pax: "", max_pax: "" }];
      props?.setFieldValue(`cooperation[${indexCooperation}].package`, packages);
    }
  };

  const handleDeletePaxPackage = (indexCooperation, indexPackage, props) => {
    let cooperation = props?.values?.cooperation[indexCooperation];
    if (
      cooperation?.package?.length && (indexPackage !== undefined || indexPackage !== null)
    ) {
      let packagePrice = cooperation?.package;
      packagePrice.splice(indexPackage, 1);
      cooperation.package = packagePrice;
      props?.setFieldValue(`cooperation[${indexCooperation}].package`, packagePrice);
    }
  };

  const mappingGroupList = (
    groupTypeData, 
    groupTypeName, 
    groupList,
  ) => {
    const list_pax = [];
    let group_id = '';

    groupTypeData.forEach(el => {
      group_id = el?.corporate_type_group_id ?? null;
      list_pax.push(el);
    })
    const group_list = {id: group_id, group_type: groupTypeName, property_list: list_pax};

    return groupList.push(group_list);
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          enableReinitialize
          validationSchema={validation}
          onSubmit={async (values, { setSubmitting }) => {
            let empty = false;
            let groupEmpty = false;
            let isNotValid = false;
            let messageErrorMOB = '';
            values?.cooperation
              ?.map((item) => {
                let group_type_data =
                  item?.is_checked_charter
                    ? item.charter
                    : item?.is_checked_hajj
                    ? item.hajj
                    : item?.is_checked_umrah
                    ? item.umrah
                    : item.others;

                if (item?.type === '') {
                  toastError(
                    `${t("masterData.typeEmptyMessage")}!`
                  );
                  isNotValid = true;
                  return;
                } else if (item?.type === 'MOB') {
                  if (item.max_mob === '') {
                    empty = true;
                    messageErrorMOB = 'quota MOB';
                  }
                } else if (item?.type === 'Group') {
                  group_type_data.forEach((el, i) => {
                    if (el?.min_pax === '' && el?.max_pax === '') {
                      empty = true;
                    }

                    if (el?.min_pax && !el?.max_pax) {
                      el.max_pax = el?.min_pax;
                    }
                    if (el?.max_pax && !el?.min_pax) {
                      el.min_pax = el?.max_pax;
                    }

                    if (
                      i >= 1 && 
                      el?.min_pax <= group_type_data[i-1]?.max_pax
                    ) {
                      toastError(
                        `${t("commons.handleMinGroupPax")}!`
                      );
                      isNotValid = true;
                      return;
                    }
                    if (el?.max_pax < el?.min_pax) {
                      toastError(
                        `${t("commons.handleMaxGroupPax")}!`
                      );
                      isNotValid = true;
                      return;
                    }
                  })
                } else if (item?.type === 'Package') {
                  item.package.forEach((el, i) => {
                    if (el?.min_pax === '' && el?.max_pax === '') {
                      empty = true;
                    }

                    if (el?.min_pax && !el?.max_pax) {
                      el.max_pax = el?.min_pax;
                    }
                    if (el?.max_pax && !el?.min_pax) {
                      el.min_pax = el?.max_pax;
                    }

                    if (
                      i >= 1 && 
                      el?.min_pax <= item.package[i-1]?.max_pax
                    ) {
                      toastError(
                        `${t("commons.handleMinGroupPax")}!`
                      );
                      isNotValid = true;
                      return;
                    }
                    if (el?.max_pax < el?.min_pax) {
                      toastError(
                        `${t("commons.handleMaxGroupPax")}!`
                      );
                      isNotValid = true;
                      return;
                    }
                  })
                }
              })

            if (empty) {
              toastError(`${t("commons.empty_data_message")} ${messageErrorMOB ? messageErrorMOB : 'PAX'}`);
              return;
            }
            
            let val = {
              name: "",
              id: null,
              unique_id: "",
              type_list: [],
            };
            val.name = values.name;
            val.unique_id = values.unique_id;
            if (data?.id || dataDetail?.id) {
              val.id = data?.id ?? dataDetail?.id;
            }

            values.cooperation.forEach(item => {
              let cooperationId = (data?.id || dataDetail?.id) && item?.id ? item?.id : null;
              let cooperationType = item.type;

              if (
                item.type === "MOB" ||
                item.type === "FIT"
              ) {
                const cooperation = {
                  id: cooperationId,
                  corporate_type: cooperationType, 
                  group_list: [{
                    group_type: cooperationType,
                    property_list: [
                      {
                        id: 
                          (data?.id || dataDetail?.id) && item?.group_list?.length 
                            ? item?.group_list[0]?.property_list[0]?.id 
                            : null,
                        quota: item.type === "MOB" ? item.max_mob : 1
                      }
                    ],
                    id: 
                      (data?.id || dataDetail?.id) && item?.group_list?.length 
                        ? item?.group_list[0]?.id 
                        : null,
                  }]
                };
                val.type_list.push(cooperation);
              }
              else if (item?.type === "Package") {
                let package_list = [];
                mappingGroupList(item.package, "Package", package_list);
                const cooperation = {
                  id: cooperationId,
                  corporate_type: cooperationType, 
                  group_list: package_list
                };
                val.type_list.push(cooperation);
              } else {
                if (
                  !item.is_checked_charter &&
                  !item.is_checked_hajj &&
                  !item.is_checked_umrah &&
                  !item.is_checked_others
                ) {
                  toastError(t("masterData.minErrorMessage"));
                  groupEmpty = true;
                  return;
                }

                let group_list = [];
                if (item.is_checked_charter) {
                  mappingGroupList(item.charter, "Charter", group_list);
                }
                if (item.is_checked_hajj) {
                  mappingGroupList(item.hajj, "Hajj", group_list);
                }
                if (item.is_checked_umrah) {
                  mappingGroupList(item.umrah, "Umrah", group_list);
                }
                if (item.is_checked_others) {
                  mappingGroupList(item.others, "Other", group_list);
                }

                const cooperation = {
                  id: cooperationId,
                  corporate_type: cooperationType, 
                  group_list: group_list
                };
                val.type_list.push(cooperation);
              }
            })
            
            if (!groupEmpty && !isNotValid) {
              action(val);
              await delay(3000);
            }
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="unique_id"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("unique_id")}
                    title="ID"
                    type="text"
                  />
                  <Input
                    name="name"
                    errors={props.errors}
                    touched={props.touched}
                    {...props.getFieldProps("name")}
                    title={t("field.corporateName")}
                    type="text"
                  />
                  {props?.values?.cooperation?.map((item, i) => { 
                    return ( 
                      <div className="wrapper-detail-card">
                        <div
                          className="d-flex p-3"
                          style={{
                            justifyContent: "space-between",
                            fontSize: 14,
                          }}
                        >
                          <div className="text-extra-bold">
                            {`${t("field.cooperationType")} ${i + 1}`}
                          </div>
                          <div
                            onClick={() =>
                              setShowCooperation(showCooperation === i ? null : i)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-icons-round left-icon">
                              expand_more
                            </span>
                          </div>
                        </div>
                        <Divider
                          orientation="horizontal"
                        />
                        {showCooperation === i && ( 
                          <>
                            <div className="p-3">
                              <Select2
                                title={`${t("commons.type")}`}
                                name="type"
                                options={CoorperationTypeList}
                                errors={props.errors}
                                touched={props.touched}
                                value={item.type}
                                placeholder="Pilih tipe"
                                onChange={(name, value) => {
                                  const isTypeExist = 
                                    props?.values?.cooperation?.some(
                                      (el) => el.type === value
                                    );
                                  if (isTypeExist) {
                                    toastError(t("masterData.cantSameType"));
                                  } else {
                                    props.setFieldValue(`cooperation[${i}].type`, value);
                                    props.setFieldValue("max_mob", "");
                                    if (value === "Package") {
                                      props.setFieldValue(
                                        `cooperation[${i}].package`, 
                                        [{ min_pax: "", max_pax: "" }]
                                      );
                                    }
                                  }
                                }}
                                onBlur={props.setFieldTouched}
                              />
                              {item.type && (
                                <div className="form-group" style={{ marginBottom: 0 }}>
                                  <label className="form-label">
                                    {item.type === "Group"
                                      ? t("field.group")
                                      : item.type === "MOB"
                                      ? "MOB"
                                      : item?.type === "Package"
                                      ? t("field.package")
                                      : ""}
                                  </label>
                                </div>
                              )}
                              {item.type === "Group" && (
                                <div style={{ marginBottom: 15 }}>
                                  <Tabs
                                    value={activeTab}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    onChange={(event, newValue) => {
                                      setActiveTab(newValue);
                                    }}
                                  >
                                    <Tab label={t("masterData.charter")} value="0" />
                                    <Tab label={t("masterData.umrah")} value="1" />
                                    <Tab label={t("masterData.hajj")} value="2" />
                                    <Tab label={t("masterData.others")} value="3" />
                                  </Tabs>
                                  <div className="overflow">
                                    {showTab(activeTab, props, i)}
                                  </div>
                                </div>
                              )}
                              {item.type === "MOB" && (
                                <Input
                                  name="max_mob"
                                  errors={props.errors}
                                  touched={props.touched}
                                  {...props.getFieldProps(`cooperation[${i}].max_mob`)}
                                  type="number"
                                  style={{ marginBottom: 30 }}
                                />
                              )}
                              {item?.type === "Package" && (
                                <>
                                  {item?.package?.map((packageItem, index) => { 
                                    return ( 
                                      <DynamicForm
                                        input={`cooperation[${i}].package[${index}].min_pax`}
                                        name={`cooperation[${i}].package[${index}].max_pax`}
                                        placeholderLeft={"Min"}
                                        placeholder={"Max"}
                                        typeLeft="number"
                                        type="number"
                                        minLeft={i >= 1 ? item?.package[index-1]?.max_pax + 1 : 1}
                                        min={i >= 1 ? item?.package[index-1]?.max_pax + 1 : 1}
                                        formik={props}
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        size={{ title: 6, value: 6 }}
                                        toggle={
                                          item?.package?.length > 1
                                            ? () => handleDeletePaxPackage(i, index, props)
                                            : null
                                        }
                                      />
                                    )
                                  })}
                                  <div style={{ textAlign: "center" }}>
                                    <Button
                                      variant="button secondary"
                                      rounded
                                      title={t("commons.add") + " " + t("field.package")}
                                      onClick={() => handleAddPaxPackage(props, i)}
                                      type="Button"
                                      style={{ padding: "8px 20px" }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <Button
                              variant="button danger"
                              title={t("commons.delete")}
                              onClick={() => deleteCooperationType(item, i, props)}
                              disabled={props?.values?.cooperation?.length === 1}
                              type="Button"
                              style={{ 
                                padding: 15,
                                width: "100%",
                                color: "white",
                                borderBottomRightRadius: 15,
                                borderBottomLeftRadius: 15,
                              }}
                            />
                          </>
                        )}
                      </div>
                    )
                  })}
                  {props?.values?.cooperation?.length < 4 && (
                    <div style={{ textAlign: "center", paddingTop: "20" }}>
                      <Button
                        variant="button secondary"
                        rounded
                        title={t("commons.add") + " " + t("field.cooperationType")}
                        onClick={() => handleAddCooperation(props)}
                        type="Button"
                        style={{ padding: "8px 20px" }}
                      />
                    </div>
                  )}
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(MasterDataModal);
