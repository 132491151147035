import "./radioButton.scss";
const RadioButton = ({
  onChange, 
  id, 
  isSelected, 
  label, 
  value, 
  name
}) => {

  return (
    <div className="RadioButton">
      <input
        id={id}
        onChange={onChange}
        value={value}
        name={name}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
  
export default RadioButton
