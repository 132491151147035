import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { DateRange, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { useClickaway } from "../../hooks/ClickawayHooks";

const DateRanges = ({
  min,
  max,
  name,
  type,
  title,
  error,
  format,
  touched,
  endDate,
  disabled,
  className,
  startDate,
  defaultValue,
  handlechange,
  selectionType,
  onChange,
  notReadOnly,
  width,
  containerPosition,
  ...props
}) => {
  const dropdownRef = useRef();

  useClickaway(dropdownRef, () => {
    setIsOpen(false);
  });

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(undefined);

  const dateFormat = format ?? "YYYY-MM-DD";

  const formatDate = (date) => {
    if (date) {
      return moment(date).format(format);
    } else {
      return null;
    }
  };

  const placehold =
    selectionType === "single"
      ? dateFormat.toLowerCase()
      : `${dateFormat.toLowerCase()} - ${dateFormat.toLowerCase()}`;

  const inputValue =
    selectionType === "single"
      ? value
        ? `${formatDate(value)}`
        : placehold
      : value
        ? `${formatDate(value?.startDate)} - ${formatDate(value?.endDate)}`
        : placehold;

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
    width: width
  };

  const onToggle = () => (disabled ? null : setIsOpen(!isOpen));

  const onClear = () => {
    setValue("");

    if (selectionType !== "single") {
      let defaultDate = null;
      if(defaultValue){
        defaultDate = new Date(moment(defaultValue).format('YYYY-MM-DD')) 
      }
      handlechange(defaultDate ?? "", defaultDate ?? "", type ? type : null);
    }
  };

  const handleSelect = (daterange) => {
    let selection = daterange;
    if (selection) {
      if (selection.selection.startDate) {
        let { startDate, endDate } = selection.selection;
        selection = {};
        startDate = moment(startDate);
        selection.startDate = startDate.isValid()
          ? startDate.toDate()
          : undefined;

        endDate = moment(endDate);
        selection.endDate = endDate.isValid() ? endDate.toDate() : undefined;
      } else {
        let date = moment(selection);
        selection = date.isValid() ? date.toDate() : undefined;
      }
    }

    setValue(selection);
    let dateData = null;

    if (selectionType === "single") {
      dateData = moment(selection).format("YYYY-MM-DD") ?? "";
    } else {
      dateData = {
        startDate: moment(selection.startDate).format("YYYY-MM-DD"),
        endDate: moment(selection.endDate).format("YYYY-MM-DD"),
      };
    }

    if (onChange) {
      onChange(name, dateData);
    }

    if (handlechange) {
      handlechange(dateData.startDate, dateData.endDate);
    }
  };

  useEffect(() => {
    if(startDate && endDate){
      let start = new Date(moment(startDate).format('YYYY-MM-DD'));
      let end = new Date(moment(endDate).format('YYYY-MM-DD'));
      setValue({startDate : start, endDate: end})
    }
  }, [startDate, endDate]);

  return (
    <div className={className} style={styles}>
      <div
        className={`form-group ${className}`}
        style={{
          position: "relative",
          fontFamily: "sans-serif",
          textAlign: "center",
        }}
        onClick={() => onToggle()}
      >
        <label
          className="form-label"
          style={{
            textAlign: "left",
            display: title ? "block" : "none",
          }}
        >
          {title}
        </label>
        <div className="input-wrapper" style={{ width: "100%" }}>
          <input
            {...props}
            value={inputValue}
            className={
              "form-control form-input " +
              className +
              (error && touched ? " is-invalid" : "")
            }
            readOnly={!notReadOnly ? true : false}
            style={{
              fontSize: 14,
              minHeight: 48,
              paddingRight: 40,
              paddingLeft: 20,
              display: "inline-block",
            }}
            placeholder={placehold}
            name={name}
          />
          {value && selectionType !== "single" ? (
            <span
              className="material-icons-round right-icon-input"
              style={{ cursor: "pointer" }}
              onClick={() => onClear()}
            >
              close
            </span>
          ) : (
            <span
              className="material-icons-round right-icon-input"
              style={{ cursor: "pointer" }}
            >
              event
            </span>
          )}
        </div>
        {touched && error && (
          <div
            className="invalid-feedback"
            style={{ textAlign: "left", display: "block" }}
          >
            {error}
          </div>
        )}
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="container-date-range"
          style={{ position: "absolute", right: containerPosition ?? 15 }}
        >
          <div style={{ position: "relative", zIndex: 99 }}>
            {selectionType === "single" && (
              <Calendar
                date={value}
                onChange={handleSelect}
                minDate={min ? moment(min).toDate() : undefined}
                maxDate={max ? moment(max).toDate() : undefined}
              />
            )}
            {selectionType !== "single" && (
              <DateRange
                ranges={[
                  {
                    startDate: value?.startDate ?? new Date(),
                    endDate: value?.endDate ?? new Date(),
                    key: "selection",
                  },
                ]}
                onChange={handleSelect}
                showMonthAndYearPickers={true}
              // moveRangeOnFirstSelection={false}
              // showDateDisplay={false}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRanges;
