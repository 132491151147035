import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";

import BookingService from "../../../store/actions/booking";
import DetailButton from "../../../components/commons/table/detailButton";
import EditPassengerModal from "../modal/ModalEditPassenger";
import EditBookerModal from "../modal/ModalEditBooker";
import Table from "../../../components/commons/table";
import { Role, RoleGroups } from "../../../utils/Constants";
import { withTrans } from "../../../i18n/withTrans";
import { getLengthPassAdult, getLengthPassChild, getLengthPassInfant } from "../../../utils/Helper";

const DetailPassenger = ({ t, dataDetail, currentUser, handleRefresh }) => {
  const dispatch = useDispatch();
  const { role_code, airport_id } =  currentUser.user;
  const isCanEdit = airport_id !== dataDetail?.airport_id_cross_selling
    && RoleGroups.subAdminRoles.includes(role_code);

  const [dataPassengerRow, setDataPassengerRow] = useState([]);
  const [editBookerModal, setEditBookerModal] = useState(false);
  const [editPassengerModal, setEditPassengerModal] = useState(false);

  const handleEditBooker = (param) => {
    const callback = () => {
      setEditBookerModal(!editBookerModal);
      handleRefresh();
    };
    if (
      param.bookers_phone !== "" &&
      param.bookers_phone !== undefined &&
      param.bookers_phone !== null &&
      !param.bookers_phone.includes("undefined") &&
      param.bookers_phone !== param.bookers_country_code
    ) {
      const bookerPhone = param.bookers_phone
        .replace("+", "")
        .replace(param?.bookers_country_code, "");

      param.bookers_phone = 
        bookerPhone.charAt(0) == 0
          ? bookerPhone?.substring(1)
          : bookerPhone;
      param.bookers_country_code = `+${param.bookers_country_code}`
    } else {
      param.bookers_phone = ""
      param.bookers_country_code = ""
    }

    if (param) {
      dispatch(BookingService.editBooker(param, callback));
    }
  };
  
  const showEditPassengerModal = (data) => {
    setEditPassengerModal(!editPassengerModal);
    setDataPassengerRow(data ? data : []);
  };

  const handleEditPassenger = (param) => {
    const callback = () => {
      setEditPassengerModal(!editPassengerModal);
      handleRefresh();
    };

    if (
      param.phoneNumber !== "" &&
      param.phoneNumber !== undefined &&
      param.phoneNumber !== null &&
      !param.phoneNumber.includes("undefined") &&
      param.phoneNumber !== param.countryCode
    ) {
      const phone = param.phoneNumber
        .replace("+", "")
        .replace(param?.countryCode, "");

      param.phoneNumber = 
        phone.charAt(0) == 0
          ? phone?.substring(1)
          : phone;
      param.countryCode = `+${param.countryCode}`
    } else {
      param.phoneNumber = ""
      param.countryCode = ""
    }

    if (param) {
      dispatch(BookingService.editPassenger(param, callback));
    }
  };

  const columnPassengers = [
    {
      Header: "No",
      Cell: ({ row }) => {
        return row?.index + 1;
      },
    },
    {
      Header: t("field.passenger"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.name ?? "-";
      },
    },
    {
      Header: t("field.nationality"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.passenger_nationality ?? "-";
      },
    },
    {
      Header: t("field.email"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.email ?? "-";
      },
    },
    {
      Header: t("field.phone"),
      Cell: ({ row }) => {
        const { original } = row;
        return original?.phone_number && original?.country_code 
          ? `${original?.country_code}${original?.phone_number}` 
          : original?.phone_number && !original?.country_code 
          ? original?.phone_number
          : "-";
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showEditPassengerModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
            </MenuItem>
          </DetailButton>
        );
      },
      show: isCanEdit,
    },
  ];

  return (
    <>
    <div className="wrapper-detail-card">
      <div className="text-extra-bold container-card" style={{ opacity: 0.5 }}>
        <Row>
          <Col md={3}>{t("field.passenger")}</Col>
        </Row>
      </div>
      <Divider orientation="horizontal" />
      <Table
        columns={columnPassengers.filter((item) => item.show !== false)}
        data={dataDetail?.list_order_passengers ?? []}
        minHeight="100%"
      />
      <Divider orientation="horizontal" />
      <div
        className="d-flex align-items-center mt-4"
        style={{ paddingLeft: 15, paddingRight: 15 }}
      >
        <p
          className="normal-title text-extra-bold"
          style={{ opacity: 0.5 }}
        >
          Total PAX
        </p>
        <div className="ml-auto">
          <p className="normal-title text-bold">
            {`${getLengthPassAdult(dataDetail?.list_order_passengers)} ${t("commons.adult")}, ${
              getLengthPassChild(dataDetail?.list_order_passengers)} ${t("commons.child")}, ${
              getLengthPassInfant(dataDetail?.list_order_passengers)} ${t("commons.infantLabel")}`}
          </p>
        </div>
      </div>
    </div>
    <div className="wrapper-detail-card">
      <div className="text-extra-bold container-card" style={{ opacity: 0.5 }}>
        <Row>
          <Col md={3}>{t("field.booker")}</Col>
        </Row>
      </div>
      <Divider orientation="horizontal" />
      <Row
        className="py-3"
        style={{ fontSize: 14, padding: "15px 25px" }}
      >
        <Col
          md={3}
          className="text-extra-bold"
          style={{ color: "#000" }}
        >
          {t("field.booker")}
        </Col>
        <Col
          md={3}
          className="text-extra-bold"
          style={{ color: "#000" }}
        >
          {t("field.nationality")}
        </Col>
        <Col
          md={3}
          className="text-extra-bold"
          style={{ color: "#000" }}
        >
          {t("field.email")}
        </Col>
        <Col
          md={2}
          className="text-extra-bold"
          style={{ color: "#000" }}
        >
          {t("field.phone")}
        </Col>
        <Col md={1}></Col>
      </Row>
      <Divider orientation="horizontal" />
      <Row
        className="py-3"
        style={{ fontSize: 14, padding: "15px 25px" }}
      >
        <Col md={3}>{dataDetail?.bookers_name ?? ""}</Col>
        <Col md={3}>{dataDetail?.bookers_nationality ?? ""}</Col>
        <Col md={3}>{dataDetail?.bookers_email ?? ""}</Col>
        <Col md={2}>
          {dataDetail?.bookers_phone && dataDetail?.bookers_country_code
            ? `${dataDetail?.bookers_country_code}${dataDetail?.bookers_phone}` 
            : dataDetail?.bookers_phone && !dataDetail?.bookers_country_code
            ? dataDetail?.bookers_phone
            : ""}
        </Col>
        <Col md={1}>
          {isCanEdit ? (
            <DetailButton>
              <MenuItem
                onClick={() => {
                  setEditBookerModal(!editBookerModal);
                }}
              >
                <span style={{ fontSize: 12 }}>
                  {t("commons.edit")}
                </span>
              </MenuItem>
            </DetailButton>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
    
    <EditPassengerModal
      show={editPassengerModal}
      toggle={showEditPassengerModal}
      action={handleEditPassenger}
      data={dataPassengerRow}
    />

    <EditBookerModal
      show={editBookerModal}
      toggle={() => setEditBookerModal(!editBookerModal)}
      action={handleEditBooker}
      data={dataDetail}
    />
    </>
  )
}

export default withTrans(DetailPassenger)