const DynamicButton = ({
  titleLeft,
  titleRight,
  titleCenter,
  iconLeft,
  iconCenter,
  iconRight,
  checkbox,
  toggle,
  outline,
  subtitleLeft,
  subtitleCenter,
  iconLeftStyle,
  description,
  error,
  active,
  value,
  checked,
  onChange,
  disabled,
  styleInput,
  label,
  desc,
  style,
  name,
  isChecked,
  marginButton
}) => {
  return (
    <>
      {titleCenter ? ( 
        <div
          className="button"
          style={{
            color: ` ${active ? "#63AE5C" : "#102316"}`,
            width: "100%",
            padding: "21px 25px",
            justifyContent: "enter",
            cursor: toggle ? "pointer" : "",
            background: "#FFFF",
            border: `solid 1px  ${active ? "#63AE5C" : "#E5E5F0"}`,
            margin: "20px 5px",
            backgroundColor: `${active ? "#ECF5EB" : "#FFFFFF"}`,
          }}
          onClick={toggle}
        >
            <div style={{ display: "flex",
              flexDirection: "column" }}>
              <div
                className={
                  outline
                    ? "material-icons-outlined left-icon"
                    : "material-icons-round left-icon"
                }
                style={{
                  color: `${active ? "#63AE5C" : "#A5A5C0"}`,
                  fontSize: subtitleCenter ? "50px" : "",
                  display: "flex",
                  margin: "auto",
                  justifyContent: "center"
                }}
              >
                {iconCenter}
              </div>
              <div>
                <p className="text-bold text-center" style={{ margin: 0 }}>
                  {titleCenter}
                </p>
                <p className="text-center"
                  style={{ color: "#A5A5C0", marginTop: 10, marginBottom: 0 }}
                >
                  {subtitleCenter}
                </p>
              </div>
            </div>
        </div>
      ): (
        <div
          className="button"
          style={{
            color: ` ${active ? "#63AE5C" : "#102316"}`,
            width: "100%",
            padding: "21px 25px",
            justifyContent: "space-between",
            cursor: toggle ? "pointer" : "",
            background: "#FFFF",
            border: `solid 1px  ${active ? "#63AE5C" : "#E5E5F0"}`,
            margin: `${marginButton ? marginButton : "20px 5px"}`,
            backgroundColor: `${active ? "#ECF5EB" : "#FFFFFF"}`,
          }}
          onClick={toggle}
        >
  
          {checkbox && (
            <label className="checkbox-container" style={{ marginBottom: 5 }}>
              <div className="title py-2 text-extra-bold" style={{ fontSize: 14, cursor: 'context-menu' }}>{label}</div>
              {desc && (
                <div className="normal-title">{desc}</div>
              )}
              <input type="checkbox"
                style={style}
                name={name}
                value={value}
                checked={checked ? checked?.includes(String(value)) : false}
                onChange={onChange}
                disabled={disabled}
              />
              <span className="checkmark" style={styleInput}></span>
            </label>
          )}
  
          {isChecked && (
            <label className="checkbox-container">
              <div className="title py-2 text-extra-bold" style={{ fontSize: 14, cursor: 'context-menu' }}>{label}</div>
              {desc && (
                <div className="normal-title">{desc}</div>
              )}
              <input type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                disabled
                value={value} />
              <span
                style={{
                  ...styleInput,
                  borderRadius: "50%",
                  backgroundColor: "#63AE5C",
                  borderColor: "#63AE5C",
                  color: "#FFFFFF",
                }}
                className="checkmark material-icons-round icon-only"
              >
                done
              </span>
            </label>
          )}
  
          <div style={{ float: "left", display: "flex", alignItems: "center" }}>
            {iconLeft && (
              <span
                className={
                  outline
                    ? "material-icons-outlined left-icon"
                    : "material-icons-round left-icon"
                }
                style={{
                  color: `${active ? "#63AE5C" : "#A5A5C0"}`,
                  fontSize: iconLeftStyle ? iconLeftStyle : subtitleLeft ? "50px" : "",
                }}
              >
                {iconLeft}
              </span>
            )}
            {titleLeft && (
              <div>
                <p className="text-bold" style={{ margin: 0 }}>
                  {titleLeft}
                </p>
                {subtitleLeft && (
                  <p
                    className="text-bold"
                    style={{ color: "#A5A5C0", marginTop: 10, marginBottom: 0 }}
                  >
                    {subtitleLeft}
                  </p>
                )}
                {description && (
                  <p
                    className="subtitle"
                    style={{ color: "#A5A5C0" }}
                  >
                    {description}
                  </p>
                )}
              </div>
            )}
          </div>
          <div style={{ float: "right", display: "flex" }}>
            {titleRight && <span>{titleRight}</span>}
            {iconRight && (
              <span className="material-icons-round left-icon">{iconRight}</span>
            )}
          </div>
        </div>
      )}
      {error && (
        <p style={{ color: "#D10D0D", marginTop: "-15px", fontSize: 12 }}>
          {error}
        </p>
      )}
    </>
  );
};

export default DynamicButton;
