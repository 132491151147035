import ReactTooltip from "react-tooltip";
import { numberWithCommas } from "../../utils/Helper";
import { withTrans } from "../../i18n/withTrans";

const TooltipTaxBooking = ({
  t,
  id,
  include_ppn,
  include_concession,
  ppn_percentage,
  concession_percentage,
  ppn_price,
  concession_price,
  dataOtherDPP
}) => {
  return (
    <>
      <span
        className="material-icons"
        style={{ fontSize: 18 }}
        data-tip
        data-for={id}
      >
        info_outlined
      </span>
      <ReactTooltip id={id} place="top" effect="solid">
        <p style={{ whiteSpace: "pre-line" }}>
          {(include_ppn && !include_concession && ppn_percentage !== null) ||
          (include_ppn &&
            include_concession &&
            concession_percentage === null &&
            ppn_percentage !== null) ? (
            <span>
              {id ==='taxOthers' && dataOtherDPP !== null && dataOtherDPP !== 0
                ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                    numberWithCommas(dataOtherDPP)}, Rp ${
                    numberWithCommas(ppn_price)
                  }`
                : `${t("product.includes_ppn")} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)}`
              }
            </span>
          ) : !include_ppn &&
            include_concession &&
            concession_percentage !== null ? (
            <span>
              {id ==='taxOthers' && dataOtherDPP !== null && dataOtherDPP !== 0
                ? `${t("product.includes_concession")} ${concession_percentage}% of DPP Rp ${
                    numberWithCommas(dataOtherDPP)}, Rp ${
                    numberWithCommas(concession_price)
                  }`
                : `${t("product.includes_concession")} ${concession_percentage}%, Rp ${
                    numberWithCommas(concession_price)
                  }`
              }
            </span>
          ) : include_ppn &&
            include_concession &&
            concession_percentage !== null &&
            ppn_percentage !== null ? (
            <span>
              {id ==='taxOthers' && dataOtherDPP !== null && dataOtherDPP !== 0
                ? `${t("product.includes_ppn")} ${ppn_percentage}% of DPP Rp ${
                    numberWithCommas(dataOtherDPP)}, Rp ${
                    numberWithCommas(ppn_price)} & ${t("product.includes_concession")} ${
                    concession_percentage}% of DPP Rp ${
                    numberWithCommas(dataOtherDPP)}, Rp ${numberWithCommas(
                    concession_price
                  )}`
                : `${t(
                    "product.includes_ppn"
                  )} ${ppn_percentage}%, Rp ${numberWithCommas(ppn_price)} & ${t(
                    "product.includes_concession"
                  )} ${concession_percentage}%, Rp ${numberWithCommas(
                    concession_price
                  )}`
              }
            </span>
          ) : (
            ""
          )}
        </p>
      </ReactTooltip>
    </>
  );
};

export default withTrans(TooltipTaxBooking);