import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastError } from "../../../components/commons/toast";

import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_DASHBOARD_PENDING = "GET_DASHBOARD_PENDING";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";
export const GET_DASHBOARD_STATUS_PENDING = "GET_DASHBOARD_STATUS_PENDING";
export const GET_DASHBOARD_STATUS_SUCCESS = "GET_DASHBOARD_STATUS_SUCCESS";
export const GET_DASHBOARD_STATUS_ERROR = "GET_DASHBOARD_STATUS_ERROR";
export const GET_DASHBOARD_TABLE_PENDING = "GET_DASHBOARD_TABLE_PENDING";
export const GET_DASHBOARD_TABLE_SUCCESS = "GET_DASHBOARD_TABLE_SUCCESS";
export const GET_DASHBOARD_TABLE_ERROR = "GET_DASHBOARD_TABLE_ERROR";
export const GET_DASHBOARD_CHART_PENDING = "GET_DASHBOARD_CHART_PENDING";
export const GET_DASHBOARD_CHART_SUCCESS = "GET_DASHBOARD_CHART_SUCCESS";
export const GET_DASHBOARD_CHART_ERROR = "GET_DASHBOARD_CHART_ERROR";
export const POST_DASHBOARD_PENDING = "POST_DASHBOARD_PENDING";
export const POST_DASHBOARD_SUCCESS = "POST_DASHBOARD_SUCCESS";
export const POST_DASHBOARD_ERROR = "POST_DASHBOARD_ERROR";
export const PUT_DASHBOARD_PENDING = "PUT_DASHBOARD_PENDING";
export const PUT_DASHBOARD_SUCCESS = "PUT_DASHBOARD_SUCCESS";
export const PUT_DASHBOARD_ERROR = "PUT_DASHBOARD_ERROR";
export const DELETE_DASHBOARD_PENDING = "DELETE_DASHBOARD_PENDING";
export const DELETE_DASHBOARD_SUCCESS = "DELETE_DASHBOARD_SUCCESS";
export const DELETE_DASHBOARD_ERROR = "DELETE_DASHBOARD_ERROR";

export const DOWNLOAD_REPORT_PENDING = "DOWNLOAD_REPORT_PENDING";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_ERROR = "DOWNLOAD_REPORT_ERROR";

// URL: URL_{URL}
const DASHBOARD_URL = `v1/report/dashboard_v2`;
const DASHBOARD_STATUS_URL = `v1/report/dashboard/status`;
const DASHBOARD_SUMMARY_TABLE_URL = `v1/report/dashboard_v2/table/resume`;
const DASHBOARD_TABLE_URL = `v1/report/dashboard_v2/table`;
const CHART_URL = `v1/report/income`;
const CHART_PRODUCTION_URL = `v1/report/dashboard_production`;
const CHART_INCOME_URL = `v1/report/dashboard_income`;
const DOWNLOAD_URL = `v1/report/dashboard_v2/table/export/excel`;

const lang = localStorage.getItem("joumpa_language");

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_PENDING));
  API.get(DASHBOARD_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getChartProduction = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_CHART_PENDING));
  API.get(CHART_PRODUCTION_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_CHART_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_CHART_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getChartIncome = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_CHART_PENDING));
  API.get(CHART_INCOME_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_CHART_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_CHART_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getReservationStatus = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_STATUS_PENDING));
  API.get(DASHBOARD_STATUS_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_STATUS_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_STATUS_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getSummaryTable = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_PENDING));
  API.get(DASHBOARD_SUMMARY_TABLE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        resolve({
          data: res.data.data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getDashboardTable = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_DASHBOARD_TABLE_PENDING));
  API.get(DASHBOARD_TABLE_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DASHBOARD_TABLE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DASHBOARD_TABLE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const downloadReport = (param, fileName, resolve) => (dispatch) => {
  dispatch(actionPending(DOWNLOAD_REPORT_PENDING));
  API.get(DOWNLOAD_URL, {
    params: param,
    responseType: "blob",
  })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DOWNLOAD_REPORT_SUCCESS, res));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (resolve) {
        resolve({
          data: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(DOWNLOAD_REPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        let message =
          lang === "id"
            ? err?.response?.data?.message
            : err?.response?.data?.message_eng;
        if (message?.includes("java")) {
          message = lang === "id" ? `Data Tidak Ditemukan` : "Data Not Found";
        }
        toastError(message);
      }
    });
};

const dashboard = {
  get,
  getChartProduction,
  getChartIncome,
  getReservationStatus,
  getSummaryTable,
  getDashboardTable,
  downloadReport,
};
export default dashboard;
