import {
  GET_ANALYTICS_PENDING,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_ERROR,
  GET_PARETO_PENDING,
  GET_PARETO_SUCCESS,
  GET_PARETO_ERROR,
  GET_PARETO_TOTAL_PENDING,
  GET_PARETO_TOTAL_SUCCESS,
  GET_PARETO_TOTAL_ERROR,
} from "../../actions/data_analytics";
  
const initialState = {
  pending: false,
  error: null,
  data: null,
};
  
export const data_analytics = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: action.data,
        pending: false,
      };
    case GET_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};

const initialStatePareto = {
  pending: false,
  error: null,
  data: null,
};
  
export const data_pareto = (state = initialStatePareto, action) => {
  switch (action.type) {
    case GET_PARETO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PARETO_SUCCESS:
      return {
        ...state,
        data: action.data,
        pending: false,
      };
    case GET_PARETO_ERROR:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case GET_PARETO_TOTAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_PARETO_TOTAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        pending: false,
      };
    case GET_PARETO_TOTAL_ERROR:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    default:
      return state;
  }
};
  