import React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/commons/table/detailButton";
import Loader from "../../../components/commons/Loader";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import Table from "../../../components/commons/table";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import LabelPills from "../../../components/commons/table/labelPills";
import TableStatus from "../../../components/commons/table/status";
import ModalFilter from "./modal/modalFilter";
import { history } from "../../../utils/History";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

// Service
import CorpPriceService from "../../../store/actions/product/corporate_price";
import { RoleGroups } from "../../../utils/Constants";

const CorpPrice = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [deleteModal, setDeleteModal] = useState(false);
  const [dataCorpPrice, setdataCorpPrice] = useState([]);
  const [dataCorpPriceRow, setDataCorpPriceRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    expired_status: "",
    start_date: "",
    end_date: "",
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        search_text: searchText,
        expired_status: paramFilter?.expired_status,
        start_date: paramFilter?.start_date,
        end_date: paramFilter?.end_date,
      };
      dispatch(CorpPriceService.getActive(param, resolve));
    }).then((res) => {
      setdataCorpPrice(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  const handleDownload = () => {
    new Promise((resolve) => {
      let param = {
        search_text: searchText,
        expired_status: paramFilter?.expired_status,
        start_date: paramFilter?.start_date,
        end_date: paramFilter?.end_date,
      };
      setLoadingDownload(true);
      dispatch(CorpPriceService.downloadCorporate(param, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataCorpPriceRow(data ? data : []);
  };

  const handleDelete = (id) => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (dataCorpPriceRow) {
      const id = dataCorpPriceRow?.id;
      dispatch(CorpPriceService.del(id, callback));
    }
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      expired_status: param?.expired_status,
      start_date: param?.start_date,
      end_date: param?.end_date,
    })
    setShowModalFilter(false);
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: t("field.corporate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let name = original?.name;
        // name = name?.length > 40 ? name.substring(0, 40) + "..." : name;
        return <>{name}</>;
      },
    },
    {
      Header: t("field.pullBranch").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let responsibleAirport = 
          `${
            original?.responsible_airport_detail?.code
          } - ${
            original?.responsible_airport_detail?.city
          } - ${
            original?.responsible_airport_detail?.name
          }`;
        return original?.responsible_airport ? responsibleAirport : '-';
      },
    },
    {
      Header: t("field.cooperationType").toUpperCase(),
      accessor: "details",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="row">
            {original?.type_list?.map((item, i) => (
              <LabelPills data={item?.corporate_type} />
            ))}
          </div>
        );
      },
    },
    {
      Header: t("field.expiredDate").toUpperCase(),
      accessor: "end_date",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.end_date ? moment(original?.end_date).format("DD/MM/YYYY") : "-";
      },
    },
    {
      Header: `${t("field.expiredStatus").toUpperCase()}`,
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableStatus
            status={
              original?.expired_status === "Expired"
                ? t("field.expired")
                : original?.expired_status === "Active"
                  ? t("promo.active")
                  : original?.expired_status === "Upcoming"
                    ? t("status.notStarted")
                    : t("field.expiredSoon")
            }
            color={
              original?.expired_status === "Expired"
                ? "danger"
                : original?.expired_status === "Active"
                ? "primary"
                : original?.expired_status === "Upcoming"
                ? "info"
                : "warning"
            }
          />
        );
      },
      minWidth: 110,
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem
              onClick={() => history.push(`/product/set-corporate-price/detail/${original?.id}`)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {!RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code) && (
              <>
                <MenuItem 
                  onClick={() => history.push(`/product/set-corporate-price/edit/${original?.id}`)}>
                  <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
                </MenuItem>
                <MenuItem onClick={() => showDeleteModal(original)}>
                  <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
                </MenuItem>
              </>
            )}
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.corporation")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={() => setShowModalFilter(true)}
        />
        {RoleGroups.superuserRoles.includes(currentUser?.user?.role_code) && (
          <Button
            title={(
              <>
                {loadingDownload && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Download
              </>
            )}
            leftIcon={!loadingDownload ? "download" : null}
            rounded={true}
            variant="button-light btn-lg"
            onClick={handleDownload}
          />
        )}
        {!RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code) && (
          <>
            <div className="nav-separator mr-3 ml-3" />
            <Button
              title={t("commons.set") + " " + t("field.price")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={() => history.push("product/set-corporate-price")}
              // onClick={showAddModal}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataCorpPrice ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        message={t("commons.areYouSureDelete")}
        messageData={dataCorpPriceRow?.corporate_name}
        icon="info"
        isDelete
      />

      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        toggleFilter={(props) => {
          setParamFilter({
            expired_status: props.expired_status,
            start_date: props.start_date,
            end_date: props.end_date,
          });
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  );
};

const mapStateToProps = ({ corp_price: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(CorpPrice)
);
