import Card from "../../../../components/commons/cards/card";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import { withTrans } from "../../../../i18n/withTrans"

const Shifts = ({ t, formik, add, toggle }) => {

  const handleDetailShift = (index) => {
    if (toggle) {
      toggle(formik, index)
    }
  }

  return (
    <>
      <Card>
        {
          formik.values.shifts.length < 1 && (
            <CardFlush
              list
              label={t('commons.no_data')}
              size={{ label: 12 }}
            />
          )
        }
        {
          formik.values.shifts?.map((item, index) => {
            return (
              <CardFlush
                list
                label={item?.name}
                button={
                  <div
                    className="d-flex justify-content-end"
                    onClick={() => handleDetailShift(index)}
                  >
                    <span className="material-icons-round right-icon">
                      navigate_next
                    </span>
                  </div>
                }
                key={index}
                size={{ label: 7, desc: 5 }}
              />
            )
          })
        }
        <div 
          style={{ 
            color: '#63ae5c', 
            padding: '10px 15px', 
            display: 'flex', 
            cursor: 'pointer' 
          }} 
          onClick={() => add(formik)}
        >
          <div 
            style={{ 
              borderRadius: '50%', 
              border: 'solid 1px #63ae5c', 
              width: 30, 
              height: 30, 
              marginRight: 20, 
              textAlign: 'center' 
            }}
          >
            <span className="material-icons-round">add</span>
          </div>
          <p 
            className="text-bold" 
            style={{ 
              fontSize: 14, 
              margin: 0, 
              alignSelf: 'center' 
            }}
          >
              {t("commons.add") + " Shift"}
          </p>
        </div>
      </Card>
    </>
  )
}

export default withTrans(Shifts)