import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import Table from "../../../components/commons/table";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import DetailButton from "../../../components/commons/table/detailButton";
import Loader from "../../../components/commons/Loader";
import SelectFilter from "../../../components/forms/SelectFilter";
import LabelPills from "../../../components/commons/table/labelPills";
import MasterDataModal from "./MasterDataModal";
import DetailModal from "./DetailModal";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import { CoorperationTypeList, RoleGroups } from "../../../utils/Constants";
import { withTrans } from "../../../i18n/withTrans";
import { getUser } from "../../../utils/User";

// ACTION REDUX
import CorporateService from "../../../store/actions/master_data/corporate";

function Index({ t, pending, pending_post, pending_put }) {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [dataCorporate, setDataCorporate] = useState([]);
  const [dataCorporateRow, setDataCorporateRow] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleEditShowModal = () => {
    showEditModal();
    showDetailModal();
  };

  const showEditModal = (data) => {
    setEditModal(!editModal);
    setDataCorporateRow(data ? data : []);
  };

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    setDataCorporateRow(data ? data : []);
  };

  const showAddModal = () => {
    setAddModal(!addModal);
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataCorporateRow(data ? data : []);
  };

  const handleEdit = (param) => {
    const callback = () => {
      handleRefresh();
      setEditModal(!editModal);
    };

    if (param) {
      dispatch(CorporateService.put(param, callback));
    }
  };

  const handleAdd = (param) => {
    const callback = () => {
      handleRefresh();
      setAddModal(!addModal);
    };

    dispatch(CorporateService.post(param, callback));
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };

    if (dataCorporateRow) {
      const id = dataCorporateRow?.id;
      dispatch(CorporateService.deleted(id, callback, dataCorporateRow?.name));
    }
  };

  const columns = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "ID",
      accessor: "unique_id",
    },
    {
      Header: t("field.corporation").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        let name = original.name;
        name = name.length > 40 ? name.substring(0, 40) + "..." : name;
        return <>{name}</>;
      },
    },
    {
      Header: t("field.cooperationType").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <div className="row">
            {original?.type_list?.map((item, i) => (
              <LabelPills data={item?.corporate_type} />
            ))}
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {!RoleGroups.contactCenterRoles.includes(currentUser?.user.role_code) && (
              <>
                <MenuItem onClick={() => showEditModal(original)}>
                  <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
                </MenuItem>
                <MenuItem onClick={() => showDeleteModal(original)}>
                  <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
                </MenuItem>
              </>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        type: filter,
      };
      dispatch(CorporateService.get(param, resolve));
    }).then((res) => {
      setDataCorporate(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  const handleFilter = (value) => {
    if (value) {
      setFilter(value?.value);
    }
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, filter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, filter]);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.corporation")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
          style={{ marginRight: 20 }}
        />
        <SelectFilter
          options={[
            { value: "", label: t("field.allType") },
            ...CoorperationTypeList,
          ]}
          placeholder={t("field.cooperationType")}
          value={filter}
          onChange={(value) => handleFilter(value)}
        />
        {!RoleGroups.contactCenterRoles.includes(currentUser?.user.role_code) && (
          <>
            <div className="nav-separator mr-3" />
            <Button
              title={t("commons.add") + " " + t("field.corporation")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={showAddModal}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataCorporate ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>

      <MasterDataModal
        show={addModal}
        toggle={showAddModal}
        action={handleAdd}
        pending={pending_post}
      />

      <MasterDataModal
        show={editModal}
        toggle={showEditModal}
        action={handleEdit}
        data={dataCorporateRow}
        // pending={pending_put}
      />

      <DetailModal
        show={detailModal}
        toggle={showDetailModal}
        confirm={handleEditShowModal}
        data={dataCorporateRow}
        pending={pending}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        data={dataCorporateRow}
        icon="info"
        param={
          dataCorporateRow?.name?.length > 40
            ? dataCorporateRow?.name?.substring(0, 40) + "..."
            : dataCorporateRow?.name
        }
        isDelete
      />
    </>
  );
}

const mapStateToProps = ({
  corporate: { pending, pending_post, pending_put },
}) => {
  return { pending, pending_post, pending_put };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
