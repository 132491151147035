import { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Container, Col } from "reactstrap";
import moment from "moment";

import Button from "../../../components/commons/buttons/Button";
import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import SideMenu from "../../../components/commons/menu/SideMenu";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import Checkout from "./Checkout";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import { getUser } from "../../../utils/User";
import { history } from "../../../utils/History";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { getLengthPassAdult, getLengthPassChild, getLengthPassInfant, calculateTimeDifference, minDateBooking, moreThanInfantAge } from "../../../utils/Helper";

import Booking from "../../../store/actions/booking";
import { titlePassenger } from "../../../utils/Constants";

const Index = ({ match, pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const currentUrl = match?.url?.split("/")?.[1];
  const roleName = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const minDate = minDateBooking(role, currentUrl);
  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };
  const handleCancel = () => {
    history.push("/booking");
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        break;
    }
  };

  const findAvailabilityPrice = (tab) => {
    let values = formikRef.current.values;
    let airport_selected =
      values.flight_type === 0
        ? values.airport_id_to
        : values.flight_type === 1
        ? values.airport_id_from
        : values.airport_id_transfer;
    const adult_total = getLengthPassAdult(values?.passenger_list);
    const child_total = getLengthPassChild(values?.passenger_list);
    const infant_total = getLengthPassInfant(values?.passenger_list);

    new Promise((resolve) => {
      let param = {
        airport_service_id: airport_selected,
        flight_type: values?.flight_type_code,
        service_date: values?.date,
        product_name: values?.product_type,
        adult_total: values?.passenger_list?.length ? adult_total : null,
        child_total: values?.passenger_list?.length ? child_total : null,
        infant_total: values?.passenger_list?.length ? infant_total : null,
      };
      if (
        airport_selected &&
        values?.flight_type_code &&
        values?.date &&
        values?.product_type
      ) {
        dispatch(Booking.findAvailPrice(param, resolve));
      }
    }).then((res) => {
      if (!res) return;

      if (res?.data) {
        const product_list = res?.data?.period_list[0]?.product_list[0];

        formikRef.current.setFieldValue(
          "period_id",
          res?.data?.period_list[0]?.id
        );
        formikRef.current.setFieldValue(
          "product_list", 
          product_list
        );
        formikRef.current.setFieldValue(
          "product_list_id", 
          product_list?.id
        );

        if (product_list?.group_prices?.length) {
          formikRef.current.setFieldValue(
            "group_price_id",
            product_list?.group_prices[0]?.id
          );
          formikRef.current.setFieldValue("price_id", null);
        } else {
          formikRef.current.setFieldValue(
            "price_id",
            product_list?.prices?.id
          );
          formikRef.current.setFieldValue("group_price_id", null);
        }
      }
        
      handleChangeForm(tab);
    });
  };

  const handleNextClick = async (tab) => {
    const values = formikRef?.current?.values;
    
    const showEmptyError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    const checkEmpty = (field, fieldName, customMsg) => {
      if (values[field] === undefined || values[field] === null || values[field] === '') {
        showEmptyError(`${customMsg ?? t("field." + fieldName)}!`);
        return true;
      }
      return false;
    };

    const checkPassengerValidity = (passenger) => {
      if (!passenger.name || !passenger.passenger_nationality || !passenger.title) {
        showEmptyError(`${t("field.passenger")}!`);
        return true;
      }
      if (passenger.title !== "Infant" && passenger.title !== "Child") {
        if (!passenger.email || !passenger.phoneNumber) {
          showEmptyError(`${t("field.passenger")}!`);
          return true;
        }
      }
      if (passenger.title === "Infant" && !passenger.passenger_date_of_birth){
        toastError(`${t("commons.complete_birth_date_message")}!`);
        return true;
      }
      if (
        passenger.title === "Infant" &&
        passenger.passenger_date_of_birth &&
        moreThanInfantAge(passenger?.passenger_date_of_birth)
      ){
        toastError(`${t("commons.birth_date_less_than_message")}!`);
        return true;
      }
      if (passenger.title && !titlePassenger.includes(passenger.title)) {
        toastError(`${t("commons.invalidTitlePassenger")}!`);
        return true;
      }

      return false;
    };

    const checkFlightTabValidity = () => {
      if (role === "admin_pusat" && values?.isGoShow) {
        toastError(`${roleName} ${t("booking.notAllowedGoShow")}!`);
        return false;
      }

      if (
        checkEmpty('flight_type', 'flightType') ||
        checkEmpty('airport_id_from', 'origin') ||
        checkEmpty('airport_id_to', 'destination') ||
        checkEmpty('airplane_name', 'airline') ||
        checkEmpty('airplane_number', 'flightNumber') ||
        checkEmpty('date', 'date') ||
        checkEmpty('time', null, `${t("commons.time")}!`)
      ) {
        return false;
      }

      const minDateOrder = values.is_cross_selling == 1 ? moment().add(1, "days").format("YYYY-MM-DD") : minDate;
      if (values.date < minDateOrder) {
        toastError(`${t("commons.orderDateHandle")} ${moment(minDateOrder).format("DD/MM/YYYY")}!`);
        return false;
      }

      if (currentUrl === 'booking-contact-center' && values.time !== null) {
        const minTimeOrder = calculateTimeDifference(minDate, moment().format("HH:mm"));
        const timeOrder = calculateTimeDifference(values.date, values.time);

        if (minDate === values.date && minTimeOrder >= timeOrder) {
          toastError(`${values.flight_type !== 1 ? t("field.arrivalTime") : t("field.departureTime")} ${t("commons.orderTimeHandle")}`);
          return false;
        }
      }

      if (values.flight_type === 2) {
        const timeArrival = calculateTimeDifference(values.date, values.time);
        const timeDeparture = calculateTimeDifference(values.date_transfer_to, values.time_transfer_to);

        if (timeArrival > timeDeparture) {
          toastError(t("commons.transfer_message"));
          return false;
        }

        if (
          checkEmpty('airport_id_transfer', 'transfer') ||
          checkEmpty('airplane_name_transfer_to', 'airline') ||
          checkEmpty('airplane_number_transfer_to', 'flightNumber') ||
          checkEmpty('date_transfer_to', 'departureDate') ||
          checkEmpty('time_transfer_to', 'departureTime')
        ) return false;

        if (values?.date_transfer_to < minDate) {
          toastError(`${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`);
          return false;
        }
      }

      if (values.product_type < 1) {
        showEmptyError(`${t("field.product")}!`);
        return false;
      }

      return true;
    }

    const checkPassengerTabValidity = () => {
      if (!values?.paging_name && !values?.paging_logo) {
        showEmptyError(`${t("field.pagingInfo")}!`);
        return false;
      }

      if (!values?.passenger_list.length) {
        showEmptyError(`${t("field.passenger")}!`);
        return false;
      }

      if (
        checkEmpty('bookers_title', 'bookersInfo') ||
        checkEmpty('bookers_name', 'bookersInfo') ||
        checkEmpty('bookers_nationality', 'bookersInfo') ||
        checkEmpty('bookers_email', 'bookersInfo')
      ) {
        return false;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.bookers_email)) {
        toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
        return false;
      }

      if (
        values?.bookers !== "admin" &&
        (values?.bookers_phone === "" ||
          values?.bookers_phone?.includes("undefined") ||
          values?.bookers_phone === values?.bookers_country_code)
      ) {
        showEmptyError(`${t("field.bookersInfo")}!`);
        return false;
      }

      let passengerNotValid = false;
      values?.passenger_list?.forEach((passenger) => {
        if (checkPassengerValidity(passenger)) {
          passengerNotValid = true;
        }
      });

      if (passengerNotValid) return false;

      return true;
    }

    const checkAdtServiceTabValidity = () => {
      if (values?.lounge) {
        if (values?.lounge_adult_number > getLengthPassAdult(values?.passenger_list)) {
          toastError(`${t("booking.handleAdultLounge")}!`);
          return false;
        }
        if (values?.lounge_child_number > getLengthPassChild(values?.passenger_list)) {
          toastError(`${t("booking.handleChildLounge")}!`);
          return false;
        }
        if (values?.lounge_infant_number > getLengthPassInfant(values?.passenger_list)) {
          toastError(`${t("booking.handleInfantLounge")}!`);
          return false;
        }
      } else {
        ['lounge_adult_number', 'lounge_child_number', 'lounge_infant_number'].forEach(field => {
          formikRef?.current?.setFieldValue(field, 0);
        });
      }

      if (values?.region) {
        if (!values?.car_type || !values?.total_unit) {
          showEmptyError(`${t("field.transportation")}!`);
          return false;
        }
      } else {
        formikRef?.current?.setFieldValue("total_unit", 0);
      }

      if (
        !values?.is_cross_selling &&
        (values?.additional_service_description ||
          values?.additional_service_concession_percentage ||
          values?.additional_service_ppn_percentage ||
          values?.additional_service_price)
      ) {
        if (
          !values?.additional_service_description ||
          !values?.additional_service_concession_percentage ||
          !values?.additional_service_ppn_percentage ||
          !values?.additional_service_price > 0
        ) {
          toastError(
            `${t("commons.empty_data_message")} ${t(
              "field.additionalService"
            )} ${t("masterData.others")}!`
          );
          return false;
        }
      }

      return true;
    }

    if (tab === "passenger-reservation") {
      if (checkFlightTabValidity()) {
        findAvailabilityPrice(tab);
      }
    } else if (tab === "adt-service-reservation") {
      if (checkFlightTabValidity() && checkPassengerTabValidity()) {
        findAvailabilityPrice(tab);
      }
    } else if (tab === "checkout") {
      if (!(checkFlightTabValidity() && checkPassengerTabValidity() && checkAdtServiceTabValidity())) {
        return;
      }
      
      formikRef.current.setFieldValue("promo_id", null);
      formikRef.current.setFieldValue("promo_id_cross_selling", null);
      if (show === "adt-service-reservation") {
        handleChangeForm(tab);
      } else {
        findAvailabilityPrice(tab);
      }
    }
  };

  const handleChangeTab = (tab, number) => {
    if (number > sequence) {
      handleNextClick(tab);
    } else {
      handleChangeForm(tab);
    }
  };

  const initialValues = {
    id: "",
    car_type: "",
    airplane_name: "",
    airplane_name_transfer_to: "",
    airplane_number: "",
    airplane_number_transfer_to: "",
    airport_id_from: "",
    airport_id_to: "",
    airport_id_transfer: "",
    is_cross_selling: null,
    available_service: "",
    corporate_id:
      roleName === "Corporate" ? currentUser?.user?.corporate_id : "",
    customer_id: "",
    assistant_id: null,
    assistant_list: null,
    date: "",
    date_transfer_to: "",
    payment_type: "",
    file: null,
    dataPrice: 0,
    dataPriceMOB: 0,
    dataPriceFIT: 0,
    dataPriceMOBIndividual: 0,
    dataPriceGroup: [],
    flight_type: null,
    isOverMOB: false,
    lounge: "",
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    product_id: null,
    product_list_id: null,
    product_type: 0,
    promo_id: null,
    promo_id_cross_selling: null,
    time: "",
    time_transfer_to: "",
    transportation: "",
    region: "",
    type: null,
    groupPrice: 0,
    grandTotalPrice: 0,
    loungePrice: 0,
    transportPrice: 0,
    isPriceNotSet: true,
    midtrans_token_id: null,
    isGoShow: false,
    passenger_as_booker: null,
    bookers_title: "",
    bookers_name: "",
    bookers_nationality: "",
    bookers_email: "",
    bookers_phone: "",
    selectedAirport: {},
    selectedAirportTransfer: {},
    selectedAirportDomestic: {},
    selectedAirline: {},
    selectedAirlineTransfer: {},
    imagePagingInfo: {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
    additional_service_description: "",
    additional_service_concession_percentage: null,
    additional_service_ppn_percentage: null,
    additional_service_price: null,
    additional_service_dpp: null,
    additional_service_is_include_concession: null,
    additional_service_is_include_ppn: null,
  };

  const handleSubmit = (values) => {
    let isNotValid = false;
    let formData = new FormData();

    values.type = values.flight_type;
    values.customer_id = null;

    const showError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    if (
      values.date <= moment().format("YYYY-MM-DD") &&
      values?.is_cross_selling != 1 &&
      currentUrl !== 'booking-contact-center' &&
      !values?.assistant_list
    ) {
      showError(`${t("field.assistant")}!`);
      isNotValid = true;
      return;
    }

    if (values?.payment_type !== "" && !values?.file) {
      showError(`${t("booking.paymentProof")}!`);
      isNotValid = true;
      return;
    }

    if (values?.payment_type === "" && values?.file) {
      showError(`${t("booking.paymentType")}!`);
      isNotValid = true;
      return;
    }

    if (values?.is_cross_selling == 1 && !values?.promo_id_cross_selling) {
      toastError(`${t("commons.validationCrossSelling")}!`);
      isNotValid = true;
      return;
    }

    const appendFormData = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    };

    appendFormData("additional_service_description", values.additional_service_description);
    appendFormData("additional_service_concession_percentage", parseFloat(values.additional_service_concession_percentage));
    appendFormData("additional_service_is_include_concession", values.additional_service_is_include_concession == 1 ? true : false);
    appendFormData("additional_service_is_include_ppn", values.additional_service_is_include_ppn == 1 ? true : false);
    appendFormData("additional_service_ppn_percentage", parseFloat(values.additional_service_ppn_percentage));
    appendFormData("additional_service_price", values.additional_service_price);
    appendFormData("additional_service_dpp", values.additional_service_dpp);
    appendFormData("airplane_name", values.airplane_name);
    appendFormData("airplane_name_transfer_to", values.airplane_name_transfer_to);
    appendFormData("airplane_number", values.airplane_number);
    appendFormData("airplane_number_transfer_to", values.airplane_number_transfer_to);
    appendFormData("airport_id_from", values.airport_id_from);
    appendFormData("airport_id_to", values.airport_id_to);
    appendFormData("airport_id_transfer", values.airport_id_transfer);

    if (values.assistant_list) {
      values.assistant_list.forEach((key, x) => {
        appendFormData(`assistant_list[${x}].assistant_id`, key.assistant_id);
        appendFormData(`assistant_list[${x}].is_main`, key.is_main);
      });
    }

    appendFormData("bookers_title", values.bookers_title);
    appendFormData("bookers_name", values.bookers_name);
    appendFormData("bookers_nationality", values.bookers_nationality);
    appendFormData("bookers_email", values.bookers_email);

    if (values.bookers_phone && !values.bookers_phone.includes("undefined") && values.bookers_phone !== values.bookers_country_code) {
      const bookerPhone = values.bookers_phone.replace(values.bookers_country_code, "").charAt(0) == 0 ? values.bookers_phone.substring(1) : values.bookers_phone;
      appendFormData('bookers_phone', bookerPhone);
      appendFormData(`bookers_country_code`, `+${values.bookers_country_code}`);
      appendFormData(`bookers_country_id`, values.bookers_country_id);
    }

    appendFormData("customer_id", values.customer_id);
    appendFormData("date", values.date);
    appendFormData("date_transfer_to", values.date_transfer_to);
    appendFormData("file", values.file);
    appendFormData("is_cross_selling", values.is_cross_selling == 1 ? true : false);
    appendFormData("airport_lounge_id", values.lounge);
    appendFormData("lounge_adult_number", values.lounge_adult_number || 0);
    appendFormData("lounge_child_number", values.lounge_child_number || 0);
    appendFormData("lounge_infant_number", values.lounge_infant_number || 0);
    appendFormData("midtrans_token_id", values.midtrans_token_id);
    appendFormData("paging_name", values.paging_name);
    appendFormData("paging_logo", values.paging_logo);

    values.passenger_list.forEach((key, x) => {
      appendFormData(`passenger_list[${x}].email`, key.email);
      appendFormData(`passenger_list[${x}].name`, key.name);

      if (key.phoneNumber && !key.phoneNumber.includes("undefined") && key.phoneNumber !== key.countryCode) {
        const phone = key.phoneNumber.replace(key.countryCode, "").charAt(0) == 0 ? key.phoneNumber.substring(1) : key.phoneNumber;
        appendFormData(`passenger_list[${x}].phoneNumber`, phone);
        appendFormData(`passenger_list[${x}].countryCode`, `+${key.countryCode}`);
        appendFormData(`passenger_list[${x}].countryId`, key.countryId);
      }

      appendFormData(`passenger_list[${x}].title`, key.title);
      appendFormData(`passenger_list[${x}].passenger_nationality`, key.passenger_nationality);
      appendFormData(`passenger_list[${x}].passenger_date_of_birth`, key.passenger_date_of_birth);
    });

    appendFormData("passenger_notes", values.passenger_notes);
    appendFormData("payment_type", values.payment_type);
    appendFormData("period_id", values.period_id);
    appendFormData("price_id", values.price_id);
    appendFormData("group_price_id", values.group_price_id);
    appendFormData("product_list_id", values.product_list_id);
    appendFormData("product_name", values.product_type);
    appendFormData("promo_id", values.promo_id);
    appendFormData("promo_id_cross_selling", values.promo_id_cross_selling);
    appendFormData("region", values.region);
    appendFormData("show_joumpa_logo", values.show_joumpa_logo == 1 ? true : false);
    appendFormData("type", values.flight_type + 1);
    appendFormData("time", values.time);
    appendFormData("time_transfer_to", values.time_transfer_to);
    appendFormData("transport_number", values.total_unit);
    appendFormData("transport_price_id", values.orderExtras.car?.id);

    if (isNotValid) {
      return;
    }

    new Promise((resolve) => {
      setLoadingSubmit(true);
      if (currentUrl !== 'booking-contact-center') {
        const callback = () => {
          history.push("/booking");
        };
        dispatch(Booking.post(formData, resolve, callback));
      } else {
        dispatch(Booking.postContactCenter(formData, resolve));
      }
    }).then((res) => {
      if (currentUrl === 'booking-contact-center' && res) {
        window.open(`${res?.data?.redirect_payment_url}`, `_blank`);
        history.push("/booking-contact-center");
      };
      setLoadingSubmit(false);
    });
  };

  useEffect(() => {
    if (error_message) {
      setLoadingSubmit(false);
    }
  }, [error_message]);

  const button = () => {
    return (
      <div style={{ float: "right" }}>
        {next ? (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm={"Button"}
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        ) : (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.save")}
            typeConfirm={"Submit"}
            toggle={back ? () => handleChangeForm(back) : ""}
            disabledConfirm={loadingSubmit}
          />
        )}
      </div>
    );
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="treatment-title menu-title text-extra-bold">
            <div className="treatment-title-icon ">
              {t("field.newReservation")}
            </div>
          </div>
          <SideMenu
            title={t("field.flight")}
            toggle={() => handleChangeTab("flight-reservation")}
            checked={sequence > 1}
            checkbox
          />
          <SideMenu
            title={t("field.passenger")}
            toggle={() => handleChangeTab("passenger-reservation", 2)}
            checked={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.additionalService")}
            toggle={() => handleChangeTab("adt-service-reservation", 3)}
            checked={sequence > 3}
            checkbox
          />
          <SideMenu
            title="Check Out"
            toggle={() => handleChangeTab("checkout", 4)}
            checked={sequence > 4}
            checkbox
          />
        </div>
        <div className="side-menu-detail">
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (next === null) {
                  handleSubmit(values);
                }
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <FlightReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <PassengerReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <AdtServiceReservation formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu
                        children={
                          <>
                            <Button
                              variant="secondary"
                              rounded
                              leftIcon="close"
                              title={t("commons.cancel")}
                              onClick={() => showCancelModal()}
                            />
                          </>
                        }
                      />
                      <Checkout formik={props} button={button} currentUrl={currentUrl} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              isDelete
              isCancel={t("commons.back")}
              isConfirm={t("commons.yesCancel")}
            >
              <Col md={8}>
                <div className="normal-title">
                  {t("commons.areYouSureCancel")} <strong>Booking</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ booking: { error_message } }) => {
  return { error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
