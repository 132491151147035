import moment from "moment";

export const toRupiah = (value) => {
    var rupiah = "";
    var valuerev = value.toString().split("").reverse().join("");
    for (var i = 0; i < valuerev.length; i++)
        if (i % 3 == 0) rupiah += valuerev.substr(i, 3) + ".";
    return (
        "Rp. " +
        rupiah
            .split("", rupiah.length - 1)
            .reverse()
            .join("")
    );
};

export const calculateTimeDifference = (date, time) => {
    const currentDateTime = moment();
    const selectedDateTime = moment(`${date}, ${time}`);
    return selectedDateTime.diff(currentDateTime.startOf('day'), 'minutes');
};

export const moreThanInfantAge = (birthDate) => {
  const currentDate = moment();
  const twoYearsOfBirth = moment(birthDate).add(2, "years").format("YYYY-MM-DD");
  const diffBirthDateFromNow = moment(birthDate).diff(currentDate, 'days');
  const diffTwoYearsFromBirth = moment(birthDate).diff(twoYearsOfBirth, 'days');

  return diffBirthDateFromNow < diffTwoYearsFromBirth
};

export const minDateBooking = (role, urlBooking) => {
  return urlBooking === "booking-contact-center"
    ? moment().format("YYYY-MM-DD")
    : role === "admin_pusat"
    ? moment().add(1, "days").format("YYYY-MM-DD")
    : role === "contact_center" || role === "lead_contact_center"
    ? null
    : moment().subtract(1, "days").format("YYYY-MM-DD");
};

export const getLengthPassAdult = (data) => {
  return data?.filter((item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms.")?.length
};

export const getLengthPassChild = (data) => {
  return data?.filter((item) => item?.title === "Child")?.length
};

export const getLengthPassInfant = (data) => {
  return data?.filter((item) => item?.title === "Infant")?.length
};

export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
};

export const getContactInfoBooking = (dataBooking) => {
  if (!dataBooking) return;

  const passengerEmail = dataBooking?.list_order_passengers[0]?.email;
  const passengerPhoneNumber = dataBooking?.list_order_passengers[0]?.phone_number;
  const passengerCountryCode = dataBooking?.list_order_passengers[0]?.country_code;
  const customerEmail = dataBooking?.customer_email;
  const customerHandphone = dataBooking?.customer_handphone;
  const customerCountryCode = dataBooking?.customer_country_code;

  let contactInfo;

  switch (true) {
    case customerHandphone === null && customerEmail === null:
      contactInfo = `${passengerEmail} / ${
        passengerCountryCode
          ? `${passengerCountryCode}${passengerPhoneNumber}`
          : passengerPhoneNumber
      }`;
      break;
    case customerHandphone !== null && customerEmail !== null:
      contactInfo = `${customerEmail} / ${
        customerCountryCode
          ? `${customerCountryCode}${customerHandphone}`
          : customerHandphone
      }`;
      break;
    case customerEmail === null:
      contactInfo = `${
        customerCountryCode
          ? `${customerCountryCode}${customerHandphone}`
          : customerHandphone
      }`;
      break;
    default:
      contactInfo = customerEmail;
  }

  return contactInfo
}

export const getRouteTypeByFlightType = (flightType, airportUniformFrom, airportUniformTo) => {
  switch (flightType) {
    case 0:
      return airportUniformFrom !== "Indonesia" ? "international" : "domestic";
    case 1:
      return airportUniformTo !== "Indonesia" ? "international" : "domestic";
    case 2:
      return airportUniformFrom !== "Indonesia" || airportUniformTo !== "Indonesia" ? "international" : "domestic";
    default:
      return null;
  }
};

export const getTransferFlightTypeCode = (airportUniformFrom, airportUniformTo) => {
  if (airportUniformFrom !== "Indonesia" && airportUniformTo !== "Indonesia") {
    return 1;
  } else if (airportUniformFrom !== "Indonesia" && airportUniformTo === "Indonesia") {
    return 2;
  } else if (airportUniformFrom === "Indonesia" && airportUniformTo !== "Indonesia") {
    return 3;
  } else if (airportUniformFrom === "Indonesia" && airportUniformTo === "Indonesia") {
    return 4;
  }
};

  