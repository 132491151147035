import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Container, Col } from "reactstrap";
import moment from "moment";

import SideMenu from "../../../components/commons/menu/SideMenu";
import Loader from "../../../components/commons/Loader";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import Button from "../../../components/commons/buttons/Button";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import FlightReservation from "./FlightReservation";
import PassengerReservation from "./PassengerReservation";
import AdtServiceReservation from "./AdtServiceReservation";
import Checkout from "./Checkout";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import OrderConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import { toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { calculateTimeDifference, minDateBooking, getLengthPassAdult, getLengthPassChild, getLengthPassInfant, moreThanInfantAge } from "../../../utils/Helper";
import { titlePassenger } from "../../../utils/Constants";

//Service
import Corp_Reservation from "../../../store/actions/corp_reservation";

const Index = ({ pending, t, error_message }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();
  const roleName = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const minDate = minDateBooking(role);
  const [show, setShow] = useState("flight-reservation");
  const [next, setNext] = useState("passenger-reservation");
  const [back, setBack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sequence, setSequence] = useState(1);
  const [corporateType, setCorporateType] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [dataFormik, setDataFormik] = useState({});
  const [errorAvailabilityPrice, setErrorAvailabilityPrice] = useState(null);

  const showCancelModal = () => {
    setCancelModal(!cancelModal);
  };

  const handleCancel = () => {
    history.push("/corp_reservation");
  };

  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleChangeForm = (tab) => {
    document.documentElement.scrollTop = 0;
    switch (tab) {
      case "flight-reservation":
        setShow("flight-reservation");
        setBack(null);
        setNext("passenger-reservation");
        setSequence(1);
        break;
      case "passenger-reservation":
        setShow("passenger-reservation");
        setBack("flight-reservation");
        setNext("adt-service-reservation");
        setSequence(2);
        break;
      case "adt-service-reservation":
        setShow("adt-service-reservation");
        setBack("passenger-reservation");
        setNext("checkout");
        setSequence(3);
        formikRef.current.setFieldTouched("transportation", false);
        formikRef.current.setFieldTouched("car_type", false);
        break;
      case "checkout":
        setShow("checkout");
        setBack("adt-service-reservation");
        setNext(null);
        setSequence(4);
        break;
    }
  };

  const findAvailabilityPrice = (tab) => {
    let values = formikRef?.current?.values;
    let airport_selected =
      values.flight_type === 0
        ?  values.airport_id_to
        :  values.flight_type === 1
        ?  values.airport_id_from
        :  values.airport_id_transfer;

    new Promise((resolve) => {
      let param = {
        corporate_id: values?.corporate_id,
        airport_service_id: airport_selected,
        flight_type: values?.flight_type_code,
        service_date:  values?.date,
        product_name:  values?.product_type,
        is_mob: values?.corporateType === "MOB" ? true : false,
      };
      if (
        values?.corporate_id &&
        airport_selected &&
        values?.flight_type_code &&
        values?.date &&
        values?.product_type
      ) {
        dispatch(Corp_Reservation.findAvailPrice(param, resolve));
      }
    }).then((res) => {
      if (res) {
        handleChangeForm(tab);
      }
    })
  };

  const handleNextClick = (tab) => {
    let values = formikRef.current.values;

    const showEmptyError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    const checkEmpty = (field, fieldName, customMsg) => {
      if (values[field] === undefined || values[field] === null || values[field] === '') {
        showEmptyError(`${customMsg ?? t("field." + fieldName)}!`);
        return true;
      }
      return false;
    };

    const checkFlightTabValidity = () => {
      if (
        checkEmpty('corporate_id', 'corporate') ||
        checkEmpty('flight_type', 'flightType') ||
        checkEmpty('airport_id_from', 'origin') ||
        checkEmpty('airport_id_to', 'destination') ||
        checkEmpty('airplane_name', 'airline') ||
        checkEmpty('airplane_number', 'flightNumber') ||
        checkEmpty('date', 'date') ||
        checkEmpty('time', null, `${t("commons.time")}!`)
      ) {
        return false;
      }

      if (values?.date < minDate) {
        toastError(`${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`);
        return false;
      }

      if (values.flight_type === 2) {
        const timeArrival = calculateTimeDifference(values.date, values.time);
        const timeDeparture = calculateTimeDifference(values.date_transfer_to, values.time_transfer_to);

        if (timeArrival > timeDeparture) {
          toastError(t("commons.transfer_message"));
          return false;
        }

        if (
          checkEmpty('airport_id_transfer', 'transfer') ||
          checkEmpty('airplane_name_transfer_to', 'airline') ||
          checkEmpty('airplane_number_transfer_to', 'flightNumber') ||
          checkEmpty('date_transfer_to', 'departureDate') ||
          checkEmpty('time_transfer_to', 'departureTime')
        ) return false;

        if (values?.date_transfer_to < minDate) {
          toastError(`${t("commons.orderDateHandle")} ${moment(minDate).format("DD/MM/YYYY")}!`);
          return false;
        }
      }

      if (values.product_type < 1) {
        showEmptyError(`${t("field.product")}!`);
        return false;
      }

      return true;
    };

    const checkPassengerValidity = (passenger) => {
      if (!passenger.name || !passenger.passenger_nationality || !passenger.title) {
        showEmptyError(`${t("field.passenger")}!`);
        return true;
      }
      if (passenger.title !== "Infant" && passenger.title !== "Child") {
        if (!passenger.email || !passenger.phoneNumber) {
          showEmptyError(`${t("field.passenger")}!`);
          return true;
        }
      }
      if (passenger.title === "Infant" && !passenger.passenger_date_of_birth){
        toastError(`${t("commons.complete_birth_date_message")}!`);
        return true;
      }
      if (
        passenger.title === "Infant" &&
        passenger.passenger_date_of_birth &&
        moreThanInfantAge(passenger.passenger_date_of_birth)
      ) {
        toastError(`${t("commons.birth_date_less_than_message")}!`);
        return true;
      }
      if (passenger.title && !titlePassenger.includes(passenger.title)) {
        toastError(`${t("commons.invalidTitlePassenger")}!`);
        return true;
      }

      return false;
    };

    const checkPassengerTabValidity = () => {
      if (values.corporateType === "Group" && !values.corporate_type_group_id) {
        showEmptyError(`${t("field.cooperationType")}!`);
        return false;
      }

      if (!values?.paging_name && !values?.paging_logo) {
        showEmptyError(`${t("field.pagingInfo")}!`);
        return false;
      }

      if (!values?.passenger_list.length > 0) {
        showEmptyError(`${t("field.passenger")}!`);
        return false;
      }

      if (
        checkEmpty('bookers_title', 'bookersInfo') ||
        checkEmpty('bookers_name', 'bookersInfo') ||
        checkEmpty('bookers_nationality', 'bookersInfo') ||
        checkEmpty('bookers_email', 'bookersInfo')
      ) {
        return false;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.bookers_email)) {
        toastError(`${t("commons.wrong_email_format")} ${t("field.booker")}!`);
        return false;
      }

      if (
        values?.bookers !== "admin" &&
        (values?.bookers_phone === "" ||
          values?.bookers_phone?.includes("undefined") ||
          values?.bookers_phone === values?.bookers_country_code)
      ) {
        showEmptyError(`${t("field.bookersInfo")}!`);
        return false;
      }

      let passengerNotValid = false;
      values?.passenger_list?.forEach((passenger) => {
        if (checkPassengerValidity(passenger)) {
          passengerNotValid = true;
        }
      });

      if (passengerNotValid) return false;

      return true;
    };

    const checkAdtServiceTabValidity = () => {
      if (values?.lounge) {
        if (values?.lounge_adult_number > getLengthPassAdult(values?.passenger_list)) {
          toastError(`${t("booking.handleAdultLounge")}!`);
          return false;
        }
        if (values?.lounge_child_number > getLengthPassChild(values?.passenger_list)) {
          toastError(`${t("booking.handleChildLounge")}!`);
          return false;
        }
        if (values?.lounge_infant_number > getLengthPassInfant(values?.passenger_list)) {
          toastError(`${t("booking.handleInfantLounge")}!`);
          return false;
        }
      } else {
        ['lounge_adult_number', 'lounge_child_number', 'lounge_infant_number'].forEach(field => {
          formikRef?.current?.setFieldValue(field, 0);
        });
      }

      if (values?.region) {
        if (!values?.car_type || !values?.total_unit) {
          showEmptyError(`${t("field.transportation")}!`);
          return false;
        }
      } else {
        formikRef?.current?.setFieldValue("total_unit", 0);
      }

      if ((
        values?.additional_service_description ||
        values?.additional_service_concession_percentage ||
        values?.additional_service_ppn_percentage ||
        values?.additional_service_price
      ) && !(
        values?.additional_service_description &&
        values?.additional_service_concession_percentage &&
        values?.additional_service_ppn_percentage &&
        values?.additional_service_price > 0
      )) {
        toastError(
          `${t("commons.empty_data_message")} ${t(
            "field.additionalService"
          )} ${t("masterData.others")}!`
        );
        return false;
      }

      return true;
    }

    if (tab === "passenger-reservation") {
      if (checkFlightTabValidity()) {
        findAvailabilityPrice(tab);
      }
    } else if (tab === "adt-service-reservation") {
      if (errorAvailabilityPrice !== null) {
        toastError(errorAvailabilityPrice);
        return;
      } 

      if (checkFlightTabValidity() && checkPassengerTabValidity()) {
        handleChangeForm(tab);
      }
    } else if (tab === "checkout") {
      if (checkFlightTabValidity() && checkPassengerTabValidity() && checkAdtServiceTabValidity()) {
        formikRef.current.setFieldValue("promo_id", null);
        handleChangeForm(tab);
      }
    }
  };

  const handleChangeTab = (tab, number) => {
    if (number > sequence) {
      handleNextClick(tab);
    } else {
      handleChangeForm(tab);
    }
  };

  const initialValues = {
    airport_list: [],
    airport_list_indo: [],
    airplane_name: "",
    airplane_number: "",
    airplane_name_transfer_to: "",
    airplane_number_transfer_to: "",
    airport_id_from: "",
    airport_id_to: "",
    airport_id_transfer: null,
    assistant_id: null,
    available_service: "",
    corporate_id:
      roleName === "Corporate" ? currentUser?.user?.corporate_id : "",
    corporate_type_id: "",
    customer_id: "",
    car_type: "",
    date: "",
    date_transfer_to: "",
    dataPrice: 0,
    dataPriceMOB: 0,
    dataPriceMOBIndividual: 0,
    dataPriceFIT: 0,
    dataPriceGroup: [],
    flight_type: -1,
    isOverMOB: false,
    lounge: "",
    lounge_adult_number: 0,
    lounge_child_number: 0,
    lounge_infant_number: 0,
    total_lounge_adult: 0,
    total_lounge_child: 0,
    total_lounge_infant: 0,
    total_lounges: 0,
    list_details: [],
    orderExtras: {
      lounge: { name: "", price: "", id: "" },
      transport: { name: "", price: "", id: "" },
      car: { name: "", price: "", id: "" },
    },
    order_extra_list: [],
    passenger_list: [],
    passenger_notes: "",
    price_list: "",
    product_id: "",
    product_type: 0,
    promo_id: null,
    time: "",
    time_transfer_to: "",
    transportation: "",
    region: "",
    type: "",
    groupPrice: 0,
    grandTotalPrice: 0,
    // loungePrice: 0,
    transportPrice: 0,
    selectedAirportArrival: {},
    selectedAirportDeparture: {},
    selectedAirline: {},
    selectedAirlineTransfer: {},
    imagePagingInfo: {
      title: t("field.uploadPagingInfo"),
      subtitle: "File",
    },
    additional_service_description: "",
    additional_service_concession_percentage: null,
    additional_service_ppn_percentage: null,
    additional_service_price: null,
    additional_service_dpp: null,
    additional_service_is_include_concession: null,
    additional_service_is_include_ppn: null
  };

  const button = (param = null, data = null) => {
    if (data) {
      setDataFormik(data);
    }
    if (param) setCorporateType(param);
    return (
      <div style={{ float: "right" }}>
        {next && (
          <ButtonModal
            cancelTitle={back ? t("commons.back") : t("commons.cancel")}
            confirmTitle={t("commons.next")}
            typeConfirm={"Button"}
            toggle={
              back ? () => handleChangeForm(back) : () => showCancelModal()
            }
            confirm={next ? () => handleNextClick(next) : null}
          />
        )}
        {next === null && (
          formikRef?.current?.values?.corporateType !== "MOB" && (
            formikRef?.current?.values?.total_service_price === 0 ||
            (formikRef?.current?.values?.lounge &&
              formikRef?.current?.values?.total_lounge_price === 0) ||
            (formikRef?.current?.values?.car_type &&
              formikRef?.current?.values?.total_transport_price === 0)
          ) ? (
            <ButtonModal
              cancelTitle={back ? t("commons.back") : t("commons.cancel")}
              confirmTitle={t("commons.save")}
              typeConfirm={"Button"}
              toggle={back ? () => handleChangeForm(back) : ""}
              confirm={() => showConfirmModal()}
            />
          ) : (
            <ButtonModal
              cancelTitle={back ? t("commons.back") : t("commons.cancel")}
              confirmTitle={t("commons.save")}
              typeConfirm={"Submit"}
              toggle={back ? () => handleChangeForm(back) : ""}
              disabledConfirm={loading}
            />
          )
        )}
      </div>
    );
  };

  const handleOrder = () => {
    let isNotValid = false;
    let formData = new FormData();

    const showEmptyError = (message) => {
      toastError(`${t("commons.empty_data_message")} ${message}`);
    };

    if (dataFormik?.passenger_list?.length < 1) {
      isNotValid = true;
      showEmptyError(`${t("field.passenger")}!`);
      return;
    }

    if (
      moment(dataFormik?.date).format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD") &&
      !dataFormik.assistant_list
    ) {
      isNotValid = true;
      showEmptyError(`${t("field.assistant")}!`);
      return;
    }

    const appendFormData = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    };
    
    appendFormData("additional_service_description", dataFormik?.additional_service_description);
    appendFormData("additional_service_concession_percentage", parseFloat(dataFormik?.additional_service_concession_percentage));
    appendFormData("additional_service_is_include_concession", dataFormik?.additional_service_is_include_concession == 1 ? true : false);
    appendFormData("additional_service_is_include_ppn", dataFormik?.additional_service_is_include_ppn == 1 ? true : false);
    appendFormData("additional_service_price", dataFormik?.additional_service_price);
    appendFormData("additional_service_ppn_percentage", parseFloat(dataFormik?.additional_service_ppn_percentage));
    appendFormData("additional_service_dpp", dataFormik?.additional_service_dpp);
    appendFormData("airplane_name", dataFormik?.airplane_name);
    appendFormData("airplane_name_transfer_to", dataFormik?.airplane_name_transfer_to);
    appendFormData("airplane_number", dataFormik?.airplane_number);
    appendFormData("airplane_number_transfer_to", dataFormik?.airplane_number_transfer_to);
    appendFormData("airport_id_from", dataFormik?.airport_id_from);
    appendFormData("airport_id_to", dataFormik?.airport_id_to);
    appendFormData("airport_id_transfer", dataFormik?.airport_id_transfer);
    appendFormData("bookers_title", dataFormik?.bookers_title);
    appendFormData("bookers_name", dataFormik?.bookers_name);
    appendFormData("bookers_nationality", dataFormik?.bookers_nationality);
    appendFormData("bookers_email", dataFormik?.bookers_email);
    appendFormData("corporate_id", dataFormik?.corporate_id);
    appendFormData("corporate_lounge_id", dataFormik?.corporate_lounge_id);
    appendFormData("corporate_price_id", dataFormik?.corporate_price_id);
    appendFormData("corporate_type_id", dataFormik?.corporate_type_id);
    appendFormData("corporate_type_group_id", dataFormik?.corporate_type_group_id);
    appendFormData("corporate_type_group_property_id", dataFormik?.corporate_type_group_property_id);
    appendFormData("date", moment(dataFormik?.date)?.format("YYYY-MM-DD"));
    appendFormData("date_transfer_to", moment(dataFormik?.date_transfer_to)?.format("YYYY-MM-DD"));
    appendFormData("lounge_id", dataFormik?.lounge_id);
    appendFormData("lounge_adult_number", dataFormik?.lounge_adult_number || 0);
    appendFormData("lounge_child_number", dataFormik?.lounge_child_number || 0);
    appendFormData("lounge_infant_number", dataFormik?.lounge_infant_number || 0);
    appendFormData("paging_name", dataFormik?.paging_name);
    appendFormData("paging_logo", dataFormik?.paging_logo);
    appendFormData("passenger_notes", dataFormik?.passenger_notes);
    appendFormData("product_list_id", dataFormik?.product_list_id);
    appendFormData("promo_id", dataFormik?.promo_id);
    appendFormData("price_period_id", dataFormik?.price_period_id);
    appendFormData("region", dataFormik.region);
    appendFormData("show_joumpa_logo", dataFormik.show_joumpa_logo == 1 ? true : false);
    appendFormData("time", dataFormik?.time);
    appendFormData("time_transfer_to", dataFormik?.time_transfer_to);
    appendFormData("transport_number", dataFormik?.total_unit);
    appendFormData("transport_price_id", dataFormik?.orderExtras?.car?.id);
    appendFormData("type", dataFormik?.flight_type + 1);

    if(dataFormik?.assistant_list) {
      dataFormik.assistant_list.forEach((key, x) => {
        appendFormData(`assistant_list[${[x]}].assistant_id`, key.assistant_id);
        appendFormData(`assistant_list[${[x]}].is_main`, key.is_main);
      })
    };

    if (dataFormik?.bookers_phone && !dataFormik?.bookers_phone.includes("undefined") && dataFormik?.bookers_phone !== dataFormik?.bookers_country_code) {
      const bookerPhone = dataFormik.bookers_phone.replace(dataFormik.bookers_country_code, "").charAt(0) == 0 ? dataFormik.bookers_phone.substring(1) : dataFormik.bookers_phone;
      appendFormData('bookers_phone', bookerPhone);
      appendFormData(`bookers_country_code`, `+${dataFormik?.bookers_country_code}`);
      appendFormData(`bookers_country_id`, dataFormik?.bookers_country_id);
    }

    dataFormik?.passenger_list.forEach((key, x) => {
      appendFormData(`passenger_list[${[x]}].email`, key.email);
      appendFormData(`passenger_list[${[x]}].name`, key.name);
      appendFormData(`passenger_list[${[x]}].title`, key.title);
      appendFormData(`passenger_list[${[x]}].passenger_nationality`, key.passenger_nationality);
      appendFormData(`passenger_list[${[x]}].passenger_date_of_birth`, key.passenger_date_of_birth);
      
      if (key.phoneNumber && !key?.phoneNumber.includes("undefined") && key?.phoneNumber !== key?.countryCode) {
        const phone = key.phoneNumber.replace(key.countryCode, "").charAt(0) == 0 ? key.phoneNumber.substring(1) : key.phoneNumber;
        appendFormData(`passenger_list[${[x]}].phoneNumber`, phone);
        appendFormData(`passenger_list[${[x]}].countryCode`, `+${key.countryCode}`);
        appendFormData(`passenger_list[${[x]}].countryId`, key.countryId);
      }
    });

    if (isNotValid) {
      return;
    }

    new Promise((resolve) => {
      setLoading(true);
      const callback = () => {
        history.push("/corp_reservation");
      };
      dispatch(Corp_Reservation.post(formData, resolve, callback));
    }).then((res) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (error_message) {
      setLoading(false);
    }
  }, [error_message]);

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu-no-border">
          <div className="treatment-title menu-title text-extra-bold">
            <div className="treatment-title-icon ">
              {t("field.newReservation")}
            </div>
          </div>
          <SideMenu
            title={t("field.flight")}
            toggle={() => handleChangeTab("flight-reservation")}
            checked={sequence > 1}
            checkbox
          />
          <SideMenu
            title={t("field.passenger")}
            toggle={() => handleChangeTab("passenger-reservation", 2)}
            checked={sequence > 2}
            checkbox
          />
          <SideMenu
            title={t("field.additionalService")}
            toggle={() => handleChangeTab("adt-service-reservation", 3)}
            checked={sequence > 3}
            checkbox
          />
          <SideMenu
            title="Check Out"
            toggle={() => handleChangeTab("checkout", 4)}
            checked={sequence > 4}
            checkbox
          />
        </div>
        <div className="side-menu-detail">
          <Container>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (next === null) {
                  handleOrder();
                }
              }}
            >
              {(props) => (
                <Form>
                  {show === "flight-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <Button
                            variant="secondary"
                            rounded
                            leftIcon="close"
                            title={t("commons.cancel")}
                            onClick={() => showCancelModal()}
                          />
                        }
                      />
                      <FlightReservation formik={props} button={button}/>
                    </>
                  )}
                  {show === "passenger-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <Button
                            variant="secondary"
                            rounded
                            leftIcon="close"
                            title="Batal"
                            onClick={() => showCancelModal()}
                          />
                        }
                      />
                      <PassengerReservation
                        formik={props}
                        idCorporate={corporateType}
                        setErrorAvailabilityPrice={(value) => setErrorAvailabilityPrice(value)}
                        button={button}
                      />
                    </>
                  )}
                  {show === "adt-service-reservation" && (
                    <>
                      <HeaderMenu
                        children={
                          <Button
                            variant="secondary"
                            rounded
                            leftIcon="close"
                            title="Batal"
                            onClick={() => showCancelModal()}
                          />
                        }
                      />
                      <AdtServiceReservation formik={props} button={button} />
                    </>
                  )}
                  {show === "checkout" && (
                    <>
                      <HeaderMenu
                        children={
                          <Button
                            variant="secondary"
                            rounded
                            leftIcon="close"
                            title="Batal"
                            onClick={() => showCancelModal()}
                          />
                        }
                      />
                      <Checkout formik={props} tab={sequence} button={button} />
                    </>
                  )}
                </Form>
              )}
            </Formik>
            <ConfirmationModal
              show={cancelModal}
              toggle={showCancelModal}
              confirm={handleCancel}
              icon="info"
              isDelete
              isCancel={t("commons.back")}
              isConfirm={t("commons.yesCancel")}
            >
              <Col md={8}>
                <div className="text-normal">
                  {t("commons.areYouSureCancel")}{" "}
                  {t("commons.add").toLowerCase()}
                  <strong> {t("field.reservation")}</strong> ?
                </div>
              </Col>
            </ConfirmationModal>
            <OrderConfirmationModal
              show={confirmModal}
              toggle={showConfirmModal}
              confirm={handleOrder}
              icon="info"
              message={t("commons.confirmOrder")}
              disabledConfirm={loading}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ corp_reserv: { pending, data, error_message }, corporate }) => {
  return { pending, data, error_message,  corporate };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
