import {
    GET_AUDIT_TRAIL_PENDING,
    GET_AUDIT_TRAIL_SUCCESS,
    GET_AUDIT_TRAIL_ERROR
} from "../../actions/audit_trail";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
};

const audit_trail = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUDIT_TRAIL_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_AUDIT_TRAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_AUDIT_TRAIL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default audit_trail;
