import { Container } from "reactstrap";
import { getUser } from "../../utils/User";
import Layout from "../../components/layout"
import NotFound from "../../components/empty_state/404";

const Index = () => {
    const currentUser = getUser()

    return (
        <>
            {
                currentUser ? (
                    <Layout>
                        <Container style={{ marginTop: 90 }}>
                            <NotFound></NotFound>
                        </Container>
                    </Layout>
                ) : (
                    <Container style={{ marginTop: 90 }}>
                        <NotFound></NotFound>
                    </Container>
                )
            }

        </>
    );
};

export default Index;