import corp_reservations from "../../../views/corp_reservation";
import {
  GET_CORP_RESERVATION_PENDING,
  GET_CORP_RESERVATION_SUCCESS,
  GET_CORP_RESERVATION_ERROR,
  POST_CORP_RESERVATION_PENDING,
  POST_CORP_RESERVATION_SUCCESS,
  POST_CORP_RESERVATION_ERROR,
  CALCULATE_CORP_RESERVATION_PENDING,
  CALCULATE_CORP_RESERVATION_SUCCESS,
  CALCULATE_CORP_RESERVATION_ERROR,
  PUT_CORP_RESERVATION_PENDING,
  PUT_CORP_RESERVATION_SUCCESS,
  PUT_CORP_RESERVATION_ERROR,
  DELETE_CORP_RESERVATION_PENDING,
  DELETE_CORP_RESERVATION_SUCCESS,
  DELETE_CORP_RESERVATION_ERROR,
  FIND_AVAIL_PRICE_PENDING,
  FIND_AVAIL_PRICE_SUCCESS,
  FIND_AVAIL_PRICE_ERROR,
  ADD_EXTRA_ORDER_PENDING,
  ADD_EXTRA_ORDER_SUCCESS,
  ADD_EXTRA_ORDER_ERROR,
} from "../../actions/corp_reservation";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
  error_delete: null,
};

const corp_reservation = (state = initialState, action) => {
  switch (action.type) {
    case GET_CORP_RESERVATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_CORP_RESERVATION_SUCCESS:
      return {
        ...state,
        data_list: action.data,
        pending: false,
      };
    case GET_CORP_RESERVATION_ERROR:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case POST_CORP_RESERVATION_PENDING:
      return {
        ...state,
        pending_post: true,
        error_message: null,
      };
    case POST_CORP_RESERVATION_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
        error_message: null,
      };
    case POST_CORP_RESERVATION_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
        error_message: action?.error?.data?.message,
      };
    case CALCULATE_CORP_RESERVATION_PENDING:
      return {
        ...state,
        pending_CALCULATE: true,
      };
    case CALCULATE_CORP_RESERVATION_SUCCESS:
      return {
        ...state,
        pending_CALCULATE: false,
        data_CALCULATE: action.data,
      };
    case CALCULATE_CORP_RESERVATION_ERROR:
      return {
        ...state,
        pending_CALCULATE: false,
        error: action.error,
      };
    case PUT_CORP_RESERVATION_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_CORP_RESERVATION_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_CORP_RESERVATION_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DELETE_CORP_RESERVATION_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_CORP_RESERVATION_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_CORP_RESERVATION_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
        error_delete: action.error,
      };
    case FIND_AVAIL_PRICE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FIND_AVAIL_PRICE_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
        error_message_eng: null,
        error_message: null,
      };
    case FIND_AVAIL_PRICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        error_message_eng: action?.error?.data?.message_eng,
        error_message: action?.error?.data?.message,
      };
    case ADD_EXTRA_ORDER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ADD_EXTRA_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case ADD_EXTRA_ORDER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default corp_reservation;
