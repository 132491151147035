import React from "react";
import { getUser } from "../../utils/User";
import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";

import DetailModal from "./DetailModal";
import FormModal from "./FormModal";
import ModalFilter from "./modalFilter";
import Table from "../../components/commons/table";
import Button from "../../components/commons/buttons/Button";
import Loader from "../../components/commons/Loader";
import TableStatus from "../../components/commons/table/status";
import DetailButton from "../../components/commons/table/detailButton";
import SearchButton from "../../components/commons/buttons/SearchButton";
import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../components/commons/modal/ConfirmModal";

// Service
import RewardService from "../../store/actions/reward";
import AirportService from "../../store/actions/master_data/airport";
import { RoleGroups } from "../../utils/Constants";

const Reward = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [searchText, setSearchText] = useState("");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [dataAirport, setDataAirport] = useState([]);
  const [dataReward, setDataReward] = useState([]);
  const [selectedDataReward, setSelectedDataReward] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [paramFilter, setParamFilter] = useState({
    status: "",
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  }, []);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      end_date: param.end_date ? moment(param.end_date).format("YYYY-MM-DD") : "",
      start_date: param.start_date ? moment(param.start_date).format("YYYY-MM-DD") : "",
      status: param.status,
    });
    setShowModalFilter(false);
  };

  const showFormModal = (action, data) => {
    setFormModal(!formModal);
    if (action !== 'add' && data) setSelectedDataReward(data);
  };

  const handleActionForm = (param) => {
    const callback = () => {
      handleRefresh();
      setFormModal(!formModal);
    };
    if (selectedDataReward) {
      dispatch(RewardService.put(param, callback));
    } else {
      dispatch(RewardService.post(param, callback));
    }
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    console.log(data)
    if (data) setSelectedDataReward(data);
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (selectedDataReward) {
      const id = selectedDataReward?.id;
      dispatch(RewardService.del(id, callback));
    }
  };

  const showDetailModal = (data) => {
    setDetailModal(!detailModal);
    if (data) setSelectedDataReward(data);
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        search_text: searchText,
        valid: paramFilter?.status !== "" ? paramFilter?.status : "",
        start_date:
          paramFilter?.start_date !== ""
            ? moment(paramFilter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          paramFilter?.end_date !== ""
            ? moment(paramFilter?.end_date).format("YYYY-MM-DD")
            : "",
      };
      dispatch(RewardService.get(param, resolve));
    }).then((res) => {
      setDataReward(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText, paramFilter]);

  const columns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "VOUCHER",
      accessor: "name",
    },
    {
      Header: t("promo.minimumPoint").toUpperCase(),
      accessor: "point",
    },
    {
      Header: t("promo.startDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_start).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: t("promo.endDate").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const date = moment(original?.period_end).format("DD/MM/YYYY");
        return `${date}`;
      },
    },
    {
      Header: "STATUS",
      Cell: ({ row }) => {
        const { original } = row;
        let { valid } = original;
        return (
          <TableStatus
            status={valid ? t("promo.active") : t("promo.nonactive")}
            color={valid ? "primary" : "warning"}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => showDetailModal(original)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {!RoleGroups.centerSPVRoles.includes(currentUser?.user?.role_code) && (
              <>
              <MenuItem onClick={() => showFormModal('edit', original)}>
                <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
              </MenuItem>
              <MenuItem onClick={() => showDeleteModal(original)}>
                <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
              </MenuItem>
              </>
            )}
          </DetailButton>
        );
      },
    },
  ];

  return (
    <>
      {pending && <Loader fullScreen loading={pending} />}
      <HeaderMenu title={t("promo.rewardManagement")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg ml-3"
          onClick={handleFilterModal}
        />
        <div className="nav-separator mr-3 ml-3" />

        {!RoleGroups.centerSPVRoles.includes(currentUser?.user?.role_code) && (
          <Button
            title={`${t("commons.add")} Reward`}
            leftIcon="add"
            rounded={true}
            variant="primary"
            onClick={() => showFormModal('add')}
          />
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataReward ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      {console.log(selectedDataReward)}
      <FormModal
        show={formModal}
        toggle={() => {
          setSelectedDataReward(null);
          showFormModal();
        }}
        data={selectedDataReward}
        action={handleActionForm}
        refresh={handleRefresh}
        dataAirport={dataAirport}
      />
      <DetailModal
        show={detailModal}
        toggle={showDetailModal}
        id={selectedDataReward?.id}
      />
      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        messageData={`` + selectedDataReward?.name}
        icon="info"
        message={t("commons.areYouSureDelete")}
        isDelete
      />
      <ModalFilter
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        onApplyFilter={onApplyFilter}
        toggleFilter={(props) => {
          setParamFilter({
            status: props.status,
            start_date: props.start_date,
            end_date: props.end_date,
          });
        }}
      />
    </>
  )
}

const mapStateToProps = ({ reward: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Reward));