import Select from "react-select";

const SelectFilter = ({ value, onChange, placeholder, clearable, searchable, options, disabled }) => {

    const customSelect = {
        control: (base, state) => ({
            ...base,
            cursor: "pointer",
            textAlign: 'left',
            fontWeight: '700',
            background: "#ffff",
            borderRadius: "32px",
            padding: "1px 1px 1px 10px",
            height: "48px",
            minWidth: "200px",
            border: "2px solid #E5E5F0",
            borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "#E5E5F0" : "#E5E5F0"
            }
        }),
        indicatorsContainer: (base) => ({
            ...base,
            marginRight: "7px",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: "#fff",
        }),
        option: (options, state) => ({
            ...options,
            cursor: "pointer",
            textAlign: "left",
            paddingLeft: "15px"
        })
    }


    return (
        <>
            <Select
                isClearable={clearable}
                isSearchable={searchable}
                options={options ?? []}
                styles={customSelect}
                placeholder={placeholder ?? "Pilih opsi"}
                className="mx-2"
                value={
                    options?.filter((obj) => {
                      return obj?.value === value;
                    })[0] ?? { label: placeholder, key: "" }
                }
                onChange={onChange}
                isDisabled={disabled}
            />
        </>
    )
}

export default SelectFilter;