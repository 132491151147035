import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";

import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import CardSelectionProduct from "../../../components/cardSelectionProduct";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicButton from "../../../components/forms/DynamicButton";
import { getUser } from "../../../utils/User";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { RoleName, RoleWithAirport, product_list } from "../../../utils/Constants";
import { minDateBooking, calculateTimeDifference, getTransferFlightTypeCode } from "../../../utils/Helper";

//SERVICE
import CorpService from "../../../store/actions/product/corporate_price";
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";

const FlightReservation = ({ formik, t, button }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const position = currentUser?.user?.role_name;
  const role = currentUser?.user?.role_code;
  const corporateId = currentUser?.user?.corporate_id;
  const airportId = currentUser?.user?.airport_id;
  const minDate = minDateBooking(role);

  const [dataAirportArrival, setDataAirportArrival] = useState([]);
  const [dataAirportDeparture, setDataAirportDeparture] = useState([]);
  const [dataAdminBandara, setDataAdminBandara] = useState([]);
  const [dataCorp, setDataCorp] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [corporateType, setCorporateType] = useState("");
  const [searchAirportArrival, setSearchAirportArrival] = useState([]);
  const [searchAirportDeparture, setSearchAirportDeparture] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);
  const [haveAirport] = useState(
    RoleWithAirport?.includes(currentUser?.user.role_code)
      ? true
      : false
  );

  const debouncedAirports = debounce((value, type) => {
    if (value === "") return;
    if (type === "arrival") setSearchAirportArrival(value);
    if (type === "departure") setSearchAirportDeparture(value);
  }, 500);

  useEffect(() => {
    // get airport data arrival
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportArrival,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        setDataAirportArrival(res.data);
      }
    });
  }, [searchAirportArrival]);

  useEffect(() => {
    // get airport data departure
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDeparture,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        setDataAirportDeparture(res.data);
      }
    });
  }, [searchAirportDeparture]);

  useEffect(() => {
    // get airport service
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        id: airportId,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      let list = [];
      if (res && res.data) {
        res.data?.map(
          (airport) =>
            (list = [
              ...list,
              {
                ...airport,
                value: airport?.id,
                label: `${airport?.code}-${airport?.city}-${airport?.name}`,
              },
            ])
        );
      }
      if (airportId) {
        setDataAdminBandara(list);
      } else {
        formik.setFieldValue("airport_list_indo", list);
      }
    });
  }, [formik?.values.flight_type]);

  const mapAirportData = (data, selectedAirport, list) => {
    const airports = [...list];
    data.forEach((airport) => {
      airports.push({
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name} ${
          airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
        }`,
      });
    });
  
    //if there is no selectedAirport data in the list airports,
    //push selectedAirport so airport search results can still appear in the dropdown
    const index = airports.findIndex((x) => x?.value === selectedAirport?.value);
    if (index === -1 && selectedAirport) {
      airports.push(selectedAirport);
    }
  
    return airports?.filter((item) => Object.keys(item).length);
  };

  //Assign Array Data Airport Arrival
  let airportsArrival = mapAirportData(
    dataAirportArrival,
    formik?.values?.selectedAirportArrival,
    []
  );

  //Assign Array Data Airport Departure
  let airportsDeparture = mapAirportData(
    dataAirportDeparture,
    formik?.values?.selectedAirportDeparture,
    []
  );

  const mapAirlineData = (data, selectedAirline, list) => {
    const airlines = [...list];
    data.forEach((airline) => {
      airlines.push({
        value: airline?.name,
        code: airline?.code,
        label: `${airline?.code} - ${airline?.name}`,
      });
    });

    //if there is no selectedAirline data in the list airlines,
    //push selectedAirline so airline search results can still appear in the dropdown
    const index = airlines.findIndex((x) => x?.value === selectedAirline?.value);
    if (index === -1 && selectedAirline) {
      airlines.push(selectedAirline);
    }
  
    return airlines?.filter((item) => Object.keys(item).length);
  };

  useEffect(() => {
    //get airline data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        const airlineList = mapAirlineData(
          res.data,
          formik?.values?.selectedAirline,
          []
        );
        setDataAirline(airlineList);
      }
    });
  }, [searchTextAirline]);

  useEffect(() => {
    //get airline transfer
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      if (res.data) {
        const airlineTransfer = mapAirlineData(
          res.data,
          formik?.values?.selectedAirlineTransfer,
          []
        );
        setDataAirlineTransfer(airlineTransfer);
      }
    });
  }, [searchTextAirlineTransfer]);

  useEffect(() => {
    // get product data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      formik.setFieldValue("data_product_list", res?.data);
    });

    // get corporate data
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
      };
      dispatch(CorpService.getActive(param, resolve));
    }).then((res) => {
      if (res?.data) {
        let data = res?.data;
        let list = data.map((corp) => ({
          ...corp,
          value: corp?.id,
          label: `${corp?.name}`,
        }));
        setDataCorp(list);
      }
    });
  }, []);

  useEffect(() => {
    if (dataCorp.length > 0 && position === RoleName.corporate) {
      formik.setFieldValue("corporate_id", corporateId);
      setCorporateType(corporateId);
    }
  }, [dataCorp]);

  useEffect(() => {
    new Promise((resolve) => {
      if (formik.values?.corporate_id) {
        let param = {
          id: formik?.values?.corporate_id
        };
        dispatch(CorpService.getActive(param, resolve));
      }
    }).then((res) => {
      if (res) {
        const typeMOB = res?.data[0]?.type_list?.filter((item) => item?.corporate_type === "MOB");
        if (typeMOB?.length) {
          formik.setFieldValue("isTypeMOBExist", true);
        } else {
          formik.setFieldValue("isTypeMOBExist", false);
        }
      }
    });
  }, [formik.values?.corporate_id]);


  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";

      if (formik?.values.flight_type !== 2 && formik?.values?.data_product_list?.length >= 1) {
        if (isInternational) {
          formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 1));
        } else {
          formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 2));
        }
      } else {
        formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 4));
      }
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type <= 0) {
      return;
    }

    const serviceInAvailableServices = 
      formik?.values?.list_available_services?.find(
        (item) => item?.type === formik?.values?.product_type
      );
    
    if (!serviceInAvailableServices) {
      formik.setFieldValue("product_type", 0);
    }
  }, [formik?.values?.list_available_services]);

  useEffect(() => {
    const { flight_type, airport_uniform_from, airport_uniform_to } = formik?.values;
    const flight_transfer_code = getTransferFlightTypeCode(airport_uniform_from, airport_uniform_to);
    const flight_type_code = flight_type === 0 ? 5 : flight_type === 1 ? 6 : flight_transfer_code;

    formik.setFieldValue("flight_type_code", flight_type_code);
  }, [
    formik?.values?.flight_type,
    formik?.values?.airport_uniform_from,
    formik?.values?.airport_uniform_to,
  ]);

  useEffect(() => {
    let currentDate = moment().format("YYYY-MM-DD");
    const currentTime = moment().format('HH:mm');
    const selectedDate = formik?.values?.date || currentDate;
    const selectedTime = formik?.values?.time || currentTime;

    const timeDifference = calculateTimeDifference(selectedDate, selectedTime);

    formik.setFieldValue('isGoShow', timeDifference >= 0 && currentDate === selectedDate);
  }, [
    formik?.values?.date,
    formik?.values?.time,
  ]);

  const clearFormFields = () => {
    formik.setFieldValue("product_type", 0);
    formik.setFieldValue("airplane_code", null);
    formik.setFieldValue("airplane_code_transfer", null);
    formik.setFieldValue("airplane_name", null);
    formik.setFieldValue("airplane_number", null);
    formik.setFieldValue("date", null);
    formik.setFieldValue("time", null);
    setSearchAirportArrival("");
    setSearchAirportDeparture("");
    setSearchTextAirline("");
    setSearchTextAirlineTransfer("");
  };

  const setAirportFields = (type, airportData) => {
    const airport = airportData[0];
    const airportFields = {
      id: `airport_id_${type}`,
      name: `airport_name_${type}`,
      uniform: `airport_uniform_${type}`,
    };
    
    if (haveAirport) {
      formik.setFieldValue(airportFields.id, airport?.id);
      formik.setFieldValue(airportFields.name, `${airport?.code} - ${airport?.city} - ${airport?.name}`);
      formik.setFieldValue(airportFields.uniform, airport?.uniform);
    } else {
      formik.setFieldValue(airportFields.id, null);
      formik.setFieldValue(airportFields.name, null);
      formik.setFieldValue(airportFields.uniform, null);
    }
  };

  const handleFlightType = (value) => {
    clearFormFields();

    switch (value) {
      case "arrival":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 0);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        setAirportFields("to", dataAdminBandara);
        break;
      case "departure":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 1);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        setAirportFields("from", dataAdminBandara);
        break;
      case "transfer":
        formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 4));
        formik.setFieldValue("product_name", "Transfer");
        formik.setFieldValue("flight_type", 2);
        formik.setFieldValue("airport_id_transfer", null);
        formik.setFieldValue("airport_uniform_transfer_to", null);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        setAirportFields("transfer", dataAdminBandara);
        break;
    }
  };

  const handleProductSelected = (item) => {
    let selectedType = item?.type;
    let selectedName = item?.name;
    let productType = formik.values.product_type;

    if (selectedType && productType === selectedType) {
      selectedType = null;
      selectedName = null;
    }
    formik.setFieldValue("product_type", selectedType);
    formik.setFieldValue("product_name", selectedName);
  };

  return (
    <Row>
      <Col md={10}>
        <Card rounded>
          <CardHeader title={t("field.corporate")} />
          <CardBody>
            <Select2
              title={t("field.corporate")}
              name="corporate_id"
              options={dataCorp}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.corporate_id}
              onChange={(name, value) => {
                formik.setFieldValue("corporate_id", value);
                setCorporateType(value);
              }}
              disabled={position === RoleName.corporate}
            />
            <br />
            {formik?.values?.isTypeMOBExist && (
              <Checkbox
                name="corporateType"
                checked={formik?.values?.corporateType}
                onChange={(e) => {
                  if (formik?.values?.corporateType === "MOB") {
                    formik?.setFieldValue("corporateType", null);
                    formik.setFieldValue("dataCorporate", "");
                    formik.setFieldValue("corporate_type_id", "");
                  } else {
                    formik.setFieldValue("corporateType", "MOB");
                  }
                }}
                value="MOB"
                label="Select MOB as the cooperation type"
              />
            )}
          </CardBody>
        </Card>
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="d-flex justify-content-left">
              <DynamicButton
                titleLeft={t("field.arrival")}
                iconLeft="flight_land"
                active={formik.values.flight_type === 0}
                toggle={() => handleFlightType("arrival")}
              />
              <DynamicButton
                titleLeft={t("field.departure")}
                iconLeft="flight_takeoff"
                active={formik.values.flight_type === 1}
                toggle={() => handleFlightType("departure")}
              />
              <DynamicButton
                titleLeft="Transfer"
                iconLeft="transfer_within_a_station"
                active={formik.values.flight_type === 2}
                toggle={() => handleFlightType("transfer")}
              />
            </div>
          </CardBody>
        </Card>
        <Card rounded>
          <CardHeader
            title={
              formik?.values?.flight_type === 2 
                ? t("field.arrivalInformation") 
                : t("field.flightInformation")
            }
          />
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="text-bold">{t("field.origin")}</div>
              <Col md={8}>
                <Select2
                  name="airport_id_from"
                  options={
                    formik?.values?.flight_type !== 1
                      ? airportsDeparture
                      : haveAirport
                        ? dataAdminBandara
                        : formik.values?.airport_list_indo
                  }
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airport_id_from ?? ""}
                  onInputChange={(keyword) => {
                    debouncedAirports(keyword, "departure");
                  }}
                  onChange={(name, value) => {
                    const isSameAirportTo = formik.values?.airport_id_to === value;
                    const isSameAirportTransfer =
                      formik.values.flight_type === 2
                        ? formik.values?.airport_id_transfer === value
                        : false;
                    const list_airports = 
                      formik?.values?.flight_type !== 1
                        ? dataAirportDeparture
                        : haveAirport
                          ? dataAdminBandara
                          : formik?.values?.airport_list_indo;
                    const index = list_airports.findIndex(
                      (x) => x.id === value
                    );

                    if (isSameAirportTo || isSameAirportTransfer) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }

                    formik.setFieldValue("airport_id_from", value);
                    formik.setFieldValue(
                      "airport_name_from",
                      `${list_airports[index]?.code} - ${
                        list_airports[index]?.city} - ${
                        list_airports[index]?.name}`
                    );
                    formik.setFieldValue(
                      "airport_uniform_from",
                      list_airports[index]?.uniform
                    );
                    formik.setFieldValue(
                      "selectedAirportDeparture", 
                      {
                        value: value,
                        label:
                          `${list_airports[index]?.code} - ${
                            list_airports[index]?.city} - ${
                            list_airports[index]?.name} ${
                            list_airports[index]?.uniform === "Indonesia"
                              ? ""
                              : `- ${list_airports[index]?.uniform}`
                          }`,
                      }
                    );
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-bold">
                {formik.values?.flight_type !== 2
                  ? t("field.destination")
                  : "Transfer"}
              </div>
              <Col md={8}>
                <Select2
                  name={
                    formik.values?.flight_type !== 2
                      ? "airport_id_to"
                      : "airport_id_transfer"
                  }
                  errors={formik.errors}
                  options={
                    formik.values?.flight_type === 1
                      ? airportsArrival
                      : haveAirport
                        ? dataAdminBandara
                        : formik?.values?.airport_list_indo
                  }
                  touched={formik.touched}
                  value={
                    formik.values?.flight_type !== 2
                      ? formik.values?.airport_id_to
                      : formik.values?.airport_id_transfer
                  }
                  onInputChange={(keyword) => {
                    formik.values?.flight_type !== 2 &&
                      debouncedAirports(keyword, "arrival");
                  }}
                  onChange={(name, value) => {
                    const isSameAirportTo =
                      formik.values?.airport_id_from === value;
                    const isSameAirportFrom =
                      formik.values?.airport_id_from === value;
                    const isNotTransfer = formik.values?.flight_type !== 2;
                    const list_airports =
                      formik.values?.flight_type === 1
                        ? dataAirportArrival
                        : haveAirport
                          ? dataAdminBandara
                          : formik?.values?.airport_list_indo
                    const index = list_airports.findIndex(
                      (x) => x.id === value
                    );

                    if (
                      isNotTransfer
                        ? isSameAirportFrom
                        : isSameAirportFrom || isSameAirportTo
                    ) {
                      toastError(`${t("field.cantSameAirport")}!`);
                      return;
                    }
                    
                    formik.setFieldValue("lounge", "");
                    formik.setFieldValue("transportation", "");
                    formik.setFieldValue("car_type", "");

                    if (isNotTransfer) {
                      formik.setFieldValue("airport_id_to", value);
                      formik.setFieldValue(
                        "airport_name_to",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_to",
                        list_airports[index]?.uniform
                      );
                      formik.setFieldValue(
                        "selectedAirportArrival", 
                        {
                          value: value,
                          label: 
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    } else {
                      formik.setFieldValue("airport_id_transfer", value);
                      formik.setFieldValue(
                        "airport_name_transfer",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_transfer",
                        list_airports[index]?.uniform
                      );
                    }
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
            </div>
          </CardBody>
          <hr />
          <CardBody>
            <div className="row">
              <Col md={6}>
                <Select2
                  title={t("field.airline")}
                  name="airplane_name"
                  options={dataAirline}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values?.airplane_name}
                  onInputChange={(keyword) => {
                    if (keyword !== "") {
                      setSearchTextAirline(keyword);
                    }
                  }}
                  onChange={(name, value) => {
                    formik.setFieldValue("airplane_name", value);
                    let airplaneCode = dataAirline.filter(function (el) {
                      return el.value == value;
                    });
                    formik.setFieldValue("airplane_code", airplaneCode[0]?.code);
                    formik.setFieldValue("selectedAirline", {
                      value: value,
                      code: airplaneCode[0]?.code,
                      label: `${airplaneCode[0]?.code} - ${value}`,
                    });
                  }}
                  onBlur={formik.setFieldTouched}
                />
              </Col>
              <Col md={6}>
                <div className="form-group" style={{ marginBottom: "0" }}>
                  <label className="form-label" style={{ marginBottom: "5px" }}>
                    {t("field.flightNumber")}
                  </label>
                </div>
                <InputPrepend
                  name="airplane_number"
                  label={t("field.flightNumber")}
                  title={formik.values.airplane_code}
                  type="text"
                  width="25%"
                  mt="2"
                  touched={formik.touched}
                  {...formik.getFieldProps("airplane_number")}
                />
              </Col>
              <Col md={6}>
                <Input
                  title={
                    formik.values.flight_type !== 1
                      ? t("field.arrivalDate")
                      : t("field.departureDate")
                  }
                  name="date"
                  min={minDate}
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("date")}
                  onChange={(e) => {
                    let value = e.target.value;
                    formik.setFieldValue("date", value);
                  }}
                  type="date"
                />
              </Col>
              <Col md={6}>
                <Input
                  title={
                    formik.values.flight_type !== 1
                      ? t("field.arrivalTime")
                      : t("field.departureTime")
                  }
                  name="time"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.time}
                  onChange={(e) => {
                    let value = e.target.value;
                    formik.setFieldValue("time", value);
                  }}
                  type="time"
                />
              </Col>
            </div>
          </CardBody>
          {formik?.values?.flight_type === 2 && (
            <React.Fragment>
              <hr />
              <CardHeader title={t("field.departureInformation")} />
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="text-bold">{t("field.destination")}</div>
                  <Col md={8}>
                    <Select2
                      name="airport_id_to"
                      options={airportsArrival}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airport_id_to}
                      onInputChange={(keyword) => {
                        debouncedAirports(keyword, "arrival");
                      }}
                      onChange={(name, value) => {
                        if (
                          formik.values?.airport_id_from === value ||
                          formik.values?.airport_id_transfer === value
                        ) {
                          toastError(`${t("field.cantSameAirport")}!`);
                          return;
                        }

                        const index = dataAirportArrival
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);

                        formik.setFieldValue("airport_id_to", value);
                        formik.setFieldValue(
                          "airport_name_to",
                          `${dataAirportArrival[index]?.code} - ${
                            dataAirportArrival[index]?.city} - ${
                            dataAirportArrival[index]?.name}`
                        );
                        formik.setFieldValue(
                          "airport_uniform_to",
                          dataAirportArrival[index]?.uniform
                        );
                        formik.setFieldValue(
                          "selectedAirportArrival", 
                          {
                            value: value,
                            label:
                              `${dataAirportArrival[index]?.code} - ${
                                dataAirportArrival[index]?.city} - ${
                                dataAirportArrival[index]?.name} ${
                                dataAirportArrival[index]?.uniform === "Indonesia"
                                  ? ""
                                  : `- ${dataAirportArrival[index]?.uniform}`
                              }`,
                          }
                        );
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                </div>
              </CardBody>
              <hr />
              <CardBody>
                <div className="row">
                  <Col md={6}>
                    <Select2
                      title={t("field.airline")}
                      name="airplane_name_transfer_to"
                      options={dataAirlineTransfer}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.airplane_name_transfer_to}
                      onInputChange={(keyword) => {
                        if (keyword !== "") {
                          setSearchTextAirlineTransfer(keyword);
                        }
                      }}
                      onChange={(name, value) => {
                        formik.setFieldValue(
                          "airplane_name_transfer_to",
                          value
                        );
                        let airplaneTransferCode = dataAirlineTransfer.filter(
                          function (el) {
                            return el.value == value;
                          }
                        );
                        formik.setFieldValue(
                          "airplane_code_transfer",
                          airplaneTransferCode[0]?.code
                        );
                        formik.setFieldValue("selectedAirlineTransfer", {
                          value: value,
                          code: airplaneTransferCode[0]?.code,
                          label: `${airplaneTransferCode[0]?.code} - ${value}`,
                        });
                      }}
                      onBlur={formik.setFieldTouched}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group" style={{ marginBottom: "0" }}>
                      <label
                        className="form-label"
                        style={{ marginBottom: "5px" }}
                      >
                        {t("field.flightNumber")}
                      </label>
                    </div>
                    <InputPrepend
                      name="airplane_number_transfer_to"
                      label={t("field.flightNumber")}
                      title={formik.values?.airplane_code_transfer}
                      type="text"
                      width="25%"
                      mt="2"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("airplane_number_transfer_to")}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      title={t("field.departureDate")}
                      name="date_transfer_to"
                      errors={formik.errors}
                      min={formik.values?.date}
                      touched={formik.touched}
                      value={formik.values?.date_transfer_to}
                      {...formik.getFieldProps("date_transfer_to")}
                      type="date"
                      onChange={(e) => {
                        let value = e.target.value;
                        formik.setFieldValue("date_transfer_to", value);
                      }}
                      disabled={!formik.values.date || !formik.values.time}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      title={t("field.departureTime")}
                      name="time_transfer_to"
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik.values?.time_transfer_to}
                      onChange={(e) => {
                        let value = e.target.value;
                        formik.setFieldValue("time_transfer_to", value);
                      }}
                      disabled={
                        formik.values.date_transfer_to === null ||
                        formik.values.date_transfer_to === "" ||
                        formik.values.date_transfer_to === undefined
                      }
                      type="time"
                    />
                  </Col>
                </div>
              </CardBody>
            </React.Fragment>
          )}
        </Card>
        <Card rounded>
          <CardHeader title={t("field.product")} />
          <CardBody>
            {(!formik?.values?.list_available_services ||
                formik?.values?.list_available_services?.length < 1) ? (
                <Card rounded>
                  <CardBody isActive={false}>
                    <CardSelectionProduct
                      notAvailable
                      desc={t("field.notAvailable")}
                    />
                  </CardBody>
                </Card>
              ) : (
                formik?.values?.list_available_services?.map((item, index) => {
                  return (
                    <React.Fragment key={`service-${index}`}>
                      <div
                        onClick={() => handleProductSelected(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <Card rounded>
                          <CardBody
                            isActive={item?.type === formik?.values.product_type}
                          >
                            <CardSelectionProduct
                              selectedType={item?.type}
                              productType={formik?.values.product_type}
                              title={item?.label}
                              desc={t(
                                `product.${
                                  item?.type === 3
                                    ? "detailFTDesc"
                                    : item?.type === 4
                                    ? "detailTransferDesc"
                                    : "detailMnGDesc"
                                }`
                              )}
                            />
                          </CardBody>
                        </Card>
                      </div>
                    </React.Fragment>
                  );
                })
              )}
          </CardBody>
        </Card>
        {button(corporateType)}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline, corp_price }) => {
  return { airport, corporate, airline, corp_price };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(FlightReservation)
);
