import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Row, Col } from "reactstrap";
import { withTrans } from "../../../../i18n/withTrans";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import InputPrepend from "../../../../components/forms/InputPrepend";
import { toastError } from "../../../../components/commons/toast";

const GroupPriceModal = ({ formik, index, value, name, show, toggle, t, reset, confirm }) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("field.groupPrice")}{" "}
          <span
            className="close clickable"
            onClick={() => toggle()}
          >
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label mb-0">
                    Min Pax
                  </label>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label mb-0">
                    Max Pax
                  </label>
                </div>
              </Col>
            </Row>
            <InputPrepend
              mt="0"
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  name: `${name}.group_prices[${index}].min_pax`,
                  value: value?.group_prices === undefined
                    ? "" 
                    : value?.group_prices[index]?.min_pax,
                  type: "number",
                  placeholder: "Min Pax",
                  onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                  onChange: (data) => {
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].min_pax`,
                      data.target.value
                    );
                  },
                },
                {
                  name: `${name}.group_prices[${index}].max_pax`,
                  value: value?.group_prices === undefined
                    ? "" 
                    : value?.group_prices[index]?.max_pax,
                  type: "number",
                  placeholder: "Max Pax",
                  onKeyDown: (e) => exceptThisSymbols.includes(e.key) && e.preventDefault(),
                  onChange: (data) => {
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].max_pax`,
                      data.target.value
                    );
                  },
                },
              ]}
            />
            <div className="form-group mt-4">
              <label className="form-label mb-0">
                {t("field.regularPrice")}
              </label>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.adult")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.child")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.infantLabel")}
                  </label>
                </div>
              </div>
            </div>
            <InputPrepend
              mb="0"
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_adult_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_adult_price,
                  placeholder: t("commons.adult"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_adult = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].reg_adult_price`,
                      price_adult
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_child_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_child_price,
                  placeholder: t("commons.child"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_child = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].reg_child_price`,
                      price_child
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].reg_infant_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.reg_infant_price,
                  placeholder: t("commons.infantLabel"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_infant = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].reg_infant_price`,
                      price_infant
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
              ]}
            />
            <div className="form-group mt-4">
              <label className="form-label mb-0">
                {t("field.goshowPrice")}
              </label>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.adult")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.child")}
                  </label>
                </div>
              </div>
              <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <label className="form-label mb-1">
                    {t("commons.infantLabel")}
                  </label>
                </div>
              </div>
            </div>
            <InputPrepend
              mb="0"
              errors={formik?.errors}
              touched={formik?.touched}
              multiple={[
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_adult_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_adult_price,
                  placeholder: t("commons.adult"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_adult = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].goshow_adult_price`,
                      price_adult
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_child_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_child_price,
                  placeholder: t("commons.child"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_child = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].goshow_child_price`,
                      price_child
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
                {
                  prefix: "Rp ",
                  min: 0,
                  isCurrency: true,
                  thousandSeparator: true,
                  name: `${name}.group_prices[${index}].goshow_infant_price`,
                  value: value?.group_prices === undefined
                    ? ""
                    : value?.group_prices[index]?.goshow_infant_price,
                  placeholder: t("commons.infantLabel"),
                  onChange: (data) => {
                    const val = data.target.value;
                    const price_infant = Number(
                      val.replace(/[^0-9\.-]+/g, "")
                    );
                    formik?.setFieldValue(
                      `${name}.group_prices[${index}].goshow_infant_price`,
                      price_infant
                    );
                  },
                  onKeyDown: (e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                  },
                },
              ]}
            />
          </Container>
          <div style={{ margin: "50px 20px 0 20px" }}>
            <ButtonModal
              reset={() => reset()}
              toggle={() => toggle()}
              confirm={() => {
                if (value?.group_prices[index]?.min_pax === "" && value?.group_prices[index]?.max_pax === "") {
                  toastError(`${t("commons.empty_data_message")} PAX!`);
                  return;
                }
                if (value?.group_prices[index]?.reg_adult_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].reg_adult_price`, 0);
                }
                if (value?.group_prices[index]?.reg_child_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].reg_child_price`, 0);
                }
                if (value?.group_prices[index]?.reg_infant_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].reg_infant_price`, 0);
                }
                if (value?.group_prices[index]?.goshow_adult_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].goshow_adult_price`, 0);
                }
                if (value?.group_prices[index]?.goshow_child_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].goshow_child_price`, 0);
                }
                if (value?.group_prices[index]?.goshow_infant_price === "") {
                  formik?.setFieldValue(`${name}.group_prices[${index}].goshow_infant_price`, 0);
                }
                confirm()
              }}
              resetTitle={t("commons.delete")}
              confirmTitle={t("commons.save")}
              icon
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(GroupPriceModal);
