import React from "react";
import { useState, useEffect } from "react";
import { withTrans } from "../../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";

import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import DetailButton from "../../../components/commons/table/detailButton";
import Loader from "../../../components/commons/Loader";
import Button from "../../../components/commons/buttons/Button";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import Table from "../../../components/commons/table";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import LabelPlus from "../../../components/commons/table/labelPlus";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";

// Service
import RegularService from "../../../store/actions/product/regular_price";
import { RoleGroups } from "../../../utils/Constants";

const Regular = ({ t, pending }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [deleteModal, setDeleteModal] = useState(false);
  const [dataRegular, setDataRegular] = useState([]);
  const [dataRegularRow, setDataRegularRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const showDeleteModal = (data) => {
    setDeleteModal(!deleteModal);
    setDataRegularRow(data ? data : null);
  };

  const handleDelete = () => {
    const callback = () => {
      handleRefresh();
      setPage(1);
      setDeleteModal(!deleteModal);
    };
    if (dataRegularRow) {
      const id = dataRegularRow?.id;
      dispatch(RegularService.del(id, callback));
    }
  };

  const columns = [
    {
      Header: "NO.",
      accessor: "no",
    },
    {
      Header: t("field.price").toUpperCase(),
      accessor: "name",
    },
    {
      Header: t("field.airport").toUpperCase(),
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        const idRow = original.id;
        const airport_list = original.airport_list.map((airport) => ({
          id: airport?.airport_id,
          name: `${airport?.airport_detail?.code}-${airport?.airport_detail?.city}-${airport?.airport_detail?.name}`,
        }));
        return <LabelPlus data={airport_list} idRow={idRow} />;
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <DetailButton>
            <MenuItem onClick={() => history.push(`product/regular_price/detail/${original?.id}`)}>
              <span style={{ fontSize: 14 }}>{t("commons.detail")}</span>
            </MenuItem>
            {!RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code) && (
              <>
                <MenuItem onClick={() => history.push(`product/regular_price/edit/${original?.id}`)}>
                  <span style={{ fontSize: 14 }}>{t("commons.edit")}</span>
                </MenuItem>
                <MenuItem onClick={() => showDeleteModal(original)}>
                  <span style={{ fontSize: 14 }}>{t("commons.delete")}</span>
                </MenuItem>
              </>
            )}
          </DetailButton>
        );
      },
    },
  ];

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        page: page === 0 ? 1 : page ?? 1,
        length: 10,
        id: null,
        search_text: searchText,
      };
      dispatch(RegularService.get(param, resolve));
    }).then((res) => {
      setDataRegular(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, searchText]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 1));
  }, [searchText]);

  return (
    <>
      {pending && <Loader sideContent loading={pending} />}
      <HeaderMenu title={t("field.regular")}>
        <SearchButton
          placeholder={t("commons.enterKeyword")}
          toggle={(value) => handleSearch(value)}
        />
        {!RoleGroups.contactCenterRoles.includes(currentUser?.user?.role_code) && (
          <>
            <div className="nav-separator mr-3 ml-3" />
            <Button
              title={t("commons.set") + " " + t("field.price")}
              leftIcon="add"
              rounded={true}
              variant="primary"
              onClick={() => history.push("product/regular_price/set")}
            />
          </>
        )}
      </HeaderMenu>
      <div className="content-container-side">
        <div className="overflow">
          <Table
            columns={columns}
            data={dataRegular ?? []}
            pagination={{ page, totalData, maxPage, toggle: setPage }}
          />
        </div>
      </div>
      <ConfirmationModal
        show={deleteModal}
        toggle={showDeleteModal}
        confirm={handleDelete}
        message={t("commons.areYouSureDelete")}
        messageData={dataRegularRow?.name}
        icon="info"
        isDelete
      />
    </>
  );
};

const mapStateToProps = ({ reg_price: { pending, data } }) => {
  return { pending, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Regular));
