import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import { Divider } from "@mui/material";
import { withTrans } from "../../../../i18n/withTrans";
import moment from "moment";

const RenewalHistoryModal = ({ t, show, toggle, data }) => {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("product.renewalHistory")}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                overflowX: "clip",
              }}
              className="my-4"
            >
              {data?.length > 0 ? (
                data?.map((item, index) => {
                  return (
                    <React.Fragment key={index + item?.id}>
                      <div className="d-flex justify-content-between my-3">
                        <div className="name-assistant">
                          <div style={{ paddingRight: 8 }}>
                            <strong>{`${t("product.period")} ${index+1}`}</strong>
                          </div>
                        </div>
                        <div className="assign normal-title">
                          <span>
                            {moment(item.start_date).format("DD/MM/YYYY")}
                          </span>
                          {" "}-{" "}
                          <span>
                            {moment(item.end_date).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      </div>
                      <Divider orientation="horizontal" />
                    </React.Fragment>
                  )
                })
              ) : (
                <div className="text-center">
                  {t("commons.dataNotFound")}
                </div>
              )}
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTrans(RenewalHistoryModal);
