import Sidebar from "./sidebar/Sidebar";
import Header from "./header/header";
import { useState } from "react"

const styledGrid = {
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  height: "100%",
  minHeight: "100hv"
}

const styledMain = {
  padding: "0px 10px",
  width: "calc(100vw - 108px)"
  // width: "100%",
}

const Index = ({ children }) => {

  const [showNav, setShowNav] = useState(false);

  const toggle = () => { setShowNav(!showNav) };


  return (
    <div style={styledGrid}>
      {/* Sidebar menu */}
      <div className="navbar-container">
        <Sidebar visible={showNav} close={toggle} />
      </div>
      <div className="header-top">
        <Header toggle={toggle} />
      </div>
      {/* Child menu */}
      <div style={styledMain} className={`main-top ${showNav ? "under-element" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default Index;
