import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import NumberFormat from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";

import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { countries } from "../../../utils/ListCountries";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";

const ModalEditPassenger = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
}) => {
  const formikRef = useRef();
  const [dataCountries, setDataCountries] = useState(
    countries?.map((item) => ({
      label: item.name,
      value: item.name,
      countryCode: item.code?.toLowerCase(),
    }))
  );
  const title = t("commons.edit") + " " + t("field.passenger");
  const initial = {
    id: data?.id,
    name: data?.name,
    passenger_nationality: data?.passenger_nationality,
    email: data?.email,
    phoneNumber: data?.country_code ? `${data?.country_code.replace("+", "")}${data?.phone_number}` : data?.phone_number,
    countryCode: data?.country_code?.includes("+") ? data?.country_code.replace("+", "") : data?.country_code,
    countryId: data?.country_id
  }

  const validation = Yup.object().shape({
    name: Yup.string().required(`${t("field.name")} ${t("commons.required")}`).nullable(),
    passenger_nationality: Yup.string().required(`${t("field.nationality")} ${t("commons.required")}`).nullable(),
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
          onSubmit={async (values, { setStatus }) => {
            if (
              data?.title !== "Infant" &&
              data?.title !== "Child"
            ) {
              if (values.email === "") {
                toastError(`${t("commons.empty_data_message")} Email!`);
                return;
              } else if (values.phoneNumber === "") {
                toastError(
                  `${t("commons.empty_data_message")} ${t("field.phone")}!`
                );
                return;
              }
            }
            setStatus();
            action(values);
            await delay(3000);
          }}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Input
                    name="name"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.name ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("name", value);
                    }}
                    title={t("field.name")}
                    type="text"
                  />
                  {/* <Input
                    name="passenger_nationality"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.passenger_nationality ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("passenger_nationality", value);
                    }}
                    title={t("field.nationality")}
                    type="text"
                  /> */}
                  <Select2
                    clearable={!!props.values?.passenger_nationality}
                    name="passenger_nationality"
                    errors={props.errors}
                    touched={props.touched}
                    title={t("field.nationality")}
                    options={dataCountries}
                    value={props.values?.passenger_nationality}
                    onChange={(name, value) => {
                      const index = dataCountries
                        ?.map(function (e) {
                          return e.value;
                        })
                        .indexOf(value);
                      props?.setFieldValue("passenger_nationality", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                  <Input
                    name="email"
                    type="email"
                    errors={props?.errors}
                    touched={props?.touched}
                    value={props?.values?.email ?? ""}
                    onChange={(e) => {
                      let value = e.target.value;
                      props?.setFieldValue("email", value);
                    }}
                    title={t("field.email")}
                  />
                  <div className="form-group">
                    <div className="form-label">{t("field.phone")}</div>
                    <div className="input-wrapper w-100">
                      <PhoneInput
                        enableTerritories={true}
                        countryCodeEditable={false}
                        placeholder={t("field.placeHolderPhoneNumber")}
                        country={props?.values?.countryId ?? "id"}
                        inputStyle={{ width: '100%' }}
                        enableSearch={true}
                        value={props?.values?.phoneNumber}
                        onChange={(phone, country) => {
                          props?.setFieldValue("countryCode", country?.dialCode);
                          props?.setFieldValue("phoneNumber", phone);
                          props?.setFieldValue("countryId", country?.countryCode);
                        }}
                      />
                    </div>
                  </div>
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={confirm}
                  confirmTitle={t("commons.save")}
                  disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default withTrans(ModalEditPassenger);
