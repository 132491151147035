import { Row, Col } from "reactstrap";

import Card from "../../../../components/commons/cards/card";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import { withTrans } from "../../../../i18n/withTrans";
import { getUser } from "../../../../utils/User";
import { Role } from "../../../../utils/Constants";

const Transportation = ({ t, formik, add, toggle }) => {
    const currentUser = getUser();

    const handleDetailZone = (index) => {
        if (toggle) {
            toggle(formik, index)
        }
    }

    return (
        <>
            <Card>
                {
                    formik.values.transports.length < 1 && (
                        <CardFlush
                            list
                            label={t('commons.no_data')}
                            size={{ label: 12 }}
                        />
                    )
                }
                {
                    formik.values.transports?.map((item, index) => {
                        return (
                            <CardFlush
                                list
                                label={item?.zone_name}
                                button={
                                    <Row>
                                        <Col md={8}>
                                            <div>
                                                {(item?.prices?.length ?? 0) + " " + t("field.priceType")}
                                            </div>
                                        </Col>
                                        <Col md={3} style={{ cursor: 'pointer' }}>
                                            <div 
                                                onClick={() => handleDetailZone(index)}
                                            >
                                                <span 
                                                    className="material-icons-round right-icon"
                                                >
                                                    navigate_next
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                key={index}
                                size={{ label: 7, desc: 5 }}
                            />
                        )
                    })
                }
                {currentUser?.user.role_code === Role.Central_admin && (
                    <div
                        style={{ 
                            color: '#63ae5c', 
                            padding: '10px 15px', 
                            display: 'flex', 
                            cursor: 'pointer' 
                        }} 
                        onClick={() => add(formik)}
                    >
                        <div 
                            style={{ 
                                borderRadius: '50%', 
                                border: 'solid 1px #63ae5c', 
                                width: 30, 
                                height: 30, 
                                marginRight: 20, 
                                textAlign: 'center' 
                            }}
                        >
                            <span className="material-icons-round">add</span>
                        </div>
                        <p 
                            className="text-bold" 
                            style={{ 
                                fontSize: 14, 
                                margin: 0, 
                                alignSelf: 'center' 
                            }}
                        >
                            {t("commons.add") + " " + t("field.zone")}
                        </p>
                    </div>
                )}
            </Card>
        </>
    )
}

export default withTrans(Transportation)