import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AIRLINE_PENDING = "GET_AIRLINE_PENDING";
export const GET_AIRLINE_SUCCESS = "GET_AIRLINE_SUCCESS";
export const GET_AIRLINE_ERROR = "GET_AIRLINE_ERROR";
export const GET_ALL_AIRLINE_PENDING = "GET_ALL_AIRLINE_PENDING";
export const GET_ALL_AIRLINE_SUCCESS = "GET_ALL_AIRLINE_SUCCESS";
export const GET_ALL_AIRLINE_ERROR = "GET_ALL_AIRLINE_ERROR";
export const POST_AIRLINE_PENDING = "POST_AIRLINE_PENDING";
export const POST_AIRLINE_SUCCESS = "POST_AIRLINE_SUCCESS";
export const POST_AIRLINE_ERROR = "POST_AIRLINE_ERROR";
export const PUT_AIRLINE_PENDING = "POST_AIRLINE_PENDING";
export const PUT_AIRLINE_SUCCESS = "POST_AIRLINE_SUCCESS";
export const PUT_AIRLINE_ERROR = "POST_AIRLINE_ERROR";
export const DELETE_AIRLINE_PENDING = "DELETE_AIRLINE_PENDING";
export const DELETE_AIRLINE_SUCCESS = "DELETE_AIRLINE_SUCCESS";
export const DELETE_AIRLINE_ERROR = "DELETE_AIRLINE_ERROR";

// URL: URL_{URL}
const AIRLINE_URL = `v1/master_data/airline`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_AIRLINE_PENDING));
    API.get(AIRLINE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_AIRLINE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_AIRLINE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const getAll = (param) => (dispatch) => {
    dispatch(actionPending(GET_ALL_AIRLINE_PENDING));
    API.get(AIRLINE_URL, { params: param })
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_ALL_AIRLINE_SUCCESS, res));
        })
        .catch((err) => {
            dispatch(actionError(GET_ALL_AIRLINE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(POST_AIRLINE_PENDING));
    API.post(AIRLINE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_AIRLINE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Tambah Data Maskapai" : "Airline Data Added Successfully");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_AIRLINE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(PUT_AIRLINE_PENDING));
    API.put(AIRLINE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_AIRLINE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Ubah Data Maskapai" : "Airline Data Changed Successfully");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_AIRLINE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const deleted = (param, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_AIRLINE_PENDING));
    API.delete(AIRLINE_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_AIRLINE_SUCCESS, res));
            toastSuccess(lang === 'id' ? `Berhasil Hapus Data Maskapai ${name}` : `Airline ${name} Removed Successfully`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_AIRLINE_ERROR));
            if (lang === 'id') {
              toastError(err?.response?.data?.message);
            } else if (lang === "en-US" || lang === "en") {
              toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
            }
        });
};

const airline = {
    get,
    getAll,
    post,
    put,
    deleted,
};
export default airline;
