import { useState } from "react"
import { useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Divider } from "@mui/material";
import { withTrans } from "../../../i18n/withTrans";
import { RoleWithAirport } from "../../../utils/Constants";

import BookingService from "../../../store/actions/booking";
import CardFlush from "../../../components/commons/cards/cardFlush";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import IconButton from "../../../components/commons/buttons/IconButton";
import PaymentProofModal from "../../../components/commons/modal";
import UploadPaymentModal from "../modal/UploadPaymentModal";

const DetailPayment = ({ t, match, currentUser, dataDetail, handleRefresh }) => {
  const dispatch = useDispatch();
  const adminPayment = RoleWithAirport?.filter(
    (item) => item !== "assistant"
  )?.includes(currentUser?.user.role_code);

  const [deleteModal, setDeleteModal] = useState(false);
  const [paymentProof, setPaymentProof] = useState(false);
  const [uploadPaymentProof, setUploadPaymentProof] = useState(false);

  const showPaymentProof = () => {
    setPaymentProof(!paymentProof);
  };

  const showUploadPaymentModal = () => {
    setUploadPaymentProof(!uploadPaymentProof);
  };

  const deletePayment = () => {
    const callback = () => {
      setDeleteModal(!deleteModal);
      handleRefresh();
    };

    dispatch(BookingService.delPayment({ id: match?.params?.id }, callback));
  };

  return (
    <>
      <div className="wrapper-detail-card">
        <div className="text-extra-bold  container-card">
          <Row>
            <Col md={3} style={{ opacity: 0.5 }}>
              {t("field.payment")}
            </Col>
            <Col md={9}>
              <div className="d-flex justify-content-end">
                <div
                  className="text-primary text-bold clickable"
                  style={{ fontSize: 14 }}
                  onClick={
                    dataDetail?.payment_proof && !dataDetail?.midtrans_order_id
                      ? showPaymentProof
                      : null
                  }
                >
                  {dataDetail?.payment_proof && !dataDetail?.midtrans_order_id
                    ? t("booking.openPaymentProof")
                    : ""}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Divider orientation="horizontal" />
        <CardFlush
          list
          label={t("booking.paymentType")}
          desc={
            dataDetail?.payment_type
              ? dataDetail?.payment_type?.charAt(0).toUpperCase() +
                dataDetail?.payment_type?.slice(1)
              : "-"
          }
          size={{ label: 3, desc: 9 }}
          descEnd
        />
        {dataDetail?.midtrans_order_id && (
          <CardFlush
            list
            label="Order ID"
            desc={dataDetail?.midtrans_order_id ?? "-"}
            size={{ label: 3, desc: 9 }}
            descEnd
            last
          />
        )}
        {dataDetail?.order_from === "web-admin" && !dataDetail?.is_booked_by_contact_center &&
          adminPayment && currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling && (
            <>
              <Divider orientation="horizontal" />
              <div className="d-flex">
                <IconButton
                  onClick={showUploadPaymentModal}
                  icon={
                    dataDetail?.payment_proof === null ? "add" : "edit"
                  }
                  title={`${
                    dataDetail?.payment_proof === null ? "Add" : "Edit"
                  } ${t("field.payment")}`}
                />
                {dataDetail?.payment_proof !== null && (
                  <>
                    <div className="my-3 mx-0 vertical-divider" />
                    <IconButton
                      onClick={() => setDeleteModal(!deleteModal)}
                      icon="delete"
                      colorIcon="#d53a34"
                      title={`${t("commons.delete")} ${t(
                        "field.payment"
                      )}`}
                    />
                  </>
                )}
              </div>
            </>
          )}
      </div>

      <PaymentProofModal
        show={paymentProof}
        toggle={() => setPaymentProof(!paymentProof)}
        title={t("booking.paymentProof")}
        content={
          <div>
            {process.env.REACT_APP_API_URL +
            dataDetail?.payment_proof?.substr(
              1,
              dataDetail?.payment_proof?.length
            ) ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  dataDetail?.payment_proof?.substr(
                    1,
                    dataDetail?.payment_proof?.length
                  )
                }
                alt={t("booking.paymentProof")}
                className="img img-fluid"
                width="100%"
                style={{ objectFit: "cover", width: "100%" }}
              />
            ) : (
              <p>Dokumen tidak ditemukan</p>
            )}
          </div>
        }
      />

      <UploadPaymentModal
        show={uploadPaymentProof}
        toggle={() => {
          setUploadPaymentProof(!uploadPaymentProof);
          handleRefresh();
        }}
        payment_type={dataDetail?.payment_type ?? null}
        payment_proof={
          dataDetail?.payment_proof
            ? process.env.REACT_APP_API_URL +
              dataDetail?.payment_proof?.substr(
                1,
                dataDetail?.payment_proof?.length
              )
            : null
        }
        order_id={match?.params?.id}
      />

      <ConfirmationModal
        show={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        confirm={deletePayment}
        icon="info"
        message={`${t("commons.deleteConfirmation")} ${t("field.payment")} `}
        messageData={`#${dataDetail?.unique_transaction_id}`}
        isDelete
      />
    </>
  )
}

export default withTrans(DetailPayment)