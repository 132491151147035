import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { Row, Col } from "reactstrap";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import moment from "moment";

import Loader from "../../../components/commons/Loader";
import Card from "../../../components/commons/cards/card";
import CardFlush from "../../../components/commons/cards/cardFlush";
import CardFlushButtonSide from "../../../components/commons/cards/cardFlushButtonSide";
import HeaderMenu from "../../../components/commons/menu/HeaderMenu";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import AssignAssistantModal from "./modal/AssignAssistantModal";
import DropdownLounge from "../../../components/commons/dropdowns/DropdownLounge";
import EvidenceAssistantModal from "./modal/EvidenceAssistantModal";
import PaymentProofModal from "./modal/PaymentProofModal";
import AssistantHistoryModal from "./modal/AssistantHistory";
import AdditionalServiceModal from "./modal/AdditionalServiceModal";
import EditNotesModal from "../../../components/commons/modal";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { history } from "../../../utils/History";
import { getUser } from "../../../utils/User";
import { corporateGroupType, statusBooking, RoleAdminBooking, RoleGroups } from "../../../utils/Constants";
import { numberWithCommas, getRouteTypeByFlightType, getLengthPassAdult, getLengthPassChild, getLengthPassInfant, getTransferFlightTypeCode, getContactInfoBooking } from "../../../utils/Helper";

import OperationalService from "../../../store/actions/schedule/operational";
import UserAssistant from "../../../store/actions/schedule/operational";
import BookingService from "../../../store/actions/booking";
import Corp_Reservation from "../../../store/actions/corp_reservation";
import AirportService from "../../../store/actions/master_data/airport";
import { productCode } from "../../../utils/Constants";
import DetailPassenger from "./DetailPassenger";

const DetailOperational = ({ data, error, error_delete, t, pending, match }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [action, setAction] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [evidenceAssistant, setEvidenceAssistant] = useState(false);
  const [paymentProof, setPaymentProof] = useState(false);
  const [dataDetail, setDataDetail] = useState("");
  const [dataLounge, setDataLounge] = useState([]);
  const [dataTransports, setDataTransports] = useState([]);
  const [dataOther, setDataOther] = useState([]);
  const [listActionLounge, setListActionLounge] =  useState([]);
  const [listActionTransports, setListActionTransports] =  useState([]);
  const [listActionOther, setListActionOther] =  useState([]);
  const [assistant, setAssistant] = useState([]);
  const [addAdditionalServiceModal, setAddAdditionalServiceModal] = useState(false);
  const [productLounge, setProductLounge] = useState([]);
  const [airportLounge, setAirportLounge] = useState([]);
  const [productTransports, setProductTransports] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [airportCrossSelling, setAirportCrossSelling] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteServiceName, setDeleteServiceName] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [passengerNotes, setPassengerNotes] = useState("");
  const [showConfirmEditNotes, setShowConfirmEditNotes] = useState(false);
  const [adminBooking] = useState(
    RoleAdminBooking?.includes(currentUser?.user.role_code)
      ? true
      : false
  );

  const handleGetDetailAirportCrossSelling = (idAirport) => {
    new Promise((resolve) => {
      let param = {
        id: idAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (res) {
        setAirportCrossSelling(
          res?.data?.map((airport) => `${airport?.code}-${airport?.city}-${airport?.name} ${airport?.uniform === 'Indonesia' ? "" : "-" + airport?.uniform}`)[0]
        );
      }
    });
  };
  
  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        draw: 1,
        length: 1,
        page: 1,
        id: match?.params?.id,
      };
      dispatch(OperationalService.get(param, resolve));
    }).then((res) => {
      setDataDetail(res?.data[0]);
      setDataLounge(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Lounge"
        )
      );
      setDataTransports(
        res?.data[0]?.list_order_extras
          ?.filter((item) => item?.type === "Transport")
          ?.map((item) => {
            const labelName = item?.region !== null
              ? `${item?.regin} - ${item?.name} (${
                  item?.total_unit ?? "1"
                } unit)`
              : `${item?.name} (${item?.total_unit ?? "1"} unit)`;

            return {
              ...item,
              labelName,
            };
          })
      );
      setDataOther(
        res?.data[0]?.list_order_extras?.filter(
          (item) => item?.type === "Other"
        )
      );
      
      if(res?.data[0]?.is_cross_selling && res?.data[0]?.airport_id_cross_selling) {
        handleGetDetailAirportCrossSelling(res?.data[0]?.airport_id_cross_selling)
      }
    });
  };

  useEffect(() => {
    handleRefresh()
  }, [action]);
  
  useEffect(() => {
    if (error) {
      setLoadingSubmit(false);
    }
  }, [error]);

  useEffect(() => {
    if (error_delete) {
      setLoadingSubmit(false);
    }
  }, [error_delete]);

  useEffect(() => {
    if (dataDetail) {
      if (dataLounge?.length) {
        setListActionLounge([
          {
            name: t("commons.change") + " " + t("field.lounge"),
            action: () => addAdditionalService("lounge"),
          },
          {
            name: t("commons.delete") + " " + t("field.lounge"),
            action: () => showConfirmModal("lounge"),
          },
        ]);
      } else {
        setListActionLounge([
          {
            name: t("commons.change") + " " + t("field.lounge"),
            action: () => addAdditionalService("lounge"),
          },
        ]);
      }

      if (dataTransports?.length) {
        setListActionTransports([
          {
            name: t("commons.change") + " " + t("field.transportation"),
            action: () => addAdditionalService("transport"),
          },
          {
            name: t("commons.delete") + " " + t("field.transportation"),
            action: () => showConfirmModal("transport"),
          },
        ]);
      } else {
        setListActionTransports([
          {
            name: t("commons.change") + " " + t("field.transportation"),
            action: () => addAdditionalService("transport"),
          },
        ]);
      }

      if (dataOther?.length) {
        setListActionOther([
          {
            name: t("commons.change") + " " + t("masterData.others"),
            action: () => addAdditionalService("other"),
          },
          {
            name: t("commons.delete") + " " + t("masterData.others"),
            action: () => showConfirmModal("other"),
          },
        ]);
      } else {
        setListActionOther([
          {
            name: t("commons.change") + " " + t("masterData.others"),
            action: () => addAdditionalService("other"),
          },
        ]);
      }
    }
  }, [dataDetail, dataLounge, dataTransports, dataOther]);

  const showAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const showHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const showEvidenceAssistant = () => {
    setEvidenceAssistant(!evidenceAssistant);
  };

  const showPaymentProof = () => {
    setPaymentProof(!paymentProof);
  };

  const handleRefreshHistory = () => {
    new Promise((resolve) => {
      let param = {
        length: 9999,
        order_id: match?.params?.id,
      };
      dispatch(UserAssistant.getHistory(param, resolve));
    }).then((res) => {
      setAssistant(res?.data);
    });
  };

  const handleGetAirportLounge = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 99999,
        id:
          dataDetail?.type === 1
            ? dataDetail?.airport_id_to
            : dataDetail?.type === 2
            ? dataDetail?.airport_id_from
            : dataDetail?.airport_id_transfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      const routeType = getRouteTypeByFlightType(
        dataDetail?.type,
        dataDetail?.airport_country_from,
        dataDetail?.airport_country_to
      );
      const airport_lounge_list = res?.data[0]?.lounges?.filter((item) => item?.route_type === routeType);

      setAirportLounge(airport_lounge_list.map((item) => ({
        label: (<DropdownLounge lounge={item}/>),
        value: item.id,
        name: `${item?.name} (${item?.route_type})`,
        adult_price: item.price_adult,
        child_price: item.price_child,
        infant_price: item.price_infant,
      })));
    });
  };

  const showConfirmModal = (service) => {
    setDeleteServiceName(service);
    setConfirmModal(!confirmModal);
  };

  const deleteAdditionalService = () => {
    setLoadingSubmit(true);
    const id = deleteServiceName === 'lounge'
      ? dataLounge[0]?.id
      : deleteServiceName === 'transport'
      ? dataTransports[0]?.id
      : dataOther[0]?.id;

    const callback = () => {
      setConfirmModal(!confirmModal);
      handleRefresh();
    };

    if(id) {
      new Promise((resolve) => {
        dispatch(Corp_Reservation.deleteExtraOrder({ id: id }, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const addAdditionalService = (service) => {
    const flight_transfer_code = getTransferFlightTypeCode(
      dataDetail?.airport_country_from,
      dataDetail?.airport_country_to
    );
    const flight_type = dataDetail?.type === 1 ? 5 : dataDetail?.type === 2 ? 6 : flight_transfer_code;
    const airport_service_id = 
      dataDetail?.type === 1
        ? dataDetail?.airport_id_to
        : dataDetail?.type === 2
        ? dataDetail?.airport_id_from
        : dataDetail?.airport_id_transfer;

    const param = {
      airport_service_id,
      flight_type,
      product_name: productCode[dataDetail?.product_name_eng]?.enum,
      corporate_id: dataDetail?.corporate_id,
      service_date: dataDetail?.date,
      is_mob: dataDetail?.corporate_group_type === "MOB" ? true : false,
      adult_total: getLengthPassAdult(dataDetail?.list_order_passengers),
      child_total: getLengthPassChild(dataDetail?.list_order_passengers),
      infant_total: getLengthPassInfant(dataDetail?.list_order_passengers),
    };

    new Promise((resolve) => {
      dispatch(Corp_Reservation.findAvailPrice(param, resolve));
    }).then((res) => {
      if (res?.data) {
        const routeType = getRouteTypeByFlightType(
          dataDetail?.type,
          dataDetail?.airport_country_from,
          dataDetail?.airport_country_to
        );
        const corporate_type = res?.data?.type_list?.find(
          (item) => item?.corporate_type === dataDetail?.corporate_group_type
        );
        const lounge =
          corporate_type?.period_list[0]?.product_list?.find(
            (item) => item?.product_name === 5
          );
        if (lounge) {
          let lounge_list;
          if (lounge?.corporate_lounge_list?.length) {
            lounge_list = lounge?.corporate_lounge_list;
          } else if (lounge?.lounge_list?.length) {
            lounge_list = lounge?.lounge_list?.filter((item) => item?.airport_id === airport_service_id);
          } 

          lounge_list = lounge_list?.filter((item) => item?.route_type === routeType);

          setProductLounge(lounge_list.map((item) => ({
            label: (<DropdownLounge lounge={item} loungePrice={true} />),
            value: item.id,
            name: `${item?.name} (${item?.route_type})`,
            adult_price: item.adult_price,
            child_price: item.child_price,
            infant_price: item.infant_price,
          })));
        } else {
          handleGetAirportLounge();
        }

        const transport =
          corporate_type?.period_list[0]?.product_list?.find(
            (item) => item?.product_name === 6
          );
        if (transport) {
          setProductTransports(
            transport?.transport_list.map((item) => ({
              label: (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="normal-title">{item?.car_name}</div>
                    <div className="normal-title text-extra-bold">
                      {`${item?.capacity} ${t("field.person")}`}
                    </div>
                    <div className="normal-title text-extra-bold">
                      {item?.price == null
                        ? "Rp 0"
                        : "Rp " + numberWithCommas(item?.price)}
                    </div>
                  </div>
                </>
              ),
              value: item?.id,
              name: item?.car_name,
              price: item?.price,
            }))
          );
        }
      }
    });
    setServiceName(service);
    setAddAdditionalServiceModal(!addAdditionalServiceModal);
  };

  const handleEdit = (param) => {
    const callback = () => {
      setAssignModal(!assignModal);
      handleRefresh();
      handleRefreshHistory();
    };
    if (param) {
      dispatch(OperationalService.change_assistant(param, callback));
    }
  };

  const handleEditNotes = () => {
    setLoadingSubmit(true);
    const callback = () => {
      handleRefresh();
      setShowConfirmEditNotes(!showConfirmEditNotes)
      setShowEditNotes(!showEditNotes);
      setPassengerNotes("");
    };

    const param ={
      order_id: dataDetail?.id, 
      notes: passengerNotes
    }

    if (passengerNotes === "") {
      toastError(`${t('commons.empty_data_message')} ${t("field.passengerNotes")}`);
      setLoadingSubmit(false);
    } else {
      new Promise((resolve) => {
        dispatch(BookingService.editPassengerNotes(param, resolve, callback));
      }).then((res) => {
        setLoadingSubmit(false);
      });
    }
  };

  const getActionsAdtService = (listActions) => {
    if (
      dataDetail?.passenger_type_label_eng === "Corporate" &&
      RoleGroups.subAdminRoles.includes(currentUser.user.role_code) &&
      currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
    ) {
      return listActions;
    }
    return null;
  };

  return (
    <div className="menu-container">
      {pending && <Loader loading={pending} />}
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">
            <div className="side-menu-back-icon ">
              <span
                className="material-icons clickable"
                onClick={() =>
                  history.push({
                    pathname: "/schedule",
                    state: { tab: "operational" },
                  })
                }
              >
                arrow_back
              </span>
            </div>
            {`Detail ${t("field.schedule")}`}
          </div>
          <div style={{ padding: "0px 15px 30px 10px" }}>
            <Card className="rounded">
              <CardFlush
                sideMenu
                title={t("field.product")}
                desc={
                  <>
                    <div>{dataDetail?.product_name_eng}</div>
                    {dataDetail?.status_go_show !== null && (
                      <div className="normal-text text-primary mt-2">
                        {dataDetail?.status_go_show === 1
                          ? "Go Show"
                          : "Go Show On The Spot"}
                      </div>
                    )}
                  </>
                }
                leftIcon="check_circle"
              />
              <CardFlush
                sideMenu
                title={t("field.bookingId")}
                desc={dataDetail?.unique_transaction_id}
                leftIcon="confirmation_number"
              />
              {dataDetail?.booking_no_from_partner && (
                <CardFlush
                  sideMenu
                  title={`${t("field.bookingId")} ${t("field.partner")}`}
                  desc={dataDetail?.booking_no_from_partner}
                  leftIcon="confirmation_number"
                />
              )}
              <CardFlush
                sideMenu
                title="SDF"
                desc={dataDetail?.sdf ? dataDetail?.sdf : "-"}
                leftIcon="book_online"
              />
              <CardFlush
                sideMenu
                title={t("field.flightType")}
                desc={
                  localStorage.getItem("joumpa_language") === "en" ||
                  localStorage.getItem("joumpa_language") === "en-US"
                    ? dataDetail?.type_label_eng
                    : dataDetail?.type_label
                }
                leftIcon="flight"
              />
              <CardFlush
                sideMenu
                title={t('commons.addedBy')}
                desc={dataDetail?.adder_name 
                  ? dataDetail?.adder_name
                  : '-'}
                leftIcon="group_add"
              />
              <CardFlushButtonSide
                sideMenu
                title={t("field.assistant")}
                desc={
                  dataDetail?.list_assistants 
                    ? dataDetail?.list_assistants
                      ?.map((item) => {
                        return item?.is_main
                          ? `${item?.assistant_name} (main)`
                          : item?.assistant_name;
                      })
                      .join(", ")
                    : dataDetail?.assistant_name === null 
                      ? "-" 
                      : dataDetail?.assistant_name
                }
                leftIcon="assignment_ind"
                listMenu={
                  !RoleGroups.contactCenterRoles.includes(currentUser.user.role_code) &&
                  currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling
                  ? dataDetail?.status === 1
                    ? [
                      {
                        name: t("schedule.change_assistant"),
                        action: () => showAssignModal(),
                      },
                      {
                        name: t("schedule.replacement_history"),
                        action: () => showHistoryModal(),
                      },
                    ]
                    : [
                      {
                        name: t("schedule.replacement_history"),
                        action: () => showHistoryModal(),
                      },
                    ]
                  : null
                }
                last
              />
            </Card>
            <Card className="rounded">
              <CardFlush
                sideMenu
                last
                title={"Status"}
                desc={
                  statusBooking(t)?.find(
                    (status) => status.enum === dataDetail?.status
                  )?.label
                }
                leftIcon={
                  dataDetail?.status ===
                  statusBooking(t)?.find(
                    (status) => status.label === t("status.done")
                  )?.enum
                    ? "done"
                    : "info"
                }
                iconColor={
                  statusBooking(t)?.find(
                    (status) => status.enum === dataDetail?.status
                  )?.color
                }
              />
            </Card>
          </div>
        </div>
        <div>
          <HeaderMenu title={t("commons.details")} />
          <div style={{ padding: "0 40px" }}>
            {dataDetail?.status === 2 || dataDetail?.status === 5 ? (
              <div>
                <Stepper
                  Linear
                  activeStep={-1}
                  alternativeLabel
                  style={{
                    width:
                      dataDetail?.list_order_milestones?.length > 7
                        ? 150 * dataDetail?.list_order_milestones?.length
                        : "100%",
                  }}
                >
                  {dataDetail?.list_order_milestones?.map((label, index) => (
                    <Step key={label.step} completed={label.status}>
                      <StepButton color="inherit">
                        {localStorage.getItem("joumpa_language") === "en" ||
                        localStorage.getItem("joumpa_language") === "en-US"
                          ? label.name_eng
                          : label.name}
                        <br />
                        {label.status === 1 && label.updated_at !== null
                          ? moment(label.updated_at).format("HH:mm")
                          : ""}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </div>
            ) : (
              <></>
            )}
            <div className="wrapper-detail-card">
              {
                (data = [
                  {
                    label: t("field.customer"),
                    desc:
                      dataDetail?.customer_name === null
                        ? dataDetail?.list_order_passengers[0]?.name
                        : dataDetail?.customer_name,
                  },
                  {
                    label: t("field.passengerType"),
                    desc: dataDetail?.passenger_type,
                  },
                  {
                    label: t("field.contact"),
                    desc: getContactInfoBooking(dataDetail),
                  },
                  {
                    label: t('field.corporate'),
                    desc: dataDetail?.corporate_name,
                  },
                  {
                    label: t('field.cooperationType'),
                    desc: corporateGroupType(
                      t,
                      dataDetail?.corporate_group_type_name
                    )[dataDetail?.corporate_group_type]?.label,
                  },  
                  {
                    label: t("field.bookingTime"),
                    desc: `${moment(dataDetail?.booking_date).format(
                      "DD/MM/YYYY"
                    )}, ${moment(dataDetail?.booking_date).format("HH:mm")}`,
                  },
                ].map((item, i) => {
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
              }))}
              <CardFlush
                list
                label={t("field.airline")}
                desc={`${dataDetail?.airplane_name} / ${dataDetail?.airplane_code}${dataDetail?.airplane_number}`}
                size={{ label: 3, desc: 8 }}
              />
              {dataDetail?.is_cross_selling && (
                <CardFlush
                  list
                  label={t("field.cross_selling")}
                  desc={airportCrossSelling}
                  size={{ label: 3, desc: 8 }}
                />
              )}
              {dataDetail?.type_label === "Transfer" ? (
                (data = [
                  {
                    label: t("field.origin"),
                    desc: `${dataDetail?.airport_code_from} - ${dataDetail?.airport_city_from} - ${dataDetail?.airport_name_from}  ${dataDetail?.airport_country_from !== "Indonesia" && dataDetail?.airport_country_from !== undefined  ? '-' + dataDetail?.airport_country_from : '' }`,
                  },
                  {
                    label: `${t("field.transfer")}`,
                    desc: `${dataDetail?.airport_code_transfer} - ${dataDetail?.airport_city_transfer} - ${dataDetail?.airport_name_transfer}  ${dataDetail?.airport_country_transfer && dataDetail?.airport_country_transfer !== "Indonesia" ? '-' + dataDetail?.airport_country_transfer : '' }`,
                  },
                  {
                    label: t("field.destination"),
                    desc: `${dataDetail?.airport_code_to} - ${dataDetail?.airport_city_to} - ${dataDetail?.airport_name_to} ${dataDetail?.airport_country_to && dataDetail?.airport_country_to !== "Indonesia" ? '-' + dataDetail?.airport_country_to : '' }`,
                  },
                  {
                    label: t("field.arrivalTime"),
                    desc:
                      moment(dataDetail?.date_transfer_to).format(
                        "DD/MM/YYYY"
                      ) +
                      " " +
                      dataDetail?.time,
                  },
                  {
                    label: "Estimate Time Departure",
                    desc: `${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${dataDetail?.time
                      }`,
                  },
                ].map((item, i) => (
                  <CardFlush
                    list
                    label={item.label}
                    desc={item.desc}
                    size={{ label: 3, desc: 8 }}
                  />
                )))
              ) : (
                <>
                  <CardFlush
                    list
                    label={t("field.origin")}
                    desc={`${dataDetail?.airport_code_from} - ${dataDetail?.airport_city_from} - ${dataDetail?.airport_name_from}  ${dataDetail?.airport_country_from && dataDetail?.airport_country_from !== "Indonesia" ? '-' + dataDetail?.airport_country_from : '' }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={t("field.destination")}
                    desc={`${dataDetail?.airport_code_to} - ${dataDetail?.airport_city_to} - ${dataDetail?.airport_name_to} ${dataDetail?.airport_country_to && dataDetail?.airport_country_to !== "Indonesia" ? '-' + dataDetail?.airport_country_to : '' }`}
                    size={{ label: 3, desc: 8 }}
                  />
                  <CardFlush
                    list
                    label={
                      dataDetail?.type_label_eng === "Departure"
                        ? t("field.departureTime")
                        : t("field.arrivalTime")
                    }
                    desc={`${moment(dataDetail?.date).format("DD/MM/YYYY")}, ${dataDetail?.time
                      }`}
                    size={{ label: 3, desc: 8 }}
                  />
                </>
              )}
              <CardFlush
                list
                label={t("field.note")}
                desc={(
                  <div className="d-flex justify-content-between">
                    {dataDetail?.passenger_notes ?? "-"}
                    {adminBooking &&
                      currentUser.user.airport_id !== dataDetail?.airport_id_cross_selling && (
                      <div
                        onClick={() => {
                          setShowEditNotes(!showEditNotes);
                          setPassengerNotes(dataDetail?.passenger_notes ?? "");
                        }}
                        className="ml-auto text-right left-icon float-left rounded-fill"
                        style={{ padding: "4px 6px 1px 6px" }}
                      >
                        <span
                          className="material-icons clickable"
                          style={{ fontSize: "15px", color: "#63ae5c" }}
                        >
                          edit
                        </span>
                      </div>
                    )}
                  </div>
                )}
                size={{ label: 3, desc: 9 }}
                last
              />
            </div>
            <div className="wrapper-detail-card">
              <div
                className="text-extra-bold"
                style={{ padding: 15, opacity: 0.5 }}
              >
                {t("field.additionalService")}
              </div>
              <Divider orientation="horizontal" />
                <CardFlush
                  addtService
                  isLounge={dataLounge?.length > 0}
                  isTransport={dataTransports?.length > 0}
                  isOther={dataOther?.length > 0}
                  nameLounge={dataLounge?.length ? dataLounge[0]?.name : t("commons.none")}
                  nameTransport={ dataTransports?.length ? dataTransports[0]?.labelName : t("commons.none")}
                  descOther={dataOther?.length ? dataOther[0]?.name : t("commons.none")}
                  actionsLounge={getActionsAdtService(listActionLounge)}
                  actionsTransport={getActionsAdtService(listActionTransports)}
                  actionsOther={getActionsAdtService(listActionOther)}
                />
            </div>
            <DetailPassenger
              dataDetail={dataDetail}
              currentUser={currentUser}
              handleRefresh={handleRefresh}
              match={match}
            />
            {dataDetail?.payment_proof !== null && (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{ 
                    paddingTop: 15, 
                    paddingBottom: 15, 
                    paddingLeft: 25, 
                    paddingRight: 25, 
                  }}
                >
                  <Row>
                    <Col md={6} 
                      style={{ opacity: 0.5 }}
                    >
                      {t("booking.paymentProof")}
                    </Col>
                    <Col md={6}>
                      <div className="d-flex justify-content-end">
                        <a className="link text-bold" 
                          style={{ 
                            fontSize: 14, 
                            opacity: 1,
                            cursor: "pointer"
                          }}
                          onClick={showPaymentProof}
                        >
                          {t("booking.openPaymentProof")}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Divider orientation="horizontal" />
                <CardFlush
                  list
                  label={t("booking.paymentType")}
                  desc={
                    dataDetail?.payment_type ? 
                    dataDetail?.payment_type : 
                    "-"
                  }
                  size={{ label: 6, desc: 6 }}
                  descEnd
                />
              </div>
            )}
            {(dataDetail?.status === 5 &&
              dataDetail?.evidences?.length) ? (
              <div className="wrapper-detail-card">
                <div
                  className="text-extra-bold"
                  style={{ 
                    paddingTop: 15, 
                    paddingBottom: 15, 
                    paddingLeft: 25, 
                    paddingRight: 25, 
                  }}
                >
                  <Row>
                    <Col md={3} 
                      style={{ opacity: 0.5 }}
                    >
                      {t("booking.evidenceAssistant")}
                    </Col>
                    <Col md={9}>
                      <div className="d-flex justify-content-end">
                        <a className="link text-bold" 
                          style={{ 
                            fontSize: 14, 
                            opacity: 1,
                            cursor: "pointer"
                          }}
                          onClick={showEvidenceAssistant}
                        >
                          {t("booking.openEvidenceAssistant")}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : <></>}
          </div>
        </div>
      </div>
      <AdditionalServiceModal
        show={addAdditionalServiceModal}
        toggle={() => {
          setAddAdditionalServiceModal(!addAdditionalServiceModal);
          handleRefresh();
        }}
        listPassegers={dataDetail?.list_order_passengers}
        listLounge={productLounge?.length ? productLounge : airportLounge}
        isCorporateLounge={productLounge?.length ? true : false}
        listTransportProduct={productTransports}
        order_id={dataDetail?.id}
        isChangeLounge={serviceName === "lounge" ? true : false}
        isChangeTransport={serviceName === "transport" ? true : false}
        isChangeOther={serviceName === "other" ? true : false}
      />
      <AssignAssistantModal
        show={assignModal}
        toggle={showAssignModal}
        action={handleEdit}
        id={match?.params?.id}
        data={dataDetail}
      />
      <AssistantHistoryModal
        show={historyModal}
        toggle={showHistoryModal}
        action={handleEdit}
        id={match?.params?.id}
        data={assistant}
      />
      <EvidenceAssistantModal
        show={evidenceAssistant}
        title={t("booking.evidenceAssistant")}
        toggle={() => setEvidenceAssistant(!evidenceAssistant)}
        images={dataDetail?.evidences}
      />
      <PaymentProofModal
        show={paymentProof}
        toggle={() => setPaymentProof(!paymentProof)}
        image={
          process.env.REACT_APP_API_URL +
          dataDetail?.payment_proof?.substr(
            1,
            dataDetail?.payment_proof?.length
          )
        }
      />
      <ConfirmationModal
        show={confirmModal}
        toggle={()=> setConfirmModal(!confirmModal)}
        confirm={deleteAdditionalService}
        icon="info"
        message={`${t("confirm.deleteAdditionalService")} `}
        disabledConfirm={loadingSubmit}
      />
      <EditNotesModal
        withAction
        show={showEditNotes}
        toggle={() => {
          setShowEditNotes(!showEditNotes);
          setPassengerNotes("")
        }}
        title={`Edit ${t("field.passengerNotes")}`}
        confirmTitle={t("commons.save")}
        confirm={() => setShowConfirmEditNotes(!showConfirmEditNotes)}
        content={
          <div className="form-group" style={{ width: "100%" }}>
            <label className="form-label">
              {t("field.passengerNotes")}
            </label>
            <textarea
              className="form-control"
              value={passengerNotes}
              placeholder={`${t("commons.input")} ${t("field.note")}`}
              style={{
                background: 'white 0% 0% no-repeat padding-box',
                borderRadius: 6,
                paddingLeft: 12,
              }}
              rows={3}
              onChange={(e) => setPassengerNotes(e?.target?.value)}
            />
          </div>
        }
      />
      <ConfirmationModal
        show={showConfirmEditNotes}
        toggle={() => setShowConfirmEditNotes(!showConfirmEditNotes)}
        confirm={handleEditNotes}
        icon="info"
        message={`${t("commons.saveConfirm")}`}
        isDelete
        disabledConfirm={loadingSubmit}
      />
    </div>
  );
};

const mapStateToProps = ({ booking: { error }, corp_reserv: { error_delete } }) => {
  return { error, error_delete };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(DetailOperational)
);
