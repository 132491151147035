import { Col } from "reactstrap";
import moment from "moment";
import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";

const TransferFlightInformation = ({
  checkedCrossSelling,
  haveAirport,
  formik,
  minDate,
  airline_list,
  airline_transfer,
  airport_list,
  airport_list_indo,
  airport_list_transfer,
  dataAdminBandara,
  dataAirport,
  dataAirportDomestic,
  dataAirportTransfer,
  debouncedResults,
  setSearchTextAirline,
  setSearchTextAirlineTransfer,
  t
}) => {

  return (
    <Card rounded>
      <CardHeader title={t("field.arrivalInformation")} />
      <CardBody>
        <div className="d-flex justify-content-between">
          <div className="text-bold">{t("field.origin")}</div>
          <Col md={8}>
            <Select2
              name="airport_id_from"
              options={airport_list}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.airport_id_from}
              onInputChange={(keyword) => {
                debouncedResults(keyword, "inter");
              }}
              onChange={(name, value) => {
                if (
                  formik.values?.airport_id_to === value ||
                  formik.values?.airport_id_transfer === value
                ) {
                  toastError(`${t("field.cantSameAirport")}!`);
                  return;
                }
                formik.setFieldValue("airport_id_from", value);
                const index = dataAirport
                  ?.map(function (e) {
                    return e.id;
                  })
                  .indexOf(value);

                formik.setFieldValue(
                  "airport_name_from",
                  `${dataAirport[index]?.code} - ${
                    dataAirport[index]?.city} - ${
                    dataAirport[index]?.name}`
                );
                formik.setFieldValue(
                  "airport_uniform_from",
                  dataAirport[index]?.uniform
                );
                formik?.setFieldValue(
                  "selectedAirport", 
                  {
                    value: value,
                    label:
                      `${dataAirport[index]?.code} - ${
                        dataAirport[index]?.city} - ${
                        dataAirport[index]?.name} ${
                        dataAirport[index]?.uniform === "Indonesia"
                          ? ""
                          :`- ${dataAirport[index]?.uniform}`
                      }`,
                  }
                );
              }}
              onBlur={formik.setFieldTouched}
            />
          </Col>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-bold">Transfer</div>
          <Col md={8}>
            <Select2
              name="airport_id_transfer"
              errors={formik.errors}
              options={
                !checkedCrossSelling && haveAirport
                  ? dataAdminBandara
                  : airport_list_indo
              }
              touched={formik.touched}
              value={formik.values?.airport_id_transfer}
              onInputChange={(keyword) => {
                debouncedResults(keyword, "domestic");
              }}
              onChange={(name, value) => {
                if (
                  formik.values.airport_id_to === value ||
                  formik.values.airport_id_from === value
                ) {
                  toastError(`${t("field.cantSameAirport")}!`);
                  return;
                }

                formik.setFieldValue("airport_id_transfer", value);
                const list_airports = 
                  !checkedCrossSelling && haveAirport
                    ? dataAdminBandara
                    : dataAirportDomestic
                const index = list_airports
                  ?.map(function (e) {
                    return e.id;
                  })
                  .indexOf(value);

                formik.setFieldValue(
                  "airport_name_transfer",
                  `${list_airports[index]?.code} - ${
                    list_airports[index]?.city} - ${
                    list_airports[index]?.name}`
                );
                formik.setFieldValue("airport_uniform_transfer", "");
                formik.setFieldValue(
                  "airport_uniform_transfer",
                  list_airports[index]?.uniform
                );
                formik?.setFieldValue(
                  "selectedAirportDomestic", 
                  {
                    value: value,
                    label:
                      `${list_airports[index]?.code} - ${
                        list_airports[index]?.city} - ${
                        list_airports[index]?.name} ${
                        list_airports[index]?.uniform ===
                        "Indonesia"
                          ? ""
                          : `- ${list_airports[index]?.uniform}`
                      }`,
                  }
                );
              }}
              onBlur={formik.setFieldTouched}
            />
          </Col>
        </div>
      </CardBody>
      <hr />
      <CardBody>
        <div className="row">
          <Col md={6}>
            <Select2
              title={t("field.airline")}
              name="airplane_name"
              options={airline_list}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.airplane_name}
              onInputChange={(keyword) => {
                if (keyword !== "") {
                  setSearchTextAirline(keyword);
                }
              }}
              onChange={(name, value) => {
                formik.setFieldValue("airplane_name", value);
                let airplaneCode = airline_list.filter(function (el) {
                  return el.value == value;
                });
                formik.setFieldValue(
                  "airplane_code",
                  airplaneCode[0]?.code
                );
                formik.setFieldValue("selectedAirline", {
                  value: value,
                  code: airplaneCode[0]?.code,
                  label: `${airplaneCode[0]?.code} - ${value}`,
                });
              }}
              onBlur={formik.setFieldTouched}
            />
          </Col>
          <Col md={6}>
            <div className="form-group" style={{ marginBottom: "0" }}>
              <label
                className="form-label"
                style={{ marginBottom: "5px" }}
              >
                {t("field.flightNumber")}
              </label>
            </div>
            <InputPrepend
              name="airplane_number"
              label={t("field.flightNumber")}
              title={formik.values.airplane_code}
              type="text"
              width="25%"
              mt="2"
              touched={formik.touched}
              {...formik.getFieldProps("airplane_number")}
            />
          </Col>
          <Col md={6}>
            <Input
              title={t("field.arrivalDate")}
              name="date"
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps("date")}
              min={
                checkedCrossSelling
                  ? moment().add(1, "days").format("YYYY-MM-DD")
                  : minDate
              }
              type="date"
            />
          </Col>
          <Col md={6}>
            <Input
              title={t("field.arrivalTime")}
              name="time"
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps("time")}
              type="time"
            />
          </Col>
        </div>
      </CardBody>
      <hr />
      <CardHeader title={t("field.departureInformation")} />
      <CardBody>
        <div className="d-flex justify-content-between">
          <div className="text-bold">{t("field.destination")}</div>
          <Col md={8}>
            <Select2
              name="airport_id_to"
              options={airport_list_transfer}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.airport_id_to}
              onInputChange={(keyword) => {
                debouncedResults(keyword, "transfer");
              }}
              onChange={(name, value) => {
                if (
                  formik.values?.airport_id_from === value ||
                  formik.values?.airport_id_transfer === value
                ) {
                  toastError(`${t("field.cantSameAirport")}!`);
                  return;
                }

                formik.setFieldValue("airport_id_to", value);
                const index = dataAirportTransfer
                  ?.map(function (e) {
                    return e.id;
                  })
                  .indexOf(value);

                formik.setFieldValue(
                  "airport_name_to",
                  `${dataAirportTransfer[index]?.code} - ${
                    dataAirportTransfer[index]?.city} - ${
                    dataAirportTransfer[index]?.name}`
                );
                formik.setFieldValue(
                  "airport_uniform_to",
                  dataAirportTransfer[index]?.uniform
                );
                formik?.setFieldValue(
                  "selectedAirportTransfer", 
                  {
                    value: value,
                    label:
                      `${dataAirportTransfer[index]?.code} - ${
                        dataAirportTransfer[index]?.city} - ${
                        dataAirportTransfer[index]?.name} ${
                        dataAirportTransfer[index]?.uniform === "Indonesia"
                          ? ""
                          : `- ${dataAirportTransfer[index]?.uniform}`
                      }`,
                  }
                );
              }}
              onBlur={formik.setFieldTouched}
            />
          </Col>
        </div>
      </CardBody>
      <hr />
      <CardBody>
        <div className="row">
          <Col md={6}>
            <Select2
              title={t("field.airline")}
              name="airplane_name_transfer_to"
              options={airline_transfer}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.airplane_name_transfer_to}
              onInputChange={(keyword) => {
                if (keyword !== "") {
                  setSearchTextAirlineTransfer(keyword);
                }
              }}
              onChange={(name, value) => {
                formik.setFieldValue("airplane_name_transfer_to", value);
                let airplaneTransferCode = airline_transfer.filter(
                  function (el) {
                    return el.value == value;
                  }
                );
                formik.setFieldValue(
                  "airplane_code_transfer",
                  airplaneTransferCode[0]?.code
                );
                formik.setFieldValue("selectedAirlineTransfer", {
                  value: value,
                  code: airplaneTransferCode[0]?.code,
                  label: `${airplaneTransferCode[0]?.code} - ${value}`,
                });
              }}
              onBlur={formik.setFieldTouched}
            />
          </Col>
          <Col md={6}>
            <div className="form-group" style={{ marginBottom: "0" }}>
              <label
                className="form-label"
                style={{ marginBottom: "5px" }}
              >
                {t("field.flightNumber")}
              </label>
            </div>
            <InputPrepend
              name="airplane_number_transfer_to"
              label={t("field.flightNumber")}
              title={formik.values.airplane_code_transfer}
              type="text"
              width="25%"
              mt="2"
              errors={formik.errors}
              touched={formik.touched}
              {...formik.getFieldProps("airplane_number_transfer_to")}
            />
          </Col>
          <Col md={6}>
            <Input
              title={t("field.departureDate")}
              name="date_transfer_to"
              errors={formik.errors}
              min={formik.values?.date}
              touched={formik.touched}
              value={formik.values?.date_transfer_to}
              {...formik.getFieldProps("date_transfer_to")}
              type="date"
              onChange={(e) => {
                let value = e.target.value;
                formik.setFieldValue("date_transfer_to", value);
              }}
              disabled={!formik.values.date || !formik.values.time}
            />
          </Col>
          <Col md={6}>
            <Input
              title={t("field.departureTime")}
              name="time_transfer_to"
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values?.time_transfer_to}
              onChange={(e) => {
                let value = e.target.value;
                formik.setFieldValue("time_transfer_to", value);
              }}
              disabled={
                formik.values.date_transfer_to === null ||
                formik.values.date_transfer_to === "" ||
                formik.values.date_transfer_to === undefined
              }
              type="time"
            />
          </Col>
        </div>
      </CardBody>
    </Card>
  )
}

export default withTrans(TransferFlightInformation); 