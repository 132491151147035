import Card from "../../../../components/commons/cards/card";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import { withTrans } from "../../../../i18n/withTrans";
import { Role } from "../../../../utils/Constants";
import { getUser } from "../../../../utils/User";

const Lounge = ({ t, formik, add, toggle }) => {
  const currentUser = getUser();

  const handleDetailLounge = (index) => {
    if (toggle) {
      toggle(formik, index)
    }
  }

  return (
    <>
      <Card>
        {
          formik.values.lounges.length < 1 && (
            <CardFlush
              list
              label={t('commons.no_data')}
              size={{ label: 12 }}
            />
          )
        }
        {
          formik.values.lounges?.map((item, index) => {
            return (
              <CardFlush
                list
                label={item?.name}
                button={
                  <div
                    className="d-flex justify-content-end"
                    onClick={() => handleDetailLounge(index)}
                  >
                    <span className="material-icons-round right-icon">
                      navigate_next
                    </span>
                  </div>
                }
                key={index}
                size={{ label: 7, desc: 5 }}
              />
            )
          })
        }
        {currentUser?.user.role_code === Role.Central_admin && (
          <div 
            style={{ 
              color: '#63ae5c', 
              padding: '10px 15px', 
              display: 'flex', 
              cursor: 'pointer' 
            }} 
            onClick={() => add(formik)}
          >
            <div
              style={{ 
                borderRadius: '50%', 
                border: 'solid 1px #63ae5c', 
                width: 30, 
                height: 30, 
                marginRight: 20, 
                textAlign: 'center' 
              }}
            >
              <span className="material-icons-round">add</span>
            </div>
            <p 
              className="text-bold" 
              style={{ 
                fontSize: 14, 
                margin: 0, 
                alignSelf: 'center' 
              }}
            >
              {t("commons.add") + " " + t("field.lounge")}
            </p>
          </div>
        )}
      </Card>
    </>
  )
}

export default withTrans(Lounge)