import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import moment from "moment";

import InputPrepend from "../../../components/forms/InputPrepend";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import Card from "../../../components/commons/cards/card";
import CardFlush from "../../../components/commons/cards/cardFlush";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import { withTrans } from "../../../i18n/withTrans";
import { toastError } from "../../../components/commons/toast";
import { getUser } from "../../../utils/User";

import AirlineService from "../../../store/actions/master_data/airline";

const RescheduleBookingModal = ({
  t,
  show,
  toggle,
  action,
  data,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const [confirmModal, setConfirmModal] = useState(false);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [togglePreviousFlight, setTogglePreviousFlight] = useState(false);
  const [toggleRescheduleFlight, setToggleRescheduleFlight] = useState(false);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);

  const initial = {
    id: data?.id,
    unique_transaction_id: data?.unique_transaction_id,
    date: "",
    time: "",
    date_transfer_to: "",
    time_transfer_to: "",
    airplane_code: "",
    airplane_code_transfer: "",
    airplane_name: "",
    airplane_name_transfer_to: "",
    airplane_number: "",
    airplane_number_transfer_to: "",
    selectedAirline: {},
    selectedAirlineTransfer: {},
  };

  const validation = Yup.object().shape({});

  const getDate = role === "admin_pusat"
    ? moment().format("YYYY-MM-DD")
    : (role === "contact_center" || role === "lead_contact_center")
    ? null
    : moment().subtract(1, 'days').format("YYYY-MM-DD");

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(
        res?.data?.map((item) => {
          return {
            value: item?.name,
            code: item?.code,
            label: `${item?.code} - ${item?.name}`,
          }
        })
      );
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirlineTransfer(
        res?.data?.map((item) => {
          return {
            value: item?.name,
            code: item?.code,
            label: `${item?.code} - ${item?.name}`,
          }
        })
      );
    });
  }, [searchTextAirlineTransfer]);

  const showConfirmModal = (values) => {
    setConfirmModal(!confirmModal);
    
    if (values) {
      setRescheduleData(values);
    }
  };

  const handleReschedule = () => {
    let values = rescheduleData;
    let isInvalidTime = false;

    if (data?.type_label_eng === "Transfer") {
      let time = moment(
        `${values?.date}, ${values?.time}`, 
        "YYYY-MM-DD HH:mm"
      )?.diff(moment().startOf("day"), "minutes");
  
      let timepick = moment(
        `${values?.date_transfer_to}, ${values?.time_transfer_to}`,
        "YYYY-MM-DD HH:mm"
      )?.diff(moment().startOf("day"), "minutes");
  
      if (time > timepick) {
        isInvalidTime = true;
      }
    }

    if (isInvalidTime) {
      toastError(t("commons.transfer_message"));
      return;
    } else if (
      values.date !== "" &&
      values.date !== null &&
      values?.date < getDate
    ) {
      toastError(
        `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
      );
      return;
    } else if (
      data?.type_label_eng === "Transfer" &&
      values.date_transfer_to !== "" &&
      values.date_transfer_to !== null &&
      values?.date_transfer_to < getDate
    ) {
      toastError(
        `${t("commons.orderDateHandle")} ${moment(getDate).format("DD/MM/YYYY")}!`
      );
      return;
    } else if (
      (values?.date === "" || values?.date === null) &&
      (values?.time === "" || values?.time === null) &&
      (values?.airplane_code === "" || values?.airplane_code === null) &&
      (values?.airplane_name === "" || values?.airplane_name === null) &&
      (values?.airplane_number === "" || values?.airplane_number === null)
    ) {
      if (
        data?.type_label_eng === "Transfer" &&
        (values?.date_transfer_to === "" || values?.date_transfer_to === null) &&
        (values?.time_transfer_to === "" || values?.time_transfer_to === null) &&
        (values?.airplane_code_transfer === "" || values?.airplane_code_transfer === null) &&
        (values?.airplane_name_transfer_to === "" || values?.airplane_name_transfer_to === null) &&
        (values?.airplane_number_transfer_to === "" || values?.airplane_number_transfer_to === null)
      ) {
        toastError(
          `${t("commons.empty_data_message")} ${t("field.reschedule")}!`
        );
        return;
      }
      toastError(
        `${t("commons.empty_data_message")} ${t("field.reschedule")}!`
      );
      return;
    } else {
      let param = {
        order_id: data?.id,
        airplane_code:
          values?.airplane_code === "" 
            ? data?.airplane_code 
            : values?.airplane_code,
        airplane_code_transfer:
          values?.airplane_code_transfer === "" 
            ? data?.airplane_code_transfer
            : values?.airplane_code_transfer,
        airplane_name:
          values?.airplane_name === "" 
            ? data?.airplane_name 
            : values?.airplane_name,
        airplane_name_transfer_to:
          values?.airplane_name_transfer_to === ""
            ? data?.airplane_name_transfer_to
            : values?.airplane_name_transfer_to,
        airplane_number:
          values?.airplane_number === ""
            ? data?.airplane_number
            : values?.airplane_number,
        airplane_number_transfer_to:
          values?.airplane_number_transfer_to === "" 
            ? data?.airplane_number_transfer_to
            : values?.airplane_number_transfer_to,
      };

      if(data?.type_label_eng === 'Arrival') {
        param.date_arrival = values?.date === "" ? data?.date : values?.date;
        param.time_arrival = values?.time === "" ? data?.time : values?.time;
      } else if (data?.type_label_eng === 'Departure') {
        param.date_departure = values?.date === "" ? data?.date : values?.date;
        param.time_departure = values?.time === "" ? data?.time : values?.time;
      } else {
        param.date_arrival = values?.date === "" ? data?.date : values?.date;
        param.time_arrival = values?.time === "" ? data?.time : values?.time;
        param.date_departure =
          values?.date_transfer_to === ""
            ? data?.date_transfer_to
            : values?.date_transfer_to;
        param.time_departure =
          values?.time_transfer_to === ""
            ? data?.time_transfer_to
            : values?.time_transfer_to;
      }
  
      action(param);
      setConfirmModal(!confirmModal);
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" Reschedule Booking"}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Container>
                  <Card className="rounded">
                    <div style={{ fontSize: 15 }}>
                      <div className="text-bold text-primary mt-2" style={{ padding: 15 }}>
                        Booking Information
                      </div>
                      <CardFlush
                        list
                        label="ID Booking"
                        desc={data?.unique_transaction_id}
                      />
                      <CardFlush
                        list
                        label={t("field.product")}
                        desc={data?.product_name_eng}
                      />
                    </div>
                    <div style={{ borderBottom: "1px solid #e5e5f0", padding: 15, fontSize: 15 }}>
                      <div
                        className="my-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div className="text-bold text-primary">
                          Previous Flight Information
                        </div>
                        <div
                          onClick={() => setTogglePreviousFlight(!togglePreviousFlight)}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="material-icons-round left-icon">
                            expand_more
                          </span>
                        </div>
                      </div>
                      {togglePreviousFlight && (
                        <>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="date"
                              type="date"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.date}
                              title={data?.type_label_eng === "Departure" ? t("field.departureDate") : t("field.arrivalDate")}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input
                              name="time"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.time}
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.departureTime") 
                                : t("field.arrivalTime")
                              }
                              type="time"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="airplane_name"
                              type="text"
                              disabled
                              errors={props.errors}
                              touched={props.touched}
                              value={data?.airplane_name}
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.deptAirline") 
                                : t("field.arrvAirline")
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: "0" }}>
                              <label
                                className="form-label"
                                style={{ marginBottom: "5px" }}
                              >
                                {t("field.flightNumber")}
                              </label>
                            </div>
                            <InputPrepend
                              name="airplane_number"
                              label={t("field.flightNumber")}
                              title={data?.airplane_code}
                              value={data?.airplane_number}
                              type="text"
                              width="25%"
                              mt="2"
                              disabled
                            />
                          </div>
                        </div>
                        {data?.type_label_eng === "Transfer" && (
                          <>
                            <hr style={{ marginTop: '0.5rem' }} />
                            <div className="row">
                              <div className="col-md-6">
                                <Input
                                  name="date_transfer_to"
                                  type="date"
                                  disabled
                                  errors={props.errors}
                                  touched={props.touched}
                                  value={data?.date_transfer_to}
                                  title={t("field.departureDate")}
                                />
                              </div>
                              <div className="col-md-6">
                                <Input
                                  name="time_transfer_to"
                                  disabled
                                  errors={props.errors}
                                  touched={props.touched}
                                  value={data?.time_transfer_to}
                                  title="Estimate Time Departure"
                                  type="time"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <Input
                                  name="airplane_name_transfer_to"
                                  type="text"
                                  disabled
                                  errors={props.errors}
                                  touched={props.touched}
                                  value={data?.airplane_name_transfer_to}
                                  title={t("field.deptAirline")}
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="form-group" style={{ marginBottom: "0" }}>
                                <label
                                  className="form-label"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {t("field.flightNumber")}
                                </label>
                              </div>
                                <InputPrepend
                                  disabled
                                  name="airplane_number"
                                  label={t("field.flightNumber")}
                                  title={data?.airplane_code_transfer}
                                  value={data?.airplane_number_transfer_to}
                                  type="text"
                                  width="25%"
                                  mt="2"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        </>
                      )}
                    </div>
                    <div style={{ padding: 15, fontSize: 15 }}>
                      <div
                        className="my-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div className="text-bold text-primary my-3">
                          Rescheduled Flight Information
                        </div>
                        <div
                          onClick={() => setToggleRescheduleFlight(!toggleRescheduleFlight)}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="material-icons-round left-icon">
                            expand_more
                          </span>
                        </div>
                      </div>
                      {toggleRescheduleFlight && (
                        <>
                        <div className="row">
                          <div className="col-md-6">
                            <Input
                              name="date"
                              type="date"
                              min={getDate}
                              errors={props.errors}
                              touched={props.touched}
                              {...props.getFieldProps("date")}
                              title={data?.type_label_eng === "Departure" ? t("field.departureDate") : t("field.arrivalDate")}
                            />
                          </div>
                          <div className="col-md-6">
                            <Input
                              name="time"
                              errors={props.errors}
                              touched={props.touched}
                              {...props.getFieldProps("time")}
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.departureTime")
                                : t("field.arrivalTime")
                              }
                              type="time"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Select2
                              title={data?.type_label_eng === "Departure" 
                                ? t("field.deptAirline") 
                                : t("field.arrvAirline")
                              }
                              name="airplane_name"
                              options={dataAirline}
                              errors={props.errors}
                              touched={props.touched}
                              value={props.values?.airplane_name}
                              onInputChange={(keyword) => {
                                if (keyword !== "") {
                                  setSearchTextAirline(keyword);
                                }
                              }}
                              onChange={(name, value) => {
                                props.setFieldValue("airplane_name", value);
                                let airplaneCode = dataAirline?.filter(function (el) {
                                  return el.value == value;
                                });
                                props.setFieldValue(
                                  "airplane_code",
                                  airplaneCode[0].code
                                );
                                props.setFieldValue("selectedAirline", {
                                  value: value,
                                  code: airplaneCode[0].code,
                                  label: `${airplaneCode[0].code} - ${value}`,
                                });
                              }}
                              onBlur={props.setFieldTouched}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{ marginBottom: "0" }}>
                              <label
                                className="form-label"
                                style={{ marginBottom: "5px" }}
                              >
                                {t("field.flightNumber")}
                              </label>
                            </div>
                            <InputPrepend
                              name="airplane_number"
                              label={t("field.flightNumber")}
                              title={props.values.airplane_code}
                              type="text"
                              width="25%"
                              mt="2"
                              touched={props.touched}
                              {...props.getFieldProps("airplane_number")}
                            />
                          </div>
                        </div>
                        {data?.type_label_eng === "Transfer" && (
                          <>
                            <hr style={{ marginTop: '0.5rem' }} />
                            <div className="row">
                              <div className="col-md-6">
                                <Input
                                  title={t("field.departureDate")}
                                  name="date_transfer_to"
                                  type="date"
                                  min={props?.values?.date}
                                  errors={props.errors}
                                  touched={props.touched}
                                  {...props.getFieldProps("date_transfer_to")}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    props.setFieldValue("date_transfer_to", value);
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <Input
                                  name="time_transfer_to"
                                  title="Estimate Time Departure"
                                  type="time"
                                  errors={props.errors}
                                  touched={props.touched}
                                  {...props.getFieldProps("time_transfer_to")}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    props.setFieldValue("time_transfer_to", value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <Select2
                                  title={t("field.deptAirline")}
                                  name="airplane_name_transfer_to"
                                  options={dataAirlineTransfer}
                                  errors={props.errors}
                                  touched={props.touched}
                                  value={props.values?.airplane_name_transfer_to}
                                  onInputChange={(keyword) => {
                                    if (keyword !== "") {
                                      setSearchTextAirlineTransfer(keyword);
                                    }
                                  }}
                                  onChange={(name, value) => {
                                    props.setFieldValue("airplane_name_transfer_to", value);
                                    let airplaneTransferCode = dataAirlineTransfer?.filter(
                                      function (el) {
                                        return el.value == value;
                                      }
                                    );
                                    props.setFieldValue(
                                      "airplane_code_transfer",
                                      airplaneTransferCode[0].code
                                    );
                                    props.setFieldValue("selectedAirlineTransfer", {
                                      value: value,
                                      code: airplaneTransferCode[0].code,
                                      label: `${airplaneTransferCode[0].code} - ${value}`,
                                    });
                                  }}
                                  onBlur={props.setFieldTouched}
                                />
                              </div>
                              <div className="col-md-6">
                                <div className="form-group" style={{ marginBottom: "0" }}>
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: "5px" }}
                                  >
                                    {t("field.flightNumber")}
                                  </label>
                                </div>
                                <InputPrepend
                                  name="airplane_number_transfer_to"
                                  label={t("field.flightNumber")}
                                  title={props.values.airplane_code_transfer}
                                  type="text"
                                  width="25%"
                                  mt="2"
                                  touched={props.touched}
                                  {...props.getFieldProps("airplane_number_transfer_to")}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        </>
                      )}
                    </div>
                  </Card>
                </Container>
              </ModalBody>
              <ModalFooter>
                <ButtonModal
                  toggle={toggle}
                  confirm={() => showConfirmModal(props?.values)}
                  confirmTitle={t("commons.save")}
                  typeConfirm={"Button"}
                  // disabledConfirm={props.isSubmitting}
                  icon
                />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={showConfirmModal}
        confirm={handleReschedule}
        messageData={`` + data?.unique_transaction_id}
        icon="info"
        message={`Are you sure reschedule booking `}
      />
    </>
  );
};

export default withTrans(RescheduleBookingModal);
