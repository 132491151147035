import { combineReducers } from "redux";
import auth from "./auth";
import promo from "./promo";
import report from "./report";
import booking from "./booking";
import dashboard from "./dashboard";
import { data_analytics, data_pareto } from "./data_analytics";
import user from "./master_data/user";
import role from "./master_data/role";
import city from "./master_data/city";
import audit_trail from "./audit_trail";
import airport from "./master_data/airport";
import airline from "./master_data/airline";
import corp_reserv from "./corp_reservation";
import shift_asst from "./schedule/shift_asst";
import corporate from "./master_data/corporate";
import commission from "./master_data/commission";
import reg_price from "./product/regular_price";
import assistant from "./master_data/assistant";
import operational from "./schedule/operational";
import corp_price from "./product/corporate_price";
import reward from "./reward";

const rootReducer = combineReducers({
    auth,
    airport,
    airline,
    corporate,
    assistant,
    user,
    role,
    city,
    reg_price,
    corp_price,
    booking,
    promo,
    report,
    corp_reserv,
    shift_asst,
    operational,
    dashboard,
    audit_trail,
    commission,
    data_analytics,
    data_pareto,
    reward
});

export default rootReducer;
