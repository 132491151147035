import { useState } from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { withTrans } from "../../i18n/withTrans";
import { connect, useDispatch } from "react-redux";
import { history } from "../../utils/History";
import { getUser } from "../../utils/User";
import Input from "../../components/forms/Input";
import Button from "../../components/commons/buttons/Button";
import account from '../../assets/images/icon/joumpa-icon.png';
import * as Yup from 'yup';

import Auth from "../../store/actions/auth"

const Index = ({ t }) => {

    const dispatch = useDispatch();
    const [visibility, setVisibility] = useState(false)

    if (getUser()) {
        history.push(`/dashboard`);
    }

    const validation = Yup.object().shape({
        email: Yup.string().required('Email wajib diisi').nullable(),
        pwd: Yup.string().required('Kata sandi wajib diisi').nullable(),
    })

    return (
        <div className="main-wrapper">
            <div className="login-container">
                <div className="content-wrapper">
                    <img src={account} style={{ marginBottom: 27 }} alt="icon-joumpa"/>
                    <p className="super-title text-extra-bold">{t("auth.titleLogin")}</p>
                    <p className="subtitle text-bold" style={{ marginBottom: 50 }}>
                        {t('auth.headerLogin')}
                    </p>
                    <div className="form-wrapper form-group">
                        <Formik
                            initialValues={{
                                email: "",
                                pwd: "",
                            }}
                            validationSchema={validation}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();
                                dispatch(Auth.auth_login(values));
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Input
                                        title={t("field.email")}
                                        name="email"
                                        type="email"
                                        placeholder={t("commons.input") + "Email"}
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        {...props?.getFieldProps("email")}
                                    />
                                    <Input
                                        title={t("commons.password")}
                                        name="pwd"
                                        type={visibility ? "text" : "password"}
                                        placeholder={t("commons.input") + t("commons.password")}
                                        errors={props?.errors}
                                        touched={props?.touched}
                                        righticon={visibility ? "visibility_off" : "visibility"}
                                        toggleicon={() => setVisibility(!visibility)}
                                        {...props?.getFieldProps("pwd")}
                                    />
                                    <div
                                        className="login-option-container"
                                        style={{ display: "flex", marginTop: 26 }}
                                    >
                                        <div style={{ marginLeft: "auto" }}>
                                            <a className="link text-bold" onClick={() => history.push('forgot-password')} style={{ fontSize: 14 }}>
                                                {t("auth.forgotPassword")}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="button-wrapper">
                                        <Button
                                            title={t("auth.titleLogin")}
                                            type="submit"
                                            variant="button primary text-white"
                                            style={{ width: "100%", textAlign: "center", borderRadius: "48px", padding: "12px 0" }}
                                        />
                                    </div>
                                </Form>)}
                        </Formik>
                    </div>
                    <p className="subtitle copyright text-bold">{'Copyright © 2021 PT Gapura Angkasa'}</p>
                </div>
                <div className="side-login">
                    <div className="side-login-title text-extra-bold">
                        Airport Service for Your Journey Companion
                    </div>
                    {/* <div className="side-login-title text-extra-bold"
                        style={{ padding: '70px 85px 0 85px'}}>
                        VIP Airport Service
                    </div>
                    <div className="side-login-title text-extra-bold"
                        style={{ padding: '0 85px'}}>
                        JOUMPA
                    </div>
                    // <img src={account} style={{ padding: '0 85px 15px 85px' }} alt="icon-joumpa"/>
                    <div className="side-login-title text-extra-bold"
                        style={{ padding: '0 85px' }}>
                        Your Journey Companion
                    </div> */}
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = ({ auth: { loggingIn } }) => {
    return { loggingIn };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(withTrans(connect(mapStateToProps, mapDispatchToProps)(Index)));
