import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

import RenewalHistoryModal from "../modal/RenewalHistoryModal";
import DetailButton from "../../../../components/commons/table/detailButton";
import TableStatus from "../../../../components/commons/table/status";
import Button from "../../../../components/commons/buttons/Button";
import Loader from "../../../../components/commons/Loader";
import CardFlush from "../../../../components/commons/cards/cardFlush";
import Card from "../../../../components/commons/cards/card";
import CardHeader from "../../../../components/commons/cards/cardHeader";
import CardBody from "../../../../components/commons/cards/cardBody";
import Select2 from "../../../../components/forms/Select2";
import LabelPills from "../../../../components/commons/table/labelPills";
import LabelPlus from "../../../../components/commons/table/labelPlus";
import { history } from "../../../../utils/History";
import { withTrans } from "../../../../i18n/withTrans";

import DetailPriceCorporate from "./DetailPriceCorporate";

//Service
import CorporatePrice from "../../../../store/actions/product/corporate_price";

const Index = ({ pending, t }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dateNow = moment().format("YYYY-MM-DD");

  const { id } = useParams();

  const [listCorps, setListCorps] = useState([]);
  const [dataAirport, setDataAirport] = useState([]);
  const [idType, setIdType] = useState(0);
  const [activeTab, setActiveTab] = useState("0");
  const [indexPrice, setIndexPrice] = useState();
  const [indexCancel, setIndexCancel] = useState(null);
  const [showPageIndex, setShowPageIndex] = useState(true);
  const [showPagePrice, setShowPagePrice] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [renewalHistory, setRenewalHistory] = useState([]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        corporate_id: id,
      };
      dispatch(CorporatePrice.getById(param, resolve));
    }).then((res) => {
      if (res) {
        let list = [{
          value: res?.data?.id,
          label: res?.data?.name,
          name: res?.data?.name,
        }];
        setListCorps(list);
        setDataAirport([{
          value: res?.data?.responsible_airport_detail?.id,
          label: `${
            res?.data?.responsible_airport_detail?.code
          } - ${
            res?.data?.responsible_airport_detail?.city
          } - ${
            res?.data?.responsible_airport_detail?.name
          }`,
        }]);

        formikRef?.current?.setFieldValue("name", res?.data?.name);
        formikRef?.current?.setFieldValue("responsible_airport", res?.data?.responsible_airport);
        formikRef?.current?.setFieldValue("type_list", res?.data?.type_list);
        res?.data?.type_list?.map((item, indexType) => {
          if (item?.period_list?.length) {
            item?.period_list?.map((period, index) => {
              if (period?.product_list?.length) { 
                period?.product_list?.map((product) => {
                  // product.airport_list = product?.airport_list?.map((x) => x.airport_id);
                  product.flight_list = product?.flight_list?.map((x) => x.flight_type);
                  if (product?.tax?.concession_percentage >= 0) {
                    product.concession_percentage = product?.tax?.concession_percentage;
                  } 
                  if (product?.tax?.is_include_concession) {
                    product.is_includes_concession = ["1"];
                  }
                  if (product?.tax?.ppn_percentage >= 0) {
                    product.ppn_percentage = product?.tax?.ppn_percentage;
                  } 
                  if (product?.tax?.is_include_ppn) {
                    product.is_includes_ppn = ["1"];
                  }

                  product?.group_list?.forEach((group) => {
                    group?.property_list?.forEach((property) => {
                      if (property?.prices?.infant_is_free) {
                        property.prices.infant_is_free = ["1"];
                      }
                    });
                  });
  
                  if (product?.product_name === 1) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_meet_inter`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_inter`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_inter.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_inter.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );
                  }
                  if (product?.product_name === 2) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_meet_dom`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_dom`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_dom.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].meet_and_greet_dom.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );
                  }
                  if (product?.product_name === 3) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_fast`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].fast_track`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].fast_track.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].fast_track.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );
                  }
                  if (product?.product_name === 4) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_transfer`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transfer`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transfer.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transfer.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );
                  }
                  if (product?.product_name === 5) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_lounge`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].lounge`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].lounge.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].lounge.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );

                    if (!product?.lounge_list?.length) {
                      formikRef?.current?.setFieldValue(
                        `type_list[${indexType}].period_list[${index}].lounge.lounge_list`, 
                        [{
                          lounge_id: "",
                          adult_price: "",
                          child_price: "",
                          infant_price: "",
                        }]
                      );
                    }
                  }
                  if (product?.product_name === 6) {
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].is_checked_transport`,
                      ["1"]
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transport`,
                      product
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transport.airport_list`,
                      product?.airport_list?.map((x) => x.airport_id)
                    );
                    formikRef?.current?.setFieldValue(
                      `type_list[${indexType}].period_list[${index}].transport.airport_code_list`,
                      product?.airport_list?.map((x) => {
                        return {
                          id: x?.id,
                          name: `${x?.code}`
                        }
                      })
                    );
                  }
                })
              }
            })
          }
        })
      }
    });
  }, []);

  const initialValues = {
    id: id ?? "",
    name: "",
    responsible_airport: "",
    type_list: [],
  };
  
  const addPrice = (formik, index, idxType) => {
    if (index !== null && index !== undefined) {
      setIndexPrice(index);
      setIndexCancel(index);
    } else {
      setIndexPrice(formik?.values?.type_list[idxType]?.period_list?.length);
      setIndexCancel(null);
    }
    setShowPagePrice(!showPagePrice);
    setShowPageIndex(!showPageIndex);
  };

  const showTab = (active, props) => {
    let idx, data;

    if (active === "0") {
      idx = props?.values?.type_list
        .map((item) => {
          return item?.corporate_type;
        })
        .indexOf("Group");
      data = props?.values?.type_list[idx]?.period_list;
      setIdType(props?.values?.type_list[idx]?.id);
    } else if (active === "1") {
      idx = props?.values?.type_list
        .map((item) => {
          return item?.corporate_type;
        })
        .indexOf("Package");
      data = props?.values?.type_list[idx]?.period_list;
      setIdType(props?.values?.type_list[idx]?.id);
    } else if (active === "2") {
      idx = props?.values?.type_list
        .map((item) => {
          return item?.corporate_type;
        })
        .indexOf("MOB");
      data = props?.values?.type_list[idx]?.period_list;
      setIdType(props?.values?.type_list[idx]?.id);
    } else if (active === "3") {
      idx = props?.values?.type_list
        .map((item) => {
          return item?.corporate_type;
        })
        .indexOf("FIT");
      data = props?.values?.type_list[idx]?.period_list;
      setIdType(props?.values?.type_list[idx]?.id);
    }

    const getRenewalHistory = (id) => {
      new Promise((resolve) => {
        let param = {
          corporate_price_period_id: id
        };
        dispatch(CorporatePrice.getRenewalHistory(param, resolve));
      }).then((res) => {
        if(res?.data) {
          setRenewalHistory(res?.data);
        }
      });
      setShowHistoryModal(!showHistoryModal)
    }

    return (
      <div className="py-4">
        <Card>
          <CardHeader title={t("field.price")} />
          <CardBody>
          {data !== undefined && (
              <Row
                style={{
                  fontWeight: 800,
                  fontSize: "16px",
                  color: "rgba(36, 39, 38, 0.3)",
                  paddingBottom: 3,
                }}
              >
                <Col md={5}>
                  <Row>
                    <Col md={2} className="px-0">
                      <div className="text-center">
                        {t("commons.number")}
                      </div>
                    </Col>
                    <Col md={5}>
                      <div>
                        {t("field.product")}
                      </div>
                    </Col>
                    <Col md={5}>
                      <div>
                        {t("commons.activities")}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <Row>
                    <Col md={4}>
                      <div>
                        {t("field.airport")}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        {t("promo.startDate")}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        {t("promo.endDate")}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={2}>
                  <Row>
                    <Col md={9}>
                      <div>
                        {t("field.expiredStatus")}
                      </div>
                    </Col>
                    <Col md={3}>
                      <div></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {data?.length < 1 && (
              <CardFlush
                list
                label={t("commons.no_data")}
                size={{ label: 12 }}
              />
            )}
            {data?.map((item, index) => {
              let airports = [];
              const flights = [];
              const serviceList = [];
              if (item?.is_checked_fast == 1) {
                serviceList.push("Fast Track");
                item?.fast_track?.flight_list?.map((flight) => {
                  if (flight === 5) {
                    flights?.push(t("field.arrival"))
                  }
                  if (flight === 6) {
                    flights?.push(t("field.departure"))
                  }
                });
                item?.fast_track?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }
              if (item?.is_checked_meet_dom == 1) {
                serviceList.push("Meet and Greet Domestic");
                item?.meet_and_greet_dom?.flight_list?.map((flight) => {
                  if (flight === 5) {
                    flights?.push(t("field.arrival"))
                  }
                  if (flight === 6) {
                    flights?.push(t("field.departure"))
                  }
                });
                item?.meet_and_greet_dom?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }
              if (item?.is_checked_meet_inter == 1) {
                serviceList.push("Meet and Greet International");
                item?.meet_and_greet_inter?.flight_list?.map((flight) => {
                  if (flight === 5) {
                    flights?.push(t("field.arrival"))
                  }
                  if (flight === 6) {
                    flights?.push(t("field.departure"))
                  }
                });
                item?.meet_and_greet_inter?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }
              if (item?.is_checked_transfer == 1) {
                serviceList.push("Transfer");
                item?.transfer?.flight_list?.map((flight) => {
                  if (flight === 1) {
                    flights?.push("International to International")
                  }
                  if (flight === 2) {
                    flights?.push(t("International to Domestic"))
                  }
                  if (flight === 3) {
                    flights?.push(t("Domestic to International"))
                  }
                  if (flight === 4) {
                    flights?.push(t("Domestic to Domestic"))
                  }
                });
                item?.transfer?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }
              if (item?.is_checked_lounge == 1) {
                serviceList.push("Lounge");
                item?.lounge?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }
              if (item?.is_checked_transport == 1) {
                serviceList.push("Transport");
                item?.transport?.airport_code_list?.map((airport) => {
                  airports?.push(airport?.name)
                });
              }

              const flightList = [...new Set(flights)];
              airports = [...new Set(airports)];
              let uniqueAirportList = [];
              airports?.map((airport) => {
                uniqueAirportList?.push({
                  name: airport
                }) 
              });

              return (
                <Row
                  style={{
                    fontSize: "14px",
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <Col md={5}>
                    <Row>
                      <Col md={2} className="px-0">
                        <div className="text-center">
                          {index + 1}
                        </div>
                      </Col>
                      <Col md={5}>
                        <div>
                          {serviceList?.map((item, i) => (
                            <LabelPills data={item} />
                          ))}
                        </div>
                      </Col>
                      <Col md={5}>
                        <div>
                          {flightList?.length ? flightList?.map((item, i) => (
                            <LabelPills data={item} />
                          )) : "-"}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5}>
                    <Row>
                      <Col md={4}>
                        <div>
                          <LabelPlus data={uniqueAirportList} idRow={uniqueAirportList?.map((item, i) => i)}/>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>
                          {moment(item?.start_date).format("DD/MM/YYYY")}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div>
                          {moment(item?.end_date).format("DD/MM/YYYY")}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col md={9}>
                        <TableStatus
                          status={
                            item?.start_date > dateNow
                              ? t("status.notStarted")
                              : item?.end_date < dateNow
                              ? t("field.expired")
                              : moment(item?.end_date).subtract(1, 'month').format("YYYY-MM-DD") <= dateNow
                              ? t("field.expiredSoon")
                              : t("promo.active")
                          }
                          color={
                            item?.start_date > dateNow
                              ? "info"
                              : item?.end_date < dateNow
                              ? "danger"
                              : moment(item?.end_date).subtract(1, 'month').format("YYYY-MM-DD") <= dateNow
                              ? "warning"
                              : "primary"
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <DetailButton>
                          <MenuItem onClick={() => addPrice(props, index, idx)}>
                            {t("commons.detail")}
                          </MenuItem>
                          {id && data?.length && (
                            <MenuItem onClick={() => getRenewalHistory(item?.id)}>
                              {t("product.renewalHistory")}
                            </MenuItem>
                          )}
                        </DetailButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
      >
        {(props) => (
          <Form>
            <>
              {showPagePrice && (
                <DetailPriceCorporate
                  formik={props}
                  idTypeList={idType}
                  index={indexPrice}
                  indexCancel={indexCancel}
                  toggle={addPrice}
                />
              )}
              {showPageIndex && (
                <div className="menu-container">
                  {pending && <Loader loading={pending} />}
                  <div className="col-md-12 px-5">
                    <div className="d-flex align-items-center">
                      <div className="treatment-title menu-title text-extra-bold pl-0">
                        <div className="treatment-title-icon ">
                          Detail {t("field.price")} {t("field.corporation")}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <Button
                          variant="secondary"
                          rounded
                          leftIcon="close"
                          title={t("commons.back")}
                          onClick={() => 
                            history.push({ pathname: "/product", state: { tab: "corp_price" } })
                          }
                        />
                      </div>
                    </div>
                    <Card className="rounded">
                      <CardHeader title={t("field.corporateName")} />
                      <CardBody>
                        <div style={{ paddingBottom: '0.3em' }}>
                          <Select2
                            name="id"
                            options={listCorps}
                            type="text"
                            errors={props?.errors}
                            touched={props?.touched}
                            {...props?.getFieldProps("id")}
                            value={props.values.id}
                            disabled
                          />
                        </div>
                        <hr />
                        <div>
                          <p style={{
                              color: '#63ae5c',
                              fontWeight: 'bold',
                              paddingTop: '1em',
                              paddingBottom: '0.8em',
                            }}
                          >
                            {t("field.pullBranch")}
                          </p>
                          <Select2
                            name="responsible_airport"
                            options={dataAirport}
                            type="text"
                            errors={props?.errors}
                            touched={props?.touched}
                            value={props.values.responsible_airport}led={id}
                            disabled
                          />
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title={t("field.cooperationType")} />
                      {props?.values?.name && (
                        <CardBody>
                          <div
                            className="content-container-side"
                            style={{ padding: "0" }}
                          >
                            <div className="tab-menu overflow">
                              <div style={{ width: "fit-content" }}>
                                <Tabs
                                  scrollButtons="auto"
                                  variant="scrollable"
                                  value={activeTab}
                                  textColor="primary"
                                  indicatorColor="primary"
                                  onChange={(event, newValue) => {
                                    setActiveTab(newValue);
                                  }}
                                >
                                  {props?.values?.type_list?.map((item) => {
                                    return (
                                      <Tab
                                        label={item.corporate_type}
                                        value={
                                          item.corporate_type === "Group"
                                            ? "0"
                                            : item.corporate_type === "Package"
                                            ? "1"
                                            : item.corporate_type === "MOB"
                                            ? "2"
                                            : "3"
                                        }
                                      />
                                    );
                                  })}
                                </Tabs>
                              </div>
                            </div>
                            <div>{showTab(activeTab, props)}</div>
                          </div>
                        </CardBody>
                      )}
                    </Card>
                  </div>
                </div>
              )}
            </>
          </Form>
        )}
      </Formik>
      <RenewalHistoryModal
        show={showHistoryModal}
        toggle={() => setShowHistoryModal(!showHistoryModal)}
        data={renewalHistory}
      />
    </>
  );
};

const mapStateToProps = ({ airport }) => {
  return { airport };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Index));
