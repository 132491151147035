import React from "react";
import ReactPaginate from "react-paginate";

const Index = ({
  toggle,
  dataTotal,
  recordTotal,
  subInfo,
  subInfoTotal,
  subSubInfo,
  subSubInfoTotal,
  maxPage,
  page,
}) => {
  const handlePageClick = (event) => {
    if (toggle) {
      toggle(event.selected + 1);
    }
  };

  return (
    <>
      {recordTotal !== 0 && recordTotal !== null && (
        <>
          <div
            className="mini-show"
            style={{ marginTop: 33, marginBottom: 33, textAlign: "right" }}
          >
            <p style={{ margin: 0, fontSize: 14 }}>
              {localStorage.getItem("joumpa_language") === "en" ||
              localStorage.getItem("joumpa_language") === "en-US"
                ? `Show ${dataTotal ?? 0} from ${recordTotal ?? 0} ${
                    subInfoTotal ? `with ${subInfo} ${subInfoTotal}` : ""
                  } ${
                    subSubInfoTotal ? `and ${subSubInfo} ${subSubInfoTotal}` : ""
                  }`
                : `Menampilkan ${dataTotal ?? 0} dari ${recordTotal ?? 0} ${
                    subInfoTotal ? `dengan ${subInfo} ${subInfoTotal}` : ""
                  } ${
                    subSubInfoTotal ? `dan ${subSubInfo} ${subSubInfoTotal}` : ""
                  }`}
            </p>
          </div>
          <div className="mini-show">
            <div>
              <ReactPaginate
                marginPagesDisplayed={1}
                previousLabel={
                  <span className="material-icons-round">arrow_back</span>
                }
                previousLinkClassName={"page-link"}
                nextLabel={
                  <span className="material-icons-round">arrow_forward</span>
                }
                nextLinkClassName={"page-link"}
                pageCount={maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => handlePageClick(event)}
                forceSelected={page - 1}
                forcePage={page - 1}
                initialPage={page - 1}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              justifyItems: "center",
              marginTop: 33,
            }}
          >
            <div className="mini-hide">
              <p style={{ margin: 0, fontSize: 14 }}>
                {localStorage.getItem("joumpa_language") === "en" ||
                localStorage.getItem("joumpa_language") === "en-US"
                  ? `Show ${dataTotal ?? 0} from ${recordTotal ?? 0} ${
                      subInfoTotal ? `with ${subInfo} ${subInfoTotal}` : ""
                    } ${
                      subSubInfoTotal ? `and ${subSubInfo} ${subSubInfoTotal}` : ""
                    }`
                  : `Menampilkan ${dataTotal ?? 0} dari ${recordTotal ?? 0} ${
                      subInfoTotal ? `dengan ${subInfo} ${subInfoTotal}` : ""
                    } ${
                      subSubInfoTotal ? `dan ${subSubInfo} ${subSubInfoTotal}` : ""
                    }`}
              </p>
            </div>
            <div className="mini-hide">
              <ReactPaginate
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                previousLabel={
                  <span className="material-icons-round">arrow_back</span>
                }
                previousLinkClassName={"page-link"}
                nextLabel={
                  <span className="material-icons-round">arrow_forward</span>
                }
                nextLinkClassName={"page-link"}
                pageCount={maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => handlePageClick(event)}
                forceSelected={page - 1}
                forcePage={page - 1}
                initialPage={page - 1}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
