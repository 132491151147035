import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import SideMenu from "../../components/commons/menu/SideMenu";
import Pareto from "./pareto";
import Customer from "./customer";
import { withTrans } from "../../i18n/withTrans";

// Service
import AirportService from "../../store/actions/master_data/airport";

const Index = ({ t }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState("pareto");
  const [dataAirport, setDataAirport] = useState([]);

  const handleAirport = () => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(
        res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
          }`,
        }))
      );
    });
  };

  useEffect(() => {
    handleAirport();
  }, []);

  return (
    <div className="menu-container">
      <div className="grid-header">
        <div className="side-menu">
          <div className="menu-title text-extra-bold">{t("dataAnalytics.analyticsData")}</div>
            <SideMenu
              title="Pareto"
              subtitle={t("dataAnalytics.paretoAnalytics")}
              toggle={() => setShow("pareto")}
              active={show === "pareto"}
            />
            <SideMenu
              title={t("field.customer")}
              subtitle={t("dataAnalytics.customerAnalytics")}
              toggle={() => setShow("customer")}
              active={show === "customer"}
            />
        </div>
        {show === "pareto" && (
          <>
            <Pareto airport_list={dataAirport} />
          </>
        )}
        {show === "customer" && (
          <>
            <Customer />
          </>
        )}
      </div>
    </div>
  );
};

export default withTrans(Index);
