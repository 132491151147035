import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Role } from "./utils/Constants";
import PrivateRoute from "./components/privateRoute";

import CantAccess from "./views/empty_state/cant_access";
import NotFound from "./views/empty_state/NotFound";
import Index from "./views/dashboard/Dashboard";
import AnalyticsData from "./views/analytics_data";
import Login from "./views/auth/Login";
import ForgotPassword from "./views/auth/ForgotPassword";
import MasterData from "./views/master_data";
import User from "./views/user";
import DetailAirport from "./views/master_data/airport/DetailAirport";
import FillPassword from "./views/auth/FillPassword";
import Product from "./views/product";
import SetRegularPrice from "./views/product/regular/set_price";
import DetailRegularPrice from "./views/product/regular/detail_price";
import SetCorporatePrice from "./views/product/corporate/set_price";
import DetailCorporatePrice from "./views/product/corporate/detail_price";
import Schedule from "./views/schedule";
import Booking from "./views/booking";
import Report from "./views/report";
import RKAP from "./views/rkap";
import Commission from "./views/commission";
import Promo from "./views/promo";
import CorpReservation from "./views/corp_reservation";
import NewReservation from "./views/corp_reservation/new_reservation";
import NewBooking from "./views/booking/new_booking";
import DetailCorpReserv from "./views/corp_reservation/detail_reservation";
import DetailBooking from "./views/booking/detail_booking";
import DetailOperational from "./views/schedule/operational/DetailOperational";
import AuditTrail from "./views/audit_trail";
import Reward from "./views/reward";

const all = [
  Role.Central_admin,
  Role.Lead_Contact_center,
  Role.Contact_center,
  Role.Sub_admin,
  Role.Supervisor,
  Role.Sales,
  Role.Sales_bandara,
  Role.Finance,
  Role.Finance_bandara
];
const CAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center
];
const allAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin,
  Role.Supervisor
];
const bookingAdmin = [
  Role.Central_admin,
  Role.Lead_Contact_center,
  Role.Contact_center,
  Role.Sub_admin,
  Role.Supervisor,
  Role.Assistant,
  Role.Sales,
  Role.Sales_bandara,
  Role.Finance,
  Role.Finance_bandara
];
const CorporateAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin, 
  Role.Corporate, 
  Role.Supervisor, 
  Role.Assistant
];
const OrderCorporateAdmin = [
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin, 
  Role.Corporate, 
  Role.Supervisor, 
  Role.Assistant
];
// const Corporate = [Role.Corporate];
const superadmin = [Role.Superadmin];
const allAdminSuperadmin = [
  Role.Central_admin,
  Role.Lead_Contact_center, 
  Role.Contact_center,
  Role.Sub_admin,
  Role.Superadmin,
  Role.Finance,
  Role.Supervisor,
  Role.Sales,
  Role.Finance_bandara,
  Role.Sales_bandara
];
const masterBandara = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin
];
const auditTrailRoles = [
  Role.Superadmin, 
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin
];

function App() {
  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route
          path="/fill-password/:verification_code"
          exact
          component={FillPassword}
        />
        <PrivateRoute path="/dashboard" exact component={Index} roles={all} />
        <PrivateRoute
          path="/dashboard-bigio"
          exact
          component={Index}
          roles={superadmin}
        />
        <PrivateRoute
          path="/analytics" 
          exact 
          component={AnalyticsData} 
          roles={[Role.Central_admin]} 
        />
        <PrivateRoute
          path="/data-master"
          exact
          component={MasterData}
          roles={masterBandara}
        />
        <PrivateRoute
          path="/data-master/airport/detail/:id"
          exact
          component={DetailAirport}
          roles={masterBandara}
        />
        <PrivateRoute path="/user" exact component={User} roles={allAdmin} />
        <PrivateRoute
          path="/product"
          exact
          component={Product}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/regular_price/set"
          exact
          component={SetRegularPrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/regular_price/edit/:id"
          exact
          component={SetRegularPrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/regular_price/detail/:id"
          exact
          component={DetailRegularPrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/set-corporate-price"
          exact
          component={SetCorporatePrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/set-corporate-price/edit/:id"
          exact
          component={SetCorporatePrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/product/set-corporate-price/detail/:id"
          exact
          component={DetailCorporatePrice}
          roles={CAdmin}
        />
        <PrivateRoute
          path="/corp_reservation"
          exact
          component={CorpReservation}
          roles={CorporateAdmin}
        />
        <PrivateRoute
          path="/corp_reservation/new-reservation"
          exact
          component={NewReservation}
          roles={OrderCorporateAdmin}
        />
        <PrivateRoute
          path="/corp_reservation/detail/:id"
          exact
          component={DetailCorpReserv}
          roles={CorporateAdmin}
        />
        <PrivateRoute
          path="/booking/detail/:id"
          exact
          component={DetailBooking}
          roles={bookingAdmin}
        />
        <PrivateRoute
          path="/booking-contact-center/detail/:id"
          exact
          component={DetailBooking}
          roles={[Role.Lead_Contact_center, Role.Contact_center]}
        />
        <PrivateRoute
          path="/booking"
          exact
          component={Booking}
          roles={bookingAdmin}
        />
        <PrivateRoute
          path="/booking-contact-center"
          exact
          component={Booking}
          roles={[Role.Lead_Contact_center, Role.Contact_center]}
        />
        <PrivateRoute
          path="/report"
          exact
          component={Report}
          roles={allAdminSuperadmin}
        />
        <PrivateRoute
          path="/rkap"
          exact
          component={RKAP}
          roles={[Role.Central_admin]}
        />
        <PrivateRoute
          path="/commission"
          exact
          component={Commission}
          roles={superadmin}
        />
        <PrivateRoute
          path="/booking/new-booking"
          exact
          component={NewBooking}
          roles={bookingAdmin}
        />
        <PrivateRoute
          path="/booking-contact-center/new-booking"
          exact
          component={NewBooking}
          roles={[Role.Lead_Contact_center, Role.Contact_center]}
        />
        <PrivateRoute
          path="/schedule"
          exact
          component={Schedule}
          roles={allAdmin}
        />
        <PrivateRoute
          path="/audit_trail"
          exact
          component={AuditTrail}
          roles={auditTrailRoles}
        />
        <PrivateRoute
          path="/schedule/operational/detail/:id"
          exact
          component={DetailOperational}
          roles={allAdmin}
        />
        <PrivateRoute path="/promo" exact component={Promo} roles={allAdmin} />
        <PrivateRoute path="/reward" exact component={Reward} roles={allAdmin} />
        <PrivateRoute path="/access-denied" exact component={CantAccess} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
