import { useState, useEffect } from "react";
import { withTrans } from "../../i18n/withTrans";
import SideMenu from "../../components/commons/menu/SideMenu"

import Regular from './regular'
import CorporatePrice from './corporate'

const Index = ({ t, location }) => {

    const [show, setShow] = useState(location?.state?.tab ?? "corp_price");

    return (
        <div className="menu-container">
            <div className="grid-header">
                <div className="side-menu">
                    <div className="menu-title text-extra-bold">
                        {t('field.product')}
                    </div>
                    <SideMenu
                        title={t('field.corporation')}
                        subtitle={t('product.list_corporate')}
                        toggle={() => setShow("corp_price")}
                        active={show === 'corp_price'}
                    />
                    <SideMenu
                        title={t('field.regular')}
                        subtitle={t('product.list_regular')}
                        toggle={() => setShow("regular")}
                        active={show === 'regular'}
                    />
                </div>
                {show === "corp_price" && (
                    <>
                        <CorporatePrice />
                    </>
                )}
                {show === "regular" && (
                    <>
                        <Regular />
                    </>
                )}
            </div>
        </div>
    );
};

export default withTrans(Index);
