import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import { RoleGroups, payment_list } from "../../../utils/Constants";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

function ModalFilter({
  t,
  show,
  airport,
  product,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  user,
  haveAirport
}) {

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("booking_start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("booking_end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("payment_status", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("payment_type", "");
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      booking_start_date: moment().format("YYYY-MM-DD"),
      booking_end_date: moment().format("YYYY-MM-DD"),
      product_id: "",
      airport_id: "",
      payment_status: "",
      booking_type: "",
      payment_type: "",
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              booking_start_date: filter?.booking_start_date ?? "",
              booking_end_date: filter?.booking_end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              payment_status: filter?.payment_status ?? "",
              booking_type: filter?.booking_type ?? "",
              payment_type: filter?.payment_type ?? "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id:
                  RoleGroups.subAdminRoles.includes(user.role_code)
                    ? user.airport_id
                    : values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                booking_start_date:
                  values.booking_start_date === "" || values.booking_start_date === undefined
                    ? ""
                    : moment(values.booking_start_date).format("YYYY-MM-DD"),
                booking_end_date:
                  values.booking_end_date === "" || values.booking_end_date === undefined
                    ? ""
                    : moment(values.booking_end_date).format("YYYY-MM-DD"),
                product_id: values.product_id,
                booking_type: values.booking_type,
                payment_type: values.payment_type,
                payment_status: values.payment_status,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                <DatePicker
                  title={t("field.bookingTime")}
                  name="booking_start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("booking_start_date", startDate);
                    props.setFieldValue("booking_end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.booking_start_date : props.values?.booking_start_date}
                  endDate={filter ? filter?.booking_end_date : props.values?.booking_end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                {!haveAirport && (
                  <Select2
                    title={t("field.airport")}
                    name="airport_id"
                    options={[
                      {
                        value: "",
                        label: `${t("commons.all")} ${t("field.airport")}`,
                      },
                      ...airport,
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.airport_id}
                    onChange={(name, value) => {
                      props.setFieldValue("airport_id", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "web-public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "web-admin",
                      label: t("field.webAdmin"),
                    },
                    {
                      value: "api",
                      label: "API",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentType")}
                  name="payment_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.paymentType")}`,
                    },
                    ...payment_list.map((item) => (
                      { label: item.label, value: item.value.toLowerCase() }
                    )),
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_type}
                  onChange={(name, value) => {
                    props.setFieldValue("payment_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentStatus")}
                  name="payment_status"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} Status`,
                    },
                    {
                      value: "paid",
                      label: t("status.paid"),
                    },
                    {
                      value: "unpaid",
                      label: t("status.unpaid"),
                    },
                    {
                      value: "invoice",
                      label: t("status.invoicePayment"),
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_status}
                  placeholder="Pilih status pembayaran"
                  onChange={(name, value) => {
                    props.setFieldValue("payment_status", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
               
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
