import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import moment from "moment";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import HeaderMenu from "../../components/commons/menu/HeaderMenu";
import Card from "../../components/commons/cards/card";
import CardHeader from "../../components/commons/cards/cardHeader";
import CardBody from "../../components/commons/cards/cardBody";
import ProductionCard from "../../components/commons/cards/ProductionCard";
import LineChart from "../../components/commons/charts/line";
import Button from "../../components/commons/buttons/Button";
import DateRangePicker from "../../components/forms/DateRangePicker";
import DatePicker from "../../components/forms/DatePicker";
import DashboardReport from "./DashboardReport";
import { reactSelectCustomStyles, Role, RoleWithAirport } from "../../utils/Constants";
import { numberWithCommas } from "../../utils/Helper";
import { getUser } from "../../utils/User";
import { withTrans } from "../../i18n/withTrans";
import { toastSuccess } from "../../components/commons/toast";

// Service
import DashboardService from "../../store/actions/dashboard";
import AirportService from "../../store/actions/master_data/airport";

const Dashboard = ({ t, pending, pending_chart }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const isSuperAdmin = currentUser.user.role_code === Role.Superadmin;
  const lang = localStorage.getItem('joumpa_language');
  let label = Array.from({ length: 24 }, (v, i) => i);
  let dataset = [
    {
      labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      datasets: [
        {
          label: "Sales",
          data: [86, 67, 91],
          fill: false,
        },
      ],
    },
  ];

  const [listAirport, setListAirport] = useState([]);
  const [haveAirport] = useState(
    RoleWithAirport?.includes(currentUser?.user.role_code)
      ? true
      : false
  );
  const [dataChart, setDataChart] = useState([]);
  const [dataChartTarget, setDataChartTarget] = useState([]);
  const [dataLabel, setDataLabel] = useState([]);
  const [filterReservation, setFilterReservation] = useState({
    date: new Date(),
    airport_id: haveAirport ? currentUser?.user.airport_id : "",
  });
  const [filterDate, setFilterDate] = useState(new Date());
  const [filterYear, setFilterYear] = useState(moment().format("yyyy"));
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth() + 1);
  const [filterChart, setFilterChart] = useState({
    month: new Date().getMonth() + 1,
    year: moment().format("yyyy"),
    start_date: `${moment().format("YYYY-MM")}-01`,
    end_date: `${moment().format("YYYY-MM-DD")}`,
  });
  const [monthYearPicker, setMonthYearPicker] = useState(new Date());
  const [activeGrafikTab, setActiveGrafikTab] = useState("pendapatan");
  const [activeTabDashboard, setActiveTabDashboard] = useState("statistik");
  const [appMobile, setAppMobile] = useState(0);
  const [appUnknown, setAppUnknown] = useState(0);
  const [appWebAdmin, setAppWebAdmin] = useState(0);
  const [agentTotalPax, setAgentTotalPax] = useState(0);
  const [commissionCorporate, setCommissionCorporate] = useState(0);
  const [commissionRegular, setCommissionRegular] = useState(0);
  const [productTransfer, setProductTransfer] = useState(0);
  const [productMeetGreetDom, setProductMeetGreetDom] = useState(0);
  const [productMeetGreetInter, setProductMeetGreetInter] = useState(0);
  const [productFastTrack, setProductFastTrack] = useState(0);
  const [crossSelling, setCrossSelling] = useState(0);
  const [passengerGroup, setPassengerGroup] = useState(0);
  const [passengerPackage, setPassengerPackage] = useState(0);
  const [passengerFIT, setPassengerFIT] = useState(0);
  const [passengerMOB, setPassengerMOB] = useState(0);
  const [passengerIndividual, setPassengerIndividual] = useState(0);
  const [passengerRegular, setPassengerRegular] = useState(0);
  const [regularTotalPax, setRegularTotalPax] = useState(0);
  const [reservationAssigned, setReservationAssigned] = useState(0);
  const [reservationCancelled, setReservationCancelled] = useState(0);
  const [reservationDone, setReservationDone] = useState(0);
  const [reservationOnProgress, setReservationOnProgress] = useState(0);
  const [reservationNew, setReservationNew] = useState(0);
  const [orderRegularDone, setOrderRegularDone] = useState(0);
  const [orderRegularWaiting, setOrderRegularWaiting] = useState(0);
  const [orderCorporateDone, setOrderCorporateDone] = useState(0);
  const [orderCorporateWaiting, setOrderCorporateWaiting] = useState(0);
  const [orderRegularDoneCount, setOrderRegularDoneCount] = useState(0);
  const [orderRegularWaitingCount, setOrderRegularWaitingCount] = useState(0);
  const [orderCorporateDoneCount, setOrderCorporateDoneCount] = useState(0);
  const [orderCorporateWaitingCount, setOrderCorporateWaitingCount] = useState(0);

  useEffect(() => {
    const justLogin = localStorage.getItem("just_login");

    if (justLogin) {
      toastSuccess(`${lang === 'id' ? `Selamat Anda berhasil login sebagai ${justLogin}` : `Well done! you have logged in as ${justLogin}`}`);

      localStorage.removeItem("just_login");
    }
  }, []);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999,
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setListAirport(
        res?.data?.map((airport) => ({
          value: airport?.id,
          label: `${airport?.code}-${airport?.city}-${airport?.name} ${
            airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
          }`,
        }))
      );
    });
  }, [])

  let yearOptions = [];
  for (let i = new Date().getFullYear(); i >= 2021; i--) {
    yearOptions = [...yearOptions, { label: i, value: i }];
  }

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = isSuperAdmin 
        ? {
          month: filterMonth,
          year: parseInt(filterYear),
        }
        : {
          date: moment(filterDate).format("YYYY-MM-DD")
        };
      dispatch(DashboardService.get(param, resolve));
    }).then((res) => {
      const data = res.data;
      const filteredDataByField = (fieldName) => {
        return data?.filter((item) => item.field === fieldName)?.length > 0
          ? data?.filter((item) => item.field === fieldName)[0]?.value
          : 0;
      };

      if (data.length > 0) {
        setAgentTotalPax(filteredDataByField("agent_total_pax"));
        setRegularTotalPax(filteredDataByField("regular_total_pax"));
        setAppMobile(filteredDataByField("mobile_app"));
        setAppUnknown(filteredDataByField("web_public"));
        setAppWebAdmin(filteredDataByField("web_admin"));
        setProductMeetGreetDom(`Rp ${numberWithCommas(filteredDataByField("meet_and_greet_domestic"))}`);
        setProductMeetGreetInter(`Rp ${numberWithCommas(filteredDataByField("meet_and_greet_international"))}`);
        setProductFastTrack(`Rp ${numberWithCommas(filteredDataByField("fast_track"))}`);
        setProductTransfer(`Rp ${numberWithCommas(filteredDataByField("transfer"))}`);
        setCrossSelling(`Rp ${numberWithCommas(filteredDataByField("cross_selling"))}`);
        setCommissionRegular(`Rp ${numberWithCommas(filteredDataByField("commission_regular"))}`);
        setCommissionCorporate(`Rp ${numberWithCommas(filteredDataByField("commission_corporate"))}`);
        setOrderCorporateDone(`Rp ${numberWithCommas(filteredDataByField("agent_total_income"))}`);
        setOrderCorporateDoneCount(filteredDataByField("agent_total_pax"));
        setOrderCorporateWaiting(`Rp ${numberWithCommas(filteredDataByField("agent_total_income_waiting"))}`);
        setOrderCorporateWaitingCount(filteredDataByField("agent_total_pax_waiting"));
        setOrderRegularDone(`Rp ${numberWithCommas(filteredDataByField("regular_total_income"))}`);
        setOrderRegularDoneCount(filteredDataByField("regular_total_pax"));
        setOrderRegularWaiting(`Rp ${numberWithCommas(filteredDataByField("regular_total_income_waiting"))}`);
        setOrderRegularWaitingCount(filteredDataByField("regular_total_pax_waiting"));
        setPassengerIndividual(filteredDataByField("mob_individual_total_pax"));
        setPassengerGroup(filteredDataByField("group_total_pax"));
        setPassengerPackage(filteredDataByField("package_total_pax"));
        setPassengerFIT(filteredDataByField("fit_total_pax"));
        setPassengerMOB(filteredDataByField("mob_total_pax"));
        setPassengerRegular(filteredDataByField("regular_total_pax"));
      } else {
        setAppMobile(0);
        setAppUnknown(0);
        setAppWebAdmin(0);
        setAgentTotalPax(0);
        setRegularTotalPax(0);
        setProductMeetGreetDom(`Rp 0`);
        setProductMeetGreetInter(`Rp 0`);
        setProductFastTrack(`Rp 0`);
        setProductTransfer(`Rp 0`);
        setCrossSelling(`Rp 0`);
        setCommissionRegular(`Rp 0`);
        setCommissionCorporate(`Rp 0`);
        setPassengerIndividual(0);
        setPassengerGroup(0);
        setPassengerPackage(0);
        setPassengerFIT(0);
        setPassengerMOB(0);
        setPassengerRegular(0);
      }
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [filterDate, filterMonth, filterYear]);

  const handleReservationStatus = () => {
    new Promise((resolve) => {
      let param = {
        airport_id: filterReservation?.airport_id ?? "",
        date: filterReservation?.date ?? moment().format("YYYY-MM-DD"),
      };
      dispatch(DashboardService.getReservationStatus(param, resolve));
    }).then((res) => {
      if (res) {
        setReservationNew(res?.data?.filter((item) => item?.status === "new")[0]?.total_order);
        setReservationAssigned(res?.data?.filter((item) => item?.status === "assigned")[0]?.total_order);
        setReservationOnProgress(res?.data?.filter((item) => item?.status === "on progress")[0]?.total_order);
        setReservationDone(res?.data?.filter((item) => item?.status === "done")[0]?.total_order);
        setReservationCancelled(res?.data?.filter((item) => item?.status === "canceled")[0]?.total_order);
      }
    });
  };

  useEffect(() => {
    handleReservationStatus();
  }, [filterReservation]);

  useEffect(() => {
    handleChart();
  }, [filterChart, activeGrafikTab]);

  const handleChart = () => {
    new Promise((resolve) => {
      let param = isSuperAdmin 
        ? {
          start_date: filterChart.start_date,
          end_date: filterChart.end_date,
        }
        : {
          month: filterChart.month,
          year: parseInt(filterChart.year),
        };
      if (activeGrafikTab === "pax") {
        dispatch(DashboardService.getChartProduction(param, resolve));
      }
      if (activeGrafikTab === "pendapatan") {
        dispatch(DashboardService.getChartIncome(param, resolve));
      }
    }).then((res) => {
      let arrayTotal = [];
      let arrayTarget = [];
      let arrayDate = [];
      res.data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      res.data.map((item, index) => {
        arrayDate.push(moment(item.date).format("DD/MM/YY"));
        arrayTotal.push(
          activeGrafikTab === "pax"
            ? parseInt(item.total_production)
            : parseInt(item.total_income)
        );
        arrayTarget.push(parseInt(item.target_daily));
      });

      setDataChart(arrayTotal);
      setDataChartTarget(arrayTarget);
      setDataLabel(arrayDate);
    });
  };

  return (
    <>
      <HeaderMenu
        title={
          isSuperAdmin
            ? "Dashboard BIG IO"
            : "Dashboard"
        }
      >
        {!isSuperAdmin && (
          <div className="row mr-3">
            <div className="tab-menu overflow">
              <div style={{ width: "fit-content" }}>
                <Tabs
                  scrollButtons="auto"
                  variant="scrollable"
                  value={activeTabDashboard}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    setActiveTabDashboard(newValue);
                  }}
                >
                  <Tab
                    label={t("commons.statistic")}
                    value="statistik"
                  />
                  <Tab
                    label={t("commons.table")}
                    value="tabel"
                  />
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </HeaderMenu>
      {activeTabDashboard === 'tabel' && (
        <DashboardReport />
      )}
      {activeTabDashboard === 'statistik' && (
        <>
        <Row
          className="content-container-side mb-3 align-items-center"
        >
          <p
            className="text-bold"
            style={{ 
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: 0
            }}
          >
            {t("field.reservationInformation")}
          </p>
          {!isSuperAdmin ? (
            <div className="d-flex ml-auto">
              <DatePicker
                showIcon
                isLocale
                useCustomInput
                customDateFormat="d MMMM yyyy"
                value={filterDate}
                onChange={(date) => {
                  const value = date ?? new Date()
                  setFilterDate(value)
                }}
              />
            </div>
          ) : (
            <div className="d-flex ml-auto">
              <DatePicker
                isLocale
                useCustomInput
                showMonthYearPicker
                value={monthYearPicker}
                onChange={(date) => {
                  const value = date ?? new Date()
                  setMonthYearPicker(value);
                  setFilterMonth(new Date(value).getMonth() + 1);
                  setFilterYear(moment(value).format("yyyy"));
                }}
              />
            </div>
          )}
        </Row>
        {isSuperAdmin ? (
          <>
            <Row className="content-container-side">
              <Card rounded className="col-md mr-4">
                <CardHeader isDashboard title={t("field.corporateReservation")} />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      className="col-md"
                      color="hijau"
                      title={t("field.done")}
                      total={orderCorporateDoneCount}
                      icon="done"
                      price={orderCorporateDone !== "Rp undefined" ? orderCorporateDone : "Rp 0"}
                      pending={pending}
                    />
                    <ProductionCard
                      color="kuning"
                      title={t("field.waiting")}
                      total={orderCorporateWaitingCount}
                      icon="access_time_filled"
                      price={orderCorporateWaiting !== "Rp undefined" ? orderCorporateWaiting : "Rp 0"}
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
              <Card rounded className="col-md">
                <CardHeader isDashboard title={t("field.regularReservation")} />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      color="hijau"
                      title={t("field.done")}
                      total={orderRegularDoneCount}
                      icon="done"
                      price={orderRegularDone !== "Rp undefined" ? orderRegularDone : "Rp 0"}
                      pending={pending}
                    />
                    <ProductionCard
                      color="kuning"
                      title={t("field.waiting")}
                      total={orderRegularWaitingCount}
                      icon="access_time_filled"
                      price={orderRegularWaiting !== "Rp undefined" ? orderRegularWaiting : "Rp 0"}
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row className="content-container-side mb-3">
              <Card rounded className="col-md-7 mr-4">
                <CardHeader isDashboard title={t("field.totalPassenger")} />
                <CardBody>
                  <Row className="justify-content-between">
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.corpPackage")}
                        total={passengerPackage}
                        icon="group"
                        pending={pending}
                      />
                    </Col>
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.corpGroup")}
                        total={passengerGroup}
                        icon="group"
                        pending={pending}
                      />
                    </Col>
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.corpFIT")}
                        total={passengerFIT}
                        icon="person"
                        pending={pending}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-between mt-3">
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.corpMOB")}
                        total={passengerMOB}
                        icon="event_seat"
                        pending={pending}
                      />
                    </Col>
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.corpIndividu")}
                        total={passengerIndividual}
                        icon="person"
                        pending={pending}
                      />
                    </Col>
                    <Col md="4">
                      <ProductionCard
                        color="abu"
                        title={t("field.regular")}
                        total={passengerRegular}
                        icon="person"
                        pending={pending}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card rounded className="col-md">
                <CardHeader
                  isDashboard
                  title={t("field.totalTransactionCommission")}
                />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      color="hijau"
                      title={t("field.corporate")}
                      total={commissionCorporate !== "Rp undefined" ? commissionCorporate : "Rp 0"}
                      icon="paid"
                      pending={pending}
                    />
                    <ProductionCard
                      color="hijau"
                      title={t("field.regular")}
                      total={commissionRegular !== "Rp undefined" ? commissionRegular : "Rp 0"}
                      icon="attach_money"
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </>
        ) : (
          <>
            <Row className="content-container-side">
              <Card rounded className="col-md mr-4">
                <CardHeader isDashboard title="Total Pax" />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      className="col-md"
                      color="hijau"
                      title={t("field.corporate")}
                      total={agentTotalPax}
                      icon="check_circle"
                      pending={pending}
                    />
                    <ProductionCard
                      className="col-md"
                      color="hijau"
                      title={t("field.regular")}
                      total={regularTotalPax}
                      icon="check_circle"
                      pending={pending}
                    />
                    <ProductionCard
                      className="col-md"
                      color="hijau"
                      title="Total Pax"
                      total={agentTotalPax + regularTotalPax}
                      icon="check_circle"
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
              <Card rounded className="col-md">
                <CardHeader
                  isDashboard
                  title={`${t("field.reservationChannel")}`}
                />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      color="abu"
                      title={t("field.mobileApp")}
                      total={appMobile}
                      icon="phone_android"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title={t("field.webPublic")}
                      total={appUnknown}
                      icon="public"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title={t("field.webAdmin")}
                      total={appWebAdmin}
                      icon="computer"
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row className="content-container-side mb-3">
              <Card rounded className="col-md">
                <CardHeader
                  isDashboard
                  title={t("field.totalIncome")}
                />
                <CardBody>
                  <Row className="justify-content-between">
                    <ProductionCard
                      color="abu"
                      title="Meet and Greet Domestic"
                      total={productMeetGreetDom}
                      icon="meeting_room"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title="Meet and Greet International"
                      total={productMeetGreetInter}
                      icon="meeting_room"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title="Fast Track"
                      total={productFastTrack}
                      icon="double_arrow"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title="Transfer"
                      total={productTransfer}
                      icon="airline_stops"
                      pending={pending}
                    />
                    <ProductionCard
                      color="abu"
                      title="Cross Selling"
                      total={crossSelling}
                      icon="currency_exchange"
                      pending={pending}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </>
        )}
        {!isSuperAdmin && (
          <>
          <hr style={{ margin: "0 40px 40px 40px" }} />
          <Row className="content-container-side mb-3">
            <Card rounded className="col-md">
              <Row
                className="p-3 align-items-center"
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
              >
                <p
                  style={{ 
                    color: 'rgb(165, 165, 192)',
                    fontWeight: 'bold',
                    lineHeight: '30px',
                    marginBottom: 0
                  }}
                >
                  {t("field.totalReservationByStatus")}
                </p>
                <div className="d-flex ml-auto align-items-center">
                  <div style={{ maxWidth: '40vw' }}>
                    <Select
                      isDisabled={haveAirport ? true : false}
                      classNamePrefix="select"
                      className="basic-single mr-3"
                      isSearchable
                      name="airport_id"
                      styles={reactSelectCustomStyles}
                      options={[
                        {
                          value: "",
                          label: `${t("commons.all")} ${t("field.airport")}`,
                        },
                        ...listAirport,
                      ]}
                      value={
                        listAirport?.filter((obj) => {
                          return obj?.value === filterReservation?.airport_id;
                        })[0] ?? {
                          label: `${t("commons.all")} ${t("field.airport")}`,
                          value: "",
                        }
                      }
                      onChange={(item) => {
                        setFilterReservation({
                          date: filterReservation?.date,
                          airport_id: item.value,
                        });
                      }}
                    />
                  </div>
                  <DatePicker
                    isLocale
                    useCustomInput
                    customDateFormat="d MMMM yyyy"
                    value={filterReservation?.date}
                    onChange={(date) => setFilterReservation({
                      date: date, 
                      airport_id: filterReservation?.airport_id
                    })}
                  />
                </div>
              </Row>
              <CardBody>
                <Row className="justify-content-between">
                  <ProductionCard
                    color="merah"
                    title={t("field.newReservation")}
                    total={reservationNew}
                    icon="info"
                    pending={pending}
                  />
                  <ProductionCard
                    color="kuning"
                    title={t("status.assigned")}
                    total={reservationAssigned}
                    icon="how_to_reg"
                    pending={pending}
                  />
                  <ProductionCard
                    color="kuning"
                    title={t("status.onProgress")}
                    total={reservationOnProgress}
                    icon="access_time_filled"
                    pending={pending}
                  />
                  <ProductionCard
                    color="abu"
                    title={t("status.cancelled")}
                    total={reservationCancelled}
                    icon="cancel"
                    pending={pending}
                  />
                  <ProductionCard
                    color="hijau"
                    title={t("status.done")}
                    total={reservationDone}
                    icon="done"
                    pending={pending}
                  />
                  <div
                    style={{
                      borderRight: "1px solid #A9A9A9",
                      height: 'inherit',
                      padding: "-24px -32px"
                    }}
                  ></div>
                  <ProductionCard
                    color="biru"
                    title="Total Booking"
                    total={reservationNew+reservationAssigned+reservationOnProgress+reservationCancelled+reservationDone}
                    icon="groups"
                    pending={pending}
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
          </>
        )}
        <hr style={{ margin: "0 40px 40px 40px" }} />
        <Row
          className="content-container-side mb-3 align-items-center"
        >
          <p
            className="text-bold"
            style={{ 
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: 0
            }}
          >
            {activeGrafikTab === 'pendapatan' ? `Joumpa ${t("field.income")}` : 'Joumpa Pax'}
          </p>
          <div className="d-flex ml-auto align-items-center">
            {!isSuperAdmin ? (
              <>
              <div className="wrapper-multi-btn mr-2">
                <Button
                  size="sm"
                  variant={activeGrafikTab === 'pendapatan' ? 'primary' : 'link'}
                  title={`Joumpa ${t("field.income")}`}
                  onClick={() => setActiveGrafikTab("pendapatan")}
                  type="Button"
                />
                <Button
                  size="sm"
                  variant={activeGrafikTab === 'pax' ? 'primary' : 'link'}
                  title="Joumpa Pax"
                  onClick={() => setActiveGrafikTab("pax")}
                  type="Button"
                />
              </div>
              <DatePicker
                isLocale
                useCustomInput
                showMonthYearPicker
                value={monthYearPicker}
                onChange={(date) => {
                  setMonthYearPicker(date);
                  setFilterChart({
                    month: new Date(date).getMonth() + 1, 
                    year: moment(date).format("yyyy")
                  })
                }}
              />
              </>
            ) : (
              <DateRangePicker
                name="start_date"
                handlechange={(startDate, endDate) => {
                  setFilterChart({
                    start_date:
                      startDate === ""
                        ? startDate
                        : moment(startDate).format("YYYY-MM-DD"),
                    end_date:
                      endDate === ""
                        ? endDate
                        : moment(endDate).format("YYYY-MM-DD"),
                  });
                }}
                width="350px"
                format="DD MMMM YYYY"
                notReadOnly
                startDate={`${moment().format("YYYY-MM")}-01`}
                endDate={moment().format("YYYY-MM-DD")}
                className="mr-3"
                containerPosition="auto"
              />
            )}
          </div>
        </Row>
        <div className="my-3 p-5">
          {activeGrafikTab === 'pendapatan' && (
            <LineChart
              labels={dataLabel}
              datasets={
                !isSuperAdmin ?
                  [{
                    label: t("field.totalIncome"),
                    data: dataChart,
                    fill: false,
                    borderColor: "#63AE5C",
                  },
                  {
                    label: "Target",
                    data: dataChartTarget,
                    fill: false,
                    borderColor: "#EDAA21",
                  }]
                : [{
                  label: t("field.totalIncome"),
                  data: dataChart,
                  fill: false,
                  borderColor: "#63AE5C",
                }]
              }
              pending_chart={pending_chart}
              height={400}
              stepSize={1}
              yTickDisplay
            />
          )}
          {activeGrafikTab === 'pax' && (
            <LineChart
              labels={dataLabel}
              datasets={[
                {
                  label: "Total Pax",
                  data: dataChart,
                  fill: false,
                  borderColor: "#63AE5C",
                  nonParsed: true,
                },
                {
                  label: "Target",
                  data: dataChartTarget,
                  fill: false,
                  borderColor: "#EDAA21",
                  nonParsed: true,
                },
              ]}
              pending_chart={pending_chart}
              height={400}
              stepSize={1}
              yTickDisplay
            />
          )}
        </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ dashboard: { pending, pending_chart, data } }) => {
  return { pending, pending_chart, data };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(Dashboard));