import {
  GET_OPERATIONAL_PENDING,
  GET_OPERATIONAL_SUCCESS,
  GET_OPERATIONAL_ERROR,
  FIND_TOTAL_PAX_PENDING,
  FIND_TOTAL_PAX_SUCCESS,
  FIND_TOTAL_PAX_ERROR,
  POST_OPERATIONAL_PENDING,
  POST_OPERATIONAL_SUCCESS,
  POST_OPERATIONAL_ERROR,
  PUT_OPERATIONAL_PENDING,
  PUT_OPERATIONAL_SUCCESS,
  PUT_OPERATIONAL_ERROR,
  DELETE_OPERATIONAL_PENDING,
  DELETE_OPERATIONAL_SUCCESS,
  DELETE_OPERATIONAL_ERROR,
  DOWNLOAD_PENDING,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} from "../../../actions/schedule/operational";

const initialState = {
  pending: false,
  error: null,
  data_list: null,
  pending_post: false,
  data_post: null,
  pending_put: false,
  data_put: false,
  pending_delete: false,
  data_delete: null,
};

const operational = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATIONAL_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_OPERATIONAL_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case GET_OPERATIONAL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FIND_TOTAL_PAX_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FIND_TOTAL_PAX_SUCCESS:
      return {
        ...state,
        pending: false,
        data_list: action.data,
      };
    case FIND_TOTAL_PAX_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case POST_OPERATIONAL_PENDING:
      return {
        ...state,
        pending_post: true,
      };
    case POST_OPERATIONAL_SUCCESS:
      return {
        ...state,
        pending_post: false,
        data_post: action.data,
      };
    case POST_OPERATIONAL_ERROR:
      return {
        ...state,
        pending_post: false,
        error: action.error,
      };
    case PUT_OPERATIONAL_PENDING:
      return {
        ...state,
        pending_put: true,
      };
    case PUT_OPERATIONAL_SUCCESS:
      return {
        ...state,
        pending_put: false,
        data_put: action.data,
      };
    case PUT_OPERATIONAL_ERROR:
      return {
        ...state,
        pending_put: false,
        error: action.error,
      };
    case DELETE_OPERATIONAL_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DELETE_OPERATIONAL_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DELETE_OPERATIONAL_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    case DOWNLOAD_PENDING:
      return {
        ...state,
        pending_delete: true,
      };
    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        pending_delete: false,
        data_delete: action.data,
      };
    case DOWNLOAD_ERROR:
      return {
        ...state,
        pending_delete: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default operational;
