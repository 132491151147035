import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonModal from "../../../../components/commons/modal/ButtonModal";
import { withTrans } from "../../../../i18n/withTrans";

function PagingLogoModal({ t, show, toggle, image }) {
  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 200 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("field.pagingImage")}{" "}
          <span className="close clickable" onClick={() => toggle()}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <img
            src={image}
            alt="payment-proof"
            className="img img-fluid"
            width="100%"
            style={{ objectFit: "cover", width: "100%" }}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonModal
            toggle={() => toggle()}
            hideConfirm
            cancelTitle={t("commons.close")}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTrans(
  connect(mapStateToProps, mapDispatchToProps)(PagingLogoModal)
);
