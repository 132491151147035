import { Role } from "../../../utils/Constants";

const CAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center
];
const allAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin, 
  Role.Supervisor
];
const dashboardAdmin = [
  Role.Central_admin,
  Role.Lead_Contact_center, 
  Role.Contact_center,
  Role.Sub_admin,
  Role.Supervisor,
  Role.Finance,
  Role.Sales,
  Role.Sales_bandara,
  Role.Finance_bandara,
];
const bookingAdmin = [
  Role.Central_admin,
  Role.Lead_Contact_center, 
  Role.Contact_center,
  Role.Sub_admin,
  Role.Supervisor,
  Role.Assistant,
  Role.Finance,
  Role.Sales,
  Role.Sales_bandara,
  Role.Finance_bandara,
];
const CorporateAdmin = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin, 
  Role.Supervisor, 
  Role.Corporate, 
  Role.Assistant
];
const superadmin = [Role.Superadmin];
const allAdminSuperadmin = [
  Role.Central_admin,
  Role.Lead_Contact_center, 
  Role.Contact_center,
  Role.Sub_admin,
  Role.Superadmin,
  Role.Finance,
  Role.Supervisor,
  Role.Sales,
  Role.Sales_bandara,
  Role.Finance_bandara,
];
const masterBandara = [
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center,
  Role.Sub_admin
];
const auditTrailRoles = [
  Role.Superadmin, 
  Role.Central_admin, 
  Role.Lead_Contact_center, 
  Role.Contact_center, 
  Role.Sub_admin
];

const lang = localStorage.getItem("joumpa_language");

export const SidebarItem = [
  {
    title: "Dashboard",
    slug: "dashboard",
    url: "/dashboard",
    icon: "grid_view",
    roles: dashboardAdmin,
  },
  {
    title: "Dashboard BIGIO",
    slug: "dashboard-bigio",
    url: "/dashboard-bigio",
    icon: "grid_view",
    roles: superadmin,
  },
  {
    title: `${lang !== "id" ? "Booking" : "Pesanan"}`,
    slug: "booking",
    url: "/booking",
    icon: "airplane_ticket",
    roles: bookingAdmin,
  },
  {
    title: `${lang !== "id" ? "Booking By Contact Center" : "Pesanan Contact Center"}`,
    slug: "booking_contact_center",
    url: "/booking-contact-center",
    icon: "assignment_turned_in",
    roles: [Role.Contact_center, Role.Lead_Contact_center],
  },
  {
    title: `${lang !== "id" ? "Corp. Reservation" : "Reservasi Korp."}`,
    slug: "corp_reservation",
    url: "/corp_reservation",
    icon: "swap_horiz",
    roles: CorporateAdmin,
  },
  {
    title: `${lang !== "id" ? "Schedule" : "Jadwal"}`,
    slug: "schedule",
    url: "/schedule",
    icon: "event_note",
    roles: allAdmin,
  },
  {
    title: `${lang !== "id" ? "Report" : "Laporan"}`,
    slug: "report",
    url: "/report",
    icon: "summarize",
    roles: allAdminSuperadmin,
  },
  {
    title: "RKAP",
    slug: "rkap",
    url: "/rkap",
    icon: "library_books",
    roles: [Role.Central_admin],
  },
  {
    title: `${lang !== "id" ? "Analytics Data" : "Data Analitik"}`,
    slug: "analytics",
    url: "/analytics",
    icon: "analytics",
    roles: [Role.Central_admin],
  },
  {
    title: `${lang !== "id" ? "Commission" : "Komisi"}`,
    slug: "commission",
    url: "/commission",
    icon: "account_balance_wallet",
    roles: superadmin,
  },
  {
    title: "Promo",
    slug: "promo",
    url: "/promo",
    icon: "local_offer",
    roles: allAdmin,
  },
  {
    title: "Reward",
    slug: "reward",
    url: "/reward",
    icon: "stars",
    roles: allAdmin,
  },
  {
    title: `${lang !== "id" ? "User" : "Pengguna"}`,
    slug: "user",
    url: "/user",
    icon: "manage_accounts",
    roles: allAdmin,
  },
  {
    title: `${lang !== "id" ? "Product" : "Produk"}`,
    slug: "product",
    url: "/product",
    icon: "verified",
    roles: CAdmin,
  },
  {
    title: "Data Master",
    slug: "data-master",
    url: "/data-master",
    icon: "storage",
    roles: masterBandara,
  }, {
    title: "Audit Trail",
    slug: "data-master",
    url: "/audit_trail",
    icon: "history",
    roles: auditTrailRoles,
  },
];
