import "./pill.scss";
import { withTrans } from "../../../../i18n/withTrans";

const LabelPills = ({ t, data, className }) => {
  return (
    <>
      <div className={`rounded-label mr-2 p-1 mb-1 px-3 ${className}`}>
        {data ? data : t("commons.no_data")}
      </div>
    </>
  );
};
export default withTrans(LabelPills);
