const cardFlushActivatedIcon = ({ icon, active, title, desc }) => {
  return (
    <div className="d-flex justify-content-left">
      <div
        className="rounded-fill"
        style={{
          backgroundColor: active ? "#ecf5eb" : "#fff",
          border: active ? "1px solid #63AE5C" : "1px solid rgb(165, 165, 192)",
        }}
      >
        <span
          className="material-icons"
          style={{
            fontSize: 25,
            color: active ? "#63ae5c" : "#A5A5C0",
          }}
        >
          {icon}
        </span>
      </div>
      <div className="d-flex flex-column ml-3">
        <div className="text-bold">{title}</div>
        <div className="normal-title">
          {desc}
        </div>
      </div>
    </div>
  )
}

export default cardFlushActivatedIcon