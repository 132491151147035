import {
    GET_CORPORATE_PRICE_PENDING,
    GET_CORPORATE_PRICE_SUCCESS,
    GET_CORPORATE_PRICE_ERROR,
    GET_CORPORATE_PRICE_V2_PENDING,
    GET_CORPORATE_PRICE_V2_SUCCESS,
    GET_CORPORATE_PRICE_V2_ERROR,
    FIND_CORPORATE_PENDING,
    FIND_CORPORATE_SUCCESS,
    FIND_CORPORATE_ERROR,
    POST_CORPORATE_PRICE_PENDING,
    POST_CORPORATE_PRICE_SUCCESS,
    POST_CORPORATE_PRICE_ERROR,
    PUT_CORPORATE_PRICE_PENDING,
    PUT_CORPORATE_PRICE_SUCCESS,
    PUT_CORPORATE_PRICE_ERROR,
    DELETE_CORPORATE_PRICE_PENDING,
    DELETE_CORPORATE_PRICE_SUCCESS,
    DELETE_CORPORATE_PRICE_ERROR,
    GET_CORPORATE_ACTIVE_PRICE_PENDING,
    GET_CORPORATE_ACTIVE_PRICE_SUCCESS,
    GET_CORPORATE_ACTIVE_PRICE_ERROR,
    GET_CORPORATE_NO_PRICE_PENDING,
    GET_CORPORATE_NO_PRICE_SUCCESS,
    GET_CORPORATE_NO_PRICE_ERROR,
    DOWNLOAD_CORPORATE_PENDING,
    DOWNLOAD_CORPORATE_SUCCESS,
    DOWNLOAD_CORPORATE_ERROR,
} from "../../../actions/product/corporate_price";

const initialState = {
    pending: false,
    error: null,
    data_list: null,
    pending_find: false,
    data_find: null,
    pending_post: false,
    data_post: null,
    pending_put: false,
    data_put: false,
    pending_delete: false,
    data_delete: null,
    data_active: null
};

const corp_price = (state = initialState, action) => {
    switch (action.type) {
        case GET_CORPORATE_PRICE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_CORPORATE_PRICE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_CORPORATE_PRICE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case GET_CORPORATE_PRICE_V2_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_CORPORATE_PRICE_V2_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case GET_CORPORATE_PRICE_V2_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case POST_CORPORATE_PRICE_PENDING:
            return {
                ...state,
                pending_post: true,
            };
        case POST_CORPORATE_PRICE_SUCCESS:
            return {
                ...state,
                pending_post: false,
                data_post: action.data,
            };
        case POST_CORPORATE_PRICE_ERROR:
            return {
                ...state,
                pending_post: false,
                error: action.error,
            };
        case PUT_CORPORATE_PRICE_PENDING:
            return {
                ...state,
                pending_put: true,
            };
        case PUT_CORPORATE_PRICE_SUCCESS:
            return {
                ...state,
                pending_put: false,
                data_put: action.data,
            };
        case PUT_CORPORATE_PRICE_ERROR:
            return {
                ...state,
                pending_put: false,
                error: action.error,
            };
        case DELETE_CORPORATE_PRICE_PENDING:
            return {
                ...state,
                pending_delete: true,
            };
        case DELETE_CORPORATE_PRICE_SUCCESS:
            return {
                ...state,
                pending_delete: false,
                data_delete: action.data,
            };
        case DELETE_CORPORATE_PRICE_ERROR:
            return {
                ...state,
                pending_delete: false,
                error: action.error,
            };
        case FIND_CORPORATE_PENDING:
            return {
                ...state,
                pending_find: true,
            };
        case FIND_CORPORATE_SUCCESS:
            return {
                ...state,
                pending_find: false,
                data_find: action.data,
            };
        case FIND_CORPORATE_ERROR:
            return {
                ...state,
                pending_find: false,
                error: action.error,
            };
        case GET_CORPORATE_ACTIVE_PRICE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_CORPORATE_ACTIVE_PRICE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_active: action.data,
            };
        case GET_CORPORATE_ACTIVE_PRICE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
                };
        case GET_CORPORATE_NO_PRICE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case GET_CORPORATE_NO_PRICE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_active: action.data,
            };
        case GET_CORPORATE_NO_PRICE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
                };
        case DOWNLOAD_CORPORATE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case DOWNLOAD_CORPORATE_SUCCESS:
            return {
                ...state,
                pending: false,
                data_list: action.data,
            };
        case DOWNLOAD_CORPORATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default corp_price;
