import Chart from "chart.js/auto";
import React, { useState, useRef, useEffect } from 'react';
import _uniqueId from 'lodash/uniqueId';

let chart;

const BarChart = ({
  height = 250,
  labels = ["Jan", "Feb", "March"],
  options = {
    yTickDisplay: true,
  },
  datasets = [
    {
      label: "Sales",
      data: [86, 67, 91],
      fill: false,
    }
  ],
  pending_chart,
  data_length
}) => {

  const graphicRef = useRef(null)
  const [id] = useState(_uniqueId('barchart-'));

  useEffect(() => {
    buildChart();
  });

  const buildChart = () => {
    if (typeof chart !== "undefined") chart.destroy();
    const myChartRef = graphicRef.current.getContext("2d");

    datasets.forEach(elem => {
      if(elem.fill){
        
        let bgStartColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, '.6)') : "rgba(0, 166, 207, .6)";
        let bgStopColor = "borderColor" in elem ? elem.borderColor.replace(/[\d\.]+\)$/g, '0)') : "rgba(0, 166, 207, 0)";
        
        let bgGradient = myChartRef.createLinearGradient(0, 0, 0, height);
        bgGradient.addColorStop(0, bgStartColor);
        bgGradient.addColorStop(1, bgStopColor);

        elem.backgroundColor = bgGradient;
      }
    });

    chart = new Chart(myChartRef, {
      type: "bar",
      data: {
        //Bring in data
        labels: labels,
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }

                if (context.parsed.y !== null) {
                  if (context.dataset.nonParsed) {
                    label += context.parsed.y
                  } else if (context.dataset.isPercentage) {
                    label += context.parsed.y+'%'
                  } else {
                    label += new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    }).format(context.parsed.y);
                  }
                }

                return [label];
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          align: 'start',
        },
        scales: {
          y: {
            id: 'y',
            position: 'left',
            type: 'linear',
            // ticks: {
            //   display: "yTickDisplay" in options ? options.yTickDisplay : true,
            //   stepSize: 50,
            // },
            // gridLines: {
            //   display: "yGridDisplay" in options ? options.yGridDisplay : true,
            //   // drawOnChartArea: "xdrawOnChartArea" in options ? options.xdrawOnChartArea : true,
            // },
            // barPercentage: 0.4,
          },
          y1: {
            id: 'y1',
            position: 'right',
            type: 'linear',
            ticks: {
              stepSize: 20,
              callback: function(label, index, labels) {
                return label+'%';
              }
            },
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
          x: {
            ticks: {
              display: "xTickDisplay" in options ? options.xTickDisplay : true,
            },
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            
          },
        }
      }
    });
  };

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <div style={{ width: (data_length * 80) + 'px', height: height + "px", position: "relative" }}>
        <canvas id={id} ref={graphicRef} />
      </div>
    </div>
  );
};

export default BarChart;
