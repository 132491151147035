import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/forms/Input";
import Select2 from "../../../components/forms/Select2";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import Button from "../../../components/commons/buttons/Button";
import { Divider } from "@mui/material";
import SearchButton from "../../../components/commons/buttons/SearchButton";
import UserAssistant from "../../../store/actions/master_data/assistant";
import moment from "moment";
import { getUser } from "../../../utils/User";
import ConfirmationModal from "../../../components/commons/modal/ConfirmModal";
import { RoleGroups } from "../../../utils/Constants";

const AssignAssistantModal = ({
  t,
  show,
  toggle,
  action,
  data,
  id,
  confirm,
  pending,
}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const currentUser = getUser();

  const [searchText, setSearchText] = useState("");
  const [assistant, setAssistant] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedProps, setSelectedProps] = useState(null);

  const handleRefresh = () => {
    new Promise((resolve) => {
      let param = {
        length: 99999,
        search_text: searchText,
        order_id: id,
      };
      dispatch(UserAssistant.getAssignAssistant(param, resolve));
    }).then((res) => {
      setAssistant(res.data);
    });
  };

  const showConfirmation = (id, props) => {
    setConfirmModal(!confirmModal);
    setSelectedId(id);
    setSelectedProps(props);
  };

  const handleAddAssistances = (id, formik) => {
    let datas = formik?.values?.list_assistans ?? [];
    let i = datas ? datas.indexOf(id) : -1;
    if (i > -1) {
      datas.splice(i, 1);
    } else {
      datas = [...datas, id];
    }
    datas = datas;
    formik?.setFieldValue("list_assistans", datas);
  }

  const handleAssistanceIds = () => {
    let datas = selectedProps?.values?.list_assistans ?? [];
    let val = {
      id: data.id,
    };
    let assistants = [];
    datas.map((item, index) => {
      let objAssistant = { assistant_id: item, is_main: false };
      if (index === 0) {
        objAssistant.is_main = true;
      }
      assistants = [...assistants, objAssistant]
    })
    val.assistant_list = assistants;
    if(RoleGroups.subAdminRoles.includes(currentUser?.user?.role_code)) {
      val.order_from = "web-admin";
    }
    if (assistants.length > 0) {
      if (data?.list_order_passengers?.length === 1 && assistants.length > 1) {
        toastError(t('booking.asstCantMoreThanOne'))
      } else {
        action(val);
      }
    } else {
      toastError(t('commons.empty_data_message'))
    }

    setConfirmModal(false);
  }

  useEffect(() => {
    if (show === true) {
      handleRefresh();
    }
  }, [show, searchText]);

  const initial = data
    ? {
      list_assistans: [],
    }
    : {
      list_assistans: []
    };

  const validation = Yup.object().shape({
    list_assistans: Yup.array().required(t("commons.required")).nullable(),
  });

  const handleSearch = (value) => {
    setSearchText(value.length > 0 ? value : "");
  };

  const ButtonShow = ({ id, props }) => {
    let values = props?.values?.list_assistans ?? [];
    let isSelected = values && values?.length > 0 ? values?.includes(id) : false;
    let title = isSelected ? t('status.assigned') :  t('commons.assign');
    let buttonType = isSelected ? "secondary" : "primary";
    return (
      <div>
        <Button
          title={title}
          type={'button'}
          rounded={true}
          variant={buttonType}
          confirmTitle={t("commons.save")}
          onClick={() => handleAddAssistances(id, props)}
          icon
        />
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {t("booking.availableAssistant")}{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <SearchButton
              placeholder={t("commons.enterKeyword")}
              toggle={(value) => handleSearch(value)}
              showStyle={true}
              width={{ width: "400px" }}
            />
            <div
              style={{
                maxHeight: "400px",
                overflowY: "scroll",
                overflowX: "clip",
              }}
              className="mt-4"
            >
              <Formik
                enableReinitialize
                initialValues={initial}
              >
                {(props) => (
                  <Form>
                    {assistant?.map((item, index) => {
                      return (
                        <React.Fragment key={`${item.id}`}>
                          <div className="my-3 d-flex justify-content-between">
                            <div className="desc">
                              <div className="text-extra-bold">{item.name}</div>
                              <div className="normal-title">
                                {item.gender === 1
                                  ? t("commons.men")
                                  : t("commons.woman")}
                              </div>
                            </div>
                            <div className="trigger">
                              <ButtonShow
                                id={item.id}
                                props={props}
                              />
                            </div>
                          </div>
                          <Divider orientation="horizontal" />
                        </React.Fragment>
                      )
                    }
                    )}
                    {assistant?.length < 1 && (
                      <div className="text-center">
                        {t("commons.dataNotFound")}
                      </div>
                    )}
                    <div style={{ marginTop: 50 }}>
                      <ButtonModal
                        toggle={toggle}
                        confirmTitle={t("commons.save")}
                        cancelTitle={t("commons.cancel")}
                        confirm={() => showConfirmation(id, props)}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <ConfirmationModal
        show={confirmModal}
        toggle={() => setConfirmModal(false)}
        confirm={handleAssistanceIds}
        icon="info"
        message={t("commons.saveConfirm")}
        isDelete
      />
    </>
  );
};

export default withTrans(AssignAssistantModal);
