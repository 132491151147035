import { history } from "../../../../utils/History";
import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_AIRPORT_PENDING = "GET_AIRPORT_PENDING";
export const GET_AIRPORT_SUCCESS = "GET_AIRPORT_SUCCESS";
export const GET_AIRPORT_ERROR = "GET_AIRPORT_ERROR";
export const GET_DETAIL_AIRPORT_PENDING = "GET_DETAIL_AIRPORT_PENDING";
export const GET_DETAIL_AIRPORT_SUCCESS = "GET_DETAIL_AIRPORT_SUCCESS";
export const GET_DETAIL_AIRPORT_ERROR = "GET_DETAIL_AIRPORT_ERROR";
export const GET_AIRPORT_LOUNGE_PENDING = "GET_AIRPORT_LOUNGE_PENDING";
export const GET_AIRPORT_LOUNGE_SUCCESS = "GET_AIRPORT_LOUNGE_SUCCESS";
export const GET_AIRPORT_LOUNGE_ERROR = "GET_AIRPORT_LOUNGE_ERROR";
export const POST_AIRPORT_PENDING = "POST_AIRPORT_PENDING";
export const POST_AIRPORT_SUCCESS = "POST_AIRPORT_SUCCESS";
export const POST_AIRPORT_ERROR = "POST_AIRPORT_ERROR";
export const PUT_AIRPORT_PENDING = "POST_AIRPORT_PENDING";
export const PUT_AIRPORT_SUCCESS = "POST_AIRPORT_SUCCESS";
export const PUT_AIRPORT_ERROR = "POST_AIRPORT_ERROR";
export const DELETE_AIRPORT_PENDING = "DELETE_AIRPORT_PENDING";
export const DELETE_AIRPORT_SUCCESS = "DELETE_AIRPORT_SUCCESS";
export const DELETE_AIRPORT_ERROR = "DELETE_AIRPORT_ERROR";

// URL: URL_{URL}
const AIRPORT_URL = `v1/master_data/airport`;
const AIRPORT_LOUNGE_LIST_URL = `v1/master_data/airport/lounge/list`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_AIRPORT_PENDING));
  API.get(AIRPORT_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_AIRPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_AIRPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const detail = (param, resolve, callback) => (dispatch) => {
  dispatch(actionPending(GET_DETAIL_AIRPORT_PENDING));
  API.get(AIRPORT_URL + "/detail", { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_DETAIL_AIRPORT_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
        });
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_DETAIL_AIRPORT_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(POST_AIRPORT_PENDING));
  API.post(AIRPORT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(POST_AIRPORT_SUCCESS, res));
      toastSuccess(lang === 'id' ? "Berhasil Tambah Data Bandara" : "Airport Data Added Successfully");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(POST_AIRPORT_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(PUT_AIRPORT_PENDING));
  API.put(AIRPORT_URL, param)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(PUT_AIRPORT_SUCCESS, res));
      toastSuccess(lang === 'id' ? "Berhasil Ubah Data Bandara" : "Airport Data Changed Successfully");
      if (resolve) {
        resolve({
          data: res.data.data,
          message: res.data.message,
          status: res.data.status,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(PUT_AIRPORT_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const deleted = (param, callback, name) => (dispatch) => {
  dispatch(actionPending(DELETE_AIRPORT_PENDING));
  API.delete(AIRPORT_URL + `?id=${param}`)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(DELETE_AIRPORT_SUCCESS, res));
      toastSuccess(lang === 'id' ? `Berhasil Hapus Data Bandara ${name}` : `Airport ${name} Removed Successfully`);
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(DELETE_AIRPORT_ERROR));
      if (lang === 'id') {
        toastError(err?.response?.data?.message);
      } else if (lang === "en-US" || lang === "en") {
        toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
      }
    });
};

const getListLounge = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_AIRPORT_LOUNGE_PENDING));
  // API.get(AIRPORT_LOUNGE_LIST_URL, { params: param })
  let airportIdString = ""
  param.airport_ids.forEach(id => {
    airportIdString = airportIdString + "&airport_ids=" + id
  })
  
  API.get(AIRPORT_LOUNGE_LIST_URL + `?length=${param.length}` + airportIdString)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_AIRPORT_LOUNGE_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page - 1) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page - 1,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_AIRPORT_LOUNGE_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const airport = {
  get,
  detail,
  post,
  put,
  deleted,
  getListLounge,
};
export default airport;
