import { actionPending, actionSuccess, actionError } from "../actionTypes";

import { toastSuccess, toastError } from "../../../components/commons/toast";
import API from "../API";
import { handleResponse } from "../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_ANALYTICS_PENDING = "GET_ANALYTICS_PENDING";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_ERROR = "GET_ANALYTICS_ERROR";
export const GET_PARETO_PENDING = "GET_PARETO_PENDING";
export const GET_PARETO_SUCCESS = "GET_PARETO_SUCCESS";
export const GET_PARETO_ERROR = "GET_PARETO_ERROR";
export const GET_PARETO_TOTAL_PENDING = "GET_PARETO_TOTAL_PENDING";
export const GET_PARETO_TOTAL_SUCCESS = "GET_PARETO_TOTAL_SUCCESS";
export const GET_PARETO_TOTAL_ERROR = "GET_PARETO_TOTAL_ERROR";

// URL: URL_{URL}
const GRAFIK_PARETO_URL = `v1/report/data-analytic/pareto`;
const PIE_CHART_PARETO_URL = `v1/report/data-analytic/sales-contribution`;
const TABLE_PARETO_URL = `v1/report/data-analytic/pareto/table`;
const TOTAL_TABLE_PARETO_URL = `v1/report/data-analytic/pareto/table/total`;
const TOTAL_USER_URL = `v1/report/data-analytic/user/total-user`;

const lang = localStorage.getItem('joumpa_language')

const getGrafikPareto = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_PARETO_PENDING));
  API.get(GRAFIK_PARETO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_PARETO_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
        }));
        resolve({
          data: data,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_PARETO_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getPieChartPareto = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ANALYTICS_PENDING));
  API.get(PIE_CHART_PARETO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ANALYTICS_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ANALYTICS_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getTablePareto = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ANALYTICS_PENDING));
  API.get(TABLE_PARETO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ANALYTICS_SUCCESS, res));
      if (resolve) {
        const records_total = res.data.total;
        let data = res.data.data.map((item, i) => ({
          ...item,
          no: i + 1 + (param?.page) * param?.length,
        }));
        resolve({
          data: data,
          page: param?.page,
          totalCount: records_total,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ANALYTICS_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getTotalTablePareto = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ANALYTICS_PENDING));
  API.get(TOTAL_TABLE_PARETO_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ANALYTICS_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          page: param?.page - 1,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ANALYTICS_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const getTotalUser = (param, resolve, reject, callback) => (dispatch) => {
  dispatch(actionPending(GET_ANALYTICS_PENDING));
  API.get(TOTAL_USER_URL, { params: param })
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch(actionSuccess(GET_ANALYTICS_SUCCESS, res));
      if (resolve) {
        resolve({
          data: res.data.data,
          page: param?.page - 1,
        });
      }
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      dispatch(actionError(GET_ANALYTICS_ERROR));
      let not_logout = handleResponse(err?.response);
      if (not_logout) {
        if (lang === 'id') {
          toastError(err?.response?.data?.message);
        } else if (lang === "en-US" || lang === "en") {
          toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
        }
      }
    });
};

const data_analytics = {
  getPieChartPareto,
  getGrafikPareto,
  getTablePareto,
  getTotalTablePareto,
  getTotalUser,
};
export default data_analytics;
