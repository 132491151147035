import { Formik, Form } from "formik";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withTrans } from "../../../i18n/withTrans";
import { payment_list, Role } from "../../../utils/Constants";
import Select2 from "../../../components/forms/Select2";
import Select2Multi from "../../../components/forms/Select2Multi";
import ButtonModal from "../../../components/commons/modal/ButtonModal";
import DynamicButton from "../../../components/forms/DynamicButton";
import DatePicker from "../../../components/forms/DateRangePicker";
import moment from "moment";

function ModalFilter({
  t,
  show,
  airport,
  product,
  corporate,
  toggle,
  filter,
  toggleFilter,
  isDelete,
  onApplyFilter,
  user
}) {

  const handleReset = (formik) => {
    formik.setFieldValue("start_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("end_date", moment().format("YYYY-MM-DD"));
    formik.setFieldValue("product_id", "");
    formik.setFieldValue("airport_id", "");
    formik.setFieldValue("corporate_id", "");
    formik.setFieldValue("booking_type", "");
    formik.setFieldValue("payment_type", "");
    formik.setFieldValue("report_type", true);
    toggleFilter({
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      product_id: "",
      airport_id: "",
      corporate_id: "",
      booking_type: "",
      payment_type: "",
      report_type: true,
    });
    toggle();
  };

  return (
    <>
      <Modal isOpen={show} modalTransition={{ timeout: 200 }}>
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          Filter{" "}
          <span className="close clickable" onClick={toggle}>
            &times;
          </span>
        </ModalHeader>
        <ModalBody style={{ margin: "0px 20px" }}>
          <DynamicButton titleLeft={t("field.chooseFilter")} iconLeft="info" />
          <Formik
            initialValues={{
              start_date: filter?.start_date ?? "",
              end_date: filter?.end_date ?? "",
              airport_id: filter?.airport_id ?? "",
              product_id: filter?.product_id ?? "",
              corporate_id: filter?.corporate_id ?? "",
              booking_type: filter?.booking_type ?? "",
              payment_type: filter?.payment_type ?? "",
              report_type: filter?.report_type ?? true,
            }}
            enableReinitialize
            onSubmit={(values) => {
              onApplyFilter({
                airport_id: values.airport_id,
                start_date:
                  values.start_date === "" || values.start_date === undefined
                    ? ""
                    : moment(values.start_date).format("YYYY-MM-DD"),
                end_date:
                  values.end_date === "" || values.end_date === undefined
                    ? ""
                    : moment(values.end_date).format("YYYY-MM-DD"),
                product_id: values.product_id,
                booking_type: values.booking_type,
                payment_type: values.payment_type,
                corporate_id: values.corporate_id,
                report_type: values.report_type,
              });
              toggle();
            }}
          >
            {(props) => (
              <Form style={{ marginTop: 30 }}>
                <DatePicker
                  title={t("report.serviceTime")}
                  name="start_date"
                  handlechange={(startDate, endDate) => {
                    props.setFieldValue("start_date", startDate);
                    props.setFieldValue("end_date", endDate);
                  }}
                  format="DD MMMM YYYY"
                  startDate={filter ? filter?.start_date : props.values?.start_date}
                  endDate={filter ? filter?.end_date : props.values?.end_date}
                  className="mx-auto"
                  errors={props.errors}
                  touched={props.touched}
                />
                
                <Select2Multi
                  title={t("field.airport")}
                  placeholder={`${t("commons.all")} ${t("field.airport")}`}
                  name="airport_id"
                  options={airport}
                  className="mb-2"
                  errors={props?.errors}
                  touched={props?.touched}
                  value={props?.values?.airport_id}
                  onChange={(name, value) => {
                    const data = value;
                    props?.setFieldValue("airport_id", data);
                  }}
                  onBlur={props?.setFieldTouched}
                />
                <Select2
                  title={t("report.agent")}
                  name="corporate_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("report.agent")}`,
                    },
                    ...corporate,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.corporate_id}
                  onChange={(name, value) => {
                    props.setFieldValue("corporate_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.product")}
                  name="product_id"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.product")}`,
                    },
                    ...product,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.product_id}
                  onChange={(name, value) => {
                    props.setFieldValue("product_id", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.bookingType")}
                  name="booking_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t(
                        "field.bookingType"
                      )}`,
                    },
                    {
                      value: "Mobile",
                      label: t("field.mobileApp"),
                    },
                    {
                      value: "Web Public",
                      label: t("field.webPublic"),
                    },
                    {
                      value: "Web Admin",
                      label: t("field.webAdmin"),
                    },
                    {
                      value: "api",
                      label: "API",
                    },
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.booking_type}
                  onChange={(name, value) => {
                    props.setFieldValue("booking_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                <Select2
                  title={t("field.paymentType")}
                  name="payment_type"
                  options={[
                    {
                      value: "",
                      label: `${t("commons.all")} ${t("field.paymentType")}`,
                    },
                    ...payment_list,
                  ]}
                  errors={props.errors}
                  touched={props.touched}
                  value={props.values?.payment_type}
                  onChange={(name, value) => {
                    props.setFieldValue("payment_type", value);
                  }}
                  onBlur={props.setFieldTouched}
                />
                {user.role_code === Role.Superadmin && (
                  <Select2
                    title={t("report.reportType")}
                    name="report_type"
                    options={[
                      {
                        value: true,
                        label: t("commons.all"),
                      },{
                        value: false,
                        label: t("report.withoutFreeGrantTotal")
                      }
                    ]}
                    errors={props.errors}
                    touched={props.touched}
                    value={props.values?.report_type}
                    onChange={(name, value) => {
                      props.setFieldValue("report_type", value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                )}
               
                <div style={{ marginTop: 50, marginBottom: 30 }}>
                  <ButtonModal
                    toggle={toggle}
                    reset={() => handleReset(props)}
                    deleted={isDelete}
                    confirmTitle={t("field.apply")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTrans(ModalFilter);
