import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import moment from "moment";

import ModalFilter from "./ModalFilter";
import Table from "../../../components/commons/table";
import Button from "../../../components/commons/buttons/Button";
import { withTrans } from "../../../i18n/withTrans";

import ReportService from "../../../store/actions/report/";
import { RoleGroups } from "../../../utils/Constants";

const ReportAPI = ({ t, user, airports, products }) => {
  const dispatch = useDispatch();
  const [dataReport, setdataReport] = useState([]);
  const [totalData, setTotalData] = useState(null);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paramFilter, setParamFilter] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
    airport_id:
      RoleGroups.subAdminRoles.includes(user.role_code)
        ? user.airport_id
        : "",
    product_id: "",
  });

  const handleFilterModal = () => {
    setShowModalFilter(true);
  };

  const onApplyFilter = (param) => {
    setParamFilter({
      start_date: param.start_date,
      end_date: param.end_date,
      airport_id: param.airport_id,
      product_id: param.product_id,
    });
  };

  const handleRefresh = () => {
    new Promise((resolve) => {
      const param = {
        ...paramFilter,
        corporate_id: "",
        page: page === 0 ? page : page - 1,
        length: 10,
      };
      dispatch(ReportService.getAPIReport(param, resolve));
    }).then((res) => {
      setdataReport(res?.data);
      setTotalData(res?.totalCount);
      setMaxPage(Math.ceil(res?.totalCount / 10));
    });
  };

  useEffect(() => {
    handleRefresh();
  }, [page, paramFilter]);

  useEffect(() => {
    setPage((prev) => (prev === null ? null : 0));
  }, [paramFilter]);

  const handleDownloadReport = (id) => {
    new Promise((resolve) => {
      let param = {
        ...paramFilter,
        corporate_id: id
      }
      setLoadingDownload(id);
      dispatch(ReportService.downloadAPIReport(param, resolve))
    }).then((res) => {
      setLoadingDownload(false);
    });
  };

  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  };

  
  const column = [
    {
      Header: "NO",
      accessor: "no",
    },
    {
      Header: "API INTEGRATION",
      accessor: "corporate",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.corporate ?? "";
      },
    },
    {
      Header: "TOTAL PAX",
      accessor: "total_pax",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return original?.total_pax ?? 0;
      },
    },
    {
      Header: "TOTAL REVENUE",
      accessor: "total_sub_total",
      Cell: ({ row }) => {
        const original = row ? row?.original : [];
        return (
          <>
            {"Rp " +
              (original?.total_sub_total
                ? numberWithCommas(original?.total_sub_total)
                : 0)}
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "ID",
      Cell: ({ row }) => (
        <Button
          title={(
            <>
              {loadingDownload === row?.original?.id && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              {t("report.downloadReport")}
            </>
          )}
          leftIcon={loadingDownload !== row?.original?.id ? "download" : null}
          rounded={true}
          variant="button-light"
          onClick={() => handleDownloadReport(row?.original?.id)}
        />
      ),
      maxWidth: '150px'
    },
  ];

  return (
    <>
      <div className="d-flex mb-4 justify-content-end">
        <Button
          title="Filter"
          size="sm"
          rounded={true}
          variant="button-light btn-lg"
          onClick={handleFilterModal}
        />
      </div>
      <Table
        columns={column.filter((item) => item.show !== false)}
        data={dataReport ?? []}
        pagination={{ page, totalData, maxPage, toggle: setPage }}
      />
      
      <ModalFilter
        user={user}
        show={showModalFilter}
        toggle={() => setShowModalFilter(false)}
        filter={paramFilter}
        airport={airports}
        product={products}
        toggleFilter={() => {
          setParamFilter({});
          handleRefresh();
        }}
        onApplyFilter={onApplyFilter}
      />
    </>
  )
}

export default withTrans(ReportAPI)