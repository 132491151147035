const Index = ({
  title,
  subtitle,
  toggle,
  active,
  checkbox,
  checked,
  icon,
}) => {
  return (
    <div
      className={`wrapper-detail-card side-menu-item ${
        active ? "active" : ""
      } ${checkbox ? "side-menu-checkbox" : "side-menu-border-image"}`}
      onClick={toggle}
      style={{ padding: "29px 20px !important" }}
    >
      {checkbox && (
        <div className="side-checkbox-container">
          <label className="side-checkbox">
            <p className="side-menu-title text-extra-bold">{title}</p>
            <input type="checkbox" checked={checked} disabled />
            {checked && (
              <span
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#63AE5C",
                  borderColor: "#63AE5C",
                  color: "#FFFFFF",
                }}
                className="checkmark material-icons-round icon-only"
              >
                done
              </span>
            )}
            {!checked && (
              <span
                className="checkmark"
                style={{ borderRadius: "50%" }}
              ></span>
            )}
          </label>
        </div>
      )}

      {!checkbox && (
        <>
          <div className="side-menu-icon">
            <span
              className="material-icons-round mr-3"
              style={{ color: "#63AE5C", padding: 0 }}
            >
              {icon}
            </span>
          </div>
          <div className="side-menu-info">
            <div className="side-menu-title text-extra-bold">{title}</div>
          </div>
          <div className="side-menu-icon">
            <span className="material-icons-round">navigate_next</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
