import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";

import Card from "../../../components/commons/cards/card";
import CardHeader from "../../../components/commons/cards/cardHeader";
import CardBody from "../../../components/commons/cards/cardBody";
import CardSelectionProduct from "../../../components/cardSelectionProduct";
import Input from "../../../components/forms/Input";
import InputPrepend from "../../../components/forms/InputPrepend";
import Select2 from "../../../components/forms/Select2";
import Checkbox from "../../../components/forms/Checkbox";
import DynamicButton from "../../../components/forms/DynamicButton";
import { getUser } from "../../../utils/User";
import { toastError } from "../../../components/commons/toast";
import { withTrans } from "../../../i18n/withTrans";
import { RoleWithAirport, product_list } from "../../../utils/Constants";
import { calculateTimeDifference, getTransferFlightTypeCode, minDateBooking } from "../../../utils/Helper";

//SERVICE
import AirportService from "../../../store/actions/master_data/airport";
import AirlineService from "../../../store/actions/master_data/airline";
import ProductService from "../../../store/actions/master_data/product";
import TransferFlightInformation from "./TransferFlightInformation";

const FlightReservation = ({ currentUrl, formik, button, t, reg_price }) => {
  const dispatch = useDispatch();
  const currentUser = getUser();
  const role = currentUser?.user?.role_code;
  const airportId = currentUser?.user?.airport_id;
  const minDate = minDateBooking(role, currentUrl);

  const [dataAirport, setDataAirport] = useState([]);
  const [dataAirportDomestic, setDataAirportDomestic] = useState([]);
  const [dataAirportTransfer, setDataAirportTransfer] = useState([]);
  const [dataAdminBandara, setDataAdminBandara] = useState([]);
  const [dataAirline, setDataAirline] = useState([]);
  const [dataAirlineTransfer, setDataAirlineTransfer] = useState([]);
  const [checkedCrossSelling, setCheckedCrossSelling] = useState(null);
  const [searchTextAirport, setSearchTextAirport] = useState([]);
  const [searchAirportDomestic, setSearchAirportDomestic] = useState([]);
  const [searchAirportTransfer, setSearchAirportTransfer] = useState([]);
  const [searchTextAirline, setSearchTextAirline] = useState([]);
  const [searchTextAirlineTransfer, setSearchTextAirlineTransfer] = useState([]);
  const [haveAirport] = useState(
    RoleWithAirport?.includes(currentUser?.user.role_code)
      ? true
      : false
  );
  const showCrossSelling = haveAirport && currentUrl !== 'booking-contact-center';

  const setFormikAirportFieldValues = (airport_list) => {
    const airport_name = `${airport_list?.code} - ${airport_list?.city} - ${airport_list?.name}`;

    if (formik?.values?.flight_type === 0) {
      formik.setFieldValue("airport_id_to", airport_list?.id);
      formik.setFieldValue("airport_name_to", airport_name);
      formik.setFieldValue("airport_uniform_to", airport_list?.uniform);
    } else if (formik?.values?.flight_type === 1) {
      formik.setFieldValue("airport_id_from", airport_list?.id);
      formik.setFieldValue("airport_name_from", airport_name);
      formik.setFieldValue("airport_uniform_from", airport_list?.uniform);
    } else if (formik?.values?.flight_type === 2) {
      formik.setFieldValue("airport_id_transfer", airport_list?.id);
      formik.setFieldValue("airport_name_transfer", airport_name);
      formik.setFieldValue("airport_uniform_transfer", airport_list?.uniform);
    }
  }
  
  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportDomestic,
        id: !checkedCrossSelling ? airportId : "",
        is_service: true,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      if (!(res && res?.data)) {
        return;
      }

      setDataAirportDomestic(res?.data);

      const list = res.data.map(airport => ({
        ...airport,
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name}`,
      }));

      if (!airportId) return;
      setDataAdminBandara(list);

      if (checkedCrossSelling) return;
      setFormikAirportFieldValues(list[0]);
    });
  }, [searchAirportDomestic, formik?.values.flight_type, checkedCrossSelling]);

  useEffect(() => {
    const { flight_type, airport_uniform_from, airport_uniform_to } = formik?.values;
    const flight_transfer_code = getTransferFlightTypeCode(airport_uniform_from, airport_uniform_to);
    const flight_type_code = flight_type === 0 ? 5 : flight_type === 1 ? 6 : flight_transfer_code;

    formik.setFieldValue("flight_type_code", flight_type_code);
  }, [
    formik?.values?.flight_type,
    formik?.values?.airport_uniform_from,
    formik?.values?.airport_uniform_to,
  ]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirport,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirport(res?.data);
    });
  }, [searchTextAirport, formik?.values.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchAirportTransfer,
      };
      dispatch(AirportService.get(param, resolve));
    }).then((res) => {
      setDataAirportTransfer(res?.data);
    });
  }, [searchAirportTransfer, formik?.values.flight_type]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirline,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirline(res?.data);
    });
  }, [searchTextAirline]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 10,
        search_text: searchTextAirlineTransfer,
      };
      dispatch(AirlineService.get(param, resolve));
    }).then((res) => {
      setDataAirlineTransfer(res?.data);
    });
  }, [searchTextAirlineTransfer]);

  useEffect(() => {
    new Promise((resolve) => {
      let param = {
        page: 1,
        length: 9999999,
      };
      dispatch(ProductService.get(param, resolve));
    }).then((res) => {
      formik.setFieldValue("data_product_list", res?.data);
    });
  }, []);

  useEffect(() => {
    if (
      formik?.values.flight_type >= 0 &&
      formik?.values?.airport_id_from &&
      formik?.values?.airport_id_to 
    ) {
      let isInternational =
        formik?.values?.airport_uniform_from !== "Indonesia" ||
        formik?.values?.airport_uniform_to !== "Indonesia";

      if (
        formik?.values.flight_type !== 2 &&
        formik?.values?.data_product_list?.length >= 1
      ) {
        if (isInternational) {
          formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 1));
        } else {
          formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 2));
        }
      } else {
        formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 4));
      }
    }
  }, [
    formik?.values?.airport_id_from,
    formik?.values?.airport_id_to,
    formik?.values.flight_type,
  ]);

  useEffect(() => {
    if (formik?.values?.product_type <= 0) {
      return;
    }

    const serviceInAvailableServices = 
      formik?.values?.list_available_services?.find(
        (item) => item?.type === formik?.values?.product_type
      );
    
    if (!serviceInAvailableServices) {
      formik.setFieldValue("product_type", 0);
    }
  }, [formik?.values?.list_available_services])

  useEffect(() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HH:mm');
    const selectedDate = formik?.values?.date || currentDate;
    const selectedTime = formik?.values?.time || currentTime;

    const timeDifference = calculateTimeDifference(selectedDate, selectedTime);

    formik.setFieldValue('isGoShow', timeDifference >= 0 && currentDate === selectedDate);
  }, [
    formik?.values?.date,
    formik?.values?.time,
  ]);

  const debouncedResults = debounce((value, type) => {
    if (value === "") return;
    if (type === "domestic") setSearchAirportDomestic(value);
    if (type === "transfer") setSearchAirportTransfer(value);
    if (type === "inter") setSearchTextAirport(value);
  }, 500);

  const mapAirportData = (data, selectedAirport, list) => {
    const airports = [...list];
    data.forEach((airport) => {
      airports.push({
        value: airport?.id,
        label: `${airport?.code}-${airport?.city}-${airport?.name} ${
          airport?.uniform === "Indonesia" ? "" : "-" + airport?.uniform
        }`,
      });
    });
  
    //if there is no selectedAirport data in the list airports,
    //push selectedAirport so airport search results can still appear in the dropdown
    const index = airports.findIndex((x) => x?.value === selectedAirport?.value);
    if (index === -1 && selectedAirport) {
      airports.push(selectedAirport);
    }
  
    return airports?.filter((item) => Object.keys(item).length);
  };

  //Assign Array Data Airport International
  let airport_list = mapAirportData(
    dataAirport,
    formik?.values?.selectedAirport,
    []
  );

  //Assign Array Data Airport Transfer
  let airport_list_transfer = mapAirportData(
    dataAirportTransfer,
    formik?.values?.selectedAirportTransfer,
    []
  );

  //Assign Array Data Airport Domestic
  let airport_list_indo = mapAirportData(
    dataAirportDomestic.filter(airport => airport.uniform === "Indonesia"),
    formik?.values?.selectedAirportDomestic,
    []
  );

  const mapAirlineData = (data, selectedAirline, list) => {
    const airlines = [...list];
    data.forEach((airline) => {
      airlines.push({
        value: airline?.name,
        code: airline?.code,
        label: `${airline?.code} - ${airline?.name}`,
      });
    });

    //if there is no selectedAirline data in the list airlines,
    //push selectedAirline so airline search results can still appear in the dropdown
    const index = airlines.findIndex((x) => x?.value === selectedAirline?.value);
    if (index === -1 && selectedAirline) {
      airlines.push(selectedAirline);
    }
  
    return airlines?.filter((item) => Object.keys(item).length);
  };

  //Assign Array Data Airline
  let airline_list = mapAirlineData(
    dataAirline,
    formik?.values?.selectedAirline,
    []
  );

  //Assign Array Data Airline Transfer
  let airline_transfer = mapAirlineData(
    dataAirlineTransfer,
    formik?.values?.selectedAirlineTransfer,
    []
  );
  
  const clearFormFields = () => {
    formik.setFieldValue("airplane_code", null);
    formik.setFieldValue("airplane_name", null);
    formik.setFieldValue("airplane_number", null);
    formik.setFieldValue("date", null);
    formik.setFieldValue("time", null);
    setSearchAirportDomestic("");
    setSearchAirportTransfer("");
    setSearchTextAirport("");
    setSearchTextAirline("");
    setSearchTextAirlineTransfer("");
  }

  const setAirportFields = (type, airportData) => {
    const airport = airportData[0];
    const airportFields = {
      id: `airport_id_${type}`,
      name: `airport_name_${type}`,
      uniform: `airport_uniform_${type}`,
    };
    
    if (!checkedCrossSelling && haveAirport) {
      formik.setFieldValue(airportFields.id, airport?.id);
      formik.setFieldValue(airportFields.name, `${airport?.code} - ${airport?.city} - ${airport?.name}`);
      formik.setFieldValue(airportFields.uniform, airport?.uniform);
    } else {
      formik.setFieldValue(airportFields.id, null);
      formik.setFieldValue(airportFields.name, null);
      formik.setFieldValue(airportFields.uniform, null);
    }
  };

  const handleFlightType = (value) => {
    clearFormFields();
  
    switch (value) {
      case "arrival":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 0);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_uniform_from", null);
        setAirportFields("to", dataAdminBandara);
        break;
      case "departure":
        formik.setFieldValue("list_available_services", []);
        formik.setFieldValue("flight_type", 1);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_to", null);
        setAirportFields("from", dataAdminBandara);
        break;
      case "transfer":
        formik.setFieldValue("list_available_services", product_list.filter(item => item.type === 4));
        formik.setFieldValue("flight_type", 2);
        formik.setFieldValue("airport_id_from", null);
        formik.setFieldValue("airport_id_to", null);
        formik.setFieldValue("airport_name_from", null);
        formik.setFieldValue("airport_name_to", null);
        formik.setFieldValue("airport_uniform_from", null);
        formik.setFieldValue("airport_uniform_to", null);
        formik.setFieldValue("airplane_code_transfer", null);
        formik.setFieldValue("airplane_name_transfer_to", null);
        formik.setFieldValue("airplane_number_transfer_to", null);
        formik.setFieldValue("date_transfer_to", null);
        formik.setFieldValue("time_transfer_to", null);
        setAirportFields("transfer", dataAdminBandara);
        break;
      default:
        formik.setFieldValue("flight_type", 0);
        break;
    }
  };

  const handleProductSelected = (item) => {
    let productType = formik.values.product_type;
    let selectedType = item?.type;
    let selectedName = item?.name;

    if (selectedType) {
      if (productType === selectedType) {
        selectedType = null;
        selectedName = null;
      }
    }
    formik.setFieldValue("available_service", selectedName);
    formik.setFieldValue("product_type", selectedType);
  };

  return (
    <Row>
      <Col md={9}>
        {showCrossSelling && (
          <Card rounded>
            <CardHeader title={t("field.cross_selling")} />
            <CardBody>
              <Checkbox
                name="is_cross_selling"
                desc={formik?.values?.airport_cross_selling}
                checked={formik?.values?.is_cross_selling}
                onChange={(e) => {
                  if (formik?.values?.is_cross_selling) {
                    formik?.setFieldValue("is_cross_selling", null);
                    setCheckedCrossSelling(false);
                    formik?.setFieldValue("airport_cross_selling", null);
                    setSearchAirportDomestic("");
                  } else {
                    formik.setFieldValue("is_cross_selling", ["1"]);
                    setCheckedCrossSelling(true);
                    formik?.setFieldValue(
                      "airport_cross_selling",
                      `${currentUser?.user?.airport_code} - ${
                        currentUser?.user?.airport_city} - ${
                        currentUser?.user?.airport_name}`
                    );
                    setSearchAirportDomestic("");
                    if (formik?.values?.flight_type === 0) {
                      formik.setFieldValue("airport_id_to", "");
                    } else if (formik?.values?.flight_type === 1) {
                      formik.setFieldValue("airport_id_from", "");
                    } else if (formik?.values?.flight_type === 2) {
                      formik.setFieldValue("airport_id_transfer", "");
                    }
                  }
                }}
                value={1}
                label={t("field.labelIsCrossSelling")}
                warning={t("commons.validationCrossSelling")}
              />
            </CardBody>
          </Card>
        )}
        <Card rounded>
          <CardHeader title={t("field.flightType")} />
          <CardBody>
            <div className="d-flex justify-content-left">
              <DynamicButton
                titleLeft={t("field.arrival")}
                iconLeft="flight_land"
                active={formik.values.flight_type === 0}
                toggle={() => handleFlightType("arrival")}
                errors={formik.error}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <DynamicButton
                titleLeft={t("field.departure")}
                iconLeft="flight_takeoff"
                active={formik.values.flight_type === 1}
                toggle={() => handleFlightType("departure")}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
              <DynamicButton
                titleLeft="Transfer"
                iconLeft="connecting_airports"
                iconLeftStyle="36px"
                active={formik.values.flight_type === 2}
                toggle={() => handleFlightType("transfer")}
                errors={formik.errors}
                touched={formik.touched}
                onBlur={formik.setFieldTouched}
              />
            </div>
          </CardBody>
        </Card>
        {formik.values.flight_type !== 2 ? (
          <Card rounded>
            <CardHeader title={t("field.flightInformation")} />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.origin")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_from"
                    options={
                      formik?.values?.flight_type !== 1
                        ? airport_list
                        : !checkedCrossSelling && haveAirport
                          ? dataAdminBandara
                          : airport_list_indo
                    }
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airport_id_from}
                    onInputChange={(keyword) => {
                      debouncedResults(
                        keyword,
                        formik?.values?.flight_type !== 1 ? "inter" : "domestic"
                      );
                    }}
                    onChange={(name, value) => {
                      if (formik.values?.airport_id_to === value) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      const list_airports =
                        formik?.values?.flight_type !== 1
                          ? dataAirport
                          : !checkedCrossSelling && haveAirport
                            ? dataAdminBandara
                            : dataAirportDomestic;
                      const index = list_airports
                          ?.map(function (e) {
                            return e.id;
                          })
                          .indexOf(value);

                      formik.setFieldValue("airport_id_from", value);
                      formik.setFieldValue(
                        "airport_name_from",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_from",
                        list_airports[index]?.uniform
                      );
                      formik?.setFieldValue(
                        formik?.values?.flight_type !== 1 
                          ? "selectedAirport" 
                          : "selectedAirportDomestic", 
                        {
                          value: value,
                          label: 
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-bold">{t("field.destination")}</div>
                <Col md={8}>
                  <Select2
                    name="airport_id_to"
                    errors={formik.errors}
                    options={
                      formik.values?.flight_type !== 0 
                      ? airport_list
                      : !checkedCrossSelling && haveAirport
                        ? dataAdminBandara
                        : airport_list_indo
                    }
                    touched={formik.touched}
                    value={formik.values?.airport_id_to}
                    onInputChange={(keyword) => {
                      debouncedResults(
                        keyword, 
                        formik.values?.flight_type !== 0 ? "inter" : "domestic"
                      );
                    }}
                    onChange={(name, value) => {
                      if (formik.values?.airport_id_from === value) {
                        toastError(`${t("field.cantSameAirport")}!`);
                        return;
                      }

                      const list_airports = 
                        formik.values?.flight_type !== 0 
                        ? dataAirport
                        : !checkedCrossSelling && haveAirport
                          ? dataAdminBandara
                          : dataAirportDomestic
                      const index = list_airports
                        ?.map(function (e) {
                          return e.id;
                        })
                        .indexOf(value);

                      formik.setFieldValue("airport_id_to", value);
                      formik.setFieldValue(
                        "airport_name_to",
                        `${list_airports[index]?.code} - ${
                          list_airports[index]?.city} - ${
                          list_airports[index]?.name}`
                      );
                      formik.setFieldValue(
                        "airport_uniform_to",
                        list_airports[index]?.uniform
                      );
                      formik?.setFieldValue(
                        formik.values?.flight_type !== 0 ? "selectedAirport" : "selectedAirportDomestic", 
                        {
                          value: value,
                          label:
                            `${list_airports[index]?.code} - ${
                              list_airports[index]?.city} - ${
                              list_airports[index]?.name} ${
                              list_airports[index]?.uniform === "Indonesia"
                                ? ""
                                : `- ${list_airports[index]?.uniform}`
                            }`,
                        }
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
              </div>
            </CardBody>
            <hr />
            <CardBody>
              <div className="row">
                <Col md={6}>
                  <Select2
                    title={t("field.airline")}
                    name="airplane_name"
                    options={airline_list}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.airplane_name}
                    onInputChange={(keyword) => {
                      if (keyword !== "") {
                        setSearchTextAirline(keyword);
                      }
                    }}
                    onChange={(name, value) => {
                      formik.setFieldValue("airplane_name", value);
                      let airplaneCode = airline_list.filter(function (el) {
                        return el.value == value;
                      });
                      formik.setFieldValue(
                        "airplane_code",
                        airplaneCode[0]?.code
                      );
                      formik.setFieldValue("selectedAirline", {
                        value: value,
                        code: airplaneCode[0]?.code,
                        label: `${airplaneCode[0]?.code} - ${value}`,
                      });
                    }}
                    onBlur={formik.setFieldTouched}
                  />
                </Col>
                <Col md={6}>
                  <div className="form-group" style={{ marginBottom: "0" }}>
                    <label
                      className="form-label"
                      style={{ marginBottom: "5px" }}
                    >
                      {t("field.flightNumber")}
                    </label>
                  </div>
                  <InputPrepend
                    name="airplane_number"
                    label={t("field.flightNumber")}
                    title={formik.values.airplane_code}
                    type="text"
                    width="25%"
                    mt="2"
                    touched={formik.touched}
                    {...formik.getFieldProps("airplane_number")}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values.flight_type !== 1
                        ? t("field.arrivalDate")
                        : t("field.departureDate")
                    }
                    name="date"
                    type="date"
                    min={
                      checkedCrossSelling
                        ? moment().add(1, "days").format("YYYY-MM-DD")
                        : minDate
                    }
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik?.getFieldProps("date")}
                    value={formik.values?.date}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    title={
                      formik.values.flight_type !== 1
                        ? t("field.arrivalTime")
                        : t("field.departureTime")
                    }
                    name="time"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.time}
                    onChange={(e) => {
                      let value = e.target.value;
                      formik.setFieldValue("time", value);
                    }}
                    type="time"
                  />
                </Col>
              </div>
            </CardBody>
          </Card>
        ) : (
          <TransferFlightInformation
            checkedCrossSelling={checkedCrossSelling}
            haveAirport={haveAirport}
            formik={formik}
            minDate={minDate}
            airline_list={airline_list}
            airline_transfer={airline_transfer}
            airport_list={airport_list}
            airport_list_indo={airport_list_indo}
            airport_list_transfer={airport_list_transfer}
            dataAdminBandara={dataAdminBandara}
            dataAirport={dataAirport}
            dataAirportDomestic={dataAirportDomestic}
            dataAirportTransfer={dataAirportTransfer}
            debouncedResults={debouncedResults}
            setSearchTextAirline={setSearchTextAirline}
            setSearchTextAirlineTransfer={setSearchTextAirlineTransfer}
          />
        )}
        <Card rounded>
          <CardHeader title={t("field.product")} />
          <CardBody>
            {(!formik?.values?.list_available_services ||
              formik?.values?.list_available_services?.length < 1) ? (
              <Card rounded>
                <CardBody isActive={false}>
                  <CardSelectionProduct
                    notAvailable
                    desc={t("field.notAvailable")}
                  />
                </CardBody>
              </Card>
            ) : (
              formik?.values?.list_available_services?.map((item, index) => {
                return (
                  <React.Fragment key={`service-${index}`}>
                    <div
                      onClick={() => handleProductSelected(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <Card rounded>
                        <CardBody
                          isActive={item?.type === formik?.values.product_type}
                        >
                          <CardSelectionProduct
                            selectedType={item?.type}
                            productType={formik?.values.product_type}
                            title={item?.label}
                            desc={t(
                              `product.${
                                item?.type === 3
                                  ? "detailFTDesc"
                                  : item?.type === 4
                                  ? "detailTransferDesc"
                                  : "detailMnGDesc"
                              }`
                            )}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </React.Fragment>
                );
              })
            )}
          </CardBody>
        </Card>
        {button()}
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ airport, corporate, airline, reg_price }) => {
  return { airport, corporate, airline, reg_price };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(
  connect(mapDispatchToProps, mapStateToProps)(FlightReservation)
);
